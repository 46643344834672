import BaseApi from '..';
import {
  BID_ACCEPT,
  BID_ADD,
  BID_DETAILS,
  BID_LIST,
  BID_PAYMENT_CONFIRM,
  BID_REFUSE,
  BID_REMOVE
} from '../endpoints';


export default class BidApi extends BaseApi {
  /* eslint-disable camelcase */

  static newBid = ({
    order_code,
    value,
    notes,
    schedule = {
      ready: '',
      deadline: ''
    }
  }) => this.post(BID_ADD, {
    order_code,
    value,
    notes,
    schedule
  });

  static bidDetails = ({
    bid_code
  }) => this.post(BID_DETAILS, {
    bid_code
  });

  static listBid = ({
    order_code,
    pages = {
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(BID_LIST, {
    order_code,
    pages
  });

  static acceptBid = ({
    bid_code
  }) => this.post(BID_ACCEPT, {
    bid_code
  });

  static refuseBid = ({
    bid_code,
    reason // optional
  }) => this.post(BID_REFUSE, {
    bid_code,
    reason
  });

  static removeBid = ({
    bid_code,
    reason // optional
  }) => this.post(BID_REMOVE, {
    bid_code,
    reason
  });

  static confirmPayment = ({
    ys_token
  }) => this.post(BID_PAYMENT_CONFIRM, {
    ys_token
  });
  /* eslint-enable camelcase */
}
