import React, {useEffect, useRef, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectUser } from 'store/slices/authentication';

import Button from '@youship/components/objects/button';

import './styles.scss';
import ShipmentOptionsOption from "../new-order/shipment-options/option";
import locationIcon from "../../images/icons/location.svg";
import userIcon from "../../images/icons/user.svg";
import removeIcon from "../../images/icons/trash--red.svg";

const DriverCard = ({
                      driver,
                      setShowRemoveModal,
                      setSelectedDriverCode,
                      handleEditDriver,
                      handleRemoveDriver,
                    }) => {

  return (
      <>
        <div className="new-order-shipment-options__route">
          <div>
            <ShipmentOptionsOption
                icon={driver.photo_url}
                title={driver.name}
                description={`${driver.phonecode} ${driver.phonenumber}`}
                onEdit={() => handleEditDriver(driver)}
                onDelete={() => handleRemoveDriver(driver)}
                type={"driver"}
            />
          </div>
        </div>
      </>
  )
};


DriverCard.defaultProps = {
  driver: null,
  full: false,
  hasCloseIcon: false,
  onClose: () => {},
};

export default DriverCard;
