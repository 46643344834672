const isInternalUrl = (url) => {
  const pageLocation = typeof window !== 'undefined' ? window?.location : null;

  if (pageLocation) {
    const URL_HOST_PATTERN = /(\w+:)?(?:\/\/)([\w.-]+)?(?::(\d+))?\/?/;
    const urlMatch = URL_HOST_PATTERN.exec(url) || [];
    const urlparts = {
      protocol: urlMatch[1] || '',
      host: urlMatch[2] || '',
      port: urlMatch[3] || ''
    };

    const defaultPort = (protocol) => {
      return { 'http:': 80, 'https:': 443 }[protocol];
    };

    const portOf = location => location.port || defaultPort(location.protocol || pageLocation.protocol);

    return !!((urlparts.protocol && (urlparts.protocol === pageLocation.protocol)) &&
      (urlparts.host && (urlparts.host === pageLocation.hostname)) &&
      (urlparts.host && (portOf(urlparts) === portOf(pageLocation)))
    );
  }

  return false;
};

export default isInternalUrl;
