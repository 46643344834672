import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FormattedMessage, useIntl} from 'react-intl';

import './styles.scss';


import {NestedListComponent} from "./NestedListComponent";
import {
    addCategory,
    addMenu,
    editCategory,
    editMenu,
    fetchAllMenus,
    fetchGetMenu, removeMenu
} from "../../../store/slices/products-management";
import Select from "../../../../../shared/components/objects/select";
import PropTypes from "prop-types";
import MenuCategoryModal from "../../products-modal/menu_category";
import category from "../../../views/category";
import {removeAddress} from "../../../store/slices/user-addresses";
import ActionModal from "../../order/action-modal";


const ProductsManagementArea = ({onChange}) => {

    const dispatch = useDispatch();
    const intl = useIntl();

    // Use an array of states to store each menu's data
    const [menuStates, setMenuStates] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null); // Set the initial selected menu
    const [dataModal, setDataModal] = useState(null); // Set the initial selected menu


    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [modalMessageTitle, setModalMessageTitle] = useState(null);
    const [showMenuCategoryModal, setShowMenuCategoryModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);
    const [isEdit, setisEdit] = useState(null);
    const [actionType, setActionType] = useState(null);

    const [showRemoveModal, setShowRemoveModal] = useState(false);
    
    const handleChange = (menu) => {
        setSelectedMenu(menu);
        setSelectedCode(menu.category_code)
        onChange(menu, menu.name);
    };

    function handleRefresh() {
        setRefresh(prevRefresh => !prevRefresh);
    }
    
    const handleNestedListInfo = (categoryCode, selectedCategory, isEditMode) => {
        setSelectedCode(categoryCode);
        setDataModal(selectedCategory);
        handleAddMenuCategoryButtonClick("category", isEditMode);
    };



    //Add or Edit Menu/Category
    const handleAddMenuCategoryButtonClick = (type, isEdit) => {
        if (isEdit) {
            if (type === "menu") {
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.edit-menu' }));
                setisEdit(true)
                setActionType("menu")
                setDataModal(selectedMenu);
            } else if (type === "category") {
                setisEdit(true)
                setActionType("category")
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.edit-category' }));
                
            }
        } else {
            if (type === "menu") {
                setisEdit(false)
                setActionType("menu")
                setDataModal(null)
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.add-menu' }));
                
            } else if (type === "category") {
                setisEdit(false)
                setActionType("category")
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.add-category' }));
            }
        }

        setShowMenuCategoryModal(true);
        
    };

    const handleMenuCategoryModal = (event, formData) => {
        event.preventDefault();

        const dispatchFunction = actionType === "menu" ? (isEdit ? editMenu : addMenu) : (isEdit ? editCategory : addCategory);
        const successMessageId = actionType === "menu" ? 'menu' : 'category';
        
        setErrorMessage(null);
        
        formData.category_code = selectedCode;

        dispatch(dispatchFunction(formData))
            .then((response) => {
                if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');
                return response;
            })
            .then((response) => {
                setErrorMessage(false);
                setModalMessageTitle(intl.formatMessage({ id: `${successMessageId}.${isEdit ? 'edit' : 'add'}` }));
                setSuccessMessage(intl.formatMessage({ id: `${successMessageId}.${isEdit ? 'edit_success' : 'add_success'}` }));
                
                setSelectedMenu(null)
                setSelectedCode(null)
                handleRefresh();
                return response;
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.message);
            });
    };

    const handleCloseMenuCategoryModal = () => {
        setShowMenuCategoryModal(false);
        setErrorMessage(null);
        setSuccessMessage(null);
    };


    useEffect(() => {
        dispatch(fetchAllMenus())
            .then((response) => {
                if (response?.error) {
                    throw new Error(response.error.message || 'Something went wrong while fetching shop all menus.');
                }
                if (response && response.payload && Array.isArray(response.payload)) {
                    // Loop through the menus and fetch each individual menu using fetchGetMenu
                    const promises = response.payload.map((menu) => {
                        return dispatch(fetchGetMenu(menu.category_code))
                            .then((menuResponse) => {
                                if (menuResponse && menuResponse.payload && Array.isArray(menuResponse.payload)) {
                                    // Assuming the response is an array of menu items, merge them with the original menu object
                                    const updatedMenu = {
                                        ...menu,
                                        items: menuResponse.payload,
                                    };
                                    console.log('Menu:', updatedMenu);
                                    
                                    return updatedMenu;
                                }
                                return menu;
                            })
                            .catch((menuError) => {
                                console.error('Error fetching menu:', menuError);
                                return menu; // In case of an error, return the original menu without modifying it
                            });
                    });

                    Promise.all(promises)
                        .then((updatedMenusData) => {
                            // Update the menusData state with the updated data
                            setMenuStates(updatedMenusData);
                            
                            if(selectedMenu == null){
                                if (updatedMenusData.length > 0)
                                    setSelectedMenu(updatedMenusData[0])
                                    setSelectedCode(menuStates[0].category_code)
                            }

                        })
                        .catch((error) => {
                            console.error('Error updating menusData:', error);
                        });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dispatch,refresh]);

    const handleRemoveModalConfirmButtonClick = (event) => {
        event.preventDefault();
        dispatch(removeMenu({
            category_code: selectedCode
        }))
            .then((response) => {

                setSelectedMenu(null)
                setSelectedCode(null)
                setShowRemoveModal(false);
                handleRefresh()
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleRemoveModalCloseButtonClick = () => {
        setShowRemoveModal(false);
    };


    // New function to handle menu drag and drop in the parent component
    const handleMenuDragEnd = (menuIndex, updatedMenu) => {
        // Find the correct menu state to update based on the menuIndex
        setMenuStates((prevMenuStates) => {
            const updatedStates = [...prevMenuStates];
            updatedStates[menuIndex] = { menuState: updatedMenu };
            return updatedStates;
        });
    };



    const header = (
        <>
            <div className="row" style={{display: "flex", alignItems: "center"}}>
                <div className="col-12" style={{flex: 1}}>
                    <h1 className="products-management__title">
                        <FormattedMessage id="products-management.title"/>
                    </h1>
                </div>
            </div>
        </>
    );




    // if (isLoadingAddressesList) {
    /*    return (
            <div className="products-management">
                <div className="container">
                    {header}
                    <div className="products-management__loader-wrapper">
                        <Loader/>
                    </div>
                </div>
            </div>
        );*/
    //}
    //else {
    return (
        <div className="products-management">
            <div className="container">
                {header}
                <div className="top">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: '25%'}}>
                            <Select
                                instructions={intl.formatMessage({ id: 'products-management.select_menu' })}
                                options={menuStates.map((menu) => ({
                                    text: menu.name,
                                    value: menu,
                                }))}
                                value={selectedMenu === null && menuStates.length > 0 ? menuStates[0] : selectedMenu}
                                onChange={(menu) => handleChange(menu)}
                            />
                        </div>

                        <span style={{marginLeft:'1rem', marginRight: '1rem',  cursor: 'pointer' }
                        }    onClick={() => handleAddMenuCategoryButtonClick("menu", false)}
                        >{intl.formatMessage({ id: 'products-management.add-menu' })
                        }</span>
                        {menuStates.length > 0 && (
                            <>
                                <span>|</span>
                                <span
                                    style={{ marginLeft: '1rem', cursor: 'pointer' }}
                                    onClick={() => handleAddMenuCategoryButtonClick("menu", true)}
                                >
                                {intl.formatMessage({ id: 'products-management.edit' })}
                                </span>
                                <span style={{ marginLeft: '1rem',}}>|</span>
                                 <span
                                    style={{ marginLeft: '1rem', cursor: 'pointer' }}
                                     onClick={() => {
                                         setShowRemoveModal(true);
                                     }}>
                                    {intl.formatMessage({ id: 'products-management.remove' })}
                                </span>
                            </>
                        )}

                    </div>
                    {selectedMenu && (
                        <NestedListComponent
                            refresh={refresh}
                            key={selectedMenu.category_code}
                            menuList={selectedMenu}
                            onNestedListInfo={handleNestedListInfo}
                        />
                    )}
                </div>
                {showMenuCategoryModal && (
                    <MenuCategoryModal
                        data={dataModal}
                        errorMessageModal={errorMessage}
                        finalMessage={successMessage}
                        title= {modalMessageTitle}
                        onClose={handleCloseMenuCategoryModal}
                        onSave={handleMenuCategoryModal}
                    />
                )}

                {showRemoveModal && (
                    <ActionModal
                        title={intl.formatMessage({id: 'menu.remove'})}
                        description={intl.formatMessage({id: 'menu.remove_menu_description'})}
                        back={intl.formatMessage({id: 'menu.go_back'})}
                        onClose={handleRemoveModalCloseButtonClick}
                        onConfirm={handleRemoveModalConfirmButtonClick}
                    />
                )}
            </div>
        </div>
    );
};

ProductsManagementArea.propTypes = {
    onChange: PropTypes.func
};

ProductsManagementArea.defaultProps = {
    onChange: () => { }
};

export default ProductsManagementArea;