import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../dropdown';

import './styles.scss';

const STATUS_DEFAULT = 'default';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

const Select = ({
  autoWidth,
  classNames,
  disabled,
  displaySelectedOptionAsIcon,
  horizontalPosition,
  icon,
  inputId,
  instructions,
  label,
  message,
  minWidth,
  name,
  noArrow,
  noBorder,
  noInstructions,
  noPadding,
  noVerticalPadding,
  onBlur,
  onChange,
  onClose,
  options,
  status,
  value
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const selectedOption = options.find(option => option.value === value);
  const selectedOptionText = selectedOption?.text || instructions;
  const selectedOptionIcon = selectedOption?.icon || null;

  const onDropdownClose = () => {
    setShowDropdown(false);
    onClose();
    onBlur();
  };

  const handleSelectClick = () => {
    if (!disabled) {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 768) {
        setShowDropdown(true);
      }
    }
  };

  const handleSelectKeyDown = (event) => {
    if (!disabled && event.keyCode === 13) {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 768) {
        setShowDropdown(true);
      }
    }
  };

  const handleSelectOnBlur = () => {
    onBlur();
    onClose();
  };

  const handleSelectedOptionClick = (option) => {
    if (!disabled) {
      setOption(option);
    }
  };

  const handleSelectedOptionKeyDown = (event, option) => {
    if (!disabled && event.keyCode === 13) {
      setOption(option);
    }
  };

  const handleDefaultSelectOptionClick = (value, index, hasInstructions) => {
    if (!disabled) {

      console.log("OptionClick: index=" + index + " v=" + value );
        //casos em que o value é numero na estrura de dados, e o value do select é sempre string,
        //tem de se ir buscar o value no formato correcto.
        if( hasInstructions ){
          //tem um option a mais
          value = options[index-1].value;
        }else{
          value = options[index].value;
        }
        console.log("value2=" + value);
        console.log(options);
     

      onChange(value);
    }
  };

  const setOption = (option) => {
    onChange(option);
    onClose();
    onBlur();
    setShowDropdown(false);
  };

  return (
    <div className={`select__wrapper${classNames ? ` ${classNames}` : ''}`}>
      {!!label && (
        <label
          className="select__label"
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div className="select__container">
        <div
          className={`select${
            disabled ? ' select--is-disabled' : ''}${
            showDropdown ? ' select--is-open' : ''}${
            (displaySelectedOptionAsIcon && selectedOptionIcon) || selectedOption || icon ? ' select--display-flex' : ' select--not-selected'}${
            noArrow ? ' select--no-arrow' : ''}${
            noBorder ? ' select--no-border' : ''}${
            noPadding ? ' select--no-padding' : ''}${
            noVerticalPadding ? ' select--no-vertical-padding' : ''}${
            icon ? ' select--has-icon' : ''}`}
          role="button"
          tabIndex={0}
          onClick={handleSelectClick}
          onKeyDown={handleSelectKeyDown}
        >
          {icon && (
            <div className="select__icon-wrapper">
              <img
                alt="User Icon"
                className="select__icon"
                src={icon}
              />
            </div>
          )}
          <span className={`select__selected-value${
            displaySelectedOptionAsIcon && selectedOptionIcon ? ' select--no-padding' : ''}${!icon ? ' select__selected-value--no-padding' : ''}`}
          >
            {displaySelectedOptionAsIcon && selectedOptionIcon ? (
              <img
                alt={selectedOptionText}
                className="select__toggler-icon"
                src={selectedOptionIcon}
              />
            ) : (
              selectedOptionText
            )}
          </span>
          <select
            className="select__default-select"
            disabled={disabled}
            id="filter-select"
            name={name}
            value={value}
            onBlur={handleSelectOnBlur}
            onChange={event => handleDefaultSelectOptionClick(event?.target?.value ?? '', event?.target?.selectedIndex ?? 0, !noInstructions ? true : false )}
          >
            {!noInstructions && (
              <option
                key={-1}
                disabled
                value=""
              >
                {instructions}
              </option>
            )}
            {options.map((option, index) => (
              <option
                key={index}
                disabled={disabled}
                value={option.value}
              >
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <Dropdown
          autoWidth={autoWidth}
          className="select-dropdown"
          fullWidth={!autoWidth}
          horizontalPosition={horizontalPosition}
          minWidth={minWidth}
          noBorder={noBorder}
          scrollable
          show={showDropdown}
          onClose={onDropdownClose}
        >
          <div className="select-dropdown__list">
            {options.map((option, index) => (
              <div
                key={index}
                className={`select-dropdown__list-item${option.value === value ? ' select-dropdown__list-item--selected' : ''}`}
                role="menuitem"
                tabIndex={0}
                onClick={() => handleSelectedOptionClick(option.value)}
                onKeyDown={event => handleSelectedOptionKeyDown(event, option.value)}
              >
                {option.icon && (
                  <img
                    alt={option.text}
                    className="select-dropdown__list-item-icon"
                    src={option.icon}
                  />
                )}
                {option.text}
              </div>
            ))}
          </div>
        </Dropdown>
      </div>
      {!!message && (
        <div className={`select__message${status === STATUS_ERROR ? ' select__message--error' : ''}${status === STATUS_SUCCESS ? ' select__message--success' : ''}`}>
          {message}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  autoWidth: PropTypes.bool,
  classNames: PropTypes.string,
  displaySelectedOptionAsIcon: PropTypes.bool,
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  inputId: PropTypes.string,
  instructions: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  minWidth: PropTypes.bool,
  noBorder: PropTypes.bool,
  noInstructions: PropTypes.bool,
  noPadding: PropTypes.bool,
  noVerticalPadding: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })),
  status: PropTypes.oneOf([STATUS_DEFAULT, STATUS_ERROR, STATUS_SUCCESS]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Select.defaultProps = {
  autoWidth: false,
  classNames: null,
  displaySelectedOptionAsIcon: false,
  horizontalPosition: null,
  inputId: 'input-id',
  instructions: 'Select an option',
  label: null,
  message: '',
  minWidth: false,
  noBorder: false,
  noInstructions: false,
  noPadding: false,
  noVerticalPadding: false,
  onBlur: () => {},
  onChange: () => {},
  onClose: () => {},
  options: [],
  status: STATUS_DEFAULT,
  value: ''
};

export default Select;
