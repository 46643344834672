import React from 'react';

import RegisterArea from 'components/sections/register-area';

const Register = () => (
  <>
    <RegisterArea />
  </>
);

export default Register;
