import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

//use selected or selectedValue to set the radio as selected
const RadioToggle = ({ onChange, options, selected, selectedValue }) => (
  <div className="radio-toggle">
    <div className="radio-toggle__content">
      {options.map(item => (
        <div
          key={item.id}
          className="radio-toggle__content-option"
        >
          <input
            className="radio-toggle__content-input"
            id={item.id}
            name="radio"
            type="radio"
            value={item.value}
          />
          <label
            className={`radio-toggle__content-input-label${ ( (selected !== null && selected + "" === item.id + "") || (selectedValue !== null && selectedValue+"" === item.value + "") ) ? ' radio-toggle__content-input-label--active' : ''}`}
            htmlFor={item.id}
            onClick={() => onChange(item.id, item.value)}
          >
            {!!item.icon && (
              <img
                className="radio-toggle__content-image"
                src={item.icon}
                alt={item.name}
              />
            )}
            {item.name} 
          </label>
        </div>
      ))}
    </div>
  </div>
);

RadioToggle.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    icon: PropTypes.string
  }))
};

RadioToggle.defaultProps = {
  onChange: () => {},
  options: []
};

export default RadioToggle;
