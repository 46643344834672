import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import { setDefaultDeliveryAddress } from 'store/slices/authentication';


import InputAddressSearch from '@youship/components/input-address-search';

import './styles.scss';

const DashboardHeader = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [address, setAddress] = useState(null);
  
  //texto no input
  const handleInputAddressSearch = (value) => {
    setAddress(value);
  };

  //apos escolher uma morada da lista do google
  const handleInputAddressChange = (formattedAddressData) => { 
    //console.log(formattedAddressData);
    //Guarda no state: state.curDeliveryAddress = address

    console.log("handleInputAddressChange:");
    console.log(formattedAddressData);

    dispatch(setDefaultDeliveryAddress({address: formattedAddressData }));
    setAddress(formattedAddressData.formattedAddress);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col dashboard-header__title">
            <FormattedMessage id="dashboard.address.title" />
        </div>
      </div>
      <div className="row">
        <div className="col dashboard-header__search">
          <InputAddressSearch
                placeholder={intl.formatMessage({ id: 'dashboard.address.address_search_input.placeholder' })}
                submitButtonText={intl.formatMessage({ id: 'dashboard.address.address_search_input.submit_button' })}
                value={address ? address : ""}
                onChange={handleInputAddressChange}
                onSearch={handleInputAddressSearch}
              />
        </div>
      </div>
      <div className="row">
        <div className="col dashboard-header__subtitle">
          <a href="/login" className='dashboard-header__link'>
            <FormattedMessage id="dashboard.address.sign_in" />
          </a>
        </div>
      </div>
    </div>
  );

};

DashboardHeader.propTypes = {
  title: PropTypes.string
};

DashboardHeader.defaultProps = {
  title: null
};

export default DashboardHeader;
