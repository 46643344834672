import React from 'react';
import PropTypes from 'prop-types';

import formatPrice from '@youship/utils/format-price';
import shortenString from '@youship/utils/shorten-string';

import './styles.scss';

const ShopProductCard = ({ description, disabled, imageUrl, name, onClick, price }) => {
  const trimmedDescription = shortenString(description === name ? null : description, 32);

  const handleOnClick = disabled ? () => {} : onClick;

  return (
    <div
      className={`shop-product-card${disabled ? ' shop-product-card--disabled' : ''}`}
      onClick={handleOnClick}
    >
      <div className="shop-product-card__info">
        {!!name && (
          <div className="shop-product-card__title">
            {name}
          </div>
        )}
        {!!trimmedDescription && (
          <div className="shop-product-card__description">
            {trimmedDescription}
          </div>
        )}
        {!!price && (
          <div className="shop-product-card__price">
            {price}
          </div>
        )}
      </div>
      {!!imageUrl && (
        <div className="shop-product-card__image-container">
          <img
            className="shop-product-card__image"
            src={imageUrl}
            alt="Item"
          />
        </div>
      )}
    </div>
  );
};

ShopProductCard.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.string
};

ShopProductCard.defaultProps = {
  description: null,
  disabled: false,
  imageUrl: null,
  name: null,
  onClick: () => {},
  price: null
};

export default ShopProductCard;
