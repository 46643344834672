import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { selectIsAuthenticated, selectRequirePhoneActivation } from 'store/slices/authentication';

import Button from 'components/objects/button';
import ImagePanel from '@youship/components/objects/image-panel';
import LoginForm from 'components/login/form';

import './styles.scss';

const LoginArea = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const requirePhoneActivation = useSelector(selectRequirePhoneActivation);

  const intl = useIntl();

  // Fallback if route protection fails:
  if (isAuthenticated && requirePhoneActivation) return <Redirect to="/register" />;

  const title = isAuthenticated ? intl.formatMessage({ id: 'login.already_signed' }) : intl.formatMessage({ id: 'login.sign_in' });

  return (
    <div className="login-area">
      <ImagePanel />
      <div className="container">
        <div className="row">
          <div className="col col-12 col-sm-6 offset-sm-6">
            <h1 className="login-area__title">
              {title}
            </h1>
            {/* Fallback if route protection fails: */}
            {isAuthenticated && (
              <div className="login-area__go-back-button-container">
                <Button
                  context="primary"
                  largeHorizontalPadding
                  noArrow
                  smallVerticalPadding
                  to="/"
                >
                  <FormattedMessage id="login.back" />
                </Button>
              </div>
            )}
            <div className="row">
              <div className="col col-12 col-lg-10">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginArea;
