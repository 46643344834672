import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectLocale, setLocale } from 'store/slices/locales';

import SharedLocaleSelect from '@youship/components/locale-select';

const LocaleSelect = ({ desktopSelector, setSelectedLanguage, showAsList }) => {
  const dispatch = useDispatch();
  const selected = useSelector(selectLocale);

  const handleChange = (value, icon, text) => {
    dispatch(setLocale(value));
    setSelectedLanguage(value, icon, text);
  };

  return (
    <SharedLocaleSelect
      desktopSelector={desktopSelector}
      selected={selected}
      showLanguageList={showAsList}
      onChange={handleChange}
    />
  );
};

LocaleSelect.propTypes = {
  desktopSelector: PropTypes.bool,
  setSelectedLanguage: PropTypes.func,
  showAsList: PropTypes.bool
};

LocaleSelect.defaultProps = {
  desktopSelector: false,
  setSelectedLanguage: () => {},
  showAsList: false
};

export default LocaleSelect;
