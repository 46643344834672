import React from 'react';
import PropTypes from 'prop-types';

import Button from '@youship/components/objects/button';

import { FormattedMessage, useIntl } from 'react-intl';

import './styles.scss';

const Banner = ({ buttons, classNames, title, text, image }) => {
  const intl = useIntl();

  return (
    <div className={`banner${classNames ? ` ${classNames}` : ''}`}>
      <img
        alt={intl.formatMessage({ id: `${title}` })}
        className="banner__image"
        src={image}
      />
      {title && (
        <h3 className="banner__title">
          <FormattedMessage id={title} />
        </h3>
      )}
      {text && (
        <div className="banner__text">
          <FormattedMessage id={text} />
        </div>
      )}
      {Array.isArray(buttons) && !!buttons.length && (
        <div className="banner__buttons">
          {buttons.map((buttonProps, index) => (
            <Button
              {...buttonProps}
              key={index}
              classNames="banner__button"
              text={intl.formatMessage({ id: `${buttonProps.text}` })}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Banner.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  classNames: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

Banner.defaultProps = {
  buttons: null,
  classNames: null,
  image: null,
  text: null,
  title: null
};

export default Banner;
