import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Modal from '@youship/components/objects/modal';

import './styles.scss';

const ContactModal = ({ companyName, contactName, contactPhoneNumber, countryCallingCode, taxId, onClose, onSave, showTaxField, title, subtitle }) => {
  
  const intl = useIntl();

  const [modalContact, setModalContact] = useState({
    countryCode: countryCallingCode,
    countryCallingCode: countryCallingCode,
    company: companyName,
    name: contactName,
    phone: contactPhoneNumber,
    tax: taxId
  });

  const handleEditContactModalSaveButton = () => {
    if (isContactValid) {
      if (modalContact.countryCode === "" ){
        modalContact.countryCallingCode = "351"
      }
      onSave(modalContact);
    }
  };

  const isContactValid = !!modalContact?.name  && !!modalContact?.phone;

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Modal
      footer={
        <Button
          context="primary"
          disabled={!isContactValid}
          largeHorizontalPadding
          text={intl.formatMessage({ id: 'new-order.contact.save' })}
          onClick={handleEditContactModalSaveButton}
        />
      }
      footerAlignRight
      title={title ?? intl.formatMessage({ id: 'new-order.contact.checkout.title' })}
      subtitle={subtitle ?? intl.formatMessage({ id: 'new-order.contact.checkout.subtitle' })}
      onClose={handleModalClose}
      onEnter={handleEditContactModalSaveButton}
    >
      <div className="contact-modal">
        <div className="row contact-modal__inputs-wrapper">
          { showTaxField ? (
          <>
          <div className="col col-6">
            <Input
              block
              inputId="input-name"
              label={intl.formatMessage({ id: 'new-order.contact.name.label' })}
              placeholder={intl.formatMessage({ id: 'new-order.contact.name.placeholder' })}
              value={modalContact?.name}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, name: value }));
              }}
            />
          </div>
          <div className="col col-6">
            <Input
              block
              inputId="input-tax-id"
              label={intl.formatMessage({ id: 'new-order.contact.vat.label' })}
              placeholder={intl.formatMessage({ id: 'new-order.contact.vat.placeholder' })}
              value={modalContact?.tax}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, tax: value }));
              }}
            />
          </div>
          </>
          ) : (
          <div className="col col-12">
            <Input
              block
              inputId="input-name"
              label={intl.formatMessage({ id: 'new-order.contact.name.label' })}
              placeholder={intl.formatMessage({ id: 'new-order.contact.name.placeholder' })}
              value={modalContact?.name}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, name: value }));
              }}
            />
          </div>
          )}
        </div>

        <div className="row contact-modal__inputs-wrapper">
          <div className="col col-12">
            <Input
              block
              inputId="input-company"
              label={intl.formatMessage({ id: 'new-order.contact.company.label' })}
              placeholder={intl.formatMessage({ id: 'new-order.contact.company.placeholder' })}
              value={modalContact?.company}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, company: value }));
              }}
            />
          </div>
        </div>
        <div className="contact-modal__inputs-wrapper">
          <div className="contact-modal__input-group">
            <Input
              block
              countryCallingCode={modalContact?.countryCode}
              inputId="input-phone"
              label={intl.formatMessage({ id: 'new-order.contact.phone.label' })}
              placeholder={intl.formatMessage({ id: 'new-order.contact.phone.placeholder' })}
              type="phone"
              value={modalContact?.phone}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, phone: value }));
              }}
              onCountryCallingCodeChange={(code) => {
                setModalContact(prevState => ({ ...prevState, countryCallingCode: code,  countryCode: code }));
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ContactModal.propTypes = {
  companyName: PropTypes.string,
  contactName: PropTypes.string,
  contactPhoneNumber: PropTypes.string,
  countryCallingCode: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  taxId: PropTypes.string,
  showTaxField: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

ContactModal.defaultProps = {
  companyName: null,
  contactName: null,
  contactPhoneNumber: null,
  countryCallingCode: null,
  onClose: () => {},
  onSave: () => {},
  showTaxField: false,
  taxId: null,
  title: null,
  subtitle: null
};

export default ContactModal;
