module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true
  },
  pt: {
    path: 'pt',
    locale: 'Português'
  }
};
