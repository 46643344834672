import React from 'react';

import ForgotPasswordArea from 'components/sections/forgot-password-area';

const ForgotPassword = () => (
  <>
    <ForgotPasswordArea />
  </>
);

export default ForgotPassword;
