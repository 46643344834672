import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import UserAddressesApi from '@youship/api/user-addresses';

const userAddressesListAdapter = createEntityAdapter();

const initialState = userAddressesListAdapter.getInitialState({
  isLoadingUserAddresses: false,
  pages: {
    current: 0,
    lastPage: 0
  }
});

// Thunks:
export const getAddressesList = createAsyncThunk(
    'userAddresses/getAddresses',
    payload => UserAddressesApi.addressList(payload)
        .then((response) => {
          if(response) {
            let addressesList = [];
            if (Array.isArray(response.rows)) {
              addressesList = response.rows.map((address, index) => ({
                ...address,
                id: index,
                address: {
                  ...address.address,
                  apartmentAddress: address.address.apartment_address ?? '',
                  countryCode: address.address.countrycode ?? '',
                  formattedAddress: address.address.formatted_address ?? '',
                  numberAddress: address.address.number_address ?? '',
                  postalCode: address.address.postalcode ?? '',
                  streetAddress: address.address.street_address ?? ''
                },
                addressCode: address.address_code,
                isDefault: address.isdefault,
                lineAddress: address.line_address,
                strAddress: address.str_address,

              }));
            }

            return {
              addressesList,
              pages: {
                current: response.pages?.current,
                lastPage: response.pages?.lastpage
              }
            }
          }

          return response;
        })
);

// Slice:

const userAddressesListSlice = createSlice({
  name: 'userAddressesList',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
        .addCase(getAddressesList.pending, (state) => {
          state.isLoadingUserAddresses = true;
        })
        .addCase(getAddressesList.fulfilled, (state, action) => {
          const response = action.payload;

          if (Array.isArray(response.addressesList)) {
            userAddressesListAdapter.setAll(state, response.addressesList);
            state.pages = response.pages;
          }
          state.isLoadingUserAddresses = false;
        })
        .addCase(getAddressesList.rejected, (state) => {
          state.isLoadingUserAddresses = false;
        });

  }
});

export default userAddressesListSlice.reducer;

// Selectors:

export const {
  selectAll: selectUserAddressesList
} = userAddressesListAdapter.getSelectors(state => state.userAddressesList);

export const selectPagesList = state => state.userAddressesList.pages;

export const selectIsLoadingUserAddressesList = state => state.userAddressesList.isLoadingUserAddresses;
