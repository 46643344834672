import React from 'react';
import PropTypes from 'prop-types';

import Disclaimer from 'components/disclaimer';
import ShopCard from 'components/shop-card';

const ShopList = ({ items }) => (
  <div className="row">
    {Array.isArray(items) && items.length ?
      items.map(item => (
        <div key={item.id} className="col col-12 col-sm-6 col-lg-4">
          <ShopCard {...item} />
        </div>
      )) : (
        <div className="col col-lg-12">
          <Disclaimer />
        </div>
      )
    }
  </div>
);

ShopList.propTypes = {
  items: PropTypes.array
};

ShopList.defaultProps = {
  items: []
};


export default ShopList;
