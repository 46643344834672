import React from 'react';

import OrderTypeList from 'components/sections/order-type-list';

const Categories = () => (
  <>
    <OrderTypeList />
  </>
);

export default Categories;
