import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@youship/components/objects/button';
import ImagePanel from '@youship/components/objects/image-panel';


import './styles.scss';

const ForgotPassword = () => (
  <div className="email-sent-area">
    <ImagePanel/>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-12 col-sm-6 offset-sm-6">
          <h1 className="email-sent-area__title">
            Email sent!
          </h1>
          <div className="row">
            <div className="col col-12 col-lg-10">
              <div className="email-sent-area__description">
                Check your joao@concealed.com inbox for instructions from
                us on how to reset your password. You may need to check your spam folder.
              </div>
              <Link to="/login">
                <Button
                  text="Back to Sign in"
                  context="primary"
                  largeHorizontalPadding
                  smallVerticalPadding
                />
              </Link>
              <div className="email-sent-area__instructions">
                Didn’t receive instructions?
                <Link
                  to="/forgot-password"
                  className="email-sent-area__instructions-link"
                >
                  Try again
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ForgotPassword;
