import axios from 'axios';

import locales from '@youship/i18n/locales';
import SessionStorageManager from '@youship/utils/SessionStorageManager';

const defaultLocale = Object.keys(locales).find(key => locales[key].default);

export default class BaseApi {
  static createHeaders = (customHeaders = {}) => ({
    Source: 'webapp',
    Language: SessionStorageManager.getLocale() || defaultLocale,
    'Content-Type': 'application/json',
    Appkey: SessionStorageManager.getAuthenticationToken(),
    ...customHeaders
  });

  static createUrl = path => `${SessionStorageManager.getApiBaseUrl()}${path}`;

  static delete = (path, params) => {
    const url = this.createUrl(path);
    const config = { params, headers: this.createHeaders() };

    return axios.delete(url, config)
      .then(response => response.data);
  };

  static get = (path, params) => {
    const url = this.createUrl(path);
    const config = { params, headers: this.createHeaders(), data: null };

    return axios.get(url, config)
      .then(response => response.data);
  };

  static post = (path, data = null, params, headers) => {
    const url = this.createUrl(path);
    const config = { params, headers: this.createHeaders(headers) };

    return axios.post(url, data, config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success) return response.data;
          if (response.data.message) throw response.data;

          return response.data;
        }

        throw response;
      });
  };

  static put = (path, data = null, params) => {
    const url = this.createUrl(path);
    const config = { params, headers: this.createHeaders() };

    return axios.put(url, data, config)
      .then(response => response.data);
  };
}
