import BaseApi from '..';
import {
  TRACK_DETAILS,
  TRACK_LIVE_TRACKING,
  TRACK_SEND_MESSAGE,
  TRACK_RATE_CUSTOMER,
  TRACK_RATE_SHOP,
  TRACK_RATE_TRANSPORTER,
  TRACK_RATE_USER
} from '../endpoints';

export default class TrackApi extends BaseApi {
  /* eslint-disable camelcase */

  static orderTracking = ({
    code
  }) => this.post(TRACK_DETAILS, {
    code
  });

  static orderLiveTracking = ({
    code
  }) => this.post(TRACK_LIVE_TRACKING, {
    code
  });

  static sendMessage = ({
    code,
    msg
  }) => this.post(TRACK_SEND_MESSAGE, {
    code,
    msg
  });

  static rateCustomer = ({
    code,
    rating,
    description
  }) => this.post(TRACK_RATE_CUSTOMER, {
    code,
    rating,
    description
  });

  static rateShop = ({
    code,
    rating,
    description
  }) => this.post(TRACK_RATE_SHOP, {
    code,
    rating,
    description
  });

  static rateTransporter = ({
    code,
    rating,
    description
  }) => this.post(TRACK_RATE_TRANSPORTER, {
    code,
    rating,
    description
  });

  static rateUser = ({
    code,
    rating,
    description
  }) => this.post(TRACK_RATE_USER, {
    code,
    rating,
    description
  });

  /* eslint-enable camelcase */
}
