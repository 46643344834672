import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import UserApi, { USER_TYPE_SHOP, USER_TYPE_TRANSPORTER } from '@youship/api/user';

const initialState = {
  isRequesting: false,
};


// Thunks:
/*
export const setWorkingStatus = createAsyncThunk(
  'user/setWorkingStatus',
  ({ working }) => UserApi.setWorkingStatus({working})
);
*/

export const removeUserAccount = createAsyncThunk(
  'user/removeUserAccount',
  () => UserApi.removeUserAccount()
);

// Slice:

const usersSlice = createSlice({
  name: 'users',

  initialState,

  reducers: {
  },
  /*
  extraReducers: (builder) => {
    builder
      //-- setWorkingStatus ----
      .addCase(setWorkingStatus.pending, (state) => {
        state.isRequesting = true;
      })
      .addCase(setWorkingStatus.fulfilled, (state, action) => {
        state.isRequesting = false;
        return action.response;
      })
      .addCase(setWorkingStatus.rejected, (state) => {
        state.isRequesting = false;
      })
  }
   */
});

export default usersSlice.reducer;

// Selectors:

//export const selectIsUserRequesting = state => state.users.isRequesting;