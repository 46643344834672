import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Dropdown from '@youship/components/objects/dropdown';

import chevronDownIcon from 'images/icons/chevron-down.svg';
import checkOrangeIcon from 'images/icons/check-orange.svg';

import normalShipmentMethodIcon from 'images/icons/shipment-methods/normal.svg';
import multipleDropoffsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-dropoffs.svg';
import multiplePickupsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-pickups.svg';
import multipleStopsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-stops.svg';

import './styles.scss';

const shipmentMethods = [
  {
    value: 'normal',
    name: 'new-order.shipment_methods.m1.name',
    description: 'new-order.shipment_methods.m1.description',
    icon: normalShipmentMethodIcon
  },
  {
    value: 'multiple_dropoffs',
    name: 'new-order.shipment_methods.m2.name',
    description: 'new-order.shipment_methods.m2.description',
    icon: multipleDropoffsShipmentMethodIcon,
    disabled: true
  },
  {
    value: 'multiple_pickups',
    name: 'new-order.shipment_methods.m3.name',
    description: 'new-order.shipment_methods.m3.description',
    icon: multiplePickupsShipmentMethodIcon,
    disabled: true
  },
  {
    value: 'multiple_stops',
    name: 'new-order.shipment_methods.m4.name',
    description: 'new-order.shipment_methods.m4.description',
    icon: multipleStopsShipmentMethodIcon,
    disabled: true
  }
];

const ShipmentMethodSelect = ({onChange, selectedValue, disabled}) => {
  //const intl = useIntl();
 
  const [shipmentMethod, setShipmentMethod] = useState(null);
  const [showShipmentMethodDropdown, setShowShipmentMethodDropdown] = useState(false);

  useEffect(() => {
    setShipmentMethod( getMethodByValue(selectedValue) );
  }, [selectedValue]);

  const getMethodByValue = (value) => {
    return shipmentMethods.find(entry => entry.value === value)
  };
  
  const handleShipmentMethodDropdownClose = () => {
    setShowShipmentMethodDropdown(false);
  };

  const handleOrderTypeTogglerClick = () => {
    setShowShipmentMethodDropdown(true);
  };

  const handleShipmentMethodClick = (method) => {
    setShipmentMethod({ ...method });
    setShowShipmentMethodDropdown(false);
    onChange(method.value)
  };
  
  return (
    <div className="new-order-shipment-options__shipment-method">
      <div
        className={`new-order-shipment-options__shipment-method-toggler${showShipmentMethodDropdown ? ' new-order-shipment-options__shipment-method-toggler--open' : ''}${disabled ? ' new-order-shipment-options__shipment-method-toggler--disabled' : ''}`}
        onClick={handleOrderTypeTogglerClick}
      >
        {shipmentMethod && (
        <>
        <div className="new-order-shipment-options__shipment-method-icon-wrapper">
          <img
            alt="Order Type Icon"
            src={shipmentMethod.icon}
          />
        </div>
        <div className="new-order-shipment-options__shipment-method-text">
          <FormattedMessage id={shipmentMethod.name} />
        </div>
        <div className="new-order-shipment-options__shipment-method-chevron">
          <img
            alt="Toggler Icon"
            src={chevronDownIcon}
          />
        </div>
        </>
        )}
      </div>
      <Dropdown
        autoWidth
        className="shipment-method-dropdown"
        show={showShipmentMethodDropdown}
        onClose={handleShipmentMethodDropdownClose}
      >
        <ul className="shipment-method-dropdown__list">
          {shipmentMethods.map((method, index) => (
            <li
              key={index}
              className={`shipment-method-dropdown__list-item${method.value === selectedValue ? ' shipment-method-dropdown__list-item--selected' : ''}${method.disabled ? ' shipment-method-dropdown__list-item--disabled' : ''}`}
              onClick={() => (!method.disabled ? handleShipmentMethodClick(method) : () => {})}
            >
              <img
                alt="Order Type icon"
                className="shipment-method-dropdown__list-icon"
                src={method.icon}
              />
              <div className="shipment-method-dropdown__text">
                <FormattedMessage id={method.name} />
                <div className="shipment-method-dropdown__description">
                  <FormattedMessage id={method.description} />
                </div>
              </div>
              {method.value === selectedValue && (
                <img
                  alt="Selected Type"
                  className="shipment-method-dropdown__selected-icon"
                  src={checkOrangeIcon}
                />
              )}
            </li>
          ))}
        </ul>
      </Dropdown>
    </div>
  );
};

ShipmentMethodSelect.propTypes = {
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool
};

ShipmentMethodSelect.defaultProps = {
  onChange: () => {},
  selectedValue: "normal",
  disabled: false
};

export default ShipmentMethodSelect;
