import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  createOrder,
  PRODUCT_OPTION_INPUT_TYPE_QUANTITY,
  removeItemByIndex,
  resetCheckoutData,
  resetShopId,
  resetShoppingCartItems,
  selectCheckoutQuote,
  selectCheckoutQuoteError,
  selectCheckoutQuoteIsLoading,
  selectCheckoutQuoteVoucherCode,
  selectDropoffSchedule,
  selectOrder,
  selectOrderError,
  selectOrderIsLoading,
  selectPaymentIsInProcess,
  selectPaymentTypeId,
  selectShopId,
  selectShoppingCartItems,
  setCustomizationByItemIndex,
  setVoucherCode,
  startPaymentProcess
} from 'store/slices/shopping-cart';
import { fetchShop, selectShopById } from 'store/slices/shops';

import formatPrice from '@youship/utils/format-price';

import ConfirmationModal from '@youship/components/confirmation-modal';
import Button from '@youship/components/objects/button';
import Notice from '@youship/components/objects/notice';

import ShoppingCartItemCustomizationModal from 'components/shopping-cart-item-customization-modal';
import ShoppingCartVoucherModal from 'components/shopping-cart-voucher-modal';

import trashIcon from 'images/icons/trash.svg';
import bagIcon from 'images/icons/bag.svg';
import locationIcon from 'images/icons/location.svg';
import clockIcon from 'images/icons/clock.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';

const ShoppingCart = ({ hasScrollBehavior, shopId }) => {
  const history = useHistory();
  const intl = useIntl();

  const dispatch = useDispatch();

  // selectCheckoutQuoteError fica com erros gerados com  throw new Error 
  // que são apanhados "".addCase(requestCheckoutQuote.rejected" e passa para a pagina no: state.checkoutQuoteError = action?.error

  const checkoutQuote = useSelector(selectCheckoutQuote);
  const checkoutQuoteError = useSelector(selectCheckoutQuoteError);
  const checkoutQuoteIsLoading = useSelector(selectCheckoutQuoteIsLoading);
  const checkoutQuoteVoucherCode = useSelector(selectCheckoutQuoteVoucherCode);

  const dropoffSchedule = useSelector(selectDropoffSchedule);
  const items = useSelector(selectShoppingCartItems);
  const order = useSelector(selectOrder);
  const orderError = useSelector(selectOrderError);
  const orderIsLoading = useSelector(selectOrderIsLoading);
  const paymentIsInProcess = useSelector(selectPaymentIsInProcess);
  const paymentTypeId = useSelector(selectPaymentTypeId);
  const shoppingCartShopId = useSelector(selectShopId);
  const shoppingCartShop = useSelector(state => selectShopById(state, shoppingCartShopId));

  const [cartItemToDelete, setCartItemToDelete] = useState(null);
  // Fixed shopping cart is temporarily disabled
  // eslint-disable-next-line no-unused-vars
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    if (!shoppingCartShop && shopId !== shoppingCartShopId && (shoppingCartShopId || typeof shoppingCartShopId === 'number')) {
      dispatch(fetchShop({ shopId: shoppingCartShopId }))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while fetching shop details.');

          return response;
        })
        .catch((error) => {
          // TODO: add proper error handling
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [dispatch, shopId, shoppingCartShop, shoppingCartShopId]);

  // Fixed shopping cart is temporarily disabled

  // useEffect(() => {
  //   if (hasScrollBehavior) {
  //     window.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [hasScrollBehavior]);

  // const handleScroll = () => {
  //   const scrollY = parseInt(document.documentElement.style.getPropertyValue('--scroll-y'), 0);

  //   setIsScrolling(scrollY > 280);
  // };

  const isCheckout = !!checkoutQuote || checkoutQuoteIsLoading || checkoutQuoteError;

  const shoppingCartIsEmpty = !items.length || (!shoppingCartShopId && shoppingCartShopId !== 'number');
  const checkoutIsDisabled = !checkoutQuote ||
    !!checkoutQuoteError ||
    checkoutQuoteIsLoading ||
    !dropoffSchedule?.date ||
    !dropoffSchedule?.timeSlotValue ||
    !paymentTypeId;

  const disableShoppingCart = isCheckout ? checkoutQuoteIsLoading || orderIsLoading : false;

  const disableSubmit = isCheckout ? (shoppingCartIsEmpty || checkoutIsDisabled || disableShoppingCart) : shoppingCartIsEmpty;

  const currencyCode = shoppingCartShop?.currencyCode;

  let shoppingCartPrice = 0;
  let formattedShoppingCartPrice = '–';

  const cartItems = items.map((item) => {
    const { choices, product, quantity } = item;
    const { options, price } = product;
    const choiceSummaryItems = [];
    let priceWithExtras = typeof price?.value === 'number' ? price.value : 0;

    // Using options.forEach() and option.extras.forEach() to add choice names in the original sorting order
    options.forEach((option) => {
      const { extras: optionExtras, id: optionId, input: optionInput } = option;

      if (choices[optionId]) {
        const extras = choices[optionId];

        if (extras) {
          optionExtras.forEach((optionExtra) => {
            const { id: extraId, name: extraName, price: extraPrice } = optionExtra;
            const extraQuantity = extras[extraId];

            if (extraQuantity) {
              if (extraName) choiceSummaryItems.push(`${optionInput === PRODUCT_OPTION_INPUT_TYPE_QUANTITY ? `${extraQuantity}× ` : ''}${extraName}`);
              if (typeof extraPrice?.value === 'number') priceWithExtras += extraPrice.value * extraQuantity;
            }
          });
        }
      }
    });

    // Adding value to shoppingCartPrice in this map() instead of dedicated calculation for optimization purposes
    shoppingCartPrice += priceWithExtras * quantity;

    return {
      ...item,
      choicesSummary: choiceSummaryItems.join(', '),
      formattedPriceWithExtras: formatPrice(priceWithExtras, currencyCode),
      priceWithExtras
    };
  });

  formattedShoppingCartPrice = formatPrice(shoppingCartPrice, currencyCode);

  const title = isCheckout ? intl.formatMessage({ id: 'shop.cart.title' }) : shoppingCartShop?.name;

  let submitButtonText = intl.formatMessage({ id: 'shop.cart.go_checkout' });

  if (isCheckout) submitButtonText = !!order && !orderIsLoading && !orderError ? intl.formatMessage({ id: 'shop.cart.submit_payment' })  :  intl.formatMessage({ id: 'shop.cart.submit_confirm' });

  const itemDeleteConfirmationModalTitle = intl.formatMessage({ id: 'shop.cart.delete_confirmation' }) + " " + ( cartItemToDelete?.product?.name ? cartItemToDelete.product.name : '');
  const itemDeleteConfirmationModalDescription = isCheckout && items.length <= 1 ?
      intl.formatMessage({ id: 'shop.cart.delete_confirmation_desc_last' }) :
      intl.formatMessage({ id: 'shop.cart.delete_confirmation_desc' });

  const handleItemDeleteClick = (item) => {
    setCartItemToDelete(item);
  };

  const handleItemDeleteConfirmationModalClose = () => {
    setCartItemToDelete(null);
  };

  const handleItemDeleteConfirmationModalConfirm = () => {
    if (typeof cartItemToDelete?.index === 'number') {
      dispatch(removeItemByIndex(cartItemToDelete.index));
      setCartItemToDelete(null);
    }
  };

  const handleItemEditClick = (item) => {
    if (typeof item?.index === 'number') dispatch(setCustomizationByItemIndex(item.index));
  };

  const handleVoucherCodeClick = () => {
    dispatch(setVoucherCode(checkoutQuoteVoucherCode || ''));
  };

  const handleSubmit = () => {
    if (!disableSubmit) {
      if (isCheckout) {
        if (!!order && !orderIsLoading && !orderError) {
          if (!paymentIsInProcess) dispatch(startPaymentProcess());
        } else {
          dispatch(createOrder())
            .then((response) => {
              if (response?.error) throw new Error(response.error.message || 'Something went wrong while creating your order.');

              if (response?.payload) {
                
                const { id, paymentUrlWithReturn } = response?.payload;

                if (paymentUrlWithReturn) {
                  //history.replace(`/order/${id}`);
                  //window.location = paymentUrlWithReturn;
                  window.location.replace(paymentUrlWithReturn);
                } else {
                  //history.push(`/order/${id}`);
                  window.location.replace(`/order/${id}`);
                }

                // Clean shopping cart
                /*
                dispatch(resetShoppingCartItems());
                dispatch(resetShopId());
                dispatch(setVoucherCode(''));
                */
                dispatch(resetCheckoutData());

              }

              return response;
            })
            .catch((error) => {
              // TODO: add proper error handling
              // eslint-disable-next-line no-console
              console.error(error);
            });
        }
      } else {
        history.push('/checkout');
      }
    }
  };

  return (
    <>
      <div className={`shopping-cart${
        disableShoppingCart ? ' shopping-cart--disabled' : ''}
      }`}
      >
        <div className="shopping-cart__header">
          {!!title && (
            <div className="shopping-cart__title">
              {title}
            </div>
          )}
          {!isCheckout && (
            <div className="shopping-cart__quantity">
              {items.length}
              {' '}
              <FormattedMessage id="shop.cart.items" />
            </div>
          )}
          {isCheckout && (
            <>
              <div className="shopping-cart__details">
                <div className="shopping-cart__details-item">
                  <div className="shopping-cart__details-item-text-wrapper">
                    <img
                      alt="Bag Icon"
                      className="shopping-cart__details-item-icon"
                      src={bagIcon}
                    />
                     <FormattedMessage id="shop.cart.from" />
                    <span className="shopping-cart__details-item-highlight">
                      {shoppingCartShop?.name || '–'}
                    </span>
                  </div>
                </div>
                <div className="shopping-cart__details-item">
                  <img
                    alt="Clock Icon"
                    className="shopping-cart__details-item-icon"
                    src={clockIcon}
                  />
                  <FormattedMessage id="shop.cart.fulfillment" />
                 
                  <span className="shopping-cart__details-item-highlight">
                      {shoppingCartShop?.fulfillment || '– min'}
                  </span>
                </div>
                <div className="shopping-cart__details-item">
                  <img
                    alt="Location Icon"
                    className="shopping-cart__details-item-icon"
                    src={locationIcon}
                  />
                  {shoppingCartShop?.address?.formattedAddress || 'To –'}
                </div>
              </div>
              <div className="shopping-cart__divider" />
            </>
          )}
        </div>
        <div className="shopping-cart__items">
          {cartItems.length ?
            cartItems.map((item, index) => {
              const { choicesSummary, formattedPriceWithExtras, product, quantity } = item;
              const { name } = product;

              return (
                <div
                  key={index}
                  className="shopping-cart__item"
                >
                  <div className="shopping-cart__item-quantity">
                    {quantity || 0}
                  </div>
                  <div className="shopping-cart__item-details">
                    <div className="shopping-cart__item-details-header">
                      <div className="shopping-cart__item-name">
                        {name}
                      </div>
                      <img
                        alt="Delete Item"
                        className="shopping-cart__item-delete-icon"
                        src={trashIcon}
                        onClick={() => handleItemDeleteClick(item)}
                      />
                    </div>
                    {!!choicesSummary && (
                      <div className="shopping-cart__item-description">
                        {choicesSummary}
                      </div>
                    )}
                    <div className="shopping-cart__item-price">
                      {formattedPriceWithExtras}
                      <div
                        className="shopping-cart__item-edit"
                        onClick={() => handleItemEditClick(item)}
                      >
                        <FormattedMessage id="shop.cart.edit" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) : (
              <div className="shopping-cart__empty">
                <FormattedMessage id="shop.cart.empty" />
              </div>
            )}
        </div>
        <div className="shopping-cart__divider" />
        <div className="shopping-cart__footer">
          {isCheckout ? (
            <>
              {checkoutQuoteIsLoading ? (
                <div className="shopping-cart__footer-item">
                  <div className="shopping-cart__footer-item-name">
                    <FormattedMessage id="shop.cart.loading" />
                  </div>
                </div>
              ) : (
                <>
                  <div className="shopping-cart__footer-item">
                    <div className="shopping-cart__footer-item-name">
                      <FormattedMessage id="shop.cart.discount_code" />
                    </div>
                    <div
                      className="shopping-cart__footer-item-voucher-code-trigger"
                      onClick={handleVoucherCodeClick}
                    >
                      {checkoutQuoteVoucherCode || intl.formatMessage({ id: 'shop.cart.discount_add' }) }
                    </div>
                  </div>
                  {checkoutQuoteError || orderError ? (
                    <div className="shopping-cart__footer-item">
                      <div className="shopping-cart__footer-item-name">
                        <Notice
                          classNames="shopping-cart__notice"
                          description={checkoutQuoteError?.message || orderError?.message }
                          fixed
                          icon={noticeIcon}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {Array.isArray(checkoutQuote?.priceDetails) && !!checkoutQuote.priceDetails.length && checkoutQuote.priceDetails.map((entry, index) => (
                        <div
                          key={index}
                          className="shopping-cart__footer-item"
                        >
                          <div className="shopping-cart__footer-item-name">
                            {entry.text}
                          </div>
                          <div className="shopping-cart__footer-item-value">
                            {entry.svalue}
                          </div>
                        </div>
                      ))}
                      {!!checkoutQuote?.total && (
                        <div className="shopping-cart__footer-item">
                          <div className="shopping-cart__footer-item-name">
                            <FormattedMessage id="shop.cart.total" />
                            {!!checkoutQuote.total.text && (
                              <div className="shopping-cart__footer-item-note">
                                {checkoutQuote.total.text}
                              </div>
                            )}
                          </div>
                          <div className="shopping-cart__footer-item-value">
                            {checkoutQuote.total.svalue}
                          </div>
                        </div>
                      )}
                      {(orderIsLoading || !!orderError || !!order) && (
                        <div className="shopping-cart__footer-item">
                          <div className="shopping-cart__footer-item-name">
                            {orderIsLoading && intl.formatMessage({ id: 'shop.cart.creating_order' }) }
                            {!orderIsLoading && !!orderError && intl.formatMessage({ id: 'shop.cart.error_creating_order' }) }
                            {!orderIsLoading && !orderError && !!order && intl.formatMessage({ id: 'shop.cart.success_creating_order' }) }
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="shopping-cart__footer-item">
              <div className="shopping-cart__footer-item-name">
                <FormattedMessage id="shop.cart.total" />
              </div>
              <div className="shopping-cart__footer-item-value">
                {formattedShoppingCartPrice}
              </div>
            </div>
          )}
          <div className="shopping-cart__divider" />
          <div className="shopping-cart__button-wrapper">
            <Button
              block
              context="primary"
              disabled={disableSubmit}
              text={submitButtonText}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
      {!!cartItemToDelete && (
        <ConfirmationModal
          description={itemDeleteConfirmationModalDescription}
          title={itemDeleteConfirmationModalTitle}
          onClose={handleItemDeleteConfirmationModalClose}
          onConfirm={handleItemDeleteConfirmationModalConfirm}
        />
      )}
      <ShoppingCartItemCustomizationModal />
      <ShoppingCartVoucherModal />
    </>
  );
};

export default ShoppingCart;
