import React from 'react';

import OrderType from 'templates/order-type';

const Category = props => (
  <>
    <OrderType {...props} />
  </>
);

export default Category;
