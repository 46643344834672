import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/slices/authentication';

import NewOrderDetails from 'components/sections/new-order-details';
import NewOrderPublicDetails from 'components/sections/new-order-public-details';

const NewOrder = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  
  return (
    <>
      {isAuthenticated ? (
        <NewOrderDetails />
      ):(
        <NewOrderPublicDetails />  
      )}
    </>
  );
};

export default NewOrder;
