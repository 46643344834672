import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '@youship/components/objects/modal';

import clockIcon from 'images/icons/clock.svg';
import markerIcon from 'images/icons/marker.svg';

import './styles.scss';

const GOOGLE_MAPS_API_KEY =  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ShopDetailsModal = ({ coordinates, name, onClose, schedule, show }) => {

  const intl = useIntl();
  
  if (!show || (!coordinates && !name && !schedule)) return null;
  
  return (
    <Modal
      title={ intl.formatMessage({ id: 'shop.info.title' })}
      onClose={onClose}
    >
      <div className="shop-details-modal">
        {!!coordinates && (
          <div className="shop-details-modal__map-wrapper">
            <img
              className="shop-details-modal__map"
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                coordinates.latitude
              },${
                coordinates.longitude
              }&zoom=13&size=600x300&maptype=roadmap&key=${GOOGLE_MAPS_API_KEY}`}
              alt={ intl.formatMessage({ id: 'shop.info.location' })}
            /> 
            <img
              className="shop-details-modal__marker"
              src={markerIcon}
              alt="Location Marker"
            />
            {!!name && (
              <div className="shop-details-modal__map-location">
                {name}
              </div>
            )}
          </div>
        )}
        {Array.isArray(schedule) && !!schedule.length && (
          <div className="shop-details-modal__text-wrapper">
            <img
              className="shop-details-modal__icon"
              src={clockIcon}
              alt="Schedule"
            />
            <div className="shop-details-modal__text">
              <div className="shop-details-modal__text-title">
                <FormattedMessage id="shop.info.schedule" />
              </div>
              <div className="shop-details-modal__text-info">
                {schedule.map((timeSlot, timeSlotIndex) => (
                  <div
                    key={timeSlotIndex}
                    className="shop-details-modal__text-info-item"
                  >
                    {!!timeSlot.day && (
                      <div className="shop-details-modal__text-info-title">
                        {timeSlot.day}
                      </div>
                    )}
                    {!!timeSlot.intervals && timeSlot.intervals.length && (
                      <div className="shop-details-modal__text-info-description">
                        {timeSlot.intervals.map((interval, intervalIndex) => (
                          <span key={intervalIndex}>
                            {interval}
                            <br />
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

ShopDetailsModal.propTypes = {
  coordinates: PropTypes.shape({
    latitude: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    longitude: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }),
  name: PropTypes.string,
  onClose: PropTypes.func,
  schedule: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string,
    intervals: PropTypes.arrayOf(PropTypes.string)
  }))
};

ShopDetailsModal.defaultProps = {
  coordinates: null,
  name: null,
  onClose: () => {},
  schedule: null
};

export default ShopDetailsModal;
