import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';

import SignatureCanvas from 'react-signature-canvas'

import Input from '@youship/components/objects/input';
import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
//import Textarea from '@youship/components/objects/textarea';
import Select from '@youship/components/objects/select';
import SwitchGroup from '@youship/components/objects/switch-group';

import cameraIcon from 'images/icons/camera.svg';
import pencilIcon from 'images/icons/pencil.svg';
import trashIcon from 'images/icons/trash.svg';

import './styles.scss';

/*
https://github.com/agilgur5/react-signature-canvas
*/

const CollectModal = ({
  isLoading,
  errorMessageModal,
  text,
  title,
  actionType,
  onClose,
  onSave
}) => {

  const intl = useIntl();


  const resultOptions = [
    {
      text: intl.formatMessage({ id: 'app.yes'}),
      value: true
    },
    {
      text:  intl.formatMessage({ id: 'app.no'}),
      value: false
    }
  ];

  const signatureTypeList = [
    {
      id: 'screen',
      name: intl.formatMessage({ id: 'order.proof.signature_screen'}),
      icon: pencilIcon
    },
    {
      id: 'photo',
      name: intl.formatMessage({ id: 'order.proof.signature_photo'}),
      icon: cameraIcon
    }
  ];
  
  const [errorMessage, setErrorMessage] = useState( errorMessageModal || null);

  const [isFormValid, setIsFormValid] = useState(false);
  const [signatureType, setSignatureType] = useState('screen');

  const sigCanvas = useRef({});
  
  const [proof, setProof] = useState(
            { 
              result: null,
              name: null, 
              comments: null, 
              signature: null,
              signature_filename: null,
            }
      );

  const handleInputChange = (value, name) => { 
    if( name === 'signature_file' ){
      //obtem base64 do ficheiro escolhido
      //let file = e.target.files[0];
      let file   = value;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        /*
        this.setState({
          file: file,
          base64: reader.result
        });
        this.handleSubmit()
        */
        setProof( { ...proof, signature: reader.result, signature_filename: file.name } );
        //console.log(reader.result);
      };
    }else{
      //console.log(value);
      setProof( { ...proof, [name]: value } );
    }
  };

  const handleSignatureTypeClick = (signatureType) => {
    setSignatureType(signatureType);
  };

  /*
  const setCanvasSignatureData = () => {
    if( sigCanvas.current != null){
      if( proof.signature != null && proof.signature !== "" ){
        sigCanvas.current.clear();
        sigCanvas.current.fromDataURL(proof.signature);
      }
    }
  };
  */

  // tem efeito qd existir uma alteracao no proof
  useEffect(() => {

    setIsFormValid(false);

    if( proof.result != null ){
      if( proof.result === true ){
        if( proof.name != null && proof.name !== "" &&
            proof.signature != null && proof.signature !== ""
         ){
          setIsFormValid(true);
        }
      }else{
        if( proof.comments != null && proof.comments !== "" ){
          setIsFormValid(true);
        }
      }
    }
  
  }, [proof]);

  const handleCollectSaveClick = (event) => {
    event.preventDefault();

    setErrorMessage(null);
    
    onSave(event, actionType, proof);
  };
  
 
  return (
    <Modal
      classNames="collect-modal"
      footer={
        <div className="collect-modal__footer">
          { (errorMessage || errorMessageModal) && (
            <p className="collect-modal__error-message">
              { errorMessage || errorMessageModal }
            </p>
          )}
          <Button
            classNames="collect-modal__button"
            context="primary"
            disabled={isLoading || !isFormValid}
            largeHorizontalPadding
            text={intl.formatMessage({ id: 'order.action.confirm' })}
            onClick={!isLoading ? event => handleCollectSaveClick(event) : () => {}}
          />
        </div>
      }
      title={text || title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="collect-modal__content">
        
      <div className="collect-modal__item">
          <Select
              label={ intl.formatMessage({ id: 'order.proof.result.title' }) + " (*)" }
              instructions={title ? title : intl.formatMessage({ id: 'order.proof.result.placeholder' })}
              options={resultOptions}
              value={proof.result}
              onChange={value => handleInputChange(value, 'result')}
              
            />
        </div>

        <div className="collect-modal__item">
          <Input
              block
              label={intl.formatMessage({ id: 'order.proof.name.title' }) + (proof.result === true ? " (*)" : "") }
              placeholder={intl.formatMessage({ id: 'order.proof.name.placeholder' })}
              type="text"
              value={proof.name}
              onChange={e => handleInputChange(e.target.value, 'name')}
            />
        </div>

        <div className="collect-modal__item">
          <Input
              block
              label={intl.formatMessage({ id: 'order.proof.comments.title' }) + (proof.result === false ? " (*)" : "") }
              placeholder={intl.formatMessage({ id: 'order.proof.comments.placeholder' })}
              inputRows="2"
              type="textarea"
              value={proof.comments}
              onChange={e => handleInputChange(e.target.value, 'comments')}
            />
        </div>

        <div className="collect-modal__item collect-modal__item_signature">
          <label class="input__label">
            <FormattedMessage id="order.proof.signature.title" /> { proof.result === true ? " (*)" : ""}
          </label>     

          <SwitchGroup
              items={signatureTypeList}
              selected={signatureType}
              className="collect-modal__switch"
              onItemClick={handleSignatureTypeClick}
            />
         </div>

        {signatureType === 'screen' && (
          <div className="collect-modal__item collect-modal__item_canvas">
              <img
                  alt="Delete Icon"
                  className="collect-modal__clear-icon"
                  src={trashIcon}
                  onClick={
                    () => (sigCanvas.current.clear())
                  }
                />  
              <SignatureCanvas 
                 penColor='#161633'
                 ref={sigCanvas}
                 canvasProps={{height: 250, className: 'collect-modal__canvas'}} 
                 redrawOnResize
                 onEnd={ () => {
                    handleInputChange(sigCanvas.current.toDataURL(), 'signature')
                }}
              />
            
          </div>
         )}

       {signatureType === 'photo' && (
          <div className="collect-modal__item">
            <Input
                block
                fileName={ proof.signature_filename || intl.formatMessage({ id: 'order.proof.signature.placeholder' }) || 'Choose File'}
                inputId="upload-signature"
                type="file"
                onChange={e => handleInputChange(e.target.files[0], 'signature_file')}
              />
          </div>
        )}
        
      </div>
    </Modal>
  );
};

CollectModal.propTypes = {
  errorMessageModal: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

CollectModal.defaultProps = {
  errorMessageModal: null,
  isLoading: false,
  onClose: () => {},
  onSave: () => {},
  schedule: null
};

export default CollectModal;
