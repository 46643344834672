const FormatAddressToApi = (address) => {
  if (!address.address) return {};

  const { apartmentAddress } = address.address;
  const { countryCode } = address.address;
  const { formattedAddress } = address.address;
  const { numberAddress } = address.address;
  const { postalCode } = address.address;
  const { streetAddress } = address.address;

  /* eslint-disable camelcase */
  return {
    ...address,
    address: {
      ...address.address,
      apartment_address: apartmentAddress,
      countrycode: countryCode,
      formatted_address: formattedAddress,
      number_address: numberAddress,
      postalcode: postalCode,
      street_address: streetAddress
    }
  };
};

const FormatShipmentToApi = (shipment) => {
  const {
    reference,
    comments,
    shipper,
    fragile,
    handDelivery,
    marketing,
    chargeValue,
    chargeInfo,
    statedValue,
    insuredValue
  } = shipment;

  return {
    chargeinfo: chargeInfo || '',
    chargevalue: Number(chargeValue) || '',
    comments,
    fragile,
    handdelivery: handDelivery || false,
    insuredvalue: insuredValue || '',
    marketing,
    shipper,
    reference,
    return: shipment?.return || false,
    statedvalue: statedValue || ''
  };
};

export {
  FormatAddressToApi,
  FormatShipmentToApi
};
