import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { setWorkingStatus, selectIsUpdatingUser } from 'store/slices/authentication';

import LocaleSelect from 'components/locale-select';
import AlertBasic from 'components/objects/alert-basic';

import avatarImage from '@youship/assets/images/avatar.jpg';
import closeIcon from '../../images/icons/close.svg';
import playIcon from '../../images/icons/play.svg';
import pauseIcon from '../../images/icons/pause.svg';

import './styles.scss';
import Cookies from "universal-cookie";
import UserApi from "../../../../shared/api/user";
import greenCheckIcon from "../../images/icons/check-green.svg";
import Notice from "../../../../shared/components/objects/notice";
import UserModal from "../user-modal";

//teste

const AccountBar = ({
                        currentLanguage,
                        hideLanguageOptionsList,
                        isLanguageMenuVisible,
                        setCurrentLanguage,
                        showLanguageOptionsList,

                        isMobileMenu,
                        handleNavbarChangeAddress,
                        handleNavbarChangeAddressKeyDown,
                        user,

                        onClose,
                        onLogoutClick,
                        keepAccountDropdownOpenned,
                        refresh

                    }) => {

    const name = user?.name || [user?.firstname, user?.lastname].join(' ');
    const imageUrl = user?.photo_url || avatarImage;
    const email = user?.email || '';
    const isWorkingBtn = (user && (user?.type === 'shop') ) ? true : false;
    const isWorking = (user?.options?.working) ? true : false;

    const dispatch = useDispatch();
    const isPerformingAction = useSelector(selectIsUpdatingUser);
    const [isKeepDropdownOpenned, setIsKeepDropdownOpenned] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

    const [menuItems, setMenuItems] = useState([]);

    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const [showMessageModal, setShowMessageModal] = useState(false);

    useEffect(() => {
        UserApi.resume()
            .then((response) => {
                if (response?.user) {
                    setMenuItems(response.user.options.menu.bar)
                }
            })
            .catch((error) => {
                // TODO: Set proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
            });


    }, [currentLanguage]);

    function handleCloseMessageModal(){
        setShowMessageModal(false)
    }


    const showModal = useCallback((menuItem) => {

        if (menuItem.action === "checkin") {

            UserApi.checkinUser()
                .then((response) => {
                    setType("Check-in")
                    setMessage(response.message)
                    setShowMessageModal(true)
                })
                .catch((error) => {
                    // TODO: Set proper error handling
                    // eslint-disable-next-line no-console
                    console.log(error);
                });

        } else if (menuItem.action === "checkout") {


            UserApi.checkoutUser()
                .then((response) => {
                    setType("Check-out")
                    setMessage(response.message)
                    setShowMessageModal(true)
                })
                .catch((error) => {
                    // TODO: Set proper error handling
                    // eslint-disable-next-line no-console
                    console.log(error);
                });

        }
    }, []);
    /*
        function showModal(menuItem) {
    
            if (menuItem.action === "checkin") {
    
                UserApi.checkinUser()
                    .then((response) => {
                        setType("Check-in")
                        setMessage(response.message)
                        setShowMessageModal(true)
                    })
                    .catch((error) => {
                        // TODO: Set proper error handling
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
    
            } else if (menuItem.action === "checkout") {
    
    
                UserApi.checkoutUser()
                    .then((response) => {
                        setType("Check-out")
                        setMessage(response.message)
                        setShowMessageModal(true)
                    })
                    .catch((error) => {
                        // TODO: Set proper error handling
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
    
            }
        }*/

    useEffect(() => {
        //ao alterar dados no menu, o menu é fechado, assim volta a abrir
        if( isKeepDropdownOpenned ){
            keepAccountDropdownOpenned(isMobileMenu);
        }
    }, [isPerformingAction, isKeepDropdownOpenned]);




    const handleSetWorking = (event, working) => {
        event.stopPropagation();

        setIsKeepDropdownOpenned(true);
        setErrorMessage(null);

        dispatch(setWorkingStatus({ working }))
            .then((response) => {
                if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

                setIsKeepDropdownOpenned(false);

                return response;
            })
            .catch((error) => {
                // NOTE: Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);

                setErrorMessage(error?.message);

                setIsKeepDropdownOpenned(false);
            });



    };

    const accountAvatar = () => {
        return (
            (!!imageUrl || !!name || !!email) && (
                <div className="account-bar__header">
                    {!!imageUrl && (
                        <div className="account-bar__avatar">
                            <img
                                alt="Avatar"
                                className="account-bar__avatar-image"
                                src={imageUrl}
                            />
                        </div>
                    )}
                    {(!!name || !!email) && (
                        <div className="account-bar__header-info">
                            {!!name && (
                                <div className="account-bar__name">
                                    {name}
                                </div>
                            )}
                            {!!email && (
                                <div className="account-bar__email">
                                    {email}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        )
    };


    if(showMessageModal){
        return(
            <UserModal
                finalMessage={message}
                title={type}
                onClose={handleCloseMessageModal}
            />

        )
    }

    if( isLanguageMenuVisible ) {
        return (
            <div className={`account-bar${isMobileMenu ? ` account-bar-mobile` : ''}`}>
                <ul className="account-bar__list">
                    <li className="account-bar__list-item">
                        {accountAvatar()}
                    </li>
                    <li
                        className="account-bar__list-item account-bar__list-item--has-back-chevron"
                        onClick={hideLanguageOptionsList}
                    >
                        <FormattedMessage id="header.back" />
                    </li>
                    <LocaleSelect
                        desktopSelector
                        setSelectedLanguage={setCurrentLanguage}
                        showAsList
                    />
                </ul>
            </div>
        );
    }

    return (
        <div className={`account-bar${isMobileMenu ? ` account-bar-mobile` : ''}`}>
            <ul className="account-bar__list">

                { isMobileMenu && (
                    <div className="navbar__close-icon">
                        <img
                            alt="Close Icon"
                            src={closeIcon}
                            onClick={onClose}
                        />
                    </div>
                )}

                <li className="account-bar__list-item">
                    {accountAvatar()}
                </li>
                <li
                    className="account-bar__list-item account-bar__list-item--has-chevron"
                    onClick={showLanguageOptionsList}
                >
                    <img
                        alt="Language Icon"
                        className="account-bar__list-item-language-icon"
                        src={currentLanguage?.icon}
                    />
                    <span>
              {currentLanguage?.text}
            </span>
                </li>
                
                

                { isMobileMenu && (
                    <li
                        className="account-bar__list-item"
                        role="button"
                        /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                        tabIndex={10}
                        onClick={handleNavbarChangeAddress}
                        onKeyDown={handleNavbarChangeAddressKeyDown}
                    >
                        {user?.default_address?.address?.streetAddress || <FormattedMessage id="header.add_default_address" />}
                    </li>
                )}

                { isWorkingBtn && (
                    <li className={`account-bar__list-item account-bar__list-item--has-action ${isWorking ? `account-bar__action--playing` : `account-bar__action--stopped`}`}
                        onClick={ (event) => !isPerformingAction ? handleSetWorking(event, isWorking ? false : true) : {}  }
                    >
                        { isPerformingAction && (
                            <FormattedMessage id="header.working.loading" />
                        )}
                        { errorMessage && (
                            <AlertBasic
                                message={errorMessage}
                                classNames="alert-basic__no-padding"
                            />
                        )}
                        { !isPerformingAction && (
                            <>
                                {isWorking && (
                                    <>
                                        <div className="account-bar__action-title">
                                            <FormattedMessage id="header.working.accepting" />
                                        </div>
                                        <div className="account-bar__action">
                                            <img
                                                alt="stop accepting"
                                                className="account-bar__action-icon"
                                                src={pauseIcon}
                                            />
                                            <span>
                        <FormattedMessage id="header.working.stop_accepting" />  
                      </span>
                                        </div>
                                    </>
                                )}
                                {!isWorking && (
                                    <>
                                        <div className="account-bar__action-title">
                                            <FormattedMessage id="header.working.stopped" />
                                        </div>
                                        <div className="account-bar__action">
                                            <img
                                                alt="star accepting"
                                                className="account-bar__action-icon"
                                                src={playIcon}
                                            />
                                            <span>
                      <FormattedMessage id="header.working.start_accepting" />  
                    </span>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </li>
                )}


                { menuItems && (
                    menuItems.map((menuItem, index) => (
                        <li
                            key={index}
                            className="account-bar__list-item account-bar__list-item--has-chevron"
                        >
                            {menuItem.action === "checkin" || menuItem.action === "checkout" ? (
                                <a
                                    className="account-bar__link"
                                    onClick={() => showModal(menuItem)}
                                >
                                    {menuItem.title}
                                </a>
                            ) : (
                                <a
                                    className="account-bar__link"
                                    href={`/${menuItem.action}`}
                                    onClick={onClose}
                                >
                                    {menuItem.title}
                                </a>
                            )}
                        </li>
                    ))
                )}




                <li className="account-bar__list-item account-bar__list-item--has-chevron">
                    <a
                        className="account-bar__link"
                        href="/account"
                        onClick={onClose}
                    >
                        <FormattedMessage id="header.edit_profile" />
                    </a>
                </li>
                <li
                    className="account-bar__list-item"
                    onClick={onLogoutClick}
                >
                    <FormattedMessage id="header.sign_out" />
                </li>

                { isMobileMenu && (
                    <a
                        className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                        href={MARKETING_WEBSITE_URL}
                    >
                        <FormattedMessage id="header.navigation_links.go_to_website" />
                    </a>
                )}

            </ul>
        </div>
    );
};

AccountBar.propTypes = {
    currentLanguage: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string
    }),
    hideLanguageOptionsList: PropTypes.func,
    isLanguageMenuVisible: PropTypes.bool,
    setCurrentLanguage: PropTypes.func,
    showLanguageOptionsList: PropTypes.func,

    isMobileMenu: PropTypes.bool,
    handleNavbarChangeAddress: PropTypes.func,
    handleNavbarChangeAddressKeyDown: PropTypes.func,

    user: PropTypes.shape(),

    onClose: PropTypes.func,
    onLogoutClick: PropTypes.func,
    keepAccountDropdownOpenned: PropTypes.func

};

AccountBar.defaultProps = {
    currentLanguage: null,
    hideLanguageOptionsList: () => {},
    isLanguageMenuVisible: false,
    setCurrentLanguage: () => {},
    showLanguageOptionsList: () => {},

    isMobileMenu: false,
    handleNavbarChangeAddress: () => {},
    handleNavbarChangeAddressKeyDown: () => {},

    user: null,

    onClose: () => {},
    onLogoutClick: () => {},
    keepAccountDropdownOpenned:  () => {},


};

export default AccountBar;
