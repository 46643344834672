import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Dropdown = ({
  autoWidth,
  block,
  children,
  className,
  fullWidth,
  hasBackgroundOverlay,
  horizontalPosition,
  minWidth,
  verticalPosition,
  scrollable,
  show,
  onClose
}) => {
  const ref = useRef(null);

  const [autoHorizontalPosition, setAutoHorizontalPosition] = useState(null);
  const left = horizontalPosition === 'left';
  const right = horizontalPosition === 'right';
  const center = horizontalPosition === 'center';

  const [autoVerticalPosition, setAutoVerticalPosition] = useState(null);
  const top = verticalPosition === 'top';
  const bottom = verticalPosition === 'bottom';

  const clickListener = (event) => {
    if (!(ref.current).contains(event?.target)) {
      handleClickOutside();
    }
  };

  const handleClickOutside = () => {
    if (show) {
      setAutoHorizontalPosition(null);
      setAutoVerticalPosition(null);
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
    };
  });

  const handleVerticalPosition = () => {
    if (ref && ref.current) {
      const windowHeight = window.innerHeight;
      const dropdownVerticalPosition = ref.current.getBoundingClientRect().bottom;

      if (dropdownVerticalPosition >= windowHeight) {
        setAutoVerticalPosition('top');
      } else {
        setAutoVerticalPosition('bottom');
      }
    }
  };

  const handleHorizontalPosition = () => {
    if (ref && ref.current) {
      const windowWidth = window.innerWidth;
      const dropdownHorizontalPosition = ref.current.getBoundingClientRect().right;

      if (dropdownHorizontalPosition >= windowWidth) {
        setAutoHorizontalPosition('right');
      } else {
        setAutoHorizontalPosition('left');
      }
    }
  };

  useEffect(() => {
    if (show) {
      handleVerticalPosition();
      handleHorizontalPosition();
    }
  }, [show]);

  return (
    <>
      <div
        ref={ref}
        className={`dropdown${
          show && autoVerticalPosition && autoHorizontalPosition ? ' dropdown--show' : ''}${
          autoHorizontalPosition === 'left' || left ? ' dropdown--left' : ''}${
          autoHorizontalPosition === 'right' || right ? ' dropdown--right' : ''}${
          center ? ' dropdown--center' : ''}${
          autoVerticalPosition === 'top' || top ? ' dropdown--top' : ''}${
          autoVerticalPosition === 'bottom' || bottom ? ' dropdown--bottom' : ''}${
          block ? ' dropdown--block' : ''}${
          fullWidth ? ' dropdown--full-width' : ''}${
          minWidth ? ' dropdown--min-width' : ''}${
          scrollable ? ' dropdown--scrollable' : ''}${
          autoWidth ? ' dropdown--auto-width' : ''} ${
          className}`}
      >
        <div className="dropdown__content">
          {children}
        </div>
      </div>
      {show && hasBackgroundOverlay && (
        <div className="dropdown--overlay" />
      )}
    </>
  );
};

Dropdown.propTypes = {
  autoWidth: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  minWidth: PropTypes.bool,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  show: PropTypes.bool,
  verticalPosition: PropTypes.oneOf(['top', 'bottom'])
};

Dropdown.defaultProps = {
  autoWidth: false,
  block: false,
  children: null,
  className: '',
  fullWidth: false,
  horizontalPosition: null,
  minWidth: false,
  onClose: () => {},
  scrollable: false,
  show: false,
  verticalPosition: null
};

export default Dropdown;
