/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { exportDeliveries, getDeliveries, getDriversBasic, selectDeliveries, selectDrivers, selectIsExportingDeliveries, selectIsLoadingDeliveryList, selectPages } from 'store/slices/deliveries';
import { selectUser, selectOptionExportOrders, selectOptionNewOrder } from 'store/slices/authentication';

import Button from '@youship/components/objects/button';
import OrdersHeader from 'components/orders/header';
import OrderCard from 'components/orders/order-card';
import Loader from '@youship/components/objects/loader';
import Notice from '@youship/components/objects/notice';
import Pagination from '@youship/components/objects/pagination';
import OrderModal from 'components/orders/order-modal';

import infoIcon from 'images/icons/info-gray.svg';
import motoIcon from 'images/moto.svg';
import ordersIcon from 'images/orders.svg';
import plusIcon from 'images/icons/plus--white.svg';

import './styles.scss';

const MAXIMUM_NUMBER_OF_SHIPMENTS = 12;

const URL_SEARCH_PARAM_DATE_FROM = 'datefrom';
const URL_SEARCH_PARAM_DATE_TO = 'dateto';
const URL_SEARCH_PARAM_KEY_DELIVERY_TYPE = 'type';
const URL_SEARCH_PARAM_KEY_PAGE_NUMBER = 'page';
const URL_SEARCH_PARAM_KEY_SEARCH_TERMS = 'search';
const URL_SEARCH_PARAM_KEY_USER_ID = 'user';
const URL_SEARCH_PARAM_KEY_DRIVER_ID = 'driver';
const URL_SEARCH_PARAM_LIST_MODE = 'grouped';

const OrdersList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isExportingDeliveries = useSelector(selectIsExportingDeliveries);
  const isOptionExportOrders = useSelector(selectOptionExportOrders);
  const isOptionNewOrder = useSelector(selectOptionNewOrder);
  const isLoadingDeliveryList = useSelector(selectIsLoadingDeliveryList);
  const ordersList = useSelector(selectDeliveries);
  const pages = useSelector(selectPages);
  const user = useSelector(selectUser);
  const userType = user?.type;
  const userIsTransporter = userType === 'transporter';
  const transporterDrivers = useSelector(selectDrivers);
  const accountUsers = user?.accountUsers;
  

  const userIsTransporterOrDriver = (userType === 'transporter' || userType === 'driver'  ) ? true : false;

  const [currentPageNumber, setCurrentPageNumber] = useState(null);
  const [deliveriesFetchRequestFilters, setDeliveriesFetchRequestFilters] = useState({
    datefrom: '',
    dateto: '',
    grouped: false,
    pages: {
      current: 0,
      resultsbypage: MAXIMUM_NUMBER_OF_SHIPMENTS
    },
    reference: '',
    str: '',
    user_code: ''
  });
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedType, setSelectedType] = useState('active');
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedListMode, setSelectedListMode] = useState(false);
  const [selectCurrentUserId, setSelectCurrentUserId] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [urlSearchParamsLoaded, setUrlSearchParamsLoaded] = useState(false);

  const numberOfPages = pages.lastPage + 1;
  const showPagination = !!numberOfPages;
 
  //normal
  //const selectedOrder = ordersList.find(order => order.id === selectedOrderId);

  // Get filter object and inputs from URL search params on mount
  useEffect(
    () => {
      const urlSearchParams = new URLSearchParams(location.search);
      const searchTermsFromUrlSearchParams = urlSearchParams.get(URL_SEARCH_PARAM_KEY_SEARCH_TERMS);
      const deliveryTypeFromUrlSearchParams = urlSearchParams.get(URL_SEARCH_PARAM_KEY_DELIVERY_TYPE);
      const currentUserIdFromUrlSearchParams = urlSearchParams.get(userType === 'user' ? URL_SEARCH_PARAM_KEY_USER_ID : URL_SEARCH_PARAM_KEY_DRIVER_ID);
      const pageNumberFromUrlSearchParams = parseInt(urlSearchParams.get(URL_SEARCH_PARAM_KEY_PAGE_NUMBER), 10);
      const startDateFromUrlSearchParams = urlSearchParams.get(URL_SEARCH_PARAM_DATE_FROM);
      const endDateFromUrlSearchParams = urlSearchParams.get(URL_SEARCH_PARAM_DATE_TO);
      const listModeFromUrlSearchParams = urlSearchParams.get(URL_SEARCH_PARAM_LIST_MODE);

      const searchTerms = searchTermsFromUrlSearchParams || '';
      const deliveryType = deliveryTypeFromUrlSearchParams || 'active';
      const userId = currentUserIdFromUrlSearchParams || '';
      const pageNumber = typeof pageNumberFromUrlSearchParams === 'number' && !isNaN(pageNumberFromUrlSearchParams) ? pageNumberFromUrlSearchParams : 1;
      const dateFrom = startDateFromUrlSearchParams || '';
      const dateTo = endDateFromUrlSearchParams || '';
      const listMode = listModeFromUrlSearchParams || false;

      setCurrentPageNumber(pageNumber);
      setSearchValue(searchTerms);
      setSelectedListMode(listMode);
      setSelectCurrentUserId(userId);
      setSelectedType(deliveryType);
      setStartDate(dateFrom);
      setEndDate(dateTo);
      setUrlSearchParamsLoaded(true);

      setDeliveriesFetchRequestFilters(prevState => ({
        ...prevState,
        datefrom: dateFrom,
        dateto: dateTo,
        pages: {
          ...prevState.pages,
          current: pageNumber - 1
        },
        str: searchTerms,
        user_code: selectCurrentUserId,
        grouped: listMode
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Set filter object and URL search params on filter inputs change
  useEffect(
    () => {
      if (urlSearchParamsLoaded) {
        const urlSearchParams = new URLSearchParams();

        if (searchValue !== '') urlSearchParams.set(URL_SEARCH_PARAM_KEY_SEARCH_TERMS, searchValue);
        if (selectedListMode !== false) urlSearchParams.set(URL_SEARCH_PARAM_LIST_MODE, selectedListMode);

        if (selectedType !== 'active') urlSearchParams.set(URL_SEARCH_PARAM_KEY_DELIVERY_TYPE, selectedType);
        if (selectCurrentUserId !== '') urlSearchParams.set(userType === 'user' ? URL_SEARCH_PARAM_KEY_USER_ID : URL_SEARCH_PARAM_KEY_DRIVER_ID, selectCurrentUserId);
        if (startDate !== '') urlSearchParams.set(URL_SEARCH_PARAM_DATE_FROM, startDate);
        if (endDate !== '') urlSearchParams.set(URL_SEARCH_PARAM_DATE_TO, endDate);
        if (currentPageNumber !== 1) urlSearchParams.set(URL_SEARCH_PARAM_KEY_PAGE_NUMBER, currentPageNumber);
        

        history.replace({ search: urlSearchParams.toString() });

        setDeliveriesFetchRequestFilters((prevState) => {
          const newState = {
            ...prevState,
            datefrom: startDate,
            dateto: endDate,
            pages: {
              ...prevState.pages,
              current: currentPageNumber - 1
            },
            str: searchValue,
            user_code: selectCurrentUserId,
            grouped: selectedListMode
          };

          if (JSON.stringify(prevState) !== JSON.stringify(newState)) return newState;

          return prevState;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageNumber, endDate, searchValue, selectCurrentUserId, selectedListMode, selectedType, startDate, urlSearchParamsLoaded]
  );

  // Dispatch new deliveries fetch on filter object change
  useEffect(
    () => {
      if (urlSearchParamsLoaded) {
        setErrorMessage(null);

        //console.log("DISPACTH getDeliveries:");
        //console.log(deliveriesFetchRequestFilters);

        dispatch(getDeliveries({
          search: deliveriesFetchRequestFilters,
          deliveriesType: selectedType,
          userType
        }))
          .then((response) => {
            if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading deliveries.');

            return response;
          })
          .catch((error) => {
            setErrorMessage(error?.message);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveriesFetchRequestFilters, urlSearchParamsLoaded, selectedType]
  );

  useEffect(() => {
    if (userIsTransporter) {
      dispatch(getDriversBasic())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading drivers.');

          return response;
        })
        .catch((error) => {
          // NOTE: implement proper error handling
          // setErrorMessage(error?.message);
        });
    }
  }, [dispatch, userIsTransporter]);


  const handleCurrentUserIdChange = (value) => {
    setSelectCurrentUserId((prevState) => {
      if (prevState !== value && currentPageNumber !== 1) setCurrentPageNumber(1);

      return value;
    });
  };

  const handleDateReset = () => {
    setStartDate('');
    setEndDate('');

    if (currentPageNumber !== 1) setCurrentPageNumber(1);
  };

  const handleDateChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate ? newStartDate.format('YYYY-MM-DD') : '');
    setEndDate(newEndDate ? newEndDate.format('YYYY-MM-DD') : '');

    if (currentPageNumber !== 1) setCurrentPageNumber(1);
  };

  const handleOrderModalOnClose = () => {
    setShowOrderModal(false);
    const enableScrollEvent = new Event('enable-scroll');

    window.dispatchEvent(enableScrollEvent);
  };

  const handleOrderCardOnSeeDetails = (id, index1) => {
    
    setSelectedOrderId(id);
    
    setSelectedOrder(ordersList[index1]);

    openOrderModal();
    
  };

  const handleOrderCardOnSeeDetails2 = (id, index1, index2) => {
    
    setSelectedOrderId(id);

    setSelectedOrder(ordersList[index1].rows[index2]);

    openOrderModal();
  }

  const handlePaginationOnChange = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
  };

  const handleSearchValueChange = (value) => {
    setSearchValue(value);

    if (currentPageNumber !== 1) setCurrentPageNumber(1);
  };

  const handleListModeChange = (value) => {
    //console.log("handleListModeChange:" + value);
    setSelectedListMode(value);
    if (currentPageNumber !== 1) setCurrentPageNumber(1);
  };

  const handleTypeChange = (value) => {
    setSelectedType((prevState) => {
      if (prevState !== value && currentPageNumber !== 1) setCurrentPageNumber(1);

      return value;
    });
  };


  const openOrderModal = () => {
    setShowOrderModal(true);

    const disableScrollEvent = new Event('disable-scroll');

    window.dispatchEvent(disableScrollEvent);
  };

  const downloadDeliveriesFile = () => {
    
    dispatch( exportDeliveries( {...deliveriesFetchRequestFilters, tab: selectedType }) )
      .then(response => response)
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const intl = useIntl();	

  const header = (
    <>
      <h1 className="orders-list__title">
        <FormattedMessage id="orders.title" />
        { !userIsTransporterOrDriver && (
          <div className="orders-list__new-order">
            <Button
                context="primary"
                icon={plusIcon}
                linkComponent={Link}
                linkProps={{ to: '/new-order' }}
                noArrow
                smallPadding
              /> 
          </div>
        )}
      </h1>
      <OrdersHeader
        accountUsers={accountUsers}
        dateFrom={startDate}
        dateTo={endDate}
        downloadDeliveriesFile={downloadDeliveriesFile}
        isExportingFile={isExportingDeliveries}
        searchValue={searchValue}
        selectedListMode={selectedListMode}
        selectedCurrentUserId={selectCurrentUserId}
        selectedType={selectedType}
        transporterDrivers={transporterDrivers}
        userType={userType}
        userIsTransporterOrDriver={userIsTransporterOrDriver}
        onCurrentUserIdChange={handleCurrentUserIdChange}
        onDateChange={handleDateChange}
        onDateReset={handleDateReset}
        onSearchValueChange={handleSearchValueChange}
        onTypeChange={handleTypeChange}
        onListModeChange={handleListModeChange}
        isOptionNewOrder={isOptionNewOrder}
        isOptionExportOrders={isOptionExportOrders}
      />
    </>
  );

  if (isLoadingDeliveryList) {
    return (
      <div className="orders-list">
        <div className="container">
          {header}
          <div className="orders-list__loader-wrapper">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="orders-list">
      <div className="container">
        {header}
        {errorMessage ? (
          <Notice
            description={errorMessage}
            fixed
            icon={infoIcon}
          />
        ) : (
          <>
            {ordersList.length ? (
              <div className="row">
                {ordersList.map((order, index1) => (
                  <>
                    { order.id  && (
                      <>
                        <div
                          key={order.id}
                          className="col col-12 col-lg-4 d-flex"
                        >
                          <OrderCard
                            {...order}
                            onSeeDetails={() => handleOrderCardOnSeeDetails(order.id, index1)}
                          />
                        </div>
                      </>
                      ) 
                    }
                    { order.rows  && (
                       <>
                        <div className="container orders-list__grouped_title">
                          {order.title} ({order.total})
                        </div>
                        <div className="container">
                        <div className="row">
                        { order.rows.map((order2, index2) => (
                            <div
                              key={order2.id}
                              className="col col-12 col-lg-4 d-flex"
                            >
                              <OrderCard
                                {...order2}
                                onSeeDetails={() => handleOrderCardOnSeeDetails2(order2.id, index1, index2)}
                              />
                            </div>
                        )) 
                        }
                        </div>
                        </div>
                        </>
                    )}
                  </>
                ))
                }
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  {userIsTransporterOrDriver ? (
                  <p className="orders-list__no-orders">
                    <p>
                      <img
                        alt=""
                        src={motoIcon}
                      />
                    </p>
                    <p>
                      <FormattedMessage id="orders.no_results" />
                    </p>
                  </p>
                  ) : (
                  <p className="orders-list__no-orders">
                    <p>
                      <img
                        alt="New order"
                        src={ordersIcon}
                      />
                    </p>
                    <p>
                      <FormattedMessage id="orders.no_results" />
                    </p>
                    <Button
                      context="primary"
                      linkComponent={Link}
                      linkProps={{ to: '/new-order' }}
                      noArrow
                      smallPadding
                      icon={plusIcon}
                      isTextLeft
                      text={intl.formatMessage({ id: 'header.navigation_links.new-order' })}
                    />
                  </p>
                  )}
                </div>
              </div>
            )}
            {showPagination && numberOfPages > 1 && (
              <div className="row justify-content-center">
                <Pagination
                  length={numberOfPages}
                  selected={currentPageNumber}
                  onChange={handlePaginationOnChange}
                />
              </div>
            )}
            {showOrderModal && selectedOrder && (
              <OrderModal
                {...selectedOrder}
                onClose={handleOrderModalOnClose}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersList;
