import React from 'react';

import DriversArea from "../components/sections/drivers-area";

const Drivers = () => (
    <>
        <DriversArea/>
    </>
);

export default Drivers;
