import BaseApi from '@youship/api';
import {
  AUTHENTICATION_ACTIVATE_PHONE,
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGIN_FROM_SESSION,
  AUTHENTICATION_REQUEST_A_CONTACT,
  AUTHENTICATION_REQUEST_RECOVER_TOKEN,
  AUTHENTICATION_SIGN_UP,
  AUTHENTICATION_RESET_PASSWORD,
  AUTHENTICATION_CONFIRM_EMAIL,
} from '@youship/api/endpoints';


export default class AuthenticationApi extends BaseApi {
  /* eslint-disable camelcase */

  static activatePhone = ({
    activationcode
  }) => this.post(AUTHENTICATION_ACTIVATE_PHONE, {
    activationcode
  });

  static login = ({
    email,
    password
  }) => this.post(AUTHENTICATION_LOGIN, {
    email,
    password
  });

  static loginFromSession = ({
    token
  }) => this.post(AUTHENTICATION_LOGIN_FROM_SESSION, {
    token
  });

  static requestAContact = ({
    name,
    email,
    phone,
    subject,
    message,
    'g-recaptcha-response': googleRecaptchaHash
  }) => this.post(AUTHENTICATION_REQUEST_A_CONTACT, {
    name,
    email,
    phone,
    subject,
    message,
    'g-recaptcha-response': googleRecaptchaHash
  });

  static requestRecoverToken = ({
    email
  }) => this.post(AUTHENTICATION_REQUEST_RECOVER_TOKEN, {
    email
  });

  static resetPassword = ({
    token, password
  }) => this.post(AUTHENTICATION_RESET_PASSWORD, {
    token, password
  });

  static confirmEmail = ({
    token
  }) => this.post(AUTHENTICATION_CONFIRM_EMAIL, {
    token
  });
  
  static signUp = ({
    email,
    firstname,
    lastname,
    password,
    company,
    phonenumber,
    idphonecode,
    phonecode,
    idcountry,
    countrycode,
    user_type,
    recaptcha
  }) => {
    const data = {
      email,
      firstname,
      lastname,
      password,
      phonenumber
    };

    if (recaptcha) data.recaptcha = recaptcha;

    if (company) data.company = company;

    if (idphonecode) data.idphonecode = idphonecode;
    else if (phonecode) data.phonecode = phonecode;

    if (idcountry) data.idcountry = idcountry;
    else if (countrycode) data.countrycode = countrycode;

    if (user_type) data.user_type = user_type;

    return this.post(AUTHENTICATION_SIGN_UP, data);
  };

  /* eslint-enable camelcase */
}
