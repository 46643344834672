import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectOrderTypeById } from 'store/slices/categories';
import { resetCheckoutQuote } from 'store/slices/shopping-cart';
import { fetchShop, selectIsLoading, selectShopById } from 'store/slices/shops';

import ShopDetails from 'components/sections/shop-details';
import CoverImage from 'components/cover-image';

const Shop = ({ match }) => {

  const intl = useIntl();

  const { shopId } = match.params;

  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const shop = useSelector(state => selectShopById(state, shopId));

  useEffect(() => {
    // Make sure that shopping cart is not in checkout mode
    dispatch(resetCheckoutQuote());

    document.body.classList.add('has-cover-image');

    return () => {
      document.body.classList.remove('has-cover-image');
    };
  });

  useEffect(() => {
    dispatch(fetchShop({ shopId, complete: true }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while fetching shop details.');

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [dispatch, shopId]);

  let backLink = {
    label: intl.formatMessage({ id: 'shop.back_categories' }),
    path: '/categories'
  };

  let details = { id: shopId };
  let imageUrl = null;
  let lastOrderTypeId = null;

  if (shop) {
    const { orderTypeIds } = shop;

    if (Array.isArray(orderTypeIds) && orderTypeIds.length) lastOrderTypeId = orderTypeIds[orderTypeIds.length - 1];

    details = shop;
    imageUrl = shop.imageUrl;
  }

  const lastOrderType = useSelector(state => selectOrderTypeById(state, lastOrderTypeId));

  if (lastOrderTypeId) {
    backLink = {
      label: lastOrderType?.name || lastOrderTypeId,
      path: `/category/${lastOrderTypeId}`
    };
  }

  return (
    <>
      <CoverImage
        backLink={backLink}
        imageUrl={imageUrl}
        message={!shop?.open ? shop?.closedMessage || null : null}
      />
      <ShopDetails
        {...details}
        isLoading={isLoading && !shop}
      />
    </>
  );
};


export default Shop;
