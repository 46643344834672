import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Tooltip = ({ autoWidth, block, children, className, show, horizontalPosition, verticalPosition, scrollable, onClose }) => {
  const ref = useRef(null);

  const left = horizontalPosition === 'left';
  const right = horizontalPosition === 'right';
  const center = horizontalPosition === 'center';

  const top = verticalPosition === 'top';
  const bottom = verticalPosition === 'bottom';

  const handleMouseLeave = () => {
    onClose();
  };

  return (
    <div
      ref={ref}
      className={`tooltip${
        show ? ' tooltip--show' : ''}${
        left ? ' tooltip--left' : ''}${
        right ? ' tooltip--right' : ''}${
        center ? ' tooltip--center' : ''}${
        top ? ' tooltip--top' : ''}${
        bottom ? ' tooltip--bottom' : ''}${
        block ? ' tooltip--block' : ''}${
        scrollable ? ' tooltip--scrollable' : ''}${
        autoWidth ? ' tooltip--auto-width' : ''} ${
        className
      }`}
      role="tooltip"
      onMouseLeave={handleMouseLeave}
    >
      <div className="tooltip__content">
        {children}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  autoWidth: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  show: PropTypes.bool,
  verticalPosition: PropTypes.oneOf(['top', 'bottom'])
};

Tooltip.defaultProps = {
  autoWidth: false,
  block: false,
  children: null,
  className: '',
  horizontalPosition: 'left',
  onClose: () => { },
  scrollable: false,
  show: false,
  verticalPosition: 'bottom'
};

export default Tooltip;
