import React, {useEffect, useRef, useState} from "react";
import {DragAndDrop, Drag, Drop} from "utils/drag-drop";
import {reorder} from "./helpers.js";
import plusIcon from "../../../images/icons/plus--white.svg";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../objects/button";
import {useDispatch, useSelector} from 'react-redux';
import verticalMenuIcon from "../../../images/icons/verticalMenu.svg";
import Dropdown from "../../../../../shared/components/objects/dropdown";

import {
    addCategory, addExtra,
    addMenu, addOption, addProduct,
    editCategory, editExtra,
    editMenu, editOption, editProduct,
    removeCategory, removeExtra,
    removeMenu, removeOption, removeProduct
} from "../../../store/slices/products-management";
import ActionModal from "../../order/action-modal";
import MenuCategoryModal from "../../products-modal/menu_category";
import ProductModal from "../../products-modal/product";
import OptionModal from "../../products-modal/option";
import ExtraModal from "../../products-modal/extra";

export const NestedListComponent = ({refresh, menuList, onNestedListInfo, onChange }) => {

    const intl = useIntl();
    const [menu, setMenu] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const dispatch = useDispatch();
    const [selectedCode, setSelectedCode] = useState(null);
    let optionCounter = 0;
    let extraCounter = 0;




    useEffect(() => {
        const menusTemp = menuList.items.map(item => item);
        setMenu(menusTemp);
    }, [refresh]);

    const handleAddOrEditButtonClick = (categoryCode, selectedCategory, isEditMode) => {
        onNestedListInfo(categoryCode, selectedCategory, isEditMode);
    };

    const handleDragEnd = (result) => {

        const {type, source, destination} = result;
        if (!destination) return;

        const sourceCategoryId = source.droppableId;
        const destinationCategoryId = destination.droppableId;

        // Reordering items
        if (type === "droppable-item") {
            // If drag and dropping within the same category
            if (sourceCategoryId === destinationCategoryId) {
                const updatedOrder = reorder(
                    menu.find((submenu) => submenu.id === sourceCategoryId).products,
                    source.index,
                    destination.index
                );
                const updatedMenu = menu.map((submenu) =>
                    submenu.id !== sourceCategoryId
                        ? submenu
                        : {...submenu, products: updatedOrder}
                );
                setMenu(updatedMenu);
            } else {
                const sourceOrder = menu.find(
                    (submenu) => submenu.id === sourceCategoryId
                ).products;
                const destinationOrder = menu.find(
                    (submenu) => submenu.id === destinationCategoryId
                ).products;

                const [removed] = sourceOrder.splice(source.index, 1);
                destinationOrder.splice(destination.index, 0, removed);

                destinationOrder[removed] = sourceOrder[removed];
                delete sourceOrder[removed];

                const updatedMenu = menu.map((submenu) =>
                    submenu.id === sourceCategoryId
                        ? {...submenu, products: sourceOrder}
                        : submenu.id === destinationCategoryId
                            ? {...submenu, products: destinationOrder}
                            : submenu
                );
                setMenu(updatedMenu);
            }
        }

        // Reordering categories
        if (type === "droppable-category") {
            const updatedMenu = reorder(
                menu,
                source.index,
                destination.index
            );
            setMenu(updatedMenu);
        }
    };
    
    const DropdownModified = ({ show, onClose, items }) => {
        return (
            <Dropdown
                autoWidth
                className="shipment-method-dropdown"
                show={show}
                onClose={onClose}
            >
                <ul className="shipment-method-dropdown__list">
                    {items.map((item, index) => (
                        <li key={index} className="shipment-method-dropdown__list-item" onClick={item.onClick}>
                            <div className="shipment-method-dropdown__text">
                                {item.text}
                            </div>
                        </li>
                    ))}
                </ul>
            </Dropdown>
        );
    };


    const [categoryDropdownStates, setCategoryDropdownStates] = useState({});
    const [productDropdownStates, setProductDropdownStates] = useState({});
    const [optionDropdownStates, setOptionDropdownStates] = useState({});
    const [extraDropdownStates, setExtraDropdownStates] = useState({});


// Update the functions to set individual dropdown visibility states
    const handleCategoryDropdownClose = (itemId) => {
        setCategoryDropdownStates(prevState => ({ ...prevState, [itemId]: false }));
    };

    const handleProductDropdownClose = (itemId) => {
        setProductDropdownStates(prevState => ({ ...prevState, [itemId]: false }));
    };

    const handleOptionDropdownClose = (itemId) => {
        setOptionDropdownStates(prevState => ({ ...prevState, [itemId]: false }));
    };

    const handleExtraDropdownClose = (itemId) => {
        setExtraDropdownStates(prevState => ({ ...prevState, [itemId]: false }));
    };


    const closeAllDropdowns = () => {
        setCategoryDropdownStates({});
        setProductDropdownStates({});
        setOptionDropdownStates({});
        setExtraDropdownStates({});
    };


    //Remove modal
    const handleRemoveModalConfirmButtonClick = (event) => {
        event.preventDefault();
        setErrorMessage(null);
        
        let dispatchFunction;
        switch (actionType) {
            case "product":
                dispatchFunction = removeProduct;
                break;
            case "category":
                dispatchFunction = removeCategory;
                break;
            case "option":
                dispatchFunction = removeOption;
                break;
            case "extra":
                dispatchFunction = removeExtra;
                break;
            default:
                console.error("Invalid action type:", actionType);
                return;
        }
        dispatch(dispatchFunction({ category_code: selectedCode, product_code: selectedCode }))
            .then((response) => {
                setShowRemoveModal(false);
               return response;
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const handleRemoveModalCloseButtonClick = () => {
        setShowRemoveModal(false);
    };


 

    //////Add or Edit Product/Option/Extra

    const [modalMessageTitle, setModalMessageTitle] = useState(null);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showOptionModal, setShowOptionModal] = useState(false);
    const [showExtraModal, setShowExtraModal] = useState(false);
    const [isEdit, setisEdit] = useState(null);
    const [dataModal, setDataModal] = useState(null); // Set the initial selected menu


    const handleAddButtonClick = (type, isEdit) => {
        if (isEdit) {
            if (type === "product") {
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.edit-product' }));
                setisEdit(true)
                setActionType("product")
                //setDataModal(selectedMenu);
                setShowProductModal(true);
            } else if (type === "option") {
                setisEdit(true)
                setActionType("option")
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.edit-option' }));
                setShowOptionModal(true);
            } else if (type === "extra") {
                setisEdit(true)
                setActionType("extra")
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.edit-extra' }));
                setShowExtraModal(true);
            }
        } else {
            if (type === "product") {
                setisEdit(false)
                setActionType("product")
                setDataModal(null)
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.add-product' }));
                setShowProductModal(true);
            } else if (type === "option") {
                setisEdit(false)
                setActionType("option")
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.add-option' }));
                setShowOptionModal(true);
            } else if (type === "extra") {
                setisEdit(false)
                setActionType("extra")
                setModalMessageTitle(intl.formatMessage({ id: 'products-management.add-extra' }));
                setShowExtraModal(true);
            }
        }

  

    };
    const handleModal = (event, formData) => {
        event.preventDefault();

        let dispatchFunction;
        let successMessageId;

        if (actionType === "product") {
            dispatchFunction = isEdit ? editProduct : addProduct;
            successMessageId = 'product';
        } else if (actionType === "option") {
            dispatchFunction = isEdit ? editOption : addOption;
            successMessageId = 'option';
        } else if (actionType === "extra") {
            dispatchFunction = isEdit ? editExtra : addExtra;
            successMessageId = 'extra';
        }

        setErrorMessage(null);

        formData.category_code = selectedCode;
        formData.product_code = selectedCode;
        
        
        dispatch(dispatchFunction(formData))
            .then((response) => {
                if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');
                return response;
            })
            .then((response) => {
                setErrorMessage(false);
                setModalMessageTitle(intl.formatMessage({ id: `${successMessageId}.${isEdit ? 'edit' : 'add'}` }));
                setSuccessMessage(intl.formatMessage({ id: `${successMessageId}.${isEdit ? 'edit_success' : 'add_success'}` }));

                setSelectedCode(null);
                // handleRefresh();
                return response;
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.message);
            });
    };


    const handleCloseModal = () => {
        setShowProductModal(false)
        setShowOptionModal(false)
        setShowExtraModal(false);
        setErrorMessage(null);
        setSuccessMessage(null);
    };
    
    
    
    return (
        <div className="category-container" key={menuList.id}>
            <div className="top-menu">
                <h2>
                    {menuList.name}
                </h2>
                <Button
                    style={{color: "white"}}
                    noArrow
                    noBackground
                    small
                    noPadding
                    icon={plusIcon}
                    text={intl.formatMessage({id: 'products-management.add-category'})}
                    onClick={() => handleAddOrEditButtonClick(menuList.category_code, null, false)}
                />
            </div>

            {showRemoveModal && (
                <ActionModal
                    title={(() => {
                        switch (actionType) {
                            case "product":
                                return intl.formatMessage({ id: 'products-management.remove-product' });
                            case "category":
                                return intl.formatMessage({ id: 'products-management.remove-category' });
                            case "option":
                                return intl.formatMessage({ id: 'products-management.remove-option' });
                            case "extra":
                                return intl.formatMessage({ id: 'products-management.remove-extra' });
                            default:
                                return intl.formatMessage({ id: 'products-management.remove' });
                        }
                    })()}
                    description={(() => {
                        switch (actionType) {
                            case "product":
                                return intl.formatMessage({ id: 'products-management.remove_product_description' });
                            case "category":
                                return intl.formatMessage({ id: 'products-management.remove_category_description' });
                            case "option":
                                return intl.formatMessage({ id: 'products-management.remove_option_description' });
                            case "extra":
                                return intl.formatMessage({ id: 'products-management.remove_extra_description' });
                            default:
                                return intl.formatMessage({ id: 'products-management.remove_menu_description' });
                        }
                    })()}
                    back={intl.formatMessage({id: 'menu.go_back'})}
                    onClose={handleRemoveModalCloseButtonClick}
                    onConfirm={handleRemoveModalConfirmButtonClick}
                />
            )}

            {showProductModal && (
                <ProductModal
                    data={dataModal}
                    errorMessageModal={errorMessage}
                    finalMessage={successMessage}
                    title= {modalMessageTitle}
                    onClose={handleCloseModal}
                    onSave={handleModal}
                />
            )}

            {showOptionModal && (
                <OptionModal
                    data={dataModal}
                    errorMessageModal={errorMessage}
                    finalMessage={successMessage}
                    title= {modalMessageTitle}
                    onClose={handleCloseModal}
                    onSave={handleModal}
                />
            )}

            {showExtraModal && (
                <ExtraModal
                    data={dataModal}
                    errorMessageModal={errorMessage}
                    finalMessage={successMessage}
                    title= {modalMessageTitle}
                    onClose={handleCloseModal}
                    onSave={handleModal}
                />
            )}

            <DragAndDrop onDragEnd={handleDragEnd}>
                {menu.map((category, itemIndex) => (
                    <div className="draggable-category" key={category.id} >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3 className="item"> {category.name}  </h3>
                            <div className="new-order-shipment-options__shipment-method">
                                <div style={{ marginLeft: '4px', cursor: 'pointer', marginTop: '6px' }}>
                                    <img src={verticalMenuIcon} alt="Edit Icon" onClick={(e) => {
                                        closeAllDropdowns();
                                        setCategoryDropdownStates(prevState => ({ ...prevState, [category.id]: true }));
                                    }} />
                                </div>
                                <DropdownModified
                                    onClose={() => handleCategoryDropdownClose(category.id)}
                                    show={
                                    categoryDropdownStates[category.id]}
                                    items={[
                                        { text: intl.formatMessage({ id: 'products-management.add-product' }), onClick: () => {
                                                closeAllDropdowns()
                                                setDataModal(null)
                                                setSelectedCode(category.category_code)
                                                handleAddButtonClick("product", false)
                                        } },
                                        { text: intl.formatMessage({ id: 'products-management.assign-product' }), onClick: () => {} },
                                        { text: intl.formatMessage({ id: 'products-management.edit-category' }), onClick: () => {
                                                closeAllDropdowns()
                                               handleAddOrEditButtonClick(category.category_code, category, true)
                                            } },
                                        { text: intl.formatMessage({ id: 'products-management.remove-category' }), onClick: () => {
                                                closeAllDropdowns()
                                                setSelectedCode(category.category_code)
                                                setActionType("category")
                                                setShowRemoveModal(true)
                                            } },
                                    ]}/>
                             
                            </div>
                        </div>
                        <Drop id={category.id} type="droppable-item">
                            {category.products.map((product, productIndex) => (
                                    <div className="pai" key={product.id}>
                                        <Drag className="draggable" id={product.id} index={productIndex} >
                                            <div style={{display: 'block', alignItems: 'center'}}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin:'0 1rem'}}>
                                                    <div className="product-container">
                                                            {product.photo_url && (
                                                                <div className="product-image">
                                                                <img
                                                                    alt="product image"
                                                                    className="account-bar__avatar-image"
                                                                    src={product.photo_url}
                                                                />
                                                                </div>
                                                            )}
                                                        <p className="product-name">
                                                            {product.name}{'   '}
                                                            <span className="option-subtitle" style={{ marginLeft: '1rem' }}>
                                                            {product.price && product.price.svalue ? (
                                                                product.price_info
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </span>
                                                        </p>
                                                        <div className="availability-container" style={{marginTop: '2rem'}}>
                                                            {product.sold_out === false ? (
                                                                <div className="green-text">{intl.formatMessage({ id: 'products-management.available' })}</div>
                                                            ) : (
                                                                <div className="red-text">{intl.formatMessage({ id: 'products-management.unavailable' })}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="new-order-shipment-options__shipment-method">
                                                        <div style={{ marginRight: '1rem', cursor: 'pointer', marginTop: '2rem' }}>
                                                            <img src={verticalMenuIcon} alt="Edit Icon" onClick={(e) => {
                                                                closeAllDropdowns();
                                                                setProductDropdownStates(prevState => ({ ...prevState, [product.id]: true }));
                                                            }} />
                                                        </div>
                                                        <DropdownModified
                                                            onClose={() => handleProductDropdownClose(product.id)}
                                                            show={productDropdownStates[product.id]}
                                                            items={[
                                                                { text: intl.formatMessage({ id: 'products-management.add-option' }), onClick: () => {
                                                                        closeAllDropdowns()
                                                                        setDataModal(null)
                                                                        setSelectedCode(product.product_code)
                                                                        handleAddButtonClick("option", false)
                                                                    } },
                                                                { text: intl.formatMessage({ id: 'products-management.edit-product' }), onClick: () => {
                                                                        closeAllDropdowns()    
                                                                        setDataModal(product.product_code)
                                                                        setDataModal(product)
                                                                        handleAddButtonClick("product", true)
                                                                    } },
                                                                { text: intl.formatMessage({ id: 'products-management.remove-product' }), onClick: () => {
                                                                        closeAllDropdowns()    
                                                                        setSelectedCode(product.product_code)
                                                                        setActionType("product")
                                                                        setShowRemoveModal(true)
                                                                    } },
                                                            ]}/>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'inline', alignItems: 'center'}}>
                                                    {product.options.map((option, optionIndex) => {
                                                        const uniqueOptionId = `${option.id}_${optionCounter++}`;
                                                        return(
                                                        <div key={uniqueOptionId}>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin:'0 1rem'}}>
                                                            <p className="option-name">
                                                                {option.name}{' '}
                                                                <span className="option-subtitle">
                                                                ({option.subtitle})
                                                                </span>
                                                            </p>


                                                            <div className="new-order-shipment-options__shipment-method">
                                                                <div style={{ marginRight: '1rem', cursor: 'pointer', marginTop: '4px' }}>
                                                                    <img src={verticalMenuIcon} alt="Edit Icon" onClick={(e) => {
                                                                        closeAllDropdowns();
                                                                        setOptionDropdownStates(prevState => ({ ...prevState, [uniqueOptionId]: true }));
                                                                    }} />
                                                                </div>
                                                                <DropdownModified
                                                                    onClose={() => handleOptionDropdownClose(uniqueOptionId)}
                                                                    show={optionDropdownStates[uniqueOptionId]}
                                                                    items={[
                                                                        { text: intl.formatMessage({ id: 'products-management.add-extra' }), onClick: () => {
                                                                                closeAllDropdowns()    
                                                                                setDataModal(null)
                                                                                setSelectedCode(option.category_code)
                                                                                handleAddButtonClick("extra", false)
                                                                            } },
                                                                        { text: intl.formatMessage({ id: 'products-management.edit-option' }), onClick: () => {
                                                                                closeAllDropdowns()    
                                                                                setSelectedCode(option.category_code)
                                                                                setDataModal(option)
                                                                                handleAddButtonClick("option", true)
                                                                            } },
                                                                        { text: intl.formatMessage({ id: 'products-management.remove-option' }), onClick: () => {
                                                                                closeAllDropdowns()    
                                                                                setSelectedCode(option.category_code)
                                                                                setActionType("option")
                                                                                setShowRemoveModal(true)
                                                                            } },
                                                                    ]}/>
                                                            </div>
                                                            </div>
                                                            
                                                            
                                                            <div>
                                                                {option.extras.map((extra, extraIndex)=> {
                                                                    const uniqueId = `${extra.product_code}_${extraCounter++}`;

                                                                    return (
                                                                    <div key={uniqueId} className="extra-container">
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                                                <p className="extra-name">{extra.name}</p>
                                                                                <div className="availability-container">
                                                                                    {extra.sold_out === false ? (
                                                                                        <div className="green-text">{intl.formatMessage({ id: 'products-management.available' })}</div>
                                                                                    ) : (
                                                                                        <div className="red-text">{intl.formatMessage({ id: 'products-management.unavailable' })}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                                                {extra.price && extra.price.svalue ? (
                                                                                    <p style={{ marginLeft: '1rem' }}>{extra.price.svalue}</p>
                                                                                ) : (
                                                                                    <p style={{ marginLeft: '2rem' }}>N/A</p>
                                                                                )}

                                                                                <div className="new-order-shipment-options__shipment-method">
                                                                                    <div style={{ marginLeft: '4px', cursor: 'pointer', marginTop: '6px' }}>
                                                                                        <img src={verticalMenuIcon} alt="Edit Icon" onClick={(e) => {
                                                                                            closeAllDropdowns(); 
                                                                                           setExtraDropdownStates(prevState => ({ ...prevState, [uniqueId]: true }));
                                                                                        }} />
                                                                                    </div>
                                                                                    <DropdownModified
                                                                                        onClose={() => handleExtraDropdownClose(uniqueId)}
                                                                                        show={extraDropdownStates[uniqueId]}
                                                                                        items={[
                                                                                            { text: intl.formatMessage({ id: 'products-management.edit-extra' }), onClick: () => {
                                                                                                    closeAllDropdowns()    
                                                                                                    setSelectedCode(extra.product_code)
                                                                                                    setDataModal(extra)
                                                                                                    handleAddButtonClick("extra", true)
                                                                                                } },
                                                                                            { text: intl.formatMessage({ id: 'products-management.remove-extra' }), onClick: () => {
                                                                                                    closeAllDropdowns()    
                                                                                                    setSelectedCode(extra.product_code)
                                                                                                    setActionType("extra")
                                                                                                    setShowRemoveModal(true)
                                                                                                } },
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    );
                                                                })}

                                                            </div>
                                                        </div>
                                                    
                                                    )})}
                                                </div>
                                            </div>
                                        </Drag>
                                    </div>
                                )
                            )}
                        </Drop>
                    </div>
                ))}
            </DragAndDrop>
        </div>
    );
};



