import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';
import Quantity from '@youship/components/objects/quantity';
import Radio from '@youship/components/objects/radio';
import Select from '@youship/components/objects/select';
import Textarea from '@youship/components/objects/textarea';
import Button from '@youship/components/objects/button';

import noticeIcon from 'images/icons/notice.svg';
import chevronLeftIcon from 'images/icons/chevron-left.svg';

const itemDataDefault = {
  autoAdd: false,
  code: '',
  quantity: 1,
  quantities: null,
  description: '',
  weight: null,
  weights: null,
  length: null,
  lengths: null,
  width: null,
  widths: null,
  height: null,
  heights: null
};

const ItemModal = ({serviceRates, editItemIndex, editItemData, onClose, onSave }) => {
   const intl = useIntl();

   const isEdit = editItemIndex != null ? true : false;

   const [shipmentCurrentStep, setShipmentCurrentStep] = useState( isEdit ? 2 : 1);
   const [formData, setFormData ]                      = useState(editItemData || itemDataDefault);
   const [selectedItemId, setSelectedItemId ]          = useState(null);
   const [disabled, setDisabled]                       = useState(true);

   //console.log("editItemIndex=" + editItemIndex);
   //console.log("isEdit=" + isEdit);

   useEffect(() => {
    if( shipmentCurrentStep === 1 ){
      setDisabled(!selectedItemId);
    }else{     
      const isFormValid = formData.quantity && formData.description && formData.weight && formData.length && formData.width && formData.height
      setDisabled(!isFormValid);
    }
  }, [selectedItemId, formData]);

   const handleChangeServiceRate = (item) => {
    //console.log("item:");
    //console.log(item);

    setSelectedItemId(item.id);

    if( item.autoAdd ){
      setFormData({ ...item, quantity: 1, description: item.name});  
    }else{ 
      setFormData({ ...item, quantity: 1, description: '', weight: "", lengt: "", width: "", height: ""});  
    }

    //console.log("formData:");
    //console.log(formData);
  };

  const handleModalContinueButtonClick = () => {
    
    if (shipmentCurrentStep === 1 && !formData.autoAdd) {
      setShipmentCurrentStep(prevStep => prevStep + 1);
    } else {
      //add item
      //para garantir que o setFormData já esta actualizado
      setTimeout(() => {
        if( isEdit ){
          //console.log("isEdit");
          onSave(formData, editItemIndex);
        }else{
          onSave(formData);
        }
      }, 0);
    }
  };

  const handleModalBackButtonClick = () => {
    // This is needed as workaround since the button on the modal immediately changes
    // And is recognized as a click outside the modal and the modal closes
    setTimeout(() => {
      setShipmentCurrentStep(prevStep => prevStep - 1);
    }, 0);
  };

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Modal
    classNames={`new-order-shipment-options-modal ${shipmentCurrentStep === 2 ? 'new-order-shipment-options-modal--show-dropdown-top' : ''}`}
    footer={
      <div className="new-order-shipment-options-modal__footer">
        {shipmentCurrentStep === 2 && !isEdit && (
          <div
            className="new-order-shipment-options-modal__footer-go-back"
            onClick={handleModalBackButtonClick}
          >
            <img
              alt="Go Back"
              className="new-order-shipment-options-modal__footer-go-back-icon"
              src={chevronLeftIcon}
            />
            <FormattedMessage id="new-order.shipment.back" />
          </div>
        )}
        <div className="new-order-shipment-options-modal__button-wrapper">
          <Button
            context="primary"
            disabled={disabled}
            largeHorizontalPadding
            text={shipmentCurrentStep === 1 ?
              intl.formatMessage({ id: 'new-order.shipment.continue' }) :
              intl.formatMessage({ id: 'new-order.shipment.add' })
            }
            onClick={handleModalContinueButtonClick}
          />
        </div>
      </div>
    }
    footerAlignRight
    noScroll
    title={intl.formatMessage({ id: 'new-order.shipment.add_title' })}
    onClose={handleModalClose}
  >
    <div className="new-order-shipment-options-modal">
      
      {shipmentCurrentStep === 1 && (
        <>
          {serviceRates?.map((item, index) => (
            <div
              key={index}
              className="new-order-shipment-options-modal__input-group"
            >
              <div>
                <div className="new-order-shipment-options-modal__input-group-title">
                  {item?.service?.name}
                </div>
                <div className="new-order-shipment-options-modal__input-group-description">
                  {item?.service?.description}
                </div>
              </div>
              {item.items?.map((optionItem, optionItemIndex) => (
                <Radio
                  key={optionItemIndex}
                  description={optionItem.description}
                  icon={optionItem.iconUrl}
                  label={optionItem.name}
                  note={optionItem.price}
                  selected={selectedItemId}
                  value={optionItem.id}
                  onChange={() => handleChangeServiceRate(optionItem)}
                />
              ))}
            </div>
          ))}
        </>
      )}

      {shipmentCurrentStep === 2 && (
        <>
          <Notice
            description={intl.formatMessage({ id: 'new-order.shipment.alert' })}
            fixed
            icon={noticeIcon}
            noTopMargin
          />
          <div className="row">
            <div className="col col-sm-4">
              <div className="new-order-shipment-options-modal__input-group">
                <Quantity
                  block
                  label={intl.formatMessage({ id: 'new-order.shipment.item.quantity.label' })}
                  value={formData.quantity}
                  onChange={quantity => setFormData(prevState => ({ ...prevState, quantity }))}
                />
              </div>
            </div>
          </div>
          <div className="new-order-shipment-options-modal__input-group">
            <Textarea
              label={intl.formatMessage({ id: 'new-order.shipment.item.description.label' })}
              resizeVertical
              singleRow
              value={formData.description}
              onChange={description => setFormData(prevState => ({ ...prevState, description }))}
            />
          </div>
          <div className="row">
            <div className="col col-sm-4">
              <div className="new-order-shipment-options-modal__input-group">
                <Select
                  instructions={intl.formatMessage({ id: 'new-order.shipment.item.weight.placeholder' })}
                  label={intl.formatMessage({ id: 'new-order.shipment.item.weight.label' })}
                  options={formData.formattedWeights}
                  value={formData.weight}
                  onChange={value => setFormData(prevState => ({ ...prevState, weight: value }))}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 new-order-shipment-options-modal__input-group--stacked-mobile">
              <Select
                instructions={intl.formatMessage({ id: 'new-order.shipment.item.length.placeholder' })}
                label={intl.formatMessage({ id: 'new-order.shipment.item.length.label' })}
                options={formData.formattedLengths}
                value={formData.length}
                onChange={value => setFormData(prevState => ({ ...prevState, length: value }))}
              />
            </div>
            <div className="col-12 col-sm-4 new-order-shipment-options-modal__input-group--stacked-mobile">
              <Select
                instructions={intl.formatMessage({ id: 'new-order.shipment.item.width.placeholder' })}
                label={intl.formatMessage({ id: 'new-order.shipment.item.width.label' })}
                options={formData.formattedWidths}
                value={formData.width}
                onChange={value => setFormData(prevState => ({ ...prevState, width: value }))}
              />
            </div>
            <div className="col-12 col-sm-4 new-order-shipment-options-modal__input-group--stacked-mobile">
              <Select
                instructions={intl.formatMessage({ id: 'new-order.shipment.item.height.placeholder' })}
                label={intl.formatMessage({ id: 'new-order.shipment.item.height.label' })}
                options={formData.formattedHeights}
                value={formData.height}
                onChange={value => setFormData(prevState => ({ ...prevState, height: value }))}
              />
            </div>
          </div>
        </>
      )}
    </div>
  </Modal>
  );
};

ItemModal.propTypes = {
  serviceRates: PropTypes.arrayOf(PropTypes.shape({})),
  editItemIndex: PropTypes.number,
  editItemData: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

ItemModal.defaultProps = {
  serviceRates: [],
  editItemIndex: null,
  editItemData: null,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

export default ItemModal;
