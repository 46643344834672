import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  selectDropoffs,
  selectPickups,
  selectShipments,
  setShipmentMethod,
  resetDelivery
} from 'store/slices/new-delivery';

import { selectUser } from 'store/slices/authentication';

import Button from 'components/objects/button';
import InfoBox from 'components/new-order/info-box';
import NewOrderShipmentOptions from 'components/new-order/shipment-options';
//import MapRoute from 'components/map-route';
//import ShipmentMethodSelect from 'components/new-order/shipment-method';

import alertTriangle from 'images/icons/alert-triangle.svg';
import chevronLeftIcon from 'images/icons/chevron-left.svg';
import mapIcon from 'images/icons/map.svg';
//import markerIcon from 'images/icons/marker-blue.svg';

import '.././new-order-details/styles.scss';

const NewConciergeDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const user = useSelector(selectUser);

  const [deliveryError, setDeliveryError] = useState(null);
  const [dropoffAddress, setDropoffAddress] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [route, setRoute] = useState([]);
  const [showMap, setShowMap] = useState(false);

  const [startLocation, setStartLocation] = useState('');
  const [endLocation, setEndLocation] = useState('');
  const [userLocation, setUserLocation] = useState(null);

  const dropoffs = useSelector(selectDropoffs);
  const pickups = useSelector(selectPickups);
  const shipmentsItems = useSelector(selectShipments);

  const mapImg = user?.options?.menu?.homeMap;

  const mapImageUrl = mapImg && mapImg.includes("http") ? mapImg : null;

  const handleDeliveryError = (error) => {
    setDeliveryError(error?.message || (typeof error === 'string') ? error : "Something went wrong." );
  };

  useEffect(() => {
    if (
      pickups.length &&
      dropoffs.length &&
      dropoffs[0]?.address &&
      pickups[0]?.address &&
      pickups[0]?.address?.streetAddress !== '' &&
      dropoffs[0]?.address?.streetAddress !== ''
    ) {
      setShowMap(true);
    }
  }, [pickups, dropoffs]);

  const handleStartLocationChange = (location) => {
    //qd se escolhe uma morada de pickup
    console.log("handleStartLocationChange");
    setStartLocation(location);
  };

  const handleEndLocationChange = (location) => {
    //qd se escolhe uma morada de pickup ou stop
    console.log("handleEndLocationChange");
    setEndLocation(location);
  };

  const handleRouteRange = (orderRoute) => {
    //qd existe alteracao nos pickups, dropoffs ou stops
    console.log("handleRouteRange");
    //console.log(orderRoute);

    const mappedPickups = orderRoute.pickups.map(pickup => ({
      location: pickup,
      type: 'pickup'
    }));

    const mappedStops = orderRoute.stops.map(stop => ({
      location: stop,
      type: 'stop'
    }));

    const mappedDropoffs = orderRoute.dropoffs.map(dropoff => ({
      location: dropoff,
      type: 'dropoff'
    }));

    const joinedRoute = mappedPickups.concat(mappedStops).concat(mappedDropoffs);

    if (joinedRoute.length && JSON.stringify(joinedRoute) !== JSON.stringify(route)) {
      setRoute(joinedRoute);
    }
  };

  //const isDropoffAddressFilled = index => !!dropoffs[index]?.address?.streetAddress && !!dropoffs[index]?.address?.city;
  const isResetButton = true;

  const handleGeolocation = (position) => {
    if (position?.coords?.latitude && position?.coords?.longitude) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      setUserLocation(`${lat},${lng}`);
    }
  };

  navigator.geolocation.getCurrentPosition(handleGeolocation);

  const [shipmentMethodValue, setShipmentMethodValue] = useState('normal');

  useEffect(() => {
    if (shipmentMethodValue) {
      dispatch(setShipmentMethod(shipmentMethodValue));
    }
  }, [dispatch, shipmentMethodValue]);

  const handleShipmentMethodClick = (value) => {
    setShipmentMethodValue(value); //useEffect(() dispatch setShipType
  };

  const handleResetDeliveryButtonClick = () => {
    dispatch(resetDelivery());
    //dispatch(setDefaultPickup());
  };

  return (
    <div className="new-order-details">
      <div className="container">
        <Button
          classNames="new-order-details__previous"
          icon={chevronLeftIcon}
          noBackground
          noPadding
          text={intl.formatMessage({ id: 'header.back' })}
          onClick={() => history.goBack()}
        />
        <div className="row">
          <div className="col col-12 col-md-6">
            
            <div className={`new-order-shipment-options`}>
              <div className="new-order-shipment-options__header">
                <h1 className="new-order-shipment-options__header-title">
                  <FormattedMessage id="new-order.new_concierge" />
                </h1>
                <div className="new-order-shipment-options__header-buttons">
                  {isResetButton && (
                    <div className="new-order-shipment-options__reset-delivery">
                      <Button
                        context="primary"
                        noBackground
                        noPadding
                        noShadow
                        text={intl.formatMessage({ id: 'new-order.reset' })}
                        onClick={handleResetDeliveryButtonClick}
                      />
                    </div>
                  )}
                </div>
              </div>
              <NewOrderShipmentOptions
                requestType="concierge"
                onDeliveryError={handleDeliveryError}
                onStartLocationChange={handleStartLocationChange}
                onEndLocationChange={handleEndLocationChange}
                onRouteChange={newRoute => handleRouteRange(newRoute)}
              />
            </div>
          </div>
          <div className="col col-12 offset-0 col-md-5 offset-lg-1">
            <div className="new-order-details__map-wrapper">
                  {showMap ? (
                    <>
                      {/*  <MapRoute
                        id="newOrderMap"
                        route={route}
                       /> */}
                      {mapImageUrl && (
                        <img
                          alt="Map"
                          className="new-order-details__map-image"
                          src={mapImageUrl}
                        />
                      )}
                      {deliveryError && (
                        <div className="new-order-details__error">
                          <InfoBox
                            icon={alertTriangle}
                            title={deliveryError}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="new-order-details__map-placeholder">
                      <img
                        alt="Map Icon"
                        className="new-order-details__map-placeholder-icon"
                        src={mapIcon}
                      />
                      <FormattedMessage id="map.choose_addresses" />
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewConciergeDetails;
