import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const Disclaimer = () => (
  <div className="disclaimer">
    <FormattedMessage id="dashboard.search_no_results" />
  </div>
);

export default Disclaimer;
