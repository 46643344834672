const shortenString = (value, maximumLength) => {
  if (typeof value === 'string' && typeof maximumLength === 'number' && value && value.length > maximumLength) {
    const isLetterRegExp = RegExp(/^\p{L}/, 'u');
    let result = value;

    result = result.slice(0, maximumLength - 1);

    while (!isLetterRegExp.test(result.slice(result.length - 1))) {
      result = result.slice(0, result.length - 2);
    }

    result = `${result}…`;

    return result;
  }

  return value;
};

export default shortenString;
