import React from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from '@youship/assets/images/icons/arrow-right.inline.svg';

import isInternalUrl from '@youship/utils/check-url';

import './styles.scss';

const BUTTON_CONTEXT_DEFAULT = 'default';
const BUTTON_CONTEXT_PRIMARY = 'primary';

const Button = ({
  block,
  children,
  classNames,
  context,
  disabled,
  external,
  highlightedBorder,
  icon,
  inverted,
  largeHorizontalPadding,
  light,
  lightBorder,
  linkComponent,
  linkProps,
  noArrow,
  noBackground,
  noNewTab,
  noPadding,
  noShadow,
  onClick,
  outline,
  small,
  smallBorderRadius,
  smallPadding,
  smallVerticalPadding,
  text,
  isTextLeft,
  verticalPadding,
  to,
  ...props
}) => {
  const primary = context === BUTTON_CONTEXT_PRIMARY;

  const buttonClassName = `button${block ? ' button--block' : ''
  }${inverted ? ' button--inverted' : ''
  }${largeHorizontalPadding ? ' button--large-horizontal-padding' : ''
  }${light ? ' button--light' : ''
  }${lightBorder ? ' button--light-border' : ''
  }${noBackground ? ' button--no-background' : ''
  }${noShadow ? ' button--no-shadow' : ''
  }${noPadding ? ' button--no-padding' : ''
  }${outline ? ' button--outline' : ''
  }${primary ? ' button--primary' : ''
  }${small ? ' button--small' : ''
  }${smallBorderRadius ? ' button--small-border-radius' : ''
  }${smallPadding ? ' button--small-padding' : ''
  }${smallVerticalPadding ? ' button--small-vertical-padding' : ''
  }${verticalPadding ? ' button--vertical-padding' : ''
  }${!context && to ? ' button--link' : ''
  }${classNames ? ` ${classNames}` : ''
  }`;

  const buttonProps = { className: buttonClassName, disabled, onClick, ...props };
  const linkClassName = 'button-link';

  // TO-DO: refactor `to` to be sourced always from the same prop independent of being internal or external (currently it has two sources: `to` and `linkProps`)
  if (external && linkProps.to) {
    return (
      <a
        className={linkClassName}
        href={linkProps.to}
        rel="noopener noreferrer"
        target={!noNewTab && !isInternalUrl(linkProps.to) ? '_blank' : ''}
      >
        <button
          type="button"
          {...buttonProps}
        >
          {!!icon && (
            <img
              alt="Button Icon"
              className={`button__icon${text ? ' button__icon--margin' : ''}`}
              src={icon}
            />
          )}
          {text}
          {children}
          {!noArrow && (
            <ArrowIcon className="button-link__icon" />
          )}
        </button>
      </a>
    );
  }

  const Link = linkComponent;

  if (!external && Link) {
    return (
      <Link
        {...linkProps}
        className={linkClassName}
      >
        <button
          type="button"
          {...buttonProps}
        >
          {isTextLeft && (
              <>{text}</>
          )}
          {!!icon && (
            <img
              alt="Button Icon"
              className={`button__icon${text ? ( isTextLeft ? ' button__icon--margin_left' : ' button__icon--margin') : ''}`}
              src={icon}
            />
          )}
          {!isTextLeft && (
               <>{text}</>
          )}
          {children}
          {!noArrow && (
            <ArrowIcon className="button-link__icon" />
          )}
        </button>
      </Link>
    );
  }

  return (
    <button
      type="button"
      {...buttonProps}
    >
      {!!icon && (
        <img
          alt="Button Icon"
          className={`button__icon${text ? ' button__icon--margin' : ''}`}
          src={icon}
        />
      )}
      {text}
      {children}
    </button>
  );
};

Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  classNames: PropTypes.string,
  context: PropTypes.oneOf([
    BUTTON_CONTEXT_DEFAULT,
    BUTTON_CONTEXT_PRIMARY,
    null
  ]),
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.string,
  inverted: PropTypes.bool,
  largeHorizontalPadding: PropTypes.bool,
  light: PropTypes.bool,
  lightBorder: PropTypes.bool,
  linkComponent: PropTypes.oneOfType([
    PropTypes.shape({
      render: PropTypes.func
    }),
    PropTypes.func
  ]),
  linkProps: PropTypes.shape(),
  noArrow: PropTypes.bool,
  noBackground: PropTypes.bool,
  noPadding: PropTypes.bool,
  noShadow: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  small: PropTypes.bool,
  smallBorderRadius: PropTypes.bool,
  smallPadding: PropTypes.bool,
  smallVerticalPadding: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isTextLeft: PropTypes.bool,
  verticalPadding: PropTypes.bool,
  to: PropTypes.string
};

Button.defaultProps = {
  block: false,
  children: null,
  classNames: null,
  context: null,
  disabled: false,
  external: false,
  icon: '',
  inverted: false,
  largeHorizontalPadding: false,
  light: false,
  lightBorder: false,
  linkComponent: null,
  linkProps: {},
  noArrow: false,
  noBackground: false,
  noPadding: false,
  noShadow: false,
  onClick: () => { },
  outline: false,
  small: false,
  smallBorderRadius: false,
  smallPadding: false,
  smallVerticalPadding: false,
  text: '',
  isTextLeft: false,
  verticalPadding: false,
  to: ''
};

export default Button;
