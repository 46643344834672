import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import searchIcon from '@youship/assets/images/icons/search.svg';
import searchIconRed from '@youship/assets/images/icons/search--red.svg';


const Search = ({ collapseAlways, collapsible, iconIsActive, largeMargin, onCollapseToggle, ...inputProps }) => {
  const [showSearchInput, setShowSearchInput] = useState(!collapsible);
  const ref = useRef(null);
  const inputElementRef = useRef(null);

  const handleSearchIconClick = () => {
    if (collapsible) {
      setShowSearchInput(true);
      inputElementRef.current.focus();
      onCollapseToggle(true);
    }
  };

  const clickListener = (event) => {
    if (!(ref.current).contains(event?.target)) {
      handleClickOutside();
    }
  };

  const handleClickOutside = () => {
    if (collapseAlways || !inputElementRef.current.value) {
      setShowSearchInput(false);
      onCollapseToggle(false);
    } else {
      setShowSearchInput(true);
    }
  };

  useEffect(() => {
    if (collapsible) {
      document.addEventListener('click', clickListener);
    }

    return () => {
      document.removeEventListener('click', clickListener);
    };
  });

  useEffect(() => {
    if (showSearchInput && collapsible) {
      inputElementRef.current.focus();
    }
  }, [showSearchInput, collapsible]);

  return (
    <div
      ref={ref}
      className={`search${largeMargin ? ' search--large-margin' : ''}${collapsible ? ' search--collapsible' : ''}${showSearchInput ? ' search--show' : ''}`}
    >
      <img
        alt="Search Icon"
        src={iconIsActive ? searchIconRed : searchIcon}
        onClick={handleSearchIconClick}
      />
      <input
        ref={inputElementRef}
        className="search__input"
        type="text"
        {...inputProps}
      />
    </div>
  );
};

Search.propTypes = {
  collapseAlways: PropTypes.bool,
  collapsible: PropTypes.bool,
  largeMargin: PropTypes.bool,
  onChange: PropTypes.func,
  onCollapseToggle: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

Search.defaultProps = {
  collapseAlways: false,
  collapsible: false,
  largeMargin: false,
  onChange: () => {},
  onCollapseToggle: () => {},
  placeholder: '',
  value: ''
};

export default Search;
