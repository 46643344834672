import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { resetDeliveryProof } from 'store/slices/deliveries';

import Modal from '@youship/components/objects/modal';

import loaderIcon from 'images/icons/loader.svg';

import './styles.scss';

const DeliveryProofModal = ({ date, description, error, isLoading, name, onClose, signature, title, comments, resultTitle }) => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetDeliveryProof()), [dispatch]);

  return (
    <Modal
      classNames="delivery-proof-modal"
      small
      title={title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="delivery-proof-modal__body">
        {isLoading ? (
          <img
            alt="Loading"
            className="delivery-proof-modal__loader"
            src={loaderIcon}
          />
        ) : (
          <>
            {error ? (
              <div className="delivery-proof-modal__item">
                <div className="delivery-proof-modal__item-title">
                  <FormattedMessage id="order.proof.error" />
                </div>
                <div className="delivery-proof-modal__item-text">
                  {error}
                </div>
              </div>
            ) : (
              <>
                <div className="delivery-proof-modal__item">
                  <div className="delivery-proof-modal__item-title">
                    <FormattedMessage id="order.proof.result.title" />
                  </div>
                  <div className="delivery-proof-modal__item-text">
                    {resultTitle}
                  </div>
                </div>
                <div className="delivery-proof-modal__item">
                  <div className="delivery-proof-modal__item-title">
                    <FormattedMessage id="order.proof.name.title" />
                  </div>
                  <div className="delivery-proof-modal__item-text">
                    {name}
                  </div>
                </div>
                <div className="delivery-proof-modal__item">
                  <div className="delivery-proof-modal__item-title">
                    <FormattedMessage id="order.proof.date.title" />
                  </div>
                  <div className="delivery-proof-modal__item-text">
                    {date}
                  </div>
                </div>
                {!!comments && (
                  <div className="delivery-proof-modal__item">
                    <div className="delivery-proof-modal__item-title">
                      <FormattedMessage id="order.proof.comments.title" />
                    </div>
                    <div className="delivery-proof-modal__item-text">
                      {comments}
                    </div>
                  </div>
                )}
                {!!signature && (
                  <div className="delivery-proof-modal__item">
                    <div className="delivery-proof-modal__item-title">
                      <FormattedMessage id="order.proof.signature.title" />
                    </div>
                    <img
                      alt="Signature"
                      className="delivery-proof-modal__signature-image"
                      src={signature}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

DeliveryProofModal.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
  signature: PropTypes.string,
  title: PropTypes.string
};

DeliveryProofModal.defaultProps = {
  date: null,
  description: 'Proof information.',
  error: null,
  name: null,
  onClose: () => {},
  signature: null,
  title: 'Warning'
};

export default DeliveryProofModal;
