import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import { fetchDashboard, fetchDashboardShops, selectDashboardShops, selectSections } from 'store/slices/dashboard';

import { isAuthenticated, selectHasDefaultAddress, selectDefaultDeliveryType, setDefaultDeliveryType } from 'store/slices/authentication';

import DashboardHeader from 'components/sections/dashboard-header';
import DashboardSection from 'components/sections/dashboard-section';
import Disclaimer from 'components/disclaimer';
import RadioToggle from '@youship/components/objects/radio-toggle';
import Search from 'components/search';
import ShopCard from 'components/shop-card';
import NewOrderCard from 'components/new-order-card';


// NOTE: MOCKED DATA
const MOCKED_SHOP_OPTIONS = [
  {
    id: 0,
    name: 'dashboard.delivery',
    value: 'delivery',
    isActive: true
  },
  {
    id: 1,
    name: 'dashboard.pickup',
    value: 'pickup',
    isActive: false
  }
];

let isMockedLangSet = false;

const Dashboard = () => {
  const dispatch = useDispatch();
  const sections = useSelector(selectSections);
  const shops = useSelector(selectDashboardShops);
  const hasDefaultAddress = useSelector(selectHasDefaultAddress);
  const deliveryTypeValue = useSelector(selectDefaultDeliveryType);

  const intl = useIntl();

  if( !isMockedLangSet ){
    MOCKED_SHOP_OPTIONS.map(item => {
      item.name = intl.formatMessage({ id: item.name })
    });
    
    isMockedLangSet = true;
  }

  const [searchValue, setSearchValue] = useState('');
  //const [selectedShopOptionId, setSelectedShopOptionId]       = useState(MOCKED_SHOP_OPTIONS[0].id);
  //const [selectedShopOptionValue, setSelectedShopOptionValue] = useState(MOCKED_SHOP_OPTIONS[0].value);

  const handleSearchChange = (event) => {
    setSearchValue(event?.target?.value ?? '');
  };

  const handleRadioToggleChange = (id, value) => {
    //setSelectedShopOptionValue(value);
    //setSelectedShopOptionId(id);
    //console.log("handleRadioToggleChange:" +  value);
    dispatch( setDefaultDeliveryType( {type: value}) );
  };

  useEffect(() => {
    //dispatch(fetchDashboard({deliveryType: deliveryTypeValue}))
    dispatch(fetchDashboard())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading the dashboard.');
        //console.log(response);
        return response;
      })
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch, deliveryTypeValue]);

  useEffect(() => {
    if (searchValue) {
      dispatch(fetchDashboardShops(searchValue))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading the dashboard.');
          return response;
        })
        .catch((error) => {
          // Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [dispatch, searchValue]);

  let existsCategories = false;
  sections.forEach(section => {
     if ( Array.isArray(section?.categories) && section.categories.length > 0 ){
      existsCategories = true;
    } 
  });
    

  return (
    <div className="dashboard">

      {!hasDefaultAddress && (
        <DashboardHeader/>
      )}

      {hasDefaultAddress && (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <Search
              placeholder={intl.formatMessage({ id: 'dashboard.search' })}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-12 col-md-4">
            <RadioToggle
              options={MOCKED_SHOP_OPTIONS}
              selectedValue={deliveryTypeValue}
              onChange={handleRadioToggleChange}
            />
          </div>
        </div>
      </div>
      )}

      {!searchValue && sections.map(section => (
        <DashboardSection
          key={section.id}
          {...section}
        />
      ))}

      {!searchValue && !existsCategories && (
        <div className="dashboard-section">
          <div className="container">
            <div className="row dashboard-section__new-order">
              <NewOrderCard />
            </div>
          </div>
        </div>
      )}
      {Array.isArray(shops) && searchValue && (
        <div className="dashboard-section">
          <div className="container">
            <div className="row">
              {shops.length ?
                shops.map(item => (
                  <div
                    key={item.id}
                    className="col col-12 col-sm-6 col-lg-4"
                  >
                    <ShopCard {...item} />
                  </div>
                )) : (
                  <div className="col col-12">
                    <Disclaimer />
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
