import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Button from 'components/objects/button';
import DateSelect from 'components/date-select';
import Search from 'components/search';
import Select from '@youship/components/objects/select';
import RadioToggle from '@youship/components/objects/radio-toggle';

import exportOrdersIcon from 'images/icons/export.svg';
import clearDateIcon from 'images/icons/circle-close.svg';
import loaderIcon from 'images/icons/loader.svg';
import filterIcon from 'images/icons/filter.svg';
import plusIcon from 'images/icons/plus--white.svg';
import userIcon from 'images/icons/account.svg';

import './styles.scss';

const USERS_OPTIONS_RESET = 'reset';

const userOrderTypes = [
  {
    name: 'orders.tab.active',
    value: 'active'
  },
  {
    name: 'orders.tab.history',
    value: 'history'
  },
  {
    name: 'orders.tab.all',
    value: 'all'
  },
  {
    name: 'orders.tab.draft',
    value: 'draft'
  }
];

const transporterOrderTypes = [
  {
    name: 'orders.tab.active',
    value: 'active'
  },
  {
    name: 'orders.tab.history',
    value: 'history'
  },
  {
    name: 'orders.tab.search',
    value: 'search'
  }
];

const driverOrderTypes = [
  {
    name: 'orders.tab.active',
    value: 'active'
  },
  {
    name: 'orders.tab.history',
    value: 'history'
  }
];

const shopOrderTypes = [
  {
    name: 'orders.tab.active',
    value: 'active'
  },
  {
    name: 'orders.tab.history',
    value: 'history'
  }
];

const listGrouped = [
  {
    id: 0,
    name: 'orders.tab.list_normal',
    value: false,
    isActive: true
  },
  {
    id: 1,
    name: 'orders.tab.list_grouped',
    value: true,
    isActive: false
  }
];

const OrdersHeader = ({
  accountUsers,
  dateFrom,
  dateTo,
  downloadDeliveriesFile,
  isExportingFile,
  onCurrentUserIdChange,
  onDateChange,
  onDateReset,
  onSearchValueChange,
  onTypeChange,
  onListModeChange,
  searchValue,
  selectedCurrentUserId,
  selectedType,
  selectedListMode,
  transporterDrivers,
  userType,
  userIsTransporterOrDriver,
  isOptionNewOrder,
  isOptionExportOrders
}) => {
  const [searchIsExpanded, setSearchIsExpanded] = useState(false);

  const handleCurrentUserTypeOptionsSelectChange = (option) => {
    onCurrentUserIdChange(option === USERS_OPTIONS_RESET ? '' : option);
  };

  const handleDateSelectChange = (startDate, endDate) => {
    onDateChange(startDate, endDate);
  };

  const handleDateSelectReset = () => {
    onDateReset();
  };

  const handleSearchChange = (event) => {
    onSearchValueChange(event?.target?.value);
  };

  const handleSearchOnCollapseToggle = (value) => {
    setSearchIsExpanded(value);
  };

  const handleTypeClick = (type) => {
    onTypeChange(type);
  };

  const handleListToggleChange = (id, value) => {
    onListModeChange(value);
  };


  const intl = useIntl();

  const accountUsersOptions = Array.isArray(accountUsers) ?
    accountUsers.map(user => ({
      text: user.name,
      value: user.id
    })) :
    [];

  accountUsersOptions.unshift({
    text: intl.formatMessage({ id: 'orders.tab.all_users' }),
    value: USERS_OPTIONS_RESET
  });


  const transporterDriversOptions = Array.isArray(transporterDrivers) ?
    transporterDrivers.map(driver => ({
      text: driver.name,
      value: driver.id
    })) :
    [];

    
  transporterDriversOptions.unshift({
    text: intl.formatMessage({ id: 'orders.tab.unassigned_drivers' }),
    value: "unassigned"
  });

  transporterDriversOptions.unshift({
    text: intl.formatMessage({ id: 'orders.tab.all_drivers' }),
    value: USERS_OPTIONS_RESET
  });
  
  const userOrderTypesOptions = Array.isArray(userOrderTypes) ?
    userOrderTypes.map(type => ({
      text: intl.formatMessage({ id: type.name }),
      value: type.value
    })) :
    [];

  const transporterOrderTypesOptions = Array.isArray(transporterOrderTypes) ?
    transporterOrderTypes.map(type => ({
      text: intl.formatMessage({ id: type.name }),
      value: type.value
    })) :
    [];

    const driverOrderTypesOptions = Array.isArray(driverOrderTypes) ?
    driverOrderTypes.map(type => ({
      text: intl.formatMessage({ id: type.name }),
      value: type.value
    })) :
    [];  

  const shopOrderTypesOptions = Array.isArray(shopOrderTypes) ?
    shopOrderTypes.map(type => ({
      text: intl.formatMessage({ id: type.name }),
      value: type.value
    })) :
    [];

    const listGroupedOptions = Array.isArray(listGrouped) ?
    listGrouped.map(type => ({...type,
      name: intl.formatMessage({ id: type.name })
    })) :
    [];

  const currentUserId = selectedCurrentUserId || USERS_OPTIONS_RESET;

  let currentUserTypeOptions = accountUsersOptions;
  let currentUserTypeOrderTypes = userOrderTypes;
  let currentUserTypeOrderTypesOptions = userOrderTypesOptions;

  if (userType === 'transporter') {
    currentUserTypeOptions = transporterDriversOptions;
    currentUserTypeOrderTypes = transporterOrderTypes;
    currentUserTypeOrderTypesOptions = transporterOrderTypesOptions;
  } else if (userType === 'driver') {
    currentUserTypeOrderTypes = driverOrderTypes;
    currentUserTypeOrderTypesOptions = driverOrderTypesOptions;
  } else if (userType === 'shop') {
    currentUserTypeOrderTypes = shopOrderTypes;
    currentUserTypeOrderTypesOptions = shopOrderTypesOptions;
  }

  return (
    <div className="orders-header">
       <ul className={`row orders-header-nav${searchIsExpanded ? ' orders-header-nav--search-is-expanded' : ''}`}>
        <div className="col-12 col-xl-3 orders-header-nav__left-col">
          <li className="orders-header-nav__item orders-header-nav__item--search">
            <Search
              collapseAlways
              iconIsActive={!!searchValue}
              placeholder={intl.formatMessage({ id: 'orders.search' })}
              value={searchValue || ''}
              onChange={handleSearchChange}
              onCollapseToggle={handleSearchOnCollapseToggle}
            />
          </li>
        </div>
        <div className="col-12 col-xl-9 orders-header-nav__right-col">
          <li className="orders-header-nav__item orders-header-nav__item--types">
            <Select
              disabled={!currentUserTypeOrderTypes.length}
              icon={filterIcon}
              minWidth
              noArrow
              noInstructions
              noPadding
              options={currentUserTypeOrderTypesOptions}
              value={selectedType}
              onChange={handleTypeClick}
            />
          </li>
          {((Array.isArray(accountUsers) && !!accountUsers.length) || (Array.isArray(transporterDrivers) && !!transporterDrivers.length)) && (
            <li className="orders-header-nav__item orders-header-nav__item--driver">
              <Select
                disabled={!currentUserTypeOptions.length}
                icon={userIcon}
                minWidth
                noArrow
                noInstructions
                noPadding
                options={currentUserTypeOptions}
                value={currentUserId}
                onChange={handleCurrentUserTypeOptionsSelectChange}
              />
            </li>
          )}
          <li className="orders-header-nav__item orders-header-nav__item--date">
            <DateSelect
              endDate={dateTo || null}
              foreground
              maxDate={new Date()}
              range
              rightIcon={(!!dateFrom || !!dateTo) ? clearDateIcon : ''}
              startDate={dateFrom || null}
              onChange={handleDateSelectChange}
              onReset={handleDateSelectReset}
            />
          </li>
          { isOptionExportOrders && (
            <>
            <li className="orders-header-nav__item orders-header-nav__item--clear-date orders-header-nav__item--export">
              {isExportingFile ? (
                <Button
                  icon={loaderIcon}
                  lightBorder
                  outline
                  smallBorderRadius
                  smallPadding
                />
              ) : (
                <Button
                  icon={exportOrdersIcon}
                  lightBorder
                  outline
                  smallBorderRadius
                  smallPadding
                  onClick={downloadDeliveriesFile}
                />
              )}
            </li>
            </>
          )}
          { isOptionNewOrder && (
            <>
            <div className="orders-header-nav__item orders-header-nav__vertical-divider" />
            <li className="orders-header-nav__item orders-header-nav__item--new-order">
              <Button
                context="primary"
                icon={plusIcon}
                linkComponent={Link}
                linkProps={{ to: '/new-order' }}
                noArrow
                smallPadding
              />
            </li>
            </>
          )}
        </div>
      </ul>
      <div className="orders-header-nav__list-mode">
        <RadioToggle
          options={listGroupedOptions}
          selectedValue={selectedListMode}
          onChange={handleListToggleChange}
        />
      </div>
    </div>
  );
};

OrdersHeader.propTypes = {
  accountUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  })),
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  downloadDeliveriesFile: PropTypes.func,
  onCurrentUserIdChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onDateReset: PropTypes.func,
  onSearchValueChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  onListModeChange: PropTypes.func,
  searchValue: PropTypes.string,
  selectedCurrentUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedType: PropTypes.string,
  transporterDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  })),
  userType: PropTypes.string,
  userIsTransporterOrDriver: PropTypes.bool,
  isOptionNewOrder: PropTypes.bool,
  isOptionExportOrders: PropTypes.bool
};

OrdersHeader.defaultProps = {
  accountUsers: [],
  dateFrom: null,
  dateTo: null,
  downloadDeliveriesFile: () => {},
  onCurrentUserIdChange: () => {},
  onDateChange: () => {},
  onDateReset: () => {},
  onSearchValueChange: () => {},
  onTypeChange: () => {},
  onListModeChange: () => {},
  searchValue: '',
  selectedCurrentUserId: null,
  selectedType: 'active',
  transporterDrivers: [],
  userType: null,
  userIsTransporterOrDriver: false,
  isOptionNewOrder: false,
  isOptionExportOrders: false
};

export default OrdersHeader;
