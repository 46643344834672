import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, selectIsLoggingIn } from 'store/slices/authentication';
import { FormattedMessage, useIntl } from 'react-intl';

import Notice from '@youship/components/objects/notice';
import Input from '@youship/components/objects/input';
import Button from '@youship/components/objects/button';

import greenCheckIcon from 'images/icons/check-green.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
 
  const intl = useIntl();

  const isLoading = useSelector(selectIsLoggingIn);

  const [errorMessage, setErrorMessage] = useState( null);
  const [requestSucceeded, setRequestSucceeded] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  
  const [formData, setFormData] = useState(
            { 
              newPassword: null,
              newPasswordRepeated: null
            }
      );

  const urlParameters = new URLSearchParams(window.location.search);
  const token = urlParameters.get('t');    

  const handleInputChange = (value, name) => {    
    setFormData( { ...formData, [name]: value } );
  };

  // tem efeito qd existir uma alteracao no formData
  useEffect(() => {

    setIsFormValid(false);

    if( 
      token !== null && token !== "" && 
      formData.newPassword !== null && formData.newPassword !== "" && 
      formData.newPasswordRepeated !== null && formData.newPasswordRepeated !== "" && 
      formData.newPassword === formData.newPasswordRepeated
      ){
      setIsFormValid(true);
    }


    if( token == null || token === "" ){
      setErrorMessage( intl.formatMessage({ id: 'password.token_error' }));
    }
  
  }, [formData, token]);

  const handleFormSaveClick = (event) => {

    event.preventDefault();

    setErrorMessage(null);

    dispatch( resetPassword( {token: token, password: formData.newPassword} ) )
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

        return response;
      })
      .then((response) => {
        
        setRequestSucceeded(response?.message || intl.formatMessage({ id: 'password.changed_success' }));
        
        return response;
      })
      .catch((error) => {
        // NOTE: Add proper error handling
        // eslint-disable-next-line no-console
        
        setErrorMessage(error?.message);
        setRequestSucceeded(null);
        
      });
      
  };

  return (
    <>
    { (!!requestSucceeded || !!errorMessage) && (
      <>
        <Notice
          classNames="action-modal__notice"
          description={requestSucceeded || errorMessage}
          fixed
          icon={requestSucceeded ? greenCheckIcon : noticeIcon}
        />
      </>  
    )}

    { ! requestSucceeded  && (
      <form className="reset-password-form">
       <div className="user-modal__item">
        <Input
            block
            inputId="password-update-modal-new-password-input"
            label={intl.formatMessage({ id: 'password.new_password' })}
            type="password"
            value={formData.newPassword}
            onChange={e => handleInputChange(e.target.value, 'newPassword')}
          />
        </div>
        <div className="reset-password-form__item">
          <Input
              block
              inputId="password-update-modal-new-password-repeated-input"
              label={intl.formatMessage({ id: 'password.repeat_password' })}
              type="password"
              value={formData.newPasswordRepeated}
              onChange={e => handleInputChange(e.target.value, 'newPasswordRepeated')}
            />
        </div>

        <div className="reset-password-form__footer">
          <Link
              className="reset-password-form__link"
              to="/login"
            >
            <FormattedMessage id="login.forgot_password.back" />
          </Link>
          <Button
            context="primary"
            disabled={!isFormValid || isLoading || requestSucceeded}
            largeHorizontalPadding
            smallVerticalPadding
            text={intl.formatMessage({ id: 'password.button' })}
            onClick={event => handleFormSaveClick(event)}
          />
        </div>
      </form>
    )}    

    { requestSucceeded  && (
      <form className="reset-password-form">  
        <div className="reset-password-form__footer">
          <Link
              className="reset-password-form__link"
              to="/login"
            >
            <FormattedMessage id="login.forgot_password.back" />
          </Link>
        </div>
       </form>
      )
    }

  </>
  )
};

export default ResetPasswordForm;
