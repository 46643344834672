/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Textarea = ({ label, onChange, resizeVertical, singleRow, ...textareaProps }) => (
  <div className="textarea">
    {label && (
      <div className="textarea__label">
        {label}
      </div>
    )}
    <textarea
      {...textareaProps}
      className={`textarea__input${
        resizeVertical ? ' textarea__input--resize-vertical' : ''}${
        singleRow ? ' textarea__input--single-row' : ''
      }`}
      rows={singleRow ? '1' : ''}
      onChange={event => onChange(event?.target?.value ?? '')}
    />
  </div>
);


Textarea.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  resizeVertical: PropTypes.bool,
  singleRow: PropTypes.bool,
  value: PropTypes.string.isRequired
};

Textarea.defaultProps = {
  label: '',
  onChange: () => {},
  resizeVertical: false,
  singleRow: false
};

export default Textarea;
