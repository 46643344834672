import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Notice from '@youship/components/objects/notice';
import Input from '@youship/components/objects/input';
import Button from '@youship/components/objects/button';

import './styles.scss';

const PRIVACY_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL + "/privacy/";

const AppPrivacyForm = () => {
  const intl = useIntl();

  return (
    <>
      <h4 className="app-privacy-area__subtitle">
        <FormattedMessage id="app-privacy.subtitle1" />
      </h4>
      <div className="app-privacy-area__subdescription">
      <a className=""
          href={PRIVACY_URL}
        >
          <FormattedMessage id="app-privacy.description1" />
        </a>
      </div>

      <h4 className="app-privacy-area__subtitle">
        <FormattedMessage id="app-privacy.subtitle2" />
      </h4>
      <div className="app-privacy-area__subdescription">
          <FormattedMessage id="app-privacy.description2" />
      </div>
    </>  
  )
};

export default AppPrivacyForm;
