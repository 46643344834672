import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
import Select from '@youship/components/objects/select';
/*
import Input from '@youship/components/objects/input';
import Textarea from '@youship/components/objects/textarea';
import SwitchGroup from '@youship/components/objects/switch-group';
*/

import './styles.scss';

const AssignModal = ({
  isLoading,
  isLoadingDrivers,
  errorMessageModal,
  text,
  title,
  driversList,
  curDriverCode,
  onClose,
  onSave
}) => {

  const intl = useIntl();
  
  const [errorMessage, setErrorMessage] = useState( errorMessageModal || null);

  const [isFormValid, setIsFormValid] = useState(false);
  
  const driversListOptions = Array.isArray(driversList) ?
    driversList.map(driver => ({
      text: driver.name,
      value: driver.id
    })) :
    [];
  
  const [assign, setAssign] = useState(
            { 
              driver_code: null
            }
      );

  const handleInputChange = (value, name) => { 
    setAssign( { ...assign, [name]: value } );
  };


  // tem efeito qd existir uma alteracao no assign driver
  useEffect(() => {
    setIsFormValid(false);
    if( assign.driver_code != null && assign.driver_code !== "" ){
      setIsFormValid(true);
    }
  }, [assign]);

  const handleCollectSaveClick = (event) => {
    event.preventDefault();

    setErrorMessage(null);
    
    onSave(event, assign.driver_code);
  };
  
 
  return (
    <Modal
      classNames="assign-modal"
      footer={
        <div className="assign-modal__footer">
          { errorMessage || errorMessageModal && (
            <p className="assign-modal__error-message">
              { errorMessage || errorMessageModal }
            </p>
          )}
          <Button
            classNames="assign-modal__button"
            context="primary"
            disabled={isLoading || !isFormValid}
            largeHorizontalPadding
            text={intl.formatMessage({ id: 'order.action.confirm' })}
            onClick={!isLoading ? event => handleCollectSaveClick(event) : () => {}}
          />
        </div>
      }
      title={text || title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="assign-modal__content">
        
        <div className="assign-modal__item">
          <Select
              label={ intl.formatMessage({ id: 'order.assign.driver.title' }) + " (*)" }
              instructions={intl.formatMessage({ id: 'order.assign.driver.placeholder' })}
              options={driversListOptions}
              value={ (curDriverCode && !assign.driver_code) ? curDriverCode : assign.driver_code  }
              onChange={value => handleInputChange(value, 'driver_code')}
              
            />
        </div>
      
      </div>
    </Modal>
  );
};

AssignModal.propTypes = {
  errorMessageModal: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingDrivers: PropTypes.bool,
  driversList: PropTypes.arrayOf(PropTypes.shape({})),
  curDriverCode: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

AssignModal.defaultProps = {
  errorMessageModal: null,
  isLoading: false,
  isLoadingDrivers: false,
  driversList: null,
  curDriverCode: null,
  onClose: () => {},
  onSave: () => {}
};

export default AssignModal;
