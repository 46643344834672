import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const OrderDropOff = ({ address, contact }) => {
  const name = contact?.lineContact;
  // NOTE: formattedAddress is only used to verify the existance of address
  const formattedAddress = address?.formattedAddress;
  let streetAddress = address?.streetAddress ? JSON.stringify(address.streetAddress) : null;
  const phone = contact?.phone;

  streetAddress = streetAddress ? streetAddress.slice(1, streetAddress.length - 1).replace(/\\n/g, '<br />') : '';

  return (
    <>
      {(!!name || !!streetAddress || !!phone) && (
        <div className="order-drop-off">
          <h3 className="order-drop-off__title">
            <FormattedMessage id="order.dropoff.title" />
          </h3>
        {/*   <div className="order-drop-off__description">
            <FormattedMessage id="order.dropoff.description" />
          </div> */}
          <div className="order-drop-off__details">
            <div className="order-drop-off__item order-drop-off__item--flex order-drop-off__item--highlight">
              {!!name && (
                <span>
                  {name}
                </span>
              )}
              {!!phone && (
                <a
                  href={`tel:${phone}`}
                >
                  <div className="order-drop-off__phone-badge">
                    <span className="order-drop-off__phone-number">
                      {phone}
                    </span>
                  </div>
                </a>
              )}
            </div>
            {!!streetAddress && (
              <div
                className="order-drop-off__item"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: streetAddress }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

OrderDropOff.propTypes = {
  address: PropTypes.shape({
    formattedAddress: PropTypes.string,
    streetAddress: PropTypes.string
  }),
  contact: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string
  })
};

OrderDropOff.defaultProps = {
  address: null,
  contact: null
};

export default OrderDropOff;
