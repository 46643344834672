import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { requestRecoverToken, selectIsLoggingIn } from 'store/slices/authentication';
import { FormattedMessage, useIntl } from 'react-intl';

import Input from '@youship/components/objects/input';
import Button from '@youship/components/objects/button';

import './styles.scss';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const isLoggingIn = useSelector(selectIsLoggingIn);

  const [email, setEmail] = useState('');
  const [emailInputMessage, setEmailInputMessage] = useState(null);
  const [emailInputStatus, setEmailInputStatus] = useState('default');
  const [requestSucceeded, setRequestSucceeded] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setEmailInputMessage(null);
    setEmailInputStatus('default');
    setIsFormValid(email.length >= 3);
  }, [email]);

  const intl = useIntl();

  const validateEmail = value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);

  const handleButtonClick = (event) => {
    event.preventDefault();

    if (validateEmail(email)) {
      if (isFormValid) {
        dispatch(requestRecoverToken(({ email })))
          .then((response) => {
            if (response && response.error) throw new Error(response.error.message || 'Something went wrong while trying to request recover token.');

            setEmailInputStatus('success');
            setEmailInputMessage(response?.message || intl.formatMessage({ id: 'login.forgot_password.success' }));
            setRequestSucceeded(true);

            return response;
          })
          .catch((error) => {
            setEmailInputMessage(error.message);
            setEmailInputStatus('error');
          });
      }
    } else {
      setEmailInputStatus('error');
      setEmailInputMessage(intl.formatMessage({ id: 'login.email.pattern' }));
    }
  };

  return (
    <form className="forgot-password-form">
      <Input
        block
        disabled={isLoggingIn || requestSucceeded}
        inputId="forgot-password-email"
        label={intl.formatMessage({ id: 'login.email.label' })}
        message={emailInputMessage}
        placeholder={intl.formatMessage({ id: 'login.email.placeholder' })}
        status={emailInputStatus}
        onChange={event => setEmail(event?.target?.value ?? '')}
      />
      <div className="forgot-password-form__footer">
        <Link
          className="forgot-password-form__link"
          to="/login"
        >
          <FormattedMessage id="login.forgot_password.back" />
        </Link>
        <Button
          context="primary"
          disabled={!isFormValid || isLoggingIn || requestSucceeded}
          largeHorizontalPadding
          smallVerticalPadding
          text={intl.formatMessage({ id: 'login.forgot_password.reset' })}
          onClick={event => handleButtonClick(event)}
        />
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
