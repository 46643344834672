import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {useIntl } from 'react-intl';

import Input from '@youship/components/objects/input';
import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';
import Loader from '@youship/components/objects/loader';

import greenCheckIcon from 'images/icons/check-green.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';

const MsgModal = ({
  title,
  isLoading,
  finalMessage,
  isNoticeMessage,
  errorMessageFooter,
  onClose,
}) => {

  const intl = useIntl();

  return (
    <Modal
      classNames="msg-modal"
      footer={
        <div className="msg-modal__footer">
          { (errorMessageFooter) && (
            <p className="msg-modal__error-message">
               {errorMessageFooter}
            </p>
          )}
          {finalMessage && (
              <Button
                classNames="msg-modal__button"
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                noArrow
                text={intl.formatMessage({ id: 'app.close' })}
                onClick={onClose}
              />
          )}

        </div>
      }
      title={title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="msg-modal__content">
        { (!!finalMessage) && (
          <>
            <Notice
              classNames="action-modal__notice"
              description={finalMessage}
              fixed
              icon={isNoticeMessage ? noticeIcon: greenCheckIcon}
            />
          </>  
        )}
        { isLoading && (
        <>
          <div>
            <Loader />
          </div>
        </>
        )}

      </div>
    </Modal>
  );
};

MsgModal.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  finalMessage: PropTypes.string,
  isNoticeMessage: PropTypes.bool,
  errorMessageFooter: PropTypes.string,
  onClose: PropTypes.func,
};

MsgModal.defaultProps = {
  isLoading: false,
  isNoticeMessage: false,
  errorMessageFooter: null,
  onClose: () => {}
};

export default MsgModal;
