const formatPrice = (value, currency='EUR', removeTraillingZeros = false) => {
  let price = value;

  if (typeof value === 'string') price = parseFloat(value.replace(',', '.'));
  else if (typeof value !== 'number') price = parseFloat(value);

  if (typeof price === 'number') {
    /* eslint-disable-next-line no-undefined */
    return price.toLocaleString(undefined, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: removeTraillingZeros ? 0 : 2,
      maximumFractionDigits: 2
    });
  }

  return '–';
};

export default formatPrice;
