
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import addressIcon from '@youship/assets/images/icons/address--input-background.svg';

import './styles.scss';

const InputAddressSearch = ({ onChange, onSearch, submitButtonText, ...inputProps }) => {
  
  const { value } = inputProps;
  const [disabled, setDisabled] = useState(false);
  const [isGoogleInput, setIsGoogleInput] = useState(false);

  useEffect(() => {
    if (value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value]);

  const handleInputStarClick = () => {
    setIsGoogleInput(true);

    setTimeout(() => {
      if(document.getElementById('user-address') ){
        document.getElementById('user-address').focus();
      } 
    }, 10);
    
  }

  const handleEnterKeyClick = (event) => {
    //escape - 27, ENTER - 13
    if (event.keyCode === 13 || event.keyCode === 27) {
      event.preventDefault();
      //guarda o nome da rua que o user escreveu
      //onAddressChange({ streetAddress: event?.target?.value ?? '' });
      //console.log("handleEnterKeyClick: " + event?.target?.value );
    }
  };

  const onSearchInputChange = (value) => { 
      //console.log("onSearchInputChange: " + value );
      onSearch(value);
  };

  const handleAddressChange = (googleAddress, coordinates) => {
    const addressComponents = googleAddress?.address_components;

    if (Array.isArray(addressComponents) && addressComponents.length) {
      const formattedAddressData = { addressComponents: {} };

      formattedAddressData.lat = coordinates?.lat ?? googleAddress.geometry.location.lat();
      formattedAddressData.lng = coordinates?.lng ?? googleAddress.geometry.location.lng();
      formattedAddressData.formattedAddress = googleAddress.formatted_address;

      //console.log("Gooogle Address Form - handleAddressChange:");
      //console.log(googleAddress);

      addressComponents.forEach((addressComponent) => {
        if (Array.isArray(addressComponent?.types) && addressComponent.types.length) {
          if (addressComponent.types.includes('postal_code')) {
            formattedAddressData.postalCode = addressComponent.long_name;
            formattedAddressData.addressComponents.postalCode = addressComponent.long_name;
          } else if (addressComponent.types.includes('locality')) {
            formattedAddressData.city = addressComponent.long_name;
            formattedAddressData.addressComponents.city = addressComponent.long_name;
          } else if (addressComponent.types.includes('street_number')) {
            formattedAddressData.numberAddress = addressComponent.long_name;
            formattedAddressData.addressComponents.numberAddress = addressComponent.long_name;
          } else if (addressComponent.types.includes('route')) {
            formattedAddressData.streetAddress = addressComponent.long_name;
            formattedAddressData.addressComponents.route = addressComponent.long_name;
          } else if (addressComponent.types.includes('sublocality')) {
            formattedAddressData.addressComponents.sublocality = addressComponent.long_name;
          } else if (addressComponent.types.includes('country')) {
            formattedAddressData.countryCode = addressComponent.short_name;
            formattedAddressData.addressComponents.country = addressComponent.short_name;
          }
        }
      });

      //fill streetAddress when streetAddress is empty
      if( !formattedAddressData.streetAddress ){
        let lines = formattedAddressData.formattedAddress.split(',');
        if( lines.length >= 4 ){
          formattedAddressData.streetAddress = lines[0] + ", " + lines[1];
        }else{
          formattedAddressData.streetAddress = lines[0];
        }
      }

      //console.log("formattedAddressData:");
      //console.log(formattedAddressData);
      onChange(formattedAddressData);
  
    }
  };

  return (
    <div className="input-address-search">
      { !isGoogleInput && (  
        <input
          {...inputProps}
          className="input-address-search__input"
          style={{ backgroundImage: `url(${addressIcon})` }}
          value={!!value ? value : ""}
          onChange={onChange}
          onClick={handleInputStarClick}
        />
      )}

      { isGoogleInput && (  
        <Input
          {...inputProps}
          block
          inputId={`user-address`}
          role="presentation"
          type="address"
          classNames="input-address-search__input"
          /*style={{ backgroundImage: `url(${addressIcon})` }}*/
          backgroundImage={addressIcon}
          value={!!value ? value : ""}
          onAddressChange={handleAddressChange}
          onChange={event => onSearchInputChange(event?.target?.value ?? '')}
          onKeyDown={handleEnterKeyClick}
        />
      )}    
      <Button
        context="primary"
        disabled={disabled}
        largeHorizontalPadding
        smallVerticalPadding
        onClick={() => onSearchInputChange(value)}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};

InputAddressSearch.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  submitButtonText: PropTypes.string
};

InputAddressSearch.defaultProps = {
  onChange: () => {},
  onSearch: () => {},
  submitButtonText: 'Search'
};

export default InputAddressSearch;
