import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';

import './styles.scss';


const NotFoundContent = () => {
  const intl = useIntl();	
  return (
  <div className="not-found">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-lg-8">
          <div className="not-found__subtitle">
            <FormattedMessage id="app.page_not_found.header" />
          </div>
          <div className="not-found__title">
            <FormattedMessage id="app.page_not_found.title" />
          </div>

          <div className="not-found__description">
           <FormattedMessage id="app.page_not_found.description" />
          </div>
          <Link
            to="/"
            className="not-found__button-link"
          >
            <Button
              text={intl.formatMessage({ id: 'app.page_not_found.btn' })}
              context="primary"
            />
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
  };

export default NotFoundContent;
