import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '@youship/components/objects/loader';
import InfoBox from 'components/new-order/info-box';

import markerPickupIcon from 'images/icons/marker-blue.svg';
import markerStopIcon from 'images/icons/marker-green.svg';
import markerDropoffIcon from 'images/icons/marker.svg';
import alertTriangle from 'images/icons/alert-triangle.svg';
import markerMotoIcon from 'images/icons/motorcycle.svg';

import './styles.scss';

const GOOGLE_MAPS_API_KEY =  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

//vehicle, robot, motorcycle
const SVG_vehicle = "M62.1,36.5c-0.9-1.2-3.6-1.5-3.6-1.5c0.1-3.5,0.5-6.9,0.7-8.2c1.9-7.3-1.7-11.8-1.7-11.8c-4.8-4.8-9.1-5-12.5-5   c-3.4,0-7.8,0.3-12.5,5c0,0-3.6,4.5-1.7,11.8c0.2,1.2,0.5,4.6,0.7,8.2c0,0-2.7,0.3-3.6,1.5c-0.9,1.2-0.9,1.9,0,1.9   c0.9,0,2.9-2.3,3.6-2.3V35c0,1,0.1,2,0.1,3c0,4.4,0,33.7,0,33.7s-0.3,6.1,5,7.8c1.2,0,4.6,0.4,8.4,0.5c3.8-0.1,7.3-0.5,8.4-0.5   c5.3-1.7,5-7.8,5-7.8s0-29.3,0-33.7c0-1,0-2,0.1-3v1.2c0.7,0,2.7,2.3,3.6,2.3C63,38.5,63,37.7,62.1,36.5z M34.7,66.5   c-0.3,3.3-2.3,4.1-2.3,4.1V37.4c0.8,1.2,2.3,6.8,2.3,6.8S34.9,63.2,34.7,66.5z M54.8,75.2c0,0-4.2,2.3-9.8,2.2   c-5.6,0.1-9.8-2.2-9.8-2.2v-2.8c4.9,2.2,9.8,2.2,9.8,2.2s4.9,0,9.8-2.2V75.2z M35.2,41.1l-1.7-10.2c0,0,4.5-3.2,11.5-3.2   s11.5,3.2,11.5,3.2l-1.7,10.2C51.4,39.2,38.6,39.2,35.2,41.1z M57.7,70.6c0,0-2.1-0.8-2.3-4.1c-0.3-3.3,0-22.4,0-22.4   s1.5-5.6,2.3-6.8V70.6z";
const SVG_robot   = 'M449.3,364.3L449.3,364.3l-18.5-0.1c-2.6,13.8-3.6,36.7-3.9,48.7h-8.4V296.2c0,0-0.4-9.7-3.9-22.2h12.1c0.2,48.7,4.5,53.4,4.5,53.4h18.1v0.1c22.2-61.1,0-113.2,0-113.2v-0.1h-18.5c-2.8,14.6-3.7,39.4-4,50.7h-15.2c-7.8-20.6-24.2-43.9-58.5-44.4l-99.7-1.5c0,0-51.8-1.4-69.6,45.9h-15.3c-0.2-10.3-1.1-37-4-52.4H146c0,0-22.2,52.1,0,113.2h18.1c0,0,4.2-4.6,4.5-51.7H181c-1.6,6.4-2.7,13.6-3,21.6l-2.8,117.3h-6.7c-0.3-11.4-1.2-35.9-4-50.4H146c0,0-22.2,52.1,0,113.2h18.1c0,0,4.3-4.7,4.5-53.7h6.4l-2,83c0,0,1.3,37.7,1.9,51.3c0.1,3,0.6,5.8,1.2,8.5h-7.6c-0.3-11.4-1.2-35.9-4-50.4H146c0,0-22.2,52.1,0,113.2h18.1c0,0,4.3-4.7,4.5-53.7h11c17.8,34,81.5,42.5,118.1,42.3c0,0,91.7,0,115.3-42.3h13.7c0.1,50.6,4.5,55.4,4.5,55.4h18.1v0.1c22.2-61.1,0-113.2,0-113.2v-0.1h-18.5c-2.6,13.8-3.6,36.7-3.9,48.7h-10.1c1.1-3.9,1.7-8.1,1.7-12.6l0-44.7V422h8.2c0.1,50.6,4.5,55.4,4.5,55.4h18.1v0.1C471.5,416.4,449.3,364.3,449.3,364.3z';
const SVG_motorcycle =`
M778.3,633.6c-12.3,0-18.4,0-18.4-14.8c0-0.3,0-0.7,0-1c-6.2-8.3-5.2-20.4-5-22.9c-17.9-7.9-26.2-24.1-30-36.3
		c-4.1-13.2-4-24.8-4-24.9c-0.5-33.6,10.2-43.1,15.1-45.7v-32c-7.8-14.1-5-30.8-4.8-32l-41.6-69.2v-0.1c-2.2-7.7,7.7-53.2,9.3-60.4
		c-1.3,0.1-2.5,0-3.6-0.4c-2.2-0.8-3-2.3-3-2.3c-1-2.7,2.5-7.7,2.9-8.2c0.5-0.7,1-1.4,1.6-2c0.1-3.4,1-6.6,3.8-8
		c0.6-0.5,6.5-4.7,12.8,0.5c0.1,0.1,0.2,0.1,0.2,0.2c4-0.7,7.1-1,7.8-1l8-4.4c3.4-9.3,19.3-19.9,24.8-23.7c0.4-0.2,0.7-0.5,0.9-0.6
		V209c0-0.1,0.2-4.7,4.5-8.6c0-0.5,0-0.9,0-1.3c0-14.8,6.1-14.8,18.4-14.8c12.2,0,18.3,0,18.3,14.8c0,0.2,0,0.4,0,0.6
		c0.3,0.2,0.6,0.5,0.9,0.7c2.4,2.2,3.5,5.1,3.5,8.5v35.4c2.7,1.4,22.5,11.9,25.9,24.5l7.7,4.3c0.7,0,4,0.3,8.1,1.1
		c0.1-0.1,0.2-0.2,0.3-0.3c6.3-5.2,12.2-1,12.7-0.5c2.9,1.5,3.8,4.8,3.8,8.4c0.5,0.5,1,1.1,1.3,1.7c0.4,0.5,3.9,5.5,3,8.1
		c-0.1,0.1-0.8,1.6-3,2.4c-1,0.3-2.1,0.5-3.3,0.4c1.6,7.2,11.5,52.6,9.3,60.4l-0.1,0.1L824.9,424c0.2,1.2,3.1,19-5.6,33.4v30.4
		c4.7,2.3,16.1,11.3,15.6,46c0,0.1,0.2,11.6-3.9,24.9c-3.8,12.2-12,28.3-30,36.2c0.5,2.6,1.4,14.2-4.4,22.4
		c-0.1,0.2-0.2,0.3-0.3,0.4c0,0.3,0,0.7,0,1c0,14.6-6.1,14.7-18.2,14.8L778.3,633.6z M718.4,348.5l13.2,20.2
		c0.7-1.4,2.6-3.1,4.5-4.5v-84.8c-2.3-0.7-4.1-1.7-5.4-3.1c-0.1-0.1-0.1-0.2-0.2-0.3l-4.7,3.4c-0.3,1.6-2.1,3.4-4.4,5.1
		c0.2,0.3,0.5,0.7,0.6,1.4c0.3,2-0.8,4.7-3.5,8.1v54.5H718.4z M819.6,363.5c2.1,1.6,4.4,3.6,5.3,5.2l13.2-20.2v-54.6
		c-2.6-3.3-3.7-6-3.4-7.9c0.1-0.6,0.3-1,0.5-1.2c-2.5-1.7-4.4-3.6-4.6-5.3l-4.6-3.3c-0.1,0.1-0.1,0.2-0.2,0.3
		c-1.4,1.5-3.4,2.6-6.1,3.3L819.6,363.5L819.6,363.5z M797.8,372.9c6.2-6.1,10.7-19.7,3.5-32c-4.4-6.9-8.5-9.8-15.6-12.1l-0.2-0.1
		l-0.1-0.2c-1.8-3.9-5.3-10.4-7.1-10.4c-1.9,0-5.4,6.4-7.1,10.3l-0.1,0.2l-0.2,0.1c-9.7,3.1-12.3,7.5-15.2,11.1
		c-10,17.3-0.5,30.2,2.9,33.2c1.2,1,9,8.4,19.7,8.4C790.2,381.4,795.9,374.6,797.8,372.9z M697,283c-0.3,0.4-0.6,0.7-0.8,1.1
		c-1,1.4-3.5,5.4-2.8,7.3c0.1,0.1,1.3,2.5,5.7,2.1C698.6,292.1,697.2,287.5,697,283z M857.5,293.5c4.1,0.2,5.4-2.1,5.4-2.2
		c0.6-1.8-1.8-5.8-2.9-7.2c-0.2-0.3-0.4-0.5-0.6-0.8C859.3,287.7,857.9,292.2,857.5,293.5z M826.5,275.2l5,3.6v0.2
		c0.1,1.5,2,3.2,4.5,4.9l4.1-0.5c0.9-1,1.7-4,2.2-8.3l0,0c-4.4-0.8-7.8-1-7.8-1h-0.1l-7.1-4c0.1,0.5,0.1,0.9,0.2,1.4
		C827.4,271.7,827.6,273.4,826.5,275.2z M716.5,283.5l3.8,0.4c2.5-1.7,4.3-3.5,4.5-4.9v-0.2l5.1-3.7c-1.1-1.9-0.9-3.5-0.9-3.6
		c0-0.5,0.1-0.9,0.2-1.4l-7.3,4h-0.1c-0.1,0-3.3,0.2-7.5,1l0,0C714.8,279.5,715.6,282.5,716.5,283.5z M741.2,573.7h73.3v-73.3h-73.3
		L741.2,573.7L741.2,573.7z M763,238.1c-0.1,2.3,0,12.6,5.5,18.4c2.5,2.6,5.8,4,9.9,4c0.1,0,4.7,0.6,8.7-3
		c4.2-3.7,6.3-10.2,6.4-19.4H763z M763,237.1h30.5v-2H763V237.1z
`;


const LiveMap = ({ id, route, liveRoute, onMapLoaded }) => {
  const [isMapCreated, setIsMapCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMapErrorMessage, setShowMapErrorMessage] = useState(false);
  const [gMap, setGMap] = useState(null);
  const [gDirections, setGDirections] = useState(null);

  //local route
  const [localMarkers, setLocalMarkers] = useState([]);
  const [routePolyline, setRoutePolyline] = useState(null);
  //live route
  const [liveMarker, setLiveMarker] = useState(null);
  const [livePolyline, setLivePolyline] = useState(null);
  const [prevLiveRoute, setPrevLiveRoute] = useState(null);

  const loadMap = () => {

    console.log("LiveMap - loadMap");
    
    var map = new window.google.maps.Map(
      document.getElementById(id),
      {
        //center: {lat: 39, lng: -8},
        mapTypeId: 'terrain',
        disableDefaultUI: true,
        zoom: 11
      }
    );

    /*
    var bounds = new window.google.maps.LatLngBounds();
    if (markers.length>0) {
        for (var i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
        }
        map.fitBounds(bounds);
    }
    */

    var directionsService = new window.google.maps.DirectionsService();
    setGDirections(directionsService);

    setGMap(map);
    
    setIsMapCreated(true);
    setIsLoading(false);
  };

  const drawRoute = () => {
      
    console.log("LiveMap - drawRoute");

    var map = gMap;
    var directionsService = gDirections;

    //limpa rota anterior
    if( routePolyline ){
      routePolyline.setMap(null);
      setRoutePolyline(null);
    }

    //limpa markers anteriores
    var markers = localMarkers;
    if (markers && markers.length  >0) {
      for (var i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
      }
      setLocalMarkers([]);
      markers = [];
    }
    
    //novas markers
    if (route && route.length > 0 ) {
      for (var i = 0; i < route.length; i++) {
        var loc  = route[i].location;
        var type = route[i].type;
        var markerIcon = type == 'dropoff' ? markerDropoffIcon :  (type == 'stop' ? markerStopIcon : markerPickupIcon);
        
        markers.push(new window.google.maps.Marker({
          position: loc,
          map,
          icon: {
            url: markerIcon,
            scaledSize: new window.google.maps.Size(50, 50),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(25, 25)
          }
        }));
      }
      setLocalMarkers(markers);
    }

    if( route && route.length > 1 ){

      var startLoc = route[0].location;
      var endLoc   = route[route.length-1].location;
      var waypts   = [];

      //waypoints
      for (var i = 1; i < route.length-1; i++) {
        waypts.push(route[i].location)
      }
      
      //draw rote
      directionsService.route(
        {
          origin: startLoc,
          destination: endLoc,
          waypoints: waypts,
          optimizeWaypoints: true,
          provideRouteAlternatives: false,
          travelMode: 'DRIVING'
        },
        function(response, status)
        {
          if (status === 'OK')
          {
            var route = response.routes[0];
            var overview_polyline = route.overview_polyline;
            // draw the polyline
            var path = [];
            if( overview_polyline != null ){
                path = window.google.maps.geometry.encoding.decodePath(overview_polyline);
            }

            //nao tem os pontos de inicio e fim, assim adiciona à rota
            path.unshift(startLoc);
            path.push(endLoc);

            // Route
            if( overview_polyline != null ){
                var polyline = new window.google.maps.Polyline({
                    path: path,
                    strokeColor: '#E69635',
                    strokeOpacity: 0.7,
                    strokeWeight: 4,
                    fillColor: '#E69635',
                    fillOpacity: 0.35,
                    map: map
                });
                polyline.setMap(map);
                
                setRoutePolyline(polyline);
            }
            //map.fitBounds( route.bounds, { top: 16, left: 16, rigth: 16, bottom: 182 } );
            //var bounds = new window.google.maps.LatLngBounds();
            var bounds = route.bounds;
            //agora adiciona ao bounds a posicao do pickup e dropoff
            if (markers && markers.length  >0) {
                for (var i = 0; i < markers.length; i++) {
                    bounds.extend(markers[i].getPosition());
                }
            }
            //agora faz o fit das bounds de modo a centrar no locais a ver  
            map.fitBounds(bounds, { top: 16, left: 16, rigth: 16, bottom: 182 } );

          }else if (status === 'ZERO_RESULTS') {
              console.log('draw route: ZERO_RESULTS');
          }else {
            console.log('draw route: Directions request failed due to ' + status)
          }
        }
      );//end directionsService.route 

    }//end if array

  };

  const drawLiveRoute = () => {

      var map = gMap;
      var directionsService = gDirections;

      if( Array.isArray(liveRoute) && liveRoute.length > 1 ){

        var startLoc = liveRoute[0].location;
        var endLoc   = liveRoute[1].location;
        var mapIcon  = liveRoute[0].map_icon;

        //verifica se existe alguma mudança
        var newRoute = true;
        if( prevLiveRoute  && prevLiveRoute.length > 1){
           if( 
              prevLiveRoute[0].location.lat ===  liveRoute[0].location.lat &&
              prevLiveRoute[0].location.lng ===  liveRoute[0].location.lng  &&
              prevLiveRoute[1].location.lat ===  liveRoute[1].location.lat &&
              prevLiveRoute[1].location.lng ===  liveRoute[1].location.lng

            ){
              newRoute = false;  
              //console.log("Rota igual à anterior");
           }
        }
        
        setPrevLiveRoute(liveRoute);

        //rota é diferente da anterior já desenhada?
        if( newRoute ){

          //limpa rota anterior
          if( livePolyline ){
            livePolyline.setMap(null);
            setLivePolyline(null);
          }

          //limpa marker anterior
          if( liveMarker ){
            liveMarker.setMap(null);
            setLiveMarker(null);
          }

          /*
          var marker = new window.google.maps.Marker({
            position:  startLoc,
            map,
            icon: {
              url: markerMotoIcon,
              //path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
              scaledSize: new window.google.maps.Size(35, 35),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 25),
              rotation: 0
            }
          });
          */

          var marker_path   = SVG_vehicle;
          var marker_anchor =  new window.google.maps.Point(45, 45);
          var marker_scale  = 0.5;

          if( mapIcon ){
            
            if( mapIcon == "robot"){
              marker_path  = SVG_robot;
              marker_anchor = new window.google.maps.Point(290, 420);
              marker_scale = 0.08;
              //marker_scale = 0.08;
            }else if( mapIcon == "motorcycle"){
              marker_path  = SVG_motorcycle;
              marker_anchor = new window.google.maps.Point(780, 400);
              marker_scale = 0.1;
            }
            
          }

          var marker = new window.google.maps.Marker({
            position:  startLoc,
            map,
            icon: {
              path: marker_path,
              fillColor: '#2490B8',
              fillOpacity: .9,
              anchor: marker_anchor,
              strokeWeight: .5,
              scale: marker_scale,
              rotation: 0  
            }
          });

          setLiveMarker(marker);
        
          //draw rote
        
          directionsService.route(
            {
                origin: startLoc,
                destination: endLoc,
                optimizeWaypoints: true,
                provideRouteAlternatives: false,
                travelMode: 'DRIVING'
            },
            function(response, status){
                if (status === 'OK') {
                    var route = response.routes[0];

                    var overview_polyline = route.overview_polyline;
    
                    // draw the polyline
                    var path = [];
                    if( overview_polyline != null ){
                        path = window.google.maps.geometry.encoding.decodePath(overview_polyline);
                    }

                    //nao tem os pontos de inicio e fim, assim adiciona à rota
                    path.unshift(startLoc);
                    path.push(endLoc);
    
                    // Route
                    if( overview_polyline != null ){
                        var polyline = new window.google.maps.Polyline({
                            path: path,
                            strokeColor: '#2DBAE4',
                            strokeOpacity: 0.85,
                            strokeWeight: 4,
                            fillColor: '#2DBAE4',
                            fillOpacity: 0.95,
                            map: map
                        });
                        polyline.setMap(map);
    
                        setLivePolyline(polyline);
                    }

                    //var bounds = new window.google.maps.LatLngBounds();
                    var bounds = route.bounds;
                    //agora adiciona ao bounds a posicao do pickup e dropoff
                    var markers = localMarkers;
                    if (markers && markers.length  >0) {
                        for (var i = 0; i < markers.length; i++) {
                            bounds.extend(markers[i].getPosition());
                        }
                    }
                    //agora faz o fit das bounds de modo a centrar no locais a ver  
                    map.fitBounds(bounds, { top: 16, left: 16, rigth: 16, bottom: 182 } );

                    //https://stackoverflow.com/questions/23149613/change-google-map-marker-orientation-according-path-direction
                    //https://stackoverflow.com/questions/56325714/rotating-google-maps-icon-using-bearing-angle/56326781#56326781      
                    
                    var heading = window.google.maps.geometry.spherical.computeHeading(startLoc, {lat: path[1].lat(), lng: path[1].lng()});
                    setTimeout(() => {
                      var icon = marker.getIcon();
                      icon.rotation = heading;
                      marker.setIcon(icon);
                      //console.log("heading="+heading)
                    }, 3000);
                    
                }else if (status === 'ZERO_RESULTS') {
                    console.log('draw live rote: ZERO_RESULTS');
                }else {
                  console.log('draw live rote: Directions request failed due to ' + status)
                }
            }
          );
        }else{
          //a rota mantêm-se
          //assim, anima apenas o icon
          if( livePolyline ){
            var path = livePolyline.getPath();
            var max = path.length >= 6 ? 6 : path.length -1;
            const rndInt = Math.floor(Math.random() * max) + 1; //entre 1 e 6
           
            if( rndInt > 0  ){
              var heading = window.google.maps.geometry.spherical.computeHeading(startLoc, {lat: path.getAt(rndInt).lat(), lng: path.getAt(rndInt).lng()});
              var marker = liveMarker;
              setTimeout(() => {
                  var icon = marker.getIcon();
                  icon.rotation = heading;
                  marker.setIcon(icon);
                  //console.log("prev livePolyline heading="+heading)
              }, 1000);
            }
          }
        }
        
      }

  };


  const rotateMarkerCss = (id, icon, rotationAngle) => {
    console.log("rotationAngle=" + rotationAngle);
    var selector = '#' + id + ' img[src="'+icon+'"]';
    var elem = window.document.querySelector(selector);
    if( elem ){
      elem.style.transform = "rotate("+rotationAngle +"deg)";
      console.log(selector);
    }
  }

  // Converts from degrees to radians.
  const toRadians = (degrees) => {
    return degrees * Math.PI / 180;
  };
  
  // Converts from radians to degrees.
  const toDegrees = (radians) => {
    return radians * 180 / Math.PI;
  }


  const bearing = (start, dest) => {
    //console.log(start);
    //console.log(dest);

    var startLat = toRadians(start.lat);
    var startLng = toRadians(start.lng);
    var destLat  = toRadians(dest.lat);
    var destLng  = toRadians(dest.lng);

    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
  }

  //logo apos o loadMap
  useEffect(() => {
    if (gMap && !isLoading) {
      if( route ){
        drawRoute();
      }
    }
  }, [gMap]);

  //se o route mudar
  useEffect(() => {
    if (isMapCreated && !isLoading) {
       drawRoute();
    }
  }, [route]);

  //qd existe alteracao ao liveRoute
  useEffect(() => {
    if (isMapCreated && !isLoading) {
      drawLiveRoute();
    }
  }, [liveRoute]);

  /*
  Load google maps api
  */
  useEffect(() => {
    if (!document.getElementById('google-maps-react')) {

      const googleMapsScript = document.createElement('script');

      googleMapsScript.setAttribute('id', 'google-maps-react');

      googleMapsScript.type = 'text/javascript';
      googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

      const googleMapsScriptTag = document.getElementsByTagName('script')[0];

      googleMapsScriptTag.parentNode.insertBefore(googleMapsScript, googleMapsScriptTag);
    }
  });

  useEffect(() => {
    if (!window.google) {
      //console.log("useEffect loadMap google-maps-react");
      document.getElementById('google-maps-react').addEventListener('load', () => {
        loadMap();
      });
    } else if (!isMapCreated) {
      //console.log("useEffect loadMap !isMapCreated");
      loadMap();
    }
  });

  return (
    <>
    <div className="map__container">
      <div
        className="map__wrapper"
        id={id}
      />
      {isLoading && (
        <div className="map__loader">
          <Loader />
        </div>
      )}
      {showMapErrorMessage && (
       /*  <div className="map__error">
          Something went wrong, try again later.
        </div> */
        <div className="map__badge_error">
          <InfoBox
            icon={alertTriangle}
            title={showMapErrorMessage}
          />
        </div>
      )}
    </div>
    </>
  );
};

LiveMap.propTypes = {
  id: PropTypes.string.isRequired,
  onMapLoaded: PropTypes.func,
  route: PropTypes.arrayOf(PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    type: PropTypes.string,
    map_icon: PropTypes.string
  })),
  liveRoute: PropTypes.arrayOf(PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    type: PropTypes.string,
    map_icon: PropTypes.string
  }))
};

LiveMap.defaultProps = {
  onMapLoaded: () => {},
  route: [],
  liveRoute: []
};

export default LiveMap;
