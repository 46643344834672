import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOrderTypes, selectOrderTypes } from 'store/slices/categories';
import { showNavBarAddressModal, selectHasDefaultAddress } from 'store/slices/authentication';

import Disclaimer from 'components/disclaimer';
import NewOrderCard from 'components/new-order-card';
import OrderTypeCard from 'components/order-type-card';
import Search from 'components/search';

const OrderTypeList = () => {
  const dispatch = useDispatch();
  const orderTypes = useSelector(selectOrderTypes);
  const [searchValue, setSearchValue] = useState('');
  const hasDefaultAddress = useSelector(selectHasDefaultAddress);

  useEffect(() => {
    //se nao tem address definida, mostra popup para indicar uma morada  
    if( !hasDefaultAddress ){
      dispatch(showNavBarAddressModal(true));
    }
    //carrega as categorias
    dispatch(fetchOrderTypes());
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchValue(event?.target?.value ?? '');
  };

  const filteredOrderTypes = searchValue ?
    orderTypes.filter(orderType => orderType.name && orderType.name.toLowerCase().includes(searchValue.toLowerCase())) :
    orderTypes;

  return (
    <div className="container">
      <Search
        largeMargin
        value={searchValue}
        onChange={handleSearchChange}
      />
      <div className="row">
        <div className="col col-12 col-sm-6 col-lg-3">
          <NewOrderCard />
        </div>
        {filteredOrderTypes.length ?
          filteredOrderTypes.map(item => (
            <div
              key={item.id}
              className="col col-12 col-sm-6 col-lg-3"
            >
              <OrderTypeCard {...item} />
            </div>
          )) : (
            <div className="col col-12 col-lg-9">
              <Disclaimer />
            </div>
          )}
      </div>
    </div>
  );
};

export default OrderTypeList;
