import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import closeIcon from '../../../assets/images/icons/close.svg';

import './styles.scss';

const Modal = ({ big, children, classNames, footer, footerAlignRight, header, noScroll, onClose, onEnter, small, subtitle, title }) => {
  const modalContentWrapper = useRef(null);
  const modalContent = useRef(null);
  const [isContentScrolled, setIsContentScrolled] = useState(false);

  const clickListener = (event) => {
    if (!(modalContentWrapper.current).contains(event?.target)) {
      handleClickOnOverlay();
    }
  };

  const handleClickOnOverlay = () => {
    onClose();
  };

  const handleCloseIconKeyDown = (event) => {
    if (event.keyCode === 13) {
      onClose();
    }
  };

  const handleContentScroll = () => {
    if (modalContent.current.scrollTop > 0 && !isContentScrolled) {
      setIsContentScrolled(true);
    } else if (modalContent.current.scrollTop <= 0 && isContentScrolled) {
      setIsContentScrolled(false);
    }
  };

  const enterKeyListener = (event) => {
    if (event.keyCode === 13) {
      onEnter();
    }
  };

  useEffect(() => {
    const disableScrollEvent = new Event('disable-scroll');

    window.dispatchEvent(disableScrollEvent);

    return () => {
      const enableScrollEvent = new Event('enable-scroll');

      window.dispatchEvent(enableScrollEvent);
    };
  }, []);

  useEffect(() => {
    const modalContentElement = modalContent.current;

    document.addEventListener('click', clickListener);
    document.addEventListener('keypress', enterKeyListener);

    if (noScroll) {
      modalContentElement.addEventListener('scroll', handleContentScroll);
    }

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keypress', enterKeyListener);
      modalContentElement.removeEventListener('scroll', handleContentScroll);
    };
  });

  const noFooter = !footer;
  const noHeader = !title && !header;

  return (
    <div
      // big prop takes precedence over small
      className={`modal${
        big ? ' modal--big' : ''}${
        classNames ? ` ${classNames}` : ''}${
        noHeader ? ' modal--no-header' : ''}${
        noFooter ? ' modal--no-footer' : ''}${
        small && !big ? ' modal--small' : ''}
      `}
    >
      <div className="modal__overlay">
        <div
          ref={modalContentWrapper}
          className={`modal__content-wrapper${noScroll ? ' modal__content-wrapper--no-scroll' : ''}`}
        >
          {!noHeader && (
            <div className={`modal__header${isContentScrolled ? ' modal__header--bordered' : ''}`}>
              {header}
              {title && (
                <div className="modal__title">
                  {title}
                </div>
              )}
              {subtitle && (
                <div className="modal__subtitle">
                  {subtitle}
                </div>
              )}
            </div>
          )}
          <div
            ref={modalContent}
            className="modal__body"
          >
            {children}
          </div>
          {!noFooter && (
            <div className={`modal__footer${footerAlignRight ? ' modal__footer--right' : ''}`}>
              {footer}
            </div>
          )}
          <span
            role="button"
            tabIndex={0}
            onClick={onClose}
            onKeyDown={handleCloseIconKeyDown}
          >
            <img
              alt="Close Icon"
              className="modal__close-icon"
              src={closeIcon}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  big: PropTypes.bool,
  children: PropTypes.element,
  classNames: PropTypes.string,
  footer: PropTypes.element,
  footerAlignRight: PropTypes.bool,
  header: PropTypes.element,
  noScroll: PropTypes.bool,
  onClose: PropTypes.func,
  onEnter: PropTypes.func,
  small: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

Modal.defaultProps = {
  big: false,
  children: null,
  classNames: null,
  footer: null,
  footerAlignRight: false,
  header: null,
  noScroll: false,
  onClose: () => {},
  onEnter: () => {},
  small: false,
  subtitle: null,
  title: null
};

export default Modal;
