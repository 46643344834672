import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {FormattedMessage, useIntl} from 'react-intl';

import './styles.scss';
import infoIcon from "../../../images/icons/info-gray.svg";
import addressIcon from "../../../images/icons/address.svg";
import {Link} from "react-router-dom";
import plusIcon from "../../../images/icons/plus--white.svg";


import Button from "../../objects/button";
import Notice from "../../../../../shared/components/objects/notice";
import Loader from "../../../../../shared/components/objects/loader";
import ActionModal from "../../order/action-modal";
import DriverCard from "../../driver-card";
import {getDrivers,addDriver, editDriver, removeDriver, selectIsUpdatingUser} from "../../../store/slices/transporter";
import UserModal from "../../user-modal";
import {selectUser} from "../../../store/slices/authentication";
import Pagination from '@youship/components/objects/pagination';

const DriversArea = () => {
    const dispatch = useDispatch();
    const [showActionModal, setShowActionModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const intl = useIntl();
    const [refresh, setRefresh] = useState(false);
    
    const user = useSelector(selectUser);
    const [phoneCode] = useState(user?.phone?.code ? user.phone.code.slice(1) : null);
    const [showDriverModal, setShowDriverModal] = useState(false);
    const [driverErrorMessage, setDriverErrorMessage] = useState(null);
    const [driverSuccessMessage, setDriverSuccessMessage] = useState(null);
    const [driverMessageTitle, setDriverMessageTitle] = useState(null);
    const isUpdatingUser = useSelector(selectIsUpdatingUser);


    let [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedDriverCode, setSelectedDriverCode] = useState(null);

    const [showRemoveDriverModal, setShowRemoveDriverModal] = useState(false);
    const [showEditDriverModal, setShowEditDriverModal] = useState(false);
    
    //lista de moradas do user
    const [isLoadingDriversList, setLoadingDriversList] = useState(true);
    let [drivers, setDrivers] = useState([]);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1)
    const showPagination = !!numberOfPages;
    
    useEffect(
        () => {
            dispatch(getDrivers({
                pages: {
                    current: currentPageNumber - 1,
                    resultsbypage: 8
                }}))
                .then(((response) => {
                    if (Array.isArray(response.payload.drivers)) {
                        setDrivers(response.payload.drivers)
                    }
                    setNumberOfPages(response.payload.pages.lastPage + 1)
                    
                    console.log("Pages: " + JSON.stringify(response))
                    setLoadingDriversList(false)
                }))

                .catch((error) => {
                    // Add proper error handling
                    setErrorMessage(error?.message);
                    console.log(error);
                });
        }, [refresh]
    );

    function handleRefresh() {
        setRefresh(!refresh);
    }
    
    const handleAddDriverButtonClick = () => {
        setDriverMessageTitle(intl.formatMessage({ id: 'drivers.add' }));
        setShowDriverModal(true);
    };

    const handleCloseDriverModal = () => {
        setShowDriverModal(false);
        setDriverErrorMessage(null);
        setDriverSuccessMessage(null);
    };

    const handleCloseEditDriverModal = () => {
        setShowEditDriverModal(false);
        setDriverErrorMessage(null);
        setDriverSuccessMessage(null);
    };
    
    const handleRemoveDriverModalCloseButtonClick = () => {
        setShowRemoveDriverModal(false);
    };


    const handleAddDriverModalSaveButton = (event, formData) => {
        event.preventDefault();

        setDriverErrorMessage(null);        
        dispatch( addDriver(formData) )
            .then((response) => {
                if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

                return response;
            })
            .then((response) => {
                setDriverErrorMessage(false);
                setDriverMessageTitle(intl.formatMessage({ id: 'drivers.add' }));
                setDriverSuccessMessage(intl.formatMessage({ id: 'drivers.add_success' }));
                handleRefresh()
                return response;
            })
            .catch((error) => {
                // NOTE: Add proper error handling
                // eslint-disable-next-line no-console

                //alert(error);
                console.log(error);
                setDriverErrorMessage(error?.message);

            });
    };

    const handleEditDriverModalSaveButton = (event, formData) => {
        event.preventDefault();

        setDriverErrorMessage(null);
        formData.user_code = selectedDriver.user_code
        dispatch( editDriver(formData) )
            .then((response) => {
                if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

                return response;
            })
            .then((response) => {
                setDriverErrorMessage(false);
                setDriverMessageTitle(intl.formatMessage({ id: 'drivers.edit' }));
                setDriverSuccessMessage(intl.formatMessage({ id: 'drivers.edit_success' }));
                handleRefresh()
                return response;
            })
            .catch((error) => {
                // NOTE: Add proper error handling
                // eslint-disable-next-line no-console

                //alert(error);
                console.log(error);
                setDriverErrorMessage(error?.message);

            });
    };

    const handleRemoveDriverModalConfirmButtonClick = (event) => {
        event.preventDefault();

        console.log("Driver> " + JSON.stringify(selectedDriver))
               dispatch(removeDriver({
                   user_code: selectedDriver.user_code
               }))
                   .then((response) => {
                       handleRefresh()

                       setShowRemoveDriverModal(false);
                       setDriverSuccessMessage(intl.formatMessage({ id: 'drivers.remove_success' }));
                       setDriverMessageTitle(intl.formatMessage({ id: 'drivers.remove' }));
                       setShowDriverModal(true)
                       
                       return response;
                   })
                   .catch((error) => {
                       // Add proper error handling
                       // eslint-disable-next-line no-console
                       console.log(error);
                   });
        
    };


    const handleEditDriverButton = (driver) => {
        setSelectedDriver(driver)
        setDriverMessageTitle(intl.formatMessage({ id: 'drivers.edit' }));
        setShowEditDriverModal(true);
    };

    const handleRemoveDriverButton = (driver) => {
        setSelectedDriver(driver)
        setShowRemoveDriverModal(true);
    };

    const updateSelectedDriverCode = (addressCode) => {
        setSelectedDriverCode(addressCode)
    }


    const updateShowEditModalShow = (showEditDriverModal) => {
        setShowEditDriverModal(showEditDriverModal)
    }

    const handlePaginationOnChange = (pageNumber) => {
        setCurrentPageNumber(pageNumber);
        handleRefresh()
    };


    const header = (
        <>
            <div className="row" style={{ display: "flex", alignItems: "center" }}>
                <div className="col-12" style={{ flex: 1 }}>
                    <h1 className="drivers__title">
                        <FormattedMessage id="driversList.title"/>
                    </h1>
                </div>

                {drivers != null && (
                <div style={{paddingRight: '16px'}}>
                    <Button
                        linkComponent={Link}
                        linkProps={{ to: '#' }}
                        noArrow
                        icon={plusIcon}
                        text={intl.formatMessage({id: 'driversList.add-driver'})}
                        onClick={handleAddDriverButtonClick}
                    />
                </div>
                    )}
            </div>
        </>
    );


    if (isLoadingDriversList) {
        return (
            <div className="drivers">
                <div className="container">
                    {header}
                    <div className="drivers__loader-wrapper">
                        <Loader/>
                    </div>
                </div>
            </div>
        );
    }else {
        return (
            <div className="drivers-list">
                <div className="container">
                    {header}
                    {errorMessage ? (
                        <Notice
                            description={errorMessage}
                            fixed
                            icon={infoIcon}
                        />
                    ) : (
                        <>
                            {drivers && drivers.length ? (
                                <div>
                                    <div className="row">
                                        {drivers.map(
                                            (driver, index) => (
                                                <>
                                                    {driver != null && (
                                                        <div
                                                            className="col col-12 col-lg-6">
                                                            <DriverCard
                                                                
                                                                driver={driver}
                                                                index={index}
                                                                setSelectedDriverCode={updateSelectedDriverCode}
                                                                setShowEditModal={updateShowEditModalShow}
                                                                handleEditDriver={handleEditDriverButton}
                                                                handleRemoveDriver={handleRemoveDriverButton}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : (
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="drivers__no-drivers">
                                                <p>
                                                    <img
                                                        alt="New driver"
                                                        src={addressIcon}
                                                    />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="driversList.no_results"/>
                                                </p>
                                                <Button
                                                    linkComponent={Link}
                                                    linkProps={{ to: '#' }}
                                                    noArrow
                                                    largeHorizontalPadding
                                                    icon={plusIcon}
                                                    isTextLeft
                                                    text={intl.formatMessage({id: 'driversList.add-driver'})}
                                                    onClick={handleAddDriverButtonClick}

                                                />
                                            </p>
                                        </div>
                                    </div>
                            )
                            }
                        </>
                    )}
                </div>

                {showPagination && numberOfPages > 1 && (
                    <div className="row justify-content-center mt-4">
                        <Pagination
                            length={numberOfPages}
                            selected={currentPageNumber}
                            onChange={handlePaginationOnChange}
                        />
                    </div>
                )}

                {showDriverModal && (
                    <UserModal
                        errorMessageModal={driverErrorMessage}
                        finalMessage={driverSuccessMessage}
                        isLoading={isUpdatingUser}
                        title={driverMessageTitle}
                        phoneCode={phoneCode}
                        onClose={handleCloseDriverModal}
                        onSave={handleAddDriverModalSaveButton}
                    />
                )}
                
                {showEditDriverModal && selectedDriver && (
                    <UserModal
                        data={selectedDriver}
                        errorMessageModal={driverErrorMessage}
                        finalMessage={driverSuccessMessage}
                        isLoading={isUpdatingUser}
                        title={driverMessageTitle}
                        phoneCode={phoneCode}
                        onClose={handleCloseEditDriverModal}
                        onSave={handleEditDriverModalSaveButton}
                    />
                    )}

                {showRemoveDriverModal && (
                    <ActionModal
                        title={intl.formatMessage({id: 'driversList.remove_driver_title'})}
                        description={intl.formatMessage({id: 'driversList.remove_driver_description'})}
                        back={intl.formatMessage({id: 'driversList.go_back'})}
                        onClose={handleRemoveDriverModalCloseButtonClick}
                        onConfirm={handleRemoveDriverModalConfirmButtonClick}
                    />
                )}
                
            </div>
        );
    }
};


export default DriversArea;
