import React from 'react';

import ResetPasswordForm from 'components/reset-password';
import ImagePanel from '@youship/components/objects/image-panel';
import { FormattedMessage } from 'react-intl';

const ResetPassword = () => (
  <div className="reset-password-area">
    <ImagePanel />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-12 col-sm-6 offset-sm-6">
          <h1 className="reset-password-area__title">
            <FormattedMessage id="password.title" />
          </h1>
          <div className="reset-password-area__description">
            <FormattedMessage id="password.description" />
          </div>
          <div className="row">
            <div className="col col-12 col-lg-10">
              <ResetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ResetPassword;

