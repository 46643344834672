import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUser, selectIsLoadingUser, selectIsUpdatingUser, selectUser, updateUser, logout } from 'store/slices/authentication';
import { addDriver } from 'store/slices/transporter';
import { removeUserAccount } from 'store/slices/users';
import { resetCheckoutData } from 'store/slices/shopping-cart';
import { resetNotifications} from 'store/slices/notifications';
import { setDefaultPickup, resetDelivery } from 'store/slices/new-delivery';
import { getAddresses } from 'store/slices/user-addresses';

import editIcon from 'images/icons/edit-gray.svg';
import noticeIcon from 'images/icons/notice.svg';

import AddressModal from '@youship/components/address-modal';
import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Notice from '@youship/components/objects/notice';
import PasswordUpdateModal from 'components/password-update-modal';
import UserModal from 'components/user-modal';
import MsgModal from 'components/msg-modal';

import './styles.scss';


const AccountDetails = () => {
  const user = useSelector(selectUser);
  const isUpdatingUser = useSelector(selectIsUpdatingUser);
  const isLoadingUser = useSelector(selectIsLoadingUser);

  const dispatch = useDispatch();
  const history = useHistory();

  const [address, setAddress] = useState({
    apartmentAddress: user?.default_address?.address.apartmentAddress || '',
    city: user?.default_address?.address.city || '',
    countryCode: user?.default_address?.address.countryCode || '',
    formattedAddress: user?.default_address?.address.formattedAddress || '',
    numberAddress: user?.default_address?.address.numberAddress || '',
    postalCode: user?.default_address?.address.postalCode || '',
    streetAddress: user?.default_address?.address.streetAddress || ''
  });
  const [company, setCompany] = useState(user?.default_address?.contact?.company || '');
  const [email, setEmail] = useState(user?.email || '');
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [phoneCode, setPhoneCode] = useState(user?.phone?.code ? user.phone.code.slice(1) : null);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone?.number || '');
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [showPasswordUpdateModal, setShowPasswordUpdateModal] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [driverErrorMessage, setDriverErrorMessage] = useState(null);
  const [driverSuccessMessage, setDriverSuccessMessage] = useState(null);

  const [msgModal, setMsgModal] = useState("");
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [isLoadingMsgModal, setIsLoadingMsgModal] = useState(false);
  const [isNoticeMsgModal, setIsNoticeMsgModal] = useState(false);
  const [isAccountDeleted, setIsAccountDeleted] = useState(false);
  
  const userIsAwaitingTransporterApproval = user?.options?.transporter_waiting_approval;
  const userIsAwaitingShopApproval = user?.options?.shop_waiting_approval;

  //const isSignupAsPartner = user?.options?.signup_as_partner;
  const btnsSignupAsPartner = user?.options?.menu?.bar?.filter(item => (item?.action && (item.action === 'transporter_signup' || item?.action === 'shop_signup')) );
  const isSignupAsPartner   =  (Array.isArray(btnsSignupAsPartner) && btnsSignupAsPartner.length > 0 ) ? true : false;

  //console.log("isSignupAsPartner=" + isSignupAsPartner);
  //console.log(user?.options?.menu?.bar);

 // const orderActionsList = Array.isArray(order?.actionsList) && order?.actionsList.filter(actionItem =>
 //  !(order.payment?.method?.code === 'mb' && actionItem.action === 'pay'));


  const userType = user?.type;
  const userTransporter = userType === 'transporter' ? true : false;

  const noticeLinks = user?.options?.pending?.btns.map(link => ({
    text: link.title,
    url: link.action_url
  }));

  // NOTE: The API is not ready yet to edit and cancel both transporter and shop registrations
  // const noticeLinks = [
  //   {
  //     text: 'Edit',
  //     url: userIsAwaitingTransporterApproval ? '/account-confirmation/carrier' : '/account-confirmation/partner'
  //   },
  //   {
  //     text: 'Cancel registration',
  //     isButton: true
  //   }
  // ];

  const pendingNotice = user?.options?.pending?.message || user?.options?.pending?.title ?
    {
      description: user?.options?.pending?.message ?? null,
      icon: noticeIcon,
      links: noticeLinks ?? null,
      title: user?.options?.pending?.title ?? null
    } :
    null;

  useEffect(() => {
    setCompany(user?.default_address?.contact?.company || '');
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setPhoneCode(user?.phone?.code ? user.phone.code.slice(1) : null);
    setPhoneNumber(user?.phone?.number || '');
    setAddress({
      apartmentAddress: user?.default_address?.address.apartmentAddress || '',
      city: user?.default_address?.address.city || '',
      countryCode: user?.default_address?.address.countryCode || '',
      formattedAddress: user?.default_address?.address.formattedAddress || '',
      numberAddress: user?.default_address?.address.numberAddress || '',
      postalCode: user?.default_address?.address.postalCode || '',
      streetAddress: user?.default_address?.address.streetAddress || ''
    });
  }, [user]);

  const emailDisplay = user?.email || '';
  const nameDisplay = user?.name || [user?.firstName, user?.lastName].join(' ') || '';

  const isFormValid = !isUpdatingUser && !isLoadingUser && !!(
    firstName &&
    lastName &&
    phoneCode &&
    phoneNumber
  ) && (
    company !== (user?.default_address?.contact?.company || '') ||
    firstName !== user?.firstName ||
    lastName !== user?.lastName ||
    phoneCode !== user?.phone?.code?.slice(1) ||
    phoneNumber !== user?.phone?.number
  );

  const handleChangePasswordButtonClick = () => {
    setShowPasswordUpdateModal(true);
  };

  const handlePasswordUpdateModalClose = () => {
    setShowPasswordUpdateModal(false);
  };

  const handleSaveButtonClick = () => {
    const countryCode = user?.country?.countrycode;

    dispatch(updateUser({
      company,
      countryCode,
      firstName,
      lastName,
      phoneCode: `+${phoneCode}`,
      phoneNumber
    }))
      .then((response) => {
        dispatch(getUser());

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const handleCancelButtonClick = () => {
    setCompany(user?.default_address?.contact?.company || '');
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setPhoneCode(user?.phone?.code ? user.phone.code.slice(1) : null);
    setPhoneNumber(user?.phone?.number || '');
  };

  const handleEditAddressButtonClick = () => {
    setShowEditAddressModal(true);
  };

  const handleCloseEditAddressModal = () => {
    setShowEditAddressModal(false);
  };

  const handleEditAddressModalSaveButton = () => {
    dispatch(getUser())
      .then(((response) => {
        dispatch(setDefaultPickup());

        return response;
      }))
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });

    dispatch(getAddresses());
    handleCloseEditAddressModal();
  };

  // NOTE: not being used until API is ready for edit and cancel transporter and ship registrations
  // const handleCancelRegistrationButtonClick = () => {
  //   if (userIsAwaitingTransporterApproval) {
  //     dispatch(cancelTransporterRegistration());
  //   } else if (userIsAwaitingShopApproval) {
  //     dispatch(cancelShopRegistration());
  //   }
  // };

  const handleAddDriverButtonClick = () => {
    setShowDriverModal(true);
  };

  const handleCloseDriverModal = () => {
    setShowDriverModal(false);
  };

  const handleDriverModalSaveButton = (event, formData) => {
    event.preventDefault();

    setDriverErrorMessage(null);
    
    dispatch( addDriver(formData) )
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

        return response;
      })
      .then((response) => {
        
        setDriverErrorMessage(false);

        setDriverSuccessMessage(intl.formatMessage({ id: 'drivers.add_success' }));

        return response;
      })
      .catch((error) => {
        // NOTE: Add proper error handling
        // eslint-disable-next-line no-console
        
        //alert(error);
         console.log(error);
         setDriverErrorMessage(error?.message);
        
      });
      
  };

  // -- Account Delete -------------
  const handleAccountDeleteButtonClick = () => {
    setIsLoadingMsgModal(true);
    setShowMsgModal(true);

    dispatch( removeUserAccount() )
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');
        return response;
      })
      .then((response) => {
        
        if(response?.success){
          setIsNoticeMsgModal(true);
          setIsAccountDeleted(true);
        }else{
          setIsNoticeMsgModal(false);
        }

        setMsgModal(response?.message);
        setIsLoadingMsgModal(false);
        
        return response;
      })
      .catch((error) => {
         // NOTE: Add proper error handling
         //alert(error);
         console.log(error);
         setIsNoticeMsgModal(true);
         setMsgModal(error?.message);
         setIsLoadingMsgModal(false);
      });
  };

  function handleCloseMessageModal(){
    setIsLoadingMsgModal(false);
    setShowMsgModal(false);
    if(isAccountDeleted){
        //logout
        (async () => {
          await dispatch(resetCheckoutData());
          await dispatch(resetDelivery());
          await dispatch(resetNotifications());
          await dispatch(logout());
          history.push('/');
        })();
    }
  }

  const intl = useIntl();

  return (
    <div className="account-details">
      <div className="container">
        <div className="account-details__header">
          <div className="account-details__header-details">
            <h1 className="account-details__title">
              {nameDisplay}
            </h1>
            <div className="account-details__subtitle">
              {emailDisplay}
            </div>
          </div>
          { isSignupAsPartner  && (
            <div className="account-details__complete-register-buttons">
              <Button
                classNames="account-details__register-button"
                context="primary"
                largeHorizontalPadding
                linkComponent={Link}
                linkProps={{ to: '/account-confirmation/carrier' }}
                noArrow
                outline
                text={intl.formatMessage({ id: 'header.sign_up_as_courier' })}
              />
              <Button
                classNames="account-details__register-button account-details__register-button--no-margin-right"
                context="primary"
                largeHorizontalPadding
                linkComponent={Link}
                linkProps={{ to: '/account-confirmation/partner' }}
                noArrow
                outline
                text={intl.formatMessage({ id: 'header.sign_up_as_partner' })}
              />
            </div>
          )}
        </div>
        {(userIsAwaitingShopApproval || userIsAwaitingTransporterApproval) && (
          <div className="account-details__status-message">
            {!!pendingNotice && (
              <Notice
                fixed
                // NOTE: not being used until API is ready for edit and cancel transporter and shop registrations
                // onButtonClick={handleCancelRegistrationButtonClick}
                {...pendingNotice}
              />
            )}
          </div>
        )}
        <section>
          <h3 className="account-details__section-title">
            <FormattedMessage id="register.step_1" />
          </h3>
          <div className="row">
            <div className="col col-12 col-sm-6 col-lg-3">
              <div className="account-details__input-group">
                <Input
                  block
                  inputId="first-name"
                  label={intl.formatMessage({ id: 'register.form.first_name.label' })}
                  placeholder={intl.formatMessage({ id: 'register.form.first_name.placeholder' })}
                  type="text"
                  value={firstName}
                  onChange={event => setFirstName(event?.target?.value ?? '')}
                />
              </div>
            </div>
            <div className="col col-12 col-sm-6 col-lg-3">
              <div className="account-details__input-group">
                <Input
                  block
                  inputId="last-name"
                  label={intl.formatMessage({ id: 'register.form.last_name.label' })}
                  placeholder={intl.formatMessage({ id: 'register.form.last_name.placeholder' })}
                  type="text"
                  value={lastName}
                  onChange={event => setLastName(event?.target?.value ?? '')}
                />
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="email"
                  label={intl.formatMessage({ id: 'register.form.email.label' })}
                  placeholder={intl.formatMessage({ id: 'register.form.email.placeholder' })}
                  readOnly
                  type="email"
                  value={email}
                  onChange={event => setEmail(event?.target?.value ?? '')}
                />
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  countryCallingCode={phoneCode}
                  inputId="phone"
                  label={intl.formatMessage({ id: 'register.form.phone.label' })}
                  placeholder={intl.formatMessage({ id: 'register.form.phone.placeholder' })}
                  type="phone"
                  value={phoneNumber}
                  onChange={event => setPhoneNumber(event?.target?.value ?? '')}
                  onCountryCallingCodeChange={code => setPhoneCode(code)}
                />
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  inputId="company"
                  label={intl.formatMessage({ id: 'register.form.company.label' })}
                  placeholder={intl.formatMessage({ id: 'register.form.company.placeholder' })}
                  type="text"
                  value={company}
                  onChange={event => setCompany(event?.target?.value ?? '')}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="account-details__button-group">
                <Button
                  classNames="account-details__button"
                  context="primary"
                  disabled={!isFormValid}
                  largeHorizontalPadding
                  outline
                  text={intl.formatMessage({ id: 'app.cancel' })}
                  onClick={handleCancelButtonClick}
                />
                <Button
                  classNames="account-details__button"
                  context="primary"
                  disabled={!isFormValid}
                  largeHorizontalPadding
                  text={intl.formatMessage({ id: 'app.save' })}
                  onClick={handleSaveButtonClick}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="account-details__divider" />
        <section className="account-details__section-address">
          <div className="account-details__section-header">
            <h3 className="account-details__section-title">
              <FormattedMessage id="map.user.title" />
            </h3>
            <div
              className="account-details__edit-address"
              onClick={handleEditAddressButtonClick}
            >
              <img
                alt="Edit Address"
                className="account-details__edit-address-icon"
                src={editIcon}
              />
              <span>
                <FormattedMessage id="app.edit" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="address"
                  label={intl.formatMessage({ id: 'map.street_address.label' })}
                  type="text"
                  value={address?.streetAddress}
                />
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="zip-code"
                  label={intl.formatMessage({ id: 'map.postalcode.label' })}
                  type="text"
                  value={address?.postalCode}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="door"
                  label={intl.formatMessage({ id: 'map.number_address.label' })}
                  type="text"
                  value={address?.numberAddress}
                />
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="floor"
                  label={intl.formatMessage({ id: 'map.apartment_address.label' })}
                  type="text"
                  value={address?.apartmentAddress}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="city"
                  label={intl.formatMessage({ id: 'map.city.label' })}
                  type="text"
                  value={address?.city}
                />
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="account-details__input-group">
                <Input
                  block
                  disabled
                  inputId="location"
                  label={intl.formatMessage({ id: 'map.country.label' })}
                  type="text"
                  value={address?.countryCode}
                />
              </div>
            </div>
          </div>
        </section>

{/*        { userTransporter && (
        <>
          <div className="account-details__divider" />
          <section>
            <h3 className="account-details__section-title">
              <FormattedMessage id="drivers.title" />
            </h3>
            <div className="account-details__input-group">
              <Button
                context="primary"
                largeHorizontalPadding
                outline
                text={intl.formatMessage({ id: 'drivers.add' })}
                onClick={handleAddDriverButtonClick}
              />
            </div>
          </section>  
        </>      
        )}*/}

        <div className="account-details__divider" />
        <section>
          <h3 className="account-details__section-title">
            <FormattedMessage id="password.title" />
          </h3>
          <div className="account-details__input-group">
            <Button
              context="primary"
              largeHorizontalPadding
              outline
              text={intl.formatMessage({ id: 'password.button' })}
              onClick={handleChangePasswordButtonClick}
            />
          </div>
        </section>

        <div className="account-details__divider" />  
        <section>
          <h3 className="account-details__section-title">
            <FormattedMessage id="account.delete-account-title" />
          </h3>
          <div className="account-details__input-group">
            <Button
              context="primary"
              largeHorizontalPadding
              outline
              text={intl.formatMessage({ id: 'account.delete-account' })}
              onClick={handleAccountDeleteButtonClick}
            />
          </div>
        </section>

      </div>


      {showPasswordUpdateModal && (
        <PasswordUpdateModal onClose={handlePasswordUpdateModalClose} />
      )}
      {showEditAddressModal && (
        <AddressModal
          currentAddress={address}
          /*title="Your addresses"*/
          addAddress={false}  
          type="user"
          onClose={handleCloseEditAddressModal}
          onSave={handleEditAddressModalSaveButton}
        />
      )}

      {showDriverModal && (
        <UserModal
          errorMessageModal={driverErrorMessage}
          finalMessage={driverSuccessMessage}
          isLoading={isUpdatingUser}
          title={intl.formatMessage({ id: 'drivers.add' })}
          phoneCode={phoneCode}
          onClose={handleCloseDriverModal}
          onSave={handleDriverModalSaveButton}
        />
      )}  

      {showMsgModal && (
            <MsgModal
                title={intl.formatMessage({ id: 'account.delete-account-title' })}    
                isLoading={isLoadingMsgModal}
                finalMessage={msgModal}
                isNoticeMessage={isNoticeMsgModal}
                onClose={handleCloseMessageModal}
            />
      )}

    </div>
  );
};

export default AccountDetails;
