import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import TransporterApi from '@youship/api/transporter';
import UserApi from '@youship/api/user';

const transporterAdapter = createEntityAdapter();

const initialState = transporterAdapter.getInitialState({
  drivers: null,
  isLoadingDrivers: false,
  isUpdatingUser: false,
  isPerformingAction: false,
  pages: {
    current: 0,
    lastPage: 0
  }
});

// Thunks:

export const getDrivers = createAsyncThunk('transporter/getDrivers',
    payload => TransporterApi.getDrivers(payload)
        .then((response) => {
                if (!Array.isArray(response?.rows)) throw new Error('The response did not include any drivers.');

                return {
                    pages: {
                        current: response.pages?.current,
                        lastPage: response.pages?.lastpage
                    },
                    drivers: response.rows.map(driver => ({
                        ...driver,
                        id: driver.user_code
                    }))
                };
            }
        ));


export const addDriver = createAsyncThunk(
  'transporter/addDriver',
  (formData) => {
    const data = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email,
      phonecode: formData.phoneCode,
      phonenumber: formData.phoneNumber
    };

    return TransporterApi.addDriver(data);
  }
);

export const editDriver = createAsyncThunk(
    'transporter/editDriver',
    (formData) => {
      const data = {
        user_code: formData.user_code,
        firstname: formData.firstName,
        lastname: formData.lastName,
        phonecode: formData.phoneCode,
        phonenumber: formData.phoneNumber
      };

      return TransporterApi.editDriver(data);
    }
);

export const removeDriver = createAsyncThunk(
    'transporter/removeDriver',
    (code) => {
        const data = {
            user_code: code
        }
      return TransporterApi.removeDriver(data);
    }
);





// Slice:

const transporterSlice = createSlice({
  name: 'transporter',

  initialState,

  reducers: {
    initialize (state) {
      state.initialized = true;
    }
  },

  extraReducers: (builder) => {
    builder
      //-- getDrivers ----
      .addCase(getDrivers.pending, (state) => {
        state.isLoadingDrivers = true;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.drivers = action.payload;
          state.pages = action.payload.pages;
        }
        state.isLoadingDrivers = false;
      })
      //-- addDriver ----
      .addCase(addDriver.pending, (state) => {
        state.isUpdatingUser = true;
      })
      .addCase(addDriver.fulfilled, (state) => {
        state.isUpdatingUser = false;
      })
      .addCase(addDriver.rejected, (state) => {
        state.isUpdatingUser = false;
      })
  }
});

export default transporterSlice.reducer;

export const selectIsLoadingDrivers = state => state.deliveries.isLoadingDrivers;

export const selectIsUpdatingUser = state => state.authentication.isUpdatingUser;
