import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAddresses, selectUserAddresses } from 'store/slices/user-addresses';

import Button from '@youship/components/objects/button';
import Modal from '@youship/components/objects/modal';

import './styles.scss';

const AddressListModal = ({ currentAddress, onClose, onSave, title, type }) => {
  const dispatch = useDispatch();
  
  //lista de moradas do user
  const userAddresses = useSelector(selectUserAddresses);
  
  //const [address, setAddress] = useState(currentAddress);
  const [addressChangeIsLoading, setAddressChangeIsLoading] = useState(false);
  const [userAddressSearchTerm, setUserAddressSearchTerm] = useState('');
  
  //onSave
  const handleEditAddressModalSaveButton = () => {

  };
  
  const handleModalClose = () => {
    if (!addressChangeIsLoading) onClose();
  };

  const intl = useIntl();

  let subtitleTranslationKey = '';
  let titleTranslateKey = '';

  if (type === 'pickup') {
    subtitleTranslationKey = 'map.pickup.subtitle';
    titleTranslateKey = 'map.pickup.title';
  } else if (type === 'delivery') {
    subtitleTranslationKey = 'map.delivery.subtitle';
    titleTranslateKey = 'map.delivery.title';
  } else if (type === 'dropoff') {
    subtitleTranslationKey = 'map.dropoff.subtitle';
    titleTranslateKey = 'map.dropoff.title';
  } else if (type === 'stop') {
    subtitleTranslationKey = 'map.stop.subtitle';
    titleTranslateKey = 'map.stop.title';
  } else if (type === 'user') {
    subtitleTranslationKey = 'map.user.subtitle';
    titleTranslateKey = 'map.user.title';
  }

  const finalSubtitle = intl.formatMessage({ id: subtitleTranslationKey }) ?? null;
  const finalTitle    = title ?? intl.formatMessage({ id: titleTranslateKey });

  
  const handleUserAddressSelectClick = (index) => {
    const selectedAddress        = userAddresses[index].address;
    const selectedAddressContact = userAddresses[index].contact;
    const selectedAddressCode    = userAddresses[index].addressCode;

    const selectedAddressData = {
      apartmentAddress: selectedAddress.apartmentAddress,
      city: selectedAddress.city,
      countryCode: selectedAddress.countryCode,
      formattedAddress: selectedAddress.formattedAddress,
      lat: selectedAddress.lat,
      lng: selectedAddress.lng,
      numberAddress: selectedAddress.numberAddress,
      postalCode: selectedAddress.postalCode,
      streetAddress: selectedAddress.streetAddress,
      addressCode: selectedAddressCode
    };

    //console.log("handleUserAddressSelectClick:");
    //console.log(selectedAddressData);

    const selectedAddressContactData = {
      ...selectedAddressContact,
      countryCallingCode: selectedAddressContact.phonecode.replace("+", "")
    };

    if (type === 'user') {
      onSave({
        city: '',
        countryCode: '',
        numberAddress: '',
        postalCode: '',
        streetAddress: '',
        ...selectedAddressData
      }, selectedAddressContactData);
    } else {
      onSave({
        city: '',
        countryCode: '',
        numberAddress: '',
        postalCode: '',
        streetAddress: '',
        ...selectedAddressData
      }, selectedAddressContactData);
    }

    //setShowUserAddressesDropdown(false);
  };

  const filteredUserAddresses = userAddressSearchTerm && Array.isArray(userAddresses) ?
    userAddresses.filter(entry => entry?.strAddress && entry.strAddress.toLowerCase().includes(userAddressSearchTerm.toLowerCase())) :
    userAddresses;

  useEffect( () => {
    console.log("dispatch getAddresses");
    
    dispatch(getAddresses({
      pages: {
        current: 0,
        resultsbypage: 100
      }
    }))
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch] );  
    
  return (
    <Modal
      footer={
        <Button
          context="primary"
          disabled={addressChangeIsLoading}
          largeHorizontalPadding
          text={type === 'user' ? intl.formatMessage({ id: 'map.save' }) : intl.formatMessage({ id: 'map.select' })}
          onClick={handleEditAddressModalSaveButton}
        />
      }
      header={
        <>
        {finalTitle && (
          <div className="modal__title">
            {finalTitle}
          </div>
        )}
        {finalSubtitle && (
          <div className="modal__subtitle">
            {finalSubtitle}
          </div>
        )}
        <div className="address-list-modal__search">
          <input
              className="input input--search input--block"
              value={userAddressSearchTerm}
              onChange={event => setUserAddressSearchTerm(event?.target?.value ?? '')}
            />
        </div>
        </>
      }
      noScroll
      footerAlignRight
      onClose={handleModalClose}
      onEnter={handleEditAddressModalSaveButton}
    >
      <div className="address-list-modal">
          <ul className="user-addresses-dropdown">
            {Array.isArray(filteredUserAddresses) && filteredUserAddresses.map((entry, index) => (
              <li
                key={index}
                className="user-addresses-dropdown__item"
                onClick={() => {
                  handleUserAddressSelectClick(index);
                }}
              >
                {entry?.lineAddress}
              </li>
            ))}
          </ul>
      </div>
    </Modal>
  );
};

AddressListModal.propTypes = {
  currentAddress: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string
};

AddressListModal.defaultProps = {
  currentAddress: null,
  onClose: () => {},
  onSave: () => {},
  title: null,
  type: 'pickup'
};

export default AddressListModal;
