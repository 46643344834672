import BaseApi from '..';
import {
  CATEGORY_CURRENCIES,
  CATEGORY_DELIVERY_REQUIREMENTS,
  CATEGORY_DELIVERY_TYPES,
  CATEGORY_DIMENSION_UNITS_TYPES,
  CATEGORY_DISTANCE_UNITS,
  CATEGORY_HIGH_LIGHTS,
  CATEGORY_ITEM_TYPES,
  CATEGORY_MEASURE_UNITS,
  CATEGORY_ORDER_TYPES,
  CATEGORY_SERVICE_TYPES,
  CATEGORY_SHIPMENT_AND_ITEM_TYPES,
  CATEGORY_SHIPMENT_TYPES,
  CATEGORY_SHOP_TYPES,
  CATEGORY_SHOPPING_LOCATIONS,
  CATEGORY_VEHICLE_CATEGORIES,
  CATEGORY_VEHICLE_TYPES,
  CATEGORY_WEIGHT_UNITS
} from '../endpoints';


export default class CategoryApi extends BaseApi {
  /* eslint-disable camelcase */

  static listOrderTypes = ({
    location
  }) => this.post(CATEGORY_ORDER_TYPES, {
    location
  });

  static listShopTypes = ({
    order_type, 
    location
  }) => this.post(CATEGORY_SHOP_TYPES, {
    order_type,
    location
  });

  static listHightLights = () => this.post(CATEGORY_HIGH_LIGHTS);

  static listLocations = () => this.post(CATEGORY_SHOPPING_LOCATIONS);

  static listCurrencies = () => this.post(CATEGORY_CURRENCIES);

  static listRequirements = () => this.post(CATEGORY_DELIVERY_REQUIREMENTS);

  static listTypes = () => this.post(CATEGORY_DELIVERY_TYPES);

  static listDistanceUnits = () => this.post(CATEGORY_DISTANCE_UNITS);

  static listMeasureUnits = () => this.post(CATEGORY_MEASURE_UNITS);

  static listVehicleCategories = () => this.post(CATEGORY_VEHICLE_CATEGORIES);

  static listVehicleTypes = ({
    idparent
  }) => this.post(CATEGORY_VEHICLE_TYPES, {
    idparent
  });

  static listWeightUnits = () => this.post(CATEGORY_WEIGHT_UNITS);

  static listDimensionUnits = () => this.post(CATEGORY_DIMENSION_UNITS_TYPES);

  static listServiceTypes = () => this.post(CATEGORY_SERVICE_TYPES);

  static listShipmentTypes = ({
    idparent // optional
  }) => this.post(CATEGORY_SHIPMENT_TYPES, {
    idparent
  });

  static listShipmentAndItemTypes = ({
    idparent
  }) => this.post(CATEGORY_SHIPMENT_AND_ITEM_TYPES, {
    idparent
  });

  static listItemTypes = ({
    idparent // optional
  }) => this.post(CATEGORY_ITEM_TYPES, {
    idparent
  });

  /* eslint-enable camelcase */
}
