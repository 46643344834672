import BaseApi from '..';
import {
  DELIVERY_OPS,
  DELIVERY_OPS_ACCEPT,
  DELIVERY_OPS_ASSIGN,
  DELIVERY_OPS_CANCEL,
  DELIVERY_OPS_COLLECT,
  DELIVERY_OPS_COLLECTION_IN_TRANSIT,
  DELIVERY_OPS_COURIER_ARRIVED_AT_COLLECTION_LOCATION,
  DELIVERY_OPS_COURIER_ARRIVED_AT_DELIVERY_LOCATION,
  DELIVERY_OPS_COURIER_ARRIVED_AT_RETURN_LOCATION,
  DELIVERY_OPS_DELIVER,
  DELIVERY_OPS_DELIVERY_IN_TRANSIT,
  DELIVERY_OPS_PAY,
  DELIVERY_OPS_RATE_CUSTOMER,
  DELIVERY_OPS_RATE_SHOP,
  DELIVERY_OPS_RATE_TRANSPORTER,
  DELIVERY_OPS_RATE_USER,
  DELIVERY_OPS_REFUSE,
  DELIVERY_OPS_REMOVE,
  DELIVERY_OPS_RESCHEDULE,
  DELIVERY_OPS_RETURN_IN_TRANSIT,
  DELIVERY_OPS_RETURNING,
  DELIVERY_OPS_SHOP_ACCEPT,
  DELIVERY_OPS_SHOP_FINISH_PREPARATION,
  DELIVERY_OPS_SHOP_PREPARE,
  DELIVERY_OPS_SHOP_REFUSE
} from '../endpoints';

export default class DeliveryOperationsApi extends BaseApi {
  /* eslint-disable camelcase */

  static payDelivery = () => this.post(DELIVERY_OPS_PAY);

  static removeDelivery = ({
    order_code
  }) => this.post(DELIVERY_OPS_REMOVE, {
    order_code
  });

  static cancelDelivery = ({
    order_code
  }) => this.post(DELIVERY_OPS_CANCEL, {
    order_code
  });

  static shopAcceptRequest = ({
    order_code
  }) => this.post(DELIVERY_OPS_SHOP_ACCEPT, {
    order_code
  });

  static shopRefusesRequest = ({
    order_code
  }) => this.post(DELIVERY_OPS_SHOP_REFUSE, {
    order_code
  });

  static shopStartsPreparationOfRequest = ({
    order_code
  }) => this.post(DELIVERY_OPS_SHOP_PREPARE, {
    order_code
  });

  static shopFinishesPreparationOfRequest = ({
    order_code
  }) => this.post(DELIVERY_OPS_SHOP_FINISH_PREPARATION, {
    order_code
  });

  static assignDelivery = ({
    order_code,
    driver_code
  }) => this.post(DELIVERY_OPS_ASSIGN, {
    order_code,
    driver_code
  });


  static directActionDelivery = ({
    order_code, actionType
  }) => this.post(DELIVERY_OPS + "/" + actionType, {
    order_code
  });

  static actionDeliveryProof = ({
    order_code,
    actionType,
    result,
    comments,
    name,
    signature
  }) => this.post(DELIVERY_OPS + "/" + actionType, {
    order_code,
    result,
    comments,
    name,
    signature
  });

  static acceptDelivery = ({
    order_code
  }) => this.post(DELIVERY_OPS_ACCEPT, {
    order_code
  });

  static refuseDelivery = ({
    order_code,
    reason
  }) => this.post(DELIVERY_OPS_REFUSE, {
    order_code,
    reason
  });

  static collectionInTransit = ({
    order_code
  }) => this.post(DELIVERY_OPS_COLLECTION_IN_TRANSIT, {
    order_code
  });

  static collectDelivery = ({
    order_code,
    result,
    comments,
    name,
    signature
  }) => this.post(DELIVERY_OPS_COLLECT, {
    order_code,
    result,
    comments,
    name,
    signature
  });

  static courierArrivedAtCollectionLocation = ({
    order_code
  }) => this.post(DELIVERY_OPS_COURIER_ARRIVED_AT_COLLECTION_LOCATION, {
    order_code
  });

  static courierArrivedAtDeliveryLocation = ({
    order_code
  }) => this.post(DELIVERY_OPS_COURIER_ARRIVED_AT_DELIVERY_LOCATION, {
    order_code
  });

  static courierArrivedAtReturnLocation = ({
    order_code
  }) => this.post(DELIVERY_OPS_COURIER_ARRIVED_AT_RETURN_LOCATION, {
    order_code
  });

  static deliveryInTransit = ({
    order_code
  }) => this.post(DELIVERY_OPS_DELIVERY_IN_TRANSIT, {
    order_code
  });

  static deliverDelivery = ({
    order_code,
    result,
    comments,
    name,
    signature
  }) => this.post(DELIVERY_OPS_DELIVER, {
    order_code,
    result,
    comments,
    name,
    signature
  });

  static returnInTransit = ({
    order_code
  }) => this.post(DELIVERY_OPS_RETURN_IN_TRANSIT, {
    order_code
  });

  static returnDelivery = ({
    order_code,
    result,
    comments,
    name,
    signature
  }) => this.post(DELIVERY_OPS_RETURNING, {
    order_code,
    result,
    comments,
    name,
    signature
  });

  static rateCustomer = ({
    order_code,
    rating,
    description
  }) => this.post(DELIVERY_OPS_RATE_CUSTOMER, {
    order_code,
    rating,
    description
  });

  static rateShop = ({
    order_code,
    rating,
    description
  }) => this.post(DELIVERY_OPS_RATE_SHOP, {
    order_code,
    rating,
    description
  });

  static rateTransporter = ({
    order_code,
    rating,
    description
  }) => this.post(DELIVERY_OPS_RATE_TRANSPORTER, {
    order_code,
    rating,
    description
  });

  static rateUser = ({
    order_code,
    rating,
    description
  }) => this.post(DELIVERY_OPS_RATE_USER, {
    order_code,
    rating,
    description
  });

  static rescheduleDelivery = ({
    order_code,
    order = {
      pickup: {
        schedule: {
          ready: '',
          deadline: ''
        },
        notes: ''
      },
      dropoff: {
        schedule: {
          ready: '',
          deadline: ''
        }
      },
      notes: ''
    }
  }) => this.post(DELIVERY_OPS_RESCHEDULE, {
    order_code,
    order
  });

  /* eslint-enable camelcase */
}
