/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';

import editIcon from 'images/icons/edit.svg';
import trashIcon from 'images/icons/trash.svg';
import trashRedIcon from 'images/icons/trash--red.svg';

import './styles.scss';
import removeIcon from "../../../../images/icons/trash--red.svg";
import avatarImage from "../../../../../../shared/assets/images/avatar.jpg";

const ShipmentOptionsOption = ({
                                 deletable,
                                 description,
                                 edit,
                                 hasError,
                                 icon,
                                 type,
                                 noBackground,
                                 noBorder,
                                 noPadding,
                                 onDelete,
                                 onEdit,
                                 title,
                                 quantity
                               }) => (
    <div className={`shipment-options-option${
        hasError ? ' shipment-options-option--has-error' : ''}${
        noBackground ? ' shipment-options-option--no-background' : ''}${
        noBorder ? ' shipment-options-option--no-border' : ''}${
        noPadding ? ' shipment-options-option--no-padding' : ''}
  `}
    >
      {(
          <div className={type === "driver" ? "avatar-button__image-wrapper" : "shipment-options-option__icon"}>
            <img
                alt={type === "driver" ? icon : "Icon"}
                className={type === "driver" ? "avatar-button__image" : "shipment-options-option__icon"}
                src={icon !== "" ? icon : avatarImage}
            />
          </div>
      )}


      <div className="shipment-options-option__content">
        <div className="shipment-options-option__header">
          <div className="shipment-options-option_title">
            {quantity && (
                <span className="shipment-options-option__description">
            {quantity}
                  ×
                  {' '}
          </span>
            )}
            {title}
          </div>



          {(deletable || edit) && (
              <div className="shipment-options-option__actions">
                {deletable && (
                    <img
                        alt="Delete Icon"
                        className="shipment-options-option__action-icon"
                        src={trashIcon}
                        onClick={onDelete}
                        onMouseOut={
                          e => (e.currentTarget.src = trashIcon)
                        }
                        onMouseOver={
                          e => (e.currentTarget.src = trashRedIcon)
                        }
                    />
                )}
                {edit && (
                    <img
                        alt="Edit Icon"
                        className="shipment-options-option__action-icon"
                        src={editIcon}
                        onClick={onEdit}
                    />
                )}

                {type === "driver" && (
                    <img
                        alt="Remove Icon"
                        className="shipment-options-option__action-icon"
                        src={removeIcon}
                        onClick={onDelete}
                    />
                )}

              </div>
          )}
        </div>
        <div className="shipment-options-option__description">
          {description}
        </div>
      </div>
    </div>
);

ShipmentOptionsOption.propTypes = {
  deletable: PropTypes.bool,
  description: PropTypes.string,
  edit: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string,
  quantity: PropTypes.number
};

ShipmentOptionsOption.defaultProps = {
  deletable: false,
  description: '',
  edit: true,
  icon: '',
  type:'',
  noBackground: false,
  noBorder: false,
  noPadding: false,
  onDelete: () => {},
  onEdit: () => {},
  title: '',
  quantity: null
};


export default ShipmentOptionsOption;
