import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import OrderCard from 'components/orders/order-card';

const OrderModal = ({ onClose, ...order }) => {
  const modalContent = useRef(null);

  const clickListener = (event) => {
    if (!(modalContent.current).contains(event?.target)) {
      handleClickOnOverlay();
    }
  };

  const handleClickOnOverlay = () => {
    onClose();
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
    };
  });

  return (
    <div className="order-modal">
      <div className="order-modal__overlay">
        <div
          className="order-modal__content"
          ref={modalContent}
        >
          <OrderCard
            {...order}
            full
            hasCloseIcon
            onClose={onClose}
          />
        </div>
      </div>
    </div>
  );
};

OrderModal.propTypes = {
  order: PropTypes.object,
  onClose: PropTypes.func
};

export default OrderModal;
