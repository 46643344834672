import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';

import HighlightCard from '@youship/components/highlight-card';

import 'swiper/swiper.scss';
import './styles.scss';

SwiperCore.use([Navigation, Pagination, A11y]);

const HighlightsSlider = ({ items }) => (
  <div className="highlights-slider">
    <Swiper
      className="highlights-slider__swiper"
      grabCursor="true"
      // spaceBetween="5" NOTE: Using spaceBetween causes the bullets to malfunction
      pagination={{
        el: '.highlights-slider__bullets',
        type: 'bullets',
        bulletClass: 'highlights-slider__bullet',
        bulletActiveClass: 'highlights-slider__bullet--active',
        clickable: true
      }}
      slidesPerView="1"
    >
      {Array.isArray(items) && !!items.length &&
        items.map((item, index) => (
          <SwiperSlide
            key={index}
            className="highlights-slider__slide"
          >
            <HighlightCard
              {...item}
              linkComponent={Link}
            />
          </SwiperSlide>
        ))}
      <div className="highlights-slider__bullets" />
    </Swiper>
  </div>
);

HighlightsSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    actionName: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string
  }))
};

HighlightsSlider.defaultProps = {
  items: null
};

export default HighlightsSlider;
