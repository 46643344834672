import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const InfoBox = ({ description, icon, title }) => (
  <div className="info-box">
    <div className="info-box__content">
      {!!icon && (
        <img
          alt="Icon"
          className="info-box__icon"
          src={icon}
        />
      )}
      <div className="info-box-text">
        <div className="info-box__title">
          {title}
        </div>
        {!!description && (
          <div className="info-box__description">
            {description}
          </div>
        )}
      </div>
    </div>
  </div>
);

InfoBox.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired
};

InfoBox.defaultProps = {
  description: null,
  icon: null
};

export default InfoBox;
