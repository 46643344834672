import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import DeliveryApi, { DELIVERY_DEFAULT_TIME_INTERVAL } from '@youship/api/delivery';
import { FormatAddressToApi, FormatShipmentToApi } from 'utils/new-order';
import SessionStorageManager from '@youship/utils/SessionStorageManager';

const newDeliveryAdapter = createEntityAdapter();

const initialState = newDeliveryAdapter.getInitialState({
  
  draftRef: Math.random(),
  requestType: null,
  shipmentMethod: null,
  serviceCode: '',

  rates: null,
  deliveryDistance: null,
  deliveryDuration: null,
  deliveryError: null,
  deliveryIsLoading: null,
  deliveryQuote: null,
  deliveryQuoteError: null,
  deliveryQuoteIsLoading: null,
  delivery: null, //result of createDelivery

  pickups: [],
  dropoffs: [],
  stops: [],
  hasReturn: false,
  
  shipments: [[]],
  shipmentsOptions: [{}],

  originSchedule: {
    pickupDate: null,
    pickupTimeSlotValue: DELIVERY_DEFAULT_TIME_INTERVAL
  },
  destinationSchedule: {
    dropoffDate: null,
    dropoffTimeSlotValue: DELIVERY_DEFAULT_TIME_INTERVAL
  },

  paymentTypeId: null,
  voucherCode: null
});

// Thunks:

export const createDelivery = createAsyncThunk(
  'newDelivery/createDelivery',
  (argument, { getState, rejectWithValue }) => {
    const state = getState().newDelivery;

    if (!state) return Promise.reject(new Error('Unable to access new delivery data.'));

    const {
      dropoffs,
      destinationSchedule,
      deliveryQuote,
      //deliveryQuoteVoucherCode,
      hasReturn,
      paymentTypeId,
      pickups,
      originSchedule,
      serviceCode,
      voucherCode,
      shipments,
      shipmentsOptions,
      requestType,
      draftRef,
      shipmentMethod
    } = state;

    if (!originSchedule) return Promise.reject(new Error('A pickup schedule must be selected before creating a delivery.'));
    if (!destinationSchedule) return Promise.reject(new Error('A dropoff schedule must be selected before creating a delivery.'));
    if (!deliveryQuote) return Promise.reject(new Error('A new delivery quote must be requested before creating a delivery.'));
    
    if( !requestType || requestType !== "concierge" ){
      if (!paymentTypeId) return Promise.reject(new Error('A payment method must be selected before creating a delivery.'));
    }

    const { dropoffAddress: deliveryDropoffAddress, deliveryItems, pickupAddress: deliveryPickupAddress } = deliveryQuote;
    const { dropoffDate, dropoffTimeSlotValue } = destinationSchedule;
    const { pickupDate, pickupTimeSlotValue } = originSchedule;

    if (!deliveryDropoffAddress) return Promise.reject(new Error('The stored new delivery quote does not include a dropoff address.'));
    if (!deliveryPickupAddress) return Promise.reject(new Error('The stored new delivery quote does not include a pickup address.'));
    if (!Array.isArray(deliveryItems) || !deliveryItems.length) return Promise.reject(new Error('The stored new delivery quote does not include an item list.'));

    // Note: This is commented since shipment data appears to be optional
    // This can be removed once we make sure that is the case
    // if (!shipment) return Promise.reject(new Error('A shipment must be selected before creating a delivery.'));
    if (!serviceCode) return Promise.reject(new Error('The stored new delivery quote does not include a service code.'));

    if (!dropoffDate) return Promise.reject(new Error('A dropoff schedule date must be selected before creating a delivery.'));
    if (!dropoffTimeSlotValue) return Promise.reject(new Error('A dropoff schedule time slot must be selected before creating a delivery.'));
    if (!pickupDate) return Promise.reject(new Error('A pickup schedule date must be selected before creating a delivery.'));
    if (!pickupTimeSlotValue) return Promise.reject(new Error('A dropoff schedule time slot must be selected before creating a delivery.'));

    const destinationScheduleDate = `${dropoffDate} ${dropoffTimeSlotValue}`;
    const originScheduleDate = `${pickupDate} ${pickupTimeSlotValue}`;

    let data = null;

    const dropoff = dropoffs[0];
    const pickup = pickups[0];

    const completeDropoff = FormatAddressToApi(dropoff);
    const completePickup = FormatAddressToApi(pickup);

    const deliveryData = populateDeliveryData({
      destination: completeDropoff,
      origin: completePickup,
      destinationSchedule,
      originSchedule,
      shipment: shipmentsOptions[0] ? shipmentsOptions[0] : {},
      items: Array.isArray(shipments[0]) ? shipments[0] : [],
      serviceCode,
      shipmentMethod,
      draftRef,
      voucherCode
    });

    if (hasReturn) {
      data = [];

      data.push(deliveryData.data);

      const returnDeliveryData = populateDeliveryData({
        destination: completePickup,
        origin: completeDropoff,
        destinationSchedule,
        originSchedule,
        shipment: shipmentsOptions[shipments.length - 1] ?  shipmentsOptions[shipments.length - 1] : {},
        items: Array.isArray(shipments[shipments.length - 1]) ? shipments[shipments.length - 1] : [],
        serviceCode,
        shipmentMethod,
        draftRef,
        voucherCode
      });

      data.push(returnDeliveryData.data);
    } else {
      data = deliveryData.data;
      data.pickup.notes = pickup.notes;
    }

    
    if (Array.isArray(data)) {
      /* eslint-disable camelcase */
      data = data.map((dataEntry) => {
        const pickupDataEntry = { ...dataEntry.pickup };
        const dropoffDataEntry = { ...dataEntry.dropoff };

        pickupDataEntry.schedule = {
          ready: originScheduleDate
        };

        dropoffDataEntry.schedule = {
          deadline: destinationScheduleDate
        };

        return {
          ...dataEntry,
          payment_code: paymentTypeId,
          dropoff: dropoffDataEntry,
          pickup: pickupDataEntry
        };
      });
    } else {
      if (voucherCode) data.voucher = voucherCode;
      data.payment_code = paymentTypeId;
      /* eslint-enable camelcase */
    }

    const deliveryCreate = Array.isArray(data) ? DeliveryApi.multipleDeliveryCreate({ requestType: requestType, orders: data }) : DeliveryApi.deliveryCreate({...data, requestType: requestType} );

    return deliveryCreate
      .then((response) => {
          return {
            ...response,
            orderCode: response.order_code,
            paymentUrl: response.payment_url,
            ysToken: response.ys_token
         }
       })
        // rejectWithValue is necessary to send a custom error on the payload
       .catch(error => rejectWithValue(error));	
  }
);

const populateDeliveryData = (payload) => {
  const {
    origin,
    destination,
    destinationSchedule,
    originSchedule,
    shipment,
    items,
    serviceCode,
    shipmentMethod,
    draftRef,
    voucherCode
  } = payload;

  const pickupAddress = origin.address;
  const pickupContact = origin.contact;
  const pickupNotes = origin.notes;

  const dropoffAddress = destination.address;
  const dropoffContact = destination.contact;
  const dropoffNotes = destination.notes;

  const { lat: dropoffLat, lng: dropoffLng } = dropoffAddress;
  const { lat: pickupLat, lng: pickupLng } = pickupAddress;

  const deliveryQuoteDropoffAddress = {};
  const deliveryQuotePickupAddress = {};

  if (typeof dropoffLat === 'number' && typeof dropoffLng === 'number') {
    deliveryQuoteDropoffAddress.lat = dropoffLat;
    deliveryQuoteDropoffAddress.lng = dropoffLng;
  }

  if (typeof pickupLat === 'number' && typeof pickupLng === 'number') {
    deliveryQuotePickupAddress.lat = pickupLat;
    deliveryQuotePickupAddress.lng = pickupLng;
  }

  const requestDropoffAddress = {
    ...dropoffAddress,
    ...deliveryQuoteDropoffAddress
  };

  const requestPickupAddress = {
    ...pickupAddress,
    ...deliveryQuotePickupAddress
  };

  
  const pickup = {
    address: {
      ...requestPickupAddress
    },
    ...(pickupContact && {
      contact: {
        ...pickupContact,
        phonecode: pickupContact.phonecode || pickupContact.countryCallingCode
      }
    }),
    ...(pickupNotes && { notes: pickupNotes })
  };

  const dropoff = {
    address: {
      ...requestDropoffAddress
    },
    ...(dropoffContact && { contact: {
      ...dropoffContact,
      phonecode: dropoffContact.phonecode || dropoffContact.countryCallingCode
    } }),
    ...(dropoffNotes && { notes: dropoffNotes })
  };

  if( requestPickupAddress.addressCode ) pickup.address_code = requestPickupAddress.addressCode;
  if( requestDropoffAddress.addressCode ) dropoff.address_code = requestDropoffAddress.addressCode;

  let requestDestinationSchedule = null;

  // Add dropoff schedule only if it has been changed from the default value
  if (destinationSchedule) {
    const { dropoffDate, dropoffTimeSlotValue } = destinationSchedule;

    const schedule = `${moment(dropoffDate).format('YYYY-MM-DD')} ${dropoffTimeSlotValue}`;

    if( moment(dropoffDate).isValid() && moment(schedule).isValid() ){
      requestDestinationSchedule = destinationSchedule;
      dropoff.schedule = { deadline: schedule };
    }
  }

  let requestOriginSchedule = null;

  // Add pickup schedule only if it has been changed from the default value
  if (originSchedule) {
    const { pickupDate, pickupTimeSlotValue } = originSchedule;

    const schedule = `${moment(pickupDate).format('YYYY-MM-DD')} ${pickupTimeSlotValue}`;

    if( moment(pickupDate).isValid() && moment(schedule).isValid() ){
      requestOriginSchedule = originSchedule;
      pickup.schedule = { ready: schedule };
    }
  }

  const deliveryItems = [];

  items.forEach((item) => {
    const {
      code,
      description,
      height,
      length,
      quantity,
      width,
      weight
    } = item;

    if (item) {
      const deliveryItem = {
        code,
        description,
        height,
        length,
        quantity,
        width,
        weight
      };

      deliveryItems.push(deliveryItem);
    }
  });

  /*
  const itemsSpecifications = items.map(item => item.specifications);
  itemsSpecifications.forEach((item) => {
    const {
      code,
      description,
      height,
      length,
      quantity,
      width,
      weight
    } = item;

    if (item) {
      const deliveryItem = {
        code,
        description,
        height,
        length,
        quantity,
        width,
        weight
      };

      deliveryItems.push(deliveryItem);
    }
  });
  */

  /* eslint-disable camelcase */
  const data = {
    data: {
      service_code: serviceCode,
      dropoff,
      pickup,
      shipment: FormatShipmentToApi(shipment),
      items: deliveryItems
    },
    request: {
      requestDestinationSchedule,
      requestOriginSchedule,
      requestDropoffAddress,
      requestPickupAddress
    },
    deliveryItems
  };

  if (shipmentMethod) data.data.ship_type = shipmentMethod;
  if (draftRef)       data.data.draft_ref = draftRef;
  if (voucherCode)    data.data.voucher   = voucherCode;

  return data;
};

export const getDeliveryQuote = createAsyncThunk(
  'newDelivery/getDeliveryQuote',
  (argument, { getState, rejectWithValue }) => {
    const state = getState().newDelivery;

    if (!state) return Promise.reject(new Error('Unable to access new delivery data.'));

    const {
      dropoffs,
      destinationSchedule,
      //deliveryQuoteVoucherCode,
      hasReturn,
      pickups,
      originSchedule,
      serviceCode,
      voucherCode,
      shipments,
      shipmentsOptions,
      requestType,
      draftRef,
      shipmentMethod
    } = state;

    const items    = Array.isArray(shipments) && shipments.length ? shipments[0] : [];
    const shipment = shipmentsOptions[0] ? shipmentsOptions[0] : {};

    //console.log("quote - shipment options:");
    //console.log(shipment);

    if (!Array.isArray(pickups) || !pickups.length || !pickups[0].address) return Promise.reject(new Error('Dropoff address is invalid.'));
    if (!Array.isArray(dropoffs) || !dropoffs.length || !dropoffs[0].address) return Promise.reject(new Error('Dropoff address is invalid.'));
    if (!serviceCode) return Promise.reject(new Error('Invalid service code.'));

    const dropoff = dropoffs[0];
    const pickup  = pickups[0];

    const completeDropoff = FormatAddressToApi(dropoff);
    const completePickup  = FormatAddressToApi(pickup);

    let data = null;
    let request = null;
    let deliveryItems = null;

    const deliveryData = populateDeliveryData({
      destination: completeDropoff,
      destinationSchedule,
      origin: completePickup,
      originSchedule,
      shipment: shipment,
      items: Array.isArray(items) ? items : [],
      serviceCode,
      shipmentMethod,
      draftRef,
      voucherCode
    });

    if (hasReturn) {
      data = [];

      data.push(deliveryData.data);

      const returnDeliveryData = populateDeliveryData({
        destination: completePickup,
        destinationSchedule,
        origin: completeDropoff,
        originSchedule,
        shipment: shipmentsOptions[shipments.length - 1] ? shipmentsOptions[shipments.length - 1] : {},
        items: Array.isArray(shipments) ? shipments[shipments.length - 1] : [],
        serviceCode,
        shipmentMethod,
        draftRef,
        voucherCode
      });

      data.push(returnDeliveryData.data);

      request = returnDeliveryData.request;
      // This part will need to be changed to account for multiple shipments with different items
      deliveryItems = items;
    } else {

      data = deliveryData.data;
      request = deliveryData.request;
      deliveryItems = deliveryData.deliveryItems;
    }

    
    /* eslint-enable camelcase */

    //alert("AQUI voucherCode:" + voucherCode);

    console.log("deliveryQuote data: ");
    console.log(data);

    const deliveryQuote = hasReturn ? DeliveryApi.multiDeliveryQuote({ requestType: requestType, orders: data }) : DeliveryApi.deliveryQuote({...data, requestType: requestType});

    return deliveryQuote
      .then((response) => {

        console.log("deliveryQuote response:");
        console.log(response);
        
        /*
        if ( !response?.quote) {
          throw new Error('Unexpected response while requesting a quote for your delivery.');
        } 
        */
        
        if ( response?.errorcode && response?.errorcode === 10){
          //voucher invalid
        }

        if ( !response?.quote) {
          //atenão ao tratamento que tem de ser dado em:
          // .addCase(getDeliveryQuote.fulfilled, (state, action) =>
          const payload = {
            ...response,
            invalid_quote: true
          };
          return payload;
        } 

        const { quote } = response;
        const {
          payment_message: paymentMessage,
          info_message: infoMessage,
          payment_types: paymentTypesFromQuote,
          price_details: priceDetailsFromQuote,
          schedule_intervals: scheduleIntervalsFromQuote,
          schedule_intervals2: scheduleIntervalsForDeliveryFromQuote,
          total
        } = quote;

        /*
        // Make sure that there are schedule intervals, since a valid schedule is required to create a delivery
        if (!Array.isArray(scheduleIntervalsFromQuote) || !scheduleIntervalsFromQuote.length) {
          throw new Error('The provided quote did not include schedule intervals.');
        }

        // Make sure that there are schedule intervals after selecting a pickup schedule, since a valid schedule is required to create a delivery
        if (!Array.isArray(scheduleIntervalsForDeliveryFromQuote) || !scheduleIntervalsForDeliveryFromQuote.length) {
          throw new Error('The provided quote did not include schedule intervals.');
        }

        // Make sure that there are payment options
        if (!Array.isArray(paymentTypesFromQuote) || !paymentTypesFromQuote.length) {
          throw new Error('The provided quote did not include payment options.');
        }
        */

        const priceDetails = Array.isArray(priceDetailsFromQuote) ? priceDetailsFromQuote : [];

        const scheduleDropoffIntervals = scheduleIntervalsForDeliveryFromQuote ? 
          scheduleIntervalsForDeliveryFromQuote.map(day => ({
            dropoffDate: day?.date?.datestring,
            dropoffTimeSlots: Array.isArray(day?.timeinterval) ?
              day.timeinterval.map(({ hour, minute, timestring }) => ({
                label: timestring,
                value: `${hour < 10 ? 0 : ''}${hour}:${minute < 10 ? 0 : ''}${minute}`
              })) :
              []
          }))
          .filter(({ dropoffDate, dropoffTimeSlots }) => dropoffDate && dropoffTimeSlots.length) : [];

        const schedulePickupIntervals = scheduleIntervalsFromQuote ? 
          scheduleIntervalsFromQuote.map(day => ({
            pickupDate: day?.date?.datestring,
            pickupTimeSlots: Array.isArray(day?.timeinterval) ?
              day.timeinterval.map(({ hour, minute, timestring }) => ({
                label: timestring,
                value: `${hour < 10 ? 0 : ''}${hour}:${minute < 10 ? 0 : ''}${minute}`
              })) :
              []
          }))
          .filter(({ pickupDate, pickupTimeSlots }) => pickupDate && pickupTimeSlots.length) : [];

       
        const paymentTypes = paymentTypesFromQuote ? 
          paymentTypesFromQuote.map(paymentType => ({
            ...paymentType,
            id: paymentType.code,
            imageUrl: paymentType.icon_url
          }))
          .filter(({ id, name }) => (id || typeof id === 'number') && name) : [];

        let quoteDistance = 0;
        let quoteDuration = 0;

        if (response?.orders) {
          response.orders.forEach((order) => {
            if (order.distance) {
              quoteDistance += order.distance;
            }
            if (order.duration) {
              quoteDuration += order.duration;
            }
          });
        } else {
          quoteDistance = response?.order?.distance;
          quoteDuration = response?.order?.duration;
        }

        const payload = {
          ...quote,
          distance: quoteDistance,
          duration: quoteDuration,
          dropoffAddress: request.requestDropoffAddress,
          paymentMessage,
          infoMessage,
          paymentTypes,
          pickupAddress: request.requestPickupAddress,
          priceDetails,
          deliveryItems,
          shipment: data.shipment,
          scheduleDropoffIntervals,
          schedulePickupIntervals,
          serviceCode,
          total
        };

        if (request.requestDestinationSchedule) payload.destinationSchedule = request.requestDestinationSchedule;
        if (request.requestOriginSchedule) payload.originSchedule = request.requestOriginSchedule;

        return payload;
      })
      // rejectWithValue is necessary to send a custom error on the payload
      .catch(error => rejectWithValue(error));
  }
);

export const getDeliveryEstimate = createAsyncThunk(
  'newDelivery/getDeliveryEstimate',
  ({ dropoff, pickup }, {getState, rejectWithValue}) => {

    const state = getState().newDelivery;

    if (!(dropoff?.lat && dropoff.lng && pickup?.lat && pickup.lng)) {
      throw new Error('There was an error getting your pickup address and dropoff address.');
    }

    /*
    draftRef: Math.random(),
  requestType: null,
  shipmentMethod: null,
  */
    const {
      requestType,
      draftRef,
      shipmentMethod
    } = state;

    const dropoffAddress = {
      address: {
        lat: dropoff.lat,
        lng: dropoff.lng
      }
    };

    const pickupAddress = {
      address: {
        lat: pickup.lat,
        lng: pickup.lng
      }
    };

    return DeliveryApi.deliveryEstimate({ requestType: requestType, ship_type:shipmentMethod, draft_ref: draftRef,  dropoff: dropoffAddress, pickup: pickupAddress })
      .then((response) => {
        const details = response;

        return {
          ...details,
          rates: Array.isArray(details.rates) ?
            details.rates.map(rate => ({
              ...rate,
              items: Array.isArray(rate.items) ?
                rate.items.map(item => ({
                  ...item,
                  autoAdd: item.auto_add,
                  iconUrl: item.icon_url,
                  id: `${rate.service?.code}-${item.name}`,
                  code: item.code,
                  formattedLength: {
                    value: item.length,
                    text: `${item.length} cm`
                  },
                  formattedLengths: Array.isArray(item.lengths) ?
                    item.lengths.map(option => ({
                      value: option,
                      text: `${option} cm`
                    })) :
                    [],
                  formattedHeight: {
                    value: item.height,
                    text: `${item.height} cm`
                  },
                  formattedHeights: Array.isArray(item.heights) ?
                    item.heights.map(option => ({
                      value: option,
                      text: `${option} cm`
                    })) :
                    [],
                  formattedWeight: {
                    value: item.weight,
                    text: `${item.weight} kg`
                  },
                  formattedWeights: Array.isArray(item.weights) ?
                    item.weights.map(option => ({
                      value: option,
                      text: `${option} kg`
                    })) :
                    [],
                  formattedWidth: {
                    value: item.width,
                    text: `${item.width} cm`
                  },
                  formattedWidths: Array.isArray(item.widths) ?
                    item.widths.map(option => ({
                      value: option,
                      text: `${option} cm`
                    })) :
                    [],
                  serviceCode: rate.service?.code
                })) :
                [],
              service: {
                ...rate.service,
                options: rate.service?.options ?
                  ({
                    ...rate.service.options,
                    hasCharge: rate.service.options.has_charge,
                    hasFragile: rate.service.options.has_fragile,
                    hasHandDelivery: rate.service.options.has_handdelivery,
                    hasInvoicing: rate.service.options.has_invoicing,
                    hasReference: rate.service.options.has_reference,
                    hasShipper: rate.service.options.has_shipper
                  }) :
                  {}
              }
            })) :
            []
        };
      })
      .then(details => ({
        ...details
      }))
      // rejectWithValue is necessary to send a custom error on the payload
      .catch(error => rejectWithValue(error));
  }
);

/* eslint-disable camelcase */
export const setDefaultPickup = createAsyncThunk(
  'newDelivery/setDefaultPickup',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const { defaultAddress, firstName, lastName, name, phone } = user;
    const company = defaultAddress?.contact?.company;
    const { code, number } = phone;

    const countryCallingCode = typeof code === 'string' ? code.replace(/^\+/, '') : code;

    if (!defaultAddress) return Promise.reject(new Error('User data does not include a default address.'));
    if (!name && !firstName && !lastName) return Promise.reject(new Error('User data does not include a name.'));
    if (!code) return Promise.reject(new Error('User data does not include a phone country code'));
    if (!number) return Promise.reject(new Error('User data does not include a phone number.'));

    const {
      apartmentAddress,
      city,
      countryCode,
      countryId,
      formattedAddress,
      lat,
      lng,
      numberAddress,
      postalCode,
      stateAddress,
      streetAddress
    } = defaultAddress;

    const addressData = {
      address: {
        apartment_address: apartmentAddress,
        city,
        formatted_address: formattedAddress,
        number_address: numberAddress,
        postalcode: postalCode,
        street_address: streetAddress
      }
    };

    if (apartmentAddress) addressData.address.apartment_address = apartmentAddress;
    if (countryCode) addressData.address.countrycode = countryCode;
    if (countryId) addressData.address.idcountry = countryId;
    if (lat) addressData.address.lat = lat;
    if (lng) addressData.address.lng = lng;
    if (numberAddress) addressData.address.number_address = numberAddress;
    if (stateAddress) addressData.address.state_address = stateAddress;

    const payload = {
      address: {
        ...defaultAddress, // keep the camelCase frontend formatted fields
        ...addressData
      },
      contact: {
        name: name ? name : [firstName, lastName].join(' '),
        company,
        countryCallingCode,
        country_calling_code: countryCallingCode,
        phone: number
      },
      details: {},
      notes: ''
    };

    return Promise.resolve(payload);
  }
);
/* eslint-enable camelcase */

// Slice:

const newDeliverySlice = createSlice({
  name: 'newDelivery',

  initialState,

  reducers: {
    /*
    addVoucherCodeToDeliveryQuote (state) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { voucherCode } = state;

      if (voucherCode && typeof voucherCode === 'string') {
        state.deliveryQuoteVoucherCode = voucherCode;
        state.voucherCode = null;

        SessionStorageManager.setDeliveryVoucherCode(voucherCode);
      }
    },
    */

    initialize (state) {
      
      const draftRef       = SessionStorageManager.getDeliveryDraftRef();
      const requestType    = SessionStorageManager.getDeliveryRequestType();
      const shipmentMethod = SessionStorageManager.getDeliveryShipmentMethod();
      const serviceCode    = SessionStorageManager.getDeliveryServiceCode();

      const pickups   = SessionStorageManager.getDeliveryPickups();
      const dropoffs  = SessionStorageManager.getDeliveryDropoffs();
      const stops     = SessionStorageManager.getDeliveryStops();
      const hasReturn = SessionStorageManager.getDeliveryHasReturn();
      const shipments = SessionStorageManager.getDeliveryShipments();
      const shipmentsOptions = SessionStorageManager.getDeliveryShipmentsOptions();
      
      const originSchedule      = SessionStorageManager.getDeliveryOriginSchedule();
      const destinationSchedule = SessionStorageManager.getDeliveryDestinationSchedule();
      
      const paymentTypeId = SessionStorageManager.getDeliveryPaymentTypeId();
      const voucherCode   = SessionStorageManager.getDeliveryVoucherCode();
      

      if (draftRef) state.draftRef = draftRef;

      if (requestType) state.requestType = requestType;
      if (shipmentMethod) state.shipmentMethod = shipmentMethod;
      if (serviceCode) state.serviceCode = serviceCode;

      if (pickups) state.pickups = pickups;
      if (dropoffs) state.dropoffs = dropoffs;
      if (stops) state.stops = stops;
      if (shipments) state.shipments = shipments;
      if (shipmentsOptions) state.shipmentsOptions = shipmentsOptions;
      state.hasReturn = hasReturn;

      if (originSchedule?.pickupDate) state.originSchedule.pickupDate = originSchedule.pickupDate;
      if (originSchedule?.pickupTimeSlotValue) state.originSchedule.pickupTimeSlotValue = originSchedule.pickupTimeSlotValue;
      
      if (destinationSchedule?.dropoffDate) state.destinationSchedule.dropoffDate = destinationSchedule.dropoffDate;
      if (destinationSchedule?.dropoffTimeSlotValue) state.destinationSchedule.dropoffTimeSlotValue = destinationSchedule.dropoffTimeSlotValue;
      
      if (paymentTypeId) state.paymentTypeId = paymentTypeId;
      if (voucherCode) state.voucherCode = voucherCode;
      
    },

    resetDelivery (state) {

      SessionStorageManager.removeDeliveryDraftRef();
      //SessionStorageManager.removeDeliveryRequestType();
      //SessionStorageManager.removeDeliveryShipmentMethod();

      SessionStorageManager.removeDeliveryServiceCode();

      SessionStorageManager.removeDeliveryPickups();
      SessionStorageManager.removeDeliveryDropoffs();
      SessionStorageManager.removeDeliveryStops();
      SessionStorageManager.removeDeliveryHasReturn();
      SessionStorageManager.removeDeliveryShipments();
      SessionStorageManager.removeDeliveryShipmentsOptions();

      SessionStorageManager.removeDeliveryOriginSchedule();
      SessionStorageManager.removeDeliveryDestinationSchedule();

      SessionStorageManager.removeDeliveryVoucherCode();
      SessionStorageManager.removeDeliveryPaymentTypeId();

      return {...initialState,  
        requestType: SessionStorageManager.getDeliveryRequestType(),
        shipmentMethod: SessionStorageManager.getDeliveryShipmentMethod(),
        draftRef: Math.random()
      };
    },

    addDeliveryDropoff (state, action) {
      const newDropoff = action.payload;

      if (newDropoff) {
        state.dropoffs.push(newDropoff);
      }
    },

    addDeliveryPickup (state, action) {
      const newPickup = action.payload;

      if (newPickup) {
        state.pickups.push(newPickup);
      }
    },

    addDeliveryStop (state, action) {
      const newStop = action.payload;

      if (newStop) {
        state.stops.push(newStop);
      }
    },

    addDeliveryStopShipment (state) {
      state.shipments.splice(state.shipments.length - 1, 0, []);
    },

    addDeliveryEmptyShipment (state) {
      state.shipments.push([]);
    },

    removeDeliveryDropoff (state, action) {
      const { dropoffIndexSelected } = action.payload;

      if (typeof dropoffIndexSelected === 'number') {
        state.dropoffs.splice(dropoffIndexSelected, 1);
      }
    },

    removeDeliveryPickup (state, action) {
      const { pickupIndexSelected } = action.payload;

      if (typeof pickupIndexSelected === 'number') {
        state.pickups.splice(pickupIndexSelected, 1);
      }
    },


    removeDeliveryStop (state, action) {
      const { stopIndexSelected } = action.payload;

      if (typeof stopIndexSelected === 'number') {
        state.stops.splice(stopIndexSelected, 1);
      }
    },

    setAddressForPickup (state, action) {
      const { address, index } = action.payload;

      if (address && typeof index === 'number') {
        state.pickups[index].address = address;
        state.hasReturn = false;
      }
    },

    setContactForPickup (state, action) {
      const { contact, index } = action.payload;
     
      if (contact.name && contact.countryCallingCode && contact.phone && typeof index === 'number') {
        state.pickups[index].contact = contact;
      }
    },

    setNotesForPickup (state, action) {
      const { notes, index } = action.payload;

      if (notes && typeof index === 'number') {
        state.pickups[index].notes = notes;
      }
    },

    setAddressForDropoff (state, action) {
      const { address, index } = action.payload;

      if (address && typeof index === 'number') {
        state.dropoffs[index].address = address;
        state.hasReturn = false;
      }
    },

    setContactForDropoff (state, action) {
      const { contact, index } = action.payload;
     
      if (contact.name && contact.countryCallingCode && contact.phone && typeof index === 'number') {
        state.dropoffs[index].contact = contact;
        console.log("OK");
      }else{
        console.log("NOK");
      }
    },

    setNotesForDropoff (state, action) {
      const { notes, index } = action.payload;

      if (notes && typeof index === 'number') {
        state.dropoffs[index].notes = notes;
      }
    },

    setAddressForStop (state, action) {
      const { address, index } = action.payload;

      if (address && typeof index === 'number') {
        state.stops[index].address = address;
      }
    },

    setContactForStop (state, action) {
      const { contact, index } = action.payload;
     
      if (contact.name && contact.countryCallingCode && contact.phone && typeof index === 'number') {
        state.dropoffs[index].contact = contact;
      }
    },

    setNotesForStop (state, action) {
      const { notes, index } = action.payload;

      if (notes && typeof index === 'number') {
        state.stops[index].notes = notes;
      }
    },

    setDeliveryDropoffs (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const dropoffs = action.payload;

      if (dropoffs) {
        state.dropoffs = dropoffs;
        SessionStorageManager.setDeliveryDropoffs(dropoffs);
      }
    },

    setDeliveryHasReturn (state, action) {
      const { hasReturn, shipmentsItems } = action.payload;

      if (shipmentsItems[0]) {
        state.shipments.push(shipmentsItems[0]);
        //adicionar o shipmentsOptions?
      } else {
        state.shipments.push([]);
      }

      if (hasReturn === false && hasReturn !== state.hasReturn) {
        state.shipments.splice(0, state.shipments.length - 1);
      }

      state.hasReturn = hasReturn;

      SessionStorageManager.setDeliveryHasReturn(hasReturn);
      SessionStorageManager.setDeliveryShipments(state.shipments);
    },

    setDeliveryPickups (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const pickups = action.payload;

      if (pickups) {
        state.pickups = pickups;
        SessionStorageManager.setDeliveryPickups(pickups);
      }
    },

    updateDeliveryShipmentOptions (state, action) {
      const {
        shipmentIndex: shipmentIndex,
        options: options
      } = action.payload;

      if (typeof shipmentIndex === 'number' )
      {
          //console.log("AQUI: shipmentIndex=" + shipmentIndex);
          //console.log(options);
          state.shipmentsOptions[shipmentIndex] = options;
          SessionStorageManager.setDeliveryShipmentsOptions(state.shipmentsOptions);
      }
    },

    addDeliveryShipmentItem (state, action) {
      const {
        shipmentIndex: shipmentIndex,
        itemData: itemData
      } = action.payload;

      if (typeof shipmentIndex === 'number' ) {
       
        if (!state.shipments[shipmentIndex]) {
          state.shipments[shipmentIndex] = []; 
        }
        state.shipments[shipmentIndex].push(itemData);
       
        SessionStorageManager.setDeliveryShipments(state.shipments);
      }
    },

    updateDeliveryShipmentItem (state, action) {
      const {
        shipmentIndex: shipmentIndex,
        itemData: itemData,
        itemIndex: itemIndex
      } = action.payload;

      if (typeof shipmentIndex === 'number' && typeof itemIndex === 'number')
      {
        const shipItem = state.shipments[shipmentIndex];
        if (shipItem) {
          shipItem[itemIndex] = itemData;
          SessionStorageManager.setDeliveryShipments(state.shipments);
        }
      }
    },

    //remote todos os items
    removeDeliveryShipment (state, action) {
      const { index } = action.payload;

      if (typeof index === 'number') {
        state.shipments.splice(index, 1);
        SessionStorageManager.setDeliveryShipments(state.shipments);

        state.shipmentsOptions.splice(index, 1);
        SessionStorageManager.setDeliveryShipmentsOptions(state.shipmentsOptions);
      }
    },

    removeDeliveryShipmentItem (state, action) {
      if (action?.payload) {
        const { index, shipmentItemIndex } = action.payload;

        if (typeof index === 'number' && typeof shipmentItemIndex === 'number') {
          
          state.shipments[index].splice(shipmentItemIndex, 1);

          console.log(" store removeDeliveryShipmentItem len=" + state.shipments[index].length );

          SessionStorageManager.setDeliveryShipments(state.shipments);
        }
      }
    },

    removeDeliveryAllShipments (state, action) {
        state.shipments = [[]];
        SessionStorageManager.removeDeliveryShipments();

        state.shipmentsOptions = [{}];
        SessionStorageManager.removeDeliveryShipmentsOptions();
    },

    setDeliveryStops (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const stops = action.payload;

      if (stops) {
        state.stops = stops;
        SessionStorageManager.setDeliveryStops(stops);
      }
    },

    setDestinationScheduleDate (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, destinationSchedule } = state;
      const dropoffDate = action.payload;

      if (dropoffDate) {
        if (deliveryQuote) {
          const { scheduleDropoffIntervals } = deliveryQuote;
          const scheduleInterval = scheduleDropoffIntervals.find(entry => entry.dropoffDate === dropoffDate);

          if (scheduleInterval) {
            const { dropoffTimeSlots } = scheduleInterval;

            // Try to keep the dropoffTimeSlotValue and fallback to the value of the first available timeSlot
            const dropoffTimeSlotValue = destinationSchedule && dropoffTimeSlots.map(({ value }) => value).includes(destinationSchedule.dropoffTimeSlotValue) ?
              destinationSchedule.dropoffTimeSlotValue :
              dropoffTimeSlots[0]?.value || null;

            const newDestinationSchedule = {
              dropoffDate,
              dropoffTimeSlotValue
            };

            state.destinationSchedule = newDestinationSchedule;

            SessionStorageManager.setDeliveryDestinationSchedule(newDestinationSchedule);
          } else if (destinationSchedule) {
            state.destinationSchedule.dropoffDate = null;

            SessionStorageManager.setDeliveryDestinationSchedule({
              dropoffDate: null,
              dropoffTimeSlotValue: destinationSchedule.dropoffTimeSlotValue
            });
          }
        }else{
          state.destinationSchedule.dropoffDate = null;
          SessionStorageManager.setDeliveryDestinationSchedule({
            dropoffDate: null,
            dropoffTimeSlotValue: null
          });
        }

        // Reset delivery when the schedule date is changed
        state.delivery = null;
        state.deliveryError = null;
      }else{
        state.destinationSchedule.dropoffDate = null;
        SessionStorageManager.setDeliveryDestinationSchedule({
          dropoffDate: null,
          dropoffTimeSlotValue: null
        });
      }
    },

    setDestinationScheduleTimeSlotValue (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, destinationSchedule } = state;
      const dropoffTimeSlotValue = action.payload;

      if (deliveryQuote && dropoffTimeSlotValue && destinationSchedule?.dropoffDate) {
        const { scheduleDropoffIntervals } = deliveryQuote;
        const { dropoffDate } = destinationSchedule;
        const scheduleInterval = scheduleDropoffIntervals.find(entry => entry.dropoffDate === dropoffDate);

        if (scheduleInterval) {
          const { dropoffTimeSlots } = scheduleInterval;

          if (dropoffTimeSlots.map(({ value }) => value).includes(dropoffTimeSlotValue)) {
            state.destinationSchedule.dropoffTimeSlotValue = dropoffTimeSlotValue;

            SessionStorageManager.setDeliveryDestinationSchedule({
              dropoffDate: destinationSchedule.dropoffDate,
              dropoffTimeSlotValue
            });

            // Reset delivery when the schedule time slot is changed
            state.delivery = null;
            state.deliveryError = null;
          }else{
            state.destinationSchedule.dropoffTimeSlotValue = null;

            SessionStorageManager.setDeliveryDestinationSchedule({
              dropoffDate: null,
              dropoffTimeSlotValue: null
            });
          }
        }
      }else{
        state.destinationSchedule.dropoffTimeSlotValue = null;

        SessionStorageManager.setDeliveryDestinationSchedule({
          dropoffDate: null,
          dropoffTimeSlotValue: null
        });
      }
    },

    setOriginScheduleDate (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, originSchedule } = state;
      const pickupDate = action.payload;

      if (pickupDate) {
        if (deliveryQuote) {
          const { schedulePickupIntervals } = deliveryQuote;
          const scheduleInterval = schedulePickupIntervals.find(entry => entry.pickupDate === pickupDate);

          if (scheduleInterval) {
            const { pickupTimeSlots } = scheduleInterval;

            // Try to keep the pickupTimeSlotValue and fallback to the value of the first available timeSlot
            const pickupTimeSlotValue = originSchedule && pickupTimeSlots.map(({ value }) => value).includes(originSchedule.pickupTimeSlotValue) ?
              originSchedule.pickupTimeSlotValue :
              pickupTimeSlots[0]?.value || null;

            const newOriginSchedule = {
              pickupDate,
              pickupTimeSlotValue
            };

            state.originSchedule = newOriginSchedule;

            SessionStorageManager.setDeliveryOriginSchedule(newOriginSchedule);
          }
        } else {
          state.originSchedule.pickupDate = pickupDate;

          SessionStorageManager.setDeliveryOriginSchedule({
            pickupDate,
            pickupTimeSlotValue: originSchedule.pickupTimeSlotValue
          });
        }

        // Reset delivery when the schedule date is changed
        state.delivery = null;
        state.deliveryError = null;
      }else{
        state.originSchedule.pickupDate = null;
        SessionStorageManager.setDeliveryOriginSchedule({
          pickupDate: null,
          pickupTimeSlotValue: null
        });
      }
    },

    setOriginScheduleTimeSlotValue (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, originSchedule } = state;
      const pickupTimeSlotValue = action.payload;

      if (deliveryQuote && pickupTimeSlotValue && originSchedule?.pickupDate) {
        const { schedulePickupIntervals } = deliveryQuote;
        const { pickupDate } = originSchedule;
        const scheduleInterval = schedulePickupIntervals.find(entry => entry.pickupDate === pickupDate);

        if (scheduleInterval) {
          const { pickupTimeSlots } = scheduleInterval;

          if (pickupTimeSlots.map(({ value }) => value).includes(pickupTimeSlotValue)) {
            state.originSchedule.pickupTimeSlotValue = pickupTimeSlotValue;

            SessionStorageManager.setDeliveryOriginSchedule({
              pickupDate: originSchedule.pickupDate,
              pickupTimeSlotValue
            });

            // Reset delivery when the schedule time slot is changed
            state.delivery = null;
            state.deliveryError = null;
          }else{
            state.originSchedule.pickupTimeSlotValue = null;
            SessionStorageManager.setDeliveryOriginSchedule({
              pickupDate: null,
              pickupTimeSlotValue: null
            });
          }
        }else{
          state.originSchedule.pickupTimeSlotValue = null;
          SessionStorageManager.setDeliveryOriginSchedule({
            pickupDate: null,
            pickupTimeSlotValue: null
          });
        }
      }else{
        state.originSchedule.pickupTimeSlotValue = null;
        SessionStorageManager.setDeliveryOriginSchedule({
          pickupDate: null,
          pickupTimeSlotValue: null
        });
      }
    },

    setPaymentTypeId (state, action) {
      const { deliveryQuote, paymentTypeId: paymentTypeIdFromState } = state;
      const paymentTypeId = action.payload;

      if (paymentTypeId && deliveryQuote && paymentTypeId !== paymentTypeIdFromState) {
        const { paymentTypes } = deliveryQuote;
        const paymentTypeIdIsValid = paymentTypes.findIndex(paymentType => paymentType.id === paymentTypeId) !== -1;

        if (paymentTypeIdIsValid) {
          // Reset delivery when the payment type is changed
          state.delivery = null;
          state.deliveryError = null;

          state.paymentTypeId = paymentTypeId;

          SessionStorageManager.setDeliveryPaymentTypeId(paymentTypeId);
        }
      }
    },

    setServiceCode (state, action) {
      const serviceCode = action.payload;

      if (serviceCode && serviceCode !== state.serviceCode) {
        state.serviceCode = serviceCode;

        SessionStorageManager.removeDeliveryShipments();
        if (serviceCode) SessionStorageManager.setDeliveryServiceCode(serviceCode);
        else SessionStorageManager.removeDeliveryServiceCode(serviceCode);
      }
    },

    
    setVoucherCode (state, action) {
      /*
      const voucherCode = action.payload;
      
      if (typeof voucherCode === 'string') state.voucherCode = voucherCode;

      const serviceCode = action.payload;
      */

      const voucherCode = action.payload;

      state.voucherCode = voucherCode;
      SessionStorageManager.removeDeliveryVoucherCode();

      if (voucherCode) {
          SessionStorageManager.setDeliveryVoucherCode(voucherCode);
      }
    },

    setShipmentMethod (state, action) {
      const type = action.payload;

      if (type) {
        state.shipmentMethod = type;
        SessionStorageManager.setDeliveryShipmentMethod(type);
      }
    },

    setRequestType (state, action) {
      const type = action.payload;

      if (type) {
        state.requestType = type;
        console.log("setRequestType:" + type)
        SessionStorageManager.setDeliveryRequestType(type);
      }
    },

    startPaymentProcess (state) {
      state.paymentIsInProcess = true;
    },

    stopPaymentProcess (state) {
      state.paymentIsInProcess = false;
    },

    resetDeliveryStateQuoteData (state, action) {
      const { deliveryQuote } = state;
      if( deliveryQuote ){
        deliveryQuote.paymentMessage = null;
        deliveryQuote.infoMessage    = null;
        //deliveryQuote?.paymentTypes = null;
        deliveryQuote.priceDetails   =  null;
        deliveryQuote.total          = null;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createDelivery.pending, (state) => {
        state.deliveryError = null;
        state.deliveryIsLoading = true;
      })
      .addCase(createDelivery.fulfilled, (state, action) => {
        const delivery = action.payload;

        if (delivery) state.delivery = delivery;

        state.deliveryIsLoading = false;
      })
      .addCase(createDelivery.rejected, (state) => {
        state.deliveryIsLoading = false;
      })
      .addCase(getDeliveryQuote.pending, (state) => {
        state.deliveryQuoteError = null;
        state.deliveryQuoteIsLoading = true;

        // Reset delivery when a new delivery quote is requested
        state.delivery = null;
        state.deliveryError = null;
      })
      .addCase(getDeliveryQuote.fulfilled, (state, action) => {
        const { destinationSchedule: currentDestinationSchedule } = state;
        const { originSchedule: currentOriginSchedule } = state;
        const quote = action.payload;

        if ( !quote?.invalid_quote) {
          const { destinationSchedule: quoteDestinationSchedule, scheduleDropoffIntervals } = quote;
          const { originSchedule: quoteOriginSchedule, schedulePickupIntervals } = quote;

          const destinationSchedule = quoteDestinationSchedule || currentDestinationSchedule;
          const originSchedule = quoteOriginSchedule || currentOriginSchedule;

          // Use the current destinationSchedule if possible and fallback to the first values present as fallback
          const scheduleDropoffInterval = scheduleDropoffIntervals.find(({ dropoffDate }) => dropoffDate === destinationSchedule.dropoffDate) ||
          scheduleDropoffIntervals[0];
          const { dropoffTimeSlots } = scheduleDropoffInterval;
          const dropoffTimeSlot = dropoffTimeSlots.find(({ value }) => value === destinationSchedule.dropoffTimeSlotValue) || dropoffTimeSlots[0];

          const newDestinationSchedule = {
            dropoffDate: scheduleDropoffInterval.dropoffDate,
            dropoffTimeSlotValue: dropoffTimeSlot.value
          };

          // Use the current originSchedule if possible and fallback to the first values present as fallback
          const schedulePickupInterval = schedulePickupIntervals.find(({ pickupDate }) => pickupDate === originSchedule.pickupDate) ||
          schedulePickupIntervals[0];
          const { pickupTimeSlots } = schedulePickupInterval;
          const pickupTimeSlot = pickupTimeSlots.find(({ value }) => value === originSchedule.pickupTimeSlotValue) || pickupTimeSlots[0];

          const newOriginSchedule = {
            pickupDate: schedulePickupInterval.pickupDate,
            pickupTimeSlotValue: pickupTimeSlot.value
          };

          state.deliveryQuote = quote;
          state.destinationSchedule = newDestinationSchedule;
          state.originSchedule = newOriginSchedule;

          SessionStorageManager.setDeliveryDestinationSchedule(newDestinationSchedule);
          SessionStorageManager.setDeliveryOriginSchedule(newOriginSchedule);
        }

        state.deliveryQuoteIsLoading = false;
      })
      .addCase(getDeliveryQuote.rejected, (state, action) => {

        console.log( "getDeliveryQuote.rejected:" );
        console.log( action?.payload);

        state.deliveryQuote = null;
        state.deliveryQuoteError = action?.payload || 'Something went wrong while requesting a quote for your delivery.';
        state.deliveryQuoteIsLoading = false;
      })
      .addCase(getDeliveryEstimate.pending, (state) => {
        state.deliveryQuoteError = null;
        state.deliveryQuoteIsLoading = true;

        // Reset delivery when a new delivery quote is requested
        state.delivery = null;
        state.deliveryError = null;
      })
      .addCase(getDeliveryEstimate.fulfilled, (state, action) => {

        if( action.payload?.rates ){
          const { rates } = action.payload;
          state.rates = rates;
        }

        if( action.payload?.resume ){
          const { distance } = action.payload?.resume;
          const { duration } = action.payload?.resume;
          state.deliveryDistance = distance;
          state.deliveryDuration = duration;
        }

        state.deliveryQuoteIsLoading = false;
      })
      .addCase(getDeliveryEstimate.rejected, (state, action) => {
        state.deliveryQuote = null;
        state.deliveryQuoteError = action?.payload || 'Something went wrong while requesting an estimate for your delivery.';
        state.deliveryQuoteIsLoading = false;
      })
      .addCase(setDefaultPickup.fulfilled, (state, action) => {
        const pickup = action.payload;

        if (pickup) {
          state.pickups[0] = pickup;
        }
      });
  }
});

export default newDeliverySlice.reducer;

// Actions

export const {
  addDeliveryDropoff,
  addDeliveryPickup,
  addDeliveryEmptyShipment,
  addDeliveryShipmentItem,
  updateDeliveryShipmentOptions,
  addDeliveryStop,
  addDeliveryStopShipment,
  addVoucherCodeToDeliveryQuote,
  initialize,
  removeDeliveryDropoff,
  removeDeliveryPickup,
  removeDeliveryShipment,
  removeDeliveryShipmentItem,
  removeDeliveryStop,
  resetDelivery,
  setAddressForDropoff,
  setAddressForPickup,
  setAddressForStop,
  setContactForDropoff,
  setContactForPickup,
  setContactForStop,
  setDeliveryDropoffs,
  setDeliveryHasReturn,
  setDeliveryPickups,
  setDeliveryShipments,
  setDeliveryStops,
  setDestinationScheduleDate,
  setDestinationScheduleTimeSlotValue,
  setNotesForDropoff,
  setNotesForPickup,
  setNotesForStop,
  setOriginScheduleDate,
  setOriginScheduleTimeSlotValue,
  setPaymentTypeId,
  setPickupNotes,
  setServiceCode,
  setShipmentMethod,
  setRequestType,
  setVoucherCode,
  startPaymentProcess,
  stopPaymentProcess,
  updateDeliveryShipmentItem,
  removeDeliveryAllShipments,
  resetDeliveryStateQuoteData
} = newDeliverySlice.actions;

// Selectors:

export const {
  selectAll: selectDeliveryItems,
  selectById: selectDeliveryItemsById
} = newDeliveryAdapter.getSelectors(state => state.newDelivery);


export const selectDeliveryDistance = state => state.newDelivery.deliveryDistance;

export const selectDeliveryDuration = state => state.newDelivery.deliveryDuration;

export const selectDeliveryQuote = state => state.newDelivery.deliveryQuote;

export const selectDeliveryQuoteError = state => state.newDelivery.deliveryQuoteError;

export const selectDeliveryQuoteIsLoading = state => state.newDelivery.deliveryQuoteIsLoading;

//export const selectDeliveryQuoteVoucherCode = state => state.newDelivery.deliveryQuoteVoucherCode;

export const selectDestinationSchedule = state => state.newDelivery.destinationSchedule;

//export const selectDelivery = state => state.newDelivery.delivery;

export const selectDeliveryError = state => state.newDelivery.deliveryError;

export const selectDeliveryIsLoading = state => state.newDelivery.deliveryIsLoading;

export const selectDropoffs = state => state.newDelivery.dropoffs;

export const selectHasReturn = state => state.newDelivery.hasReturn;

export const selectOriginSchedule = state => state.newDelivery.originSchedule;

export const selectPaymentIsInProcess = state => state.newDelivery.paymentIsInProcess;

export const selectPaymentTypeId = state => state.newDelivery.paymentTypeId;

export const selectPickups = state => state.newDelivery.pickups;

export const selectServiceCode = state => state.newDelivery.serviceCode;

export const selectShipmentsOptions = state => state.newDelivery.shipmentsOptions;

export const selectShipments = state => state.newDelivery.shipments;

export const selectRates = state => state.newDelivery.rates;

export const selectShipmentMethod = state => state.newDelivery.shipmentMethod;

export const selectRequestType = state => state.newDelivery.requestType;

export const selectDraftRef = state => state.newDelivery.draftRef;

export const selectStops = state => state.newDelivery.stops;

export const selectVoucherCode = state => state.newDelivery.voucherCode;

export const selectVoucherIsValid = state => state.newDelivery.voucherCode && typeof state.newDelivery.voucherCode === 'string';
