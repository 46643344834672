import packageExtraSmallIcon from 'images/icons/it-extra-small.svg';
import packageSmallIcon from 'images/icons/it-small.svg';
import packageMediumIcon from 'images/icons/it-medium.svg';
import packageBigIcon from 'images/icons/it-big.svg';
import packageLargeIcon from 'images/icons/it-large.svg';
import packageExtraLargeIcon from 'images/icons/it-extra-large.svg';

import packageBagIcon from 'images/icons/it-bag.svg';
import packageDocIcon from 'images/icons/it-doc.svg';
import packageFlowersIcon from 'images/icons/it-flowers.svg';
import packageFoodBagIcon from 'images/icons/it-food-bag.svg';
import packageMedicineIcon from 'images/icons/it-medicine.svg';


const getItemIcon = (icon) => {
  switch (icon) {
    case 'it_extra_small':
      return packageExtraSmallIcon;
    case 'it_small':
      return packageSmallIcon;
    case 'it_medium':
      return packageMediumIcon;
    case 'it_big':
      return packageBigIcon;
    case 'it_large':
      return packageLargeIcon;
    case 'it_extra_large':
      return packageExtraLargeIcon;
    case 'it_extra_it_baglarge':
      return packageBagIcon;  
    case 'it_extra_it_doclarge':
      return packageDocIcon;    
    case 'it_flowers':
      return packageFlowersIcon;  
    case 'it_food_bag':
      return packageFoodBagIcon;          
    case 'it_medicine':
      return packageMedicineIcon;    
    default:
      return packageSmallIcon;
  }
};

export default getItemIcon;
