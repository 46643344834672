import React from 'react';
import PropTypes from 'prop-types';

import canceledIcon from '@youship/assets/images/icons/notifications/canceled.svg';
import deliveredIcon from '@youship/assets/images/icons/notifications/delivered.svg';
import inTransitIcon from '@youship/assets/images/icons/notifications/in-transit.svg';
import preparingIcon from '@youship/assets/images/icons/notifications/preparing.svg';
import waitingPaymentIcon from '@youship/assets/images/icons/notifications/waiting-payment.svg';

import './styles.scss';

const Notification = ({
  compact,
  date: dateFromProps,
  hoverable,
  id,
  linkComponent,
  linkPath,
  onClick,
  seen,
  showTimeDifference,
  showSeenStatus,
  text,
  timeDifference,
  type
}) => {
  let icon = null;

  switch (type) {
    case 'delivered':
      icon = deliveredIcon;
      break;
    case 'in_transit':
      icon = inTransitIcon;
      break;
    case 'preparing':
      icon = preparingIcon;
      break;
    case 'canceled':
      icon = canceledIcon;
      break;
    case 'waiting_payment':
      icon = waitingPaymentIcon;
      break;
    default:
      break;
  }

  const componentProps = {
    children: (
      <>
        {!!icon && (
          <img
            alt={`${type} Icon`}
            className="notification__icon"
            src={icon}
          />
        )}
        <div className="notification__text">
          {text}
        </div>
        {(showSeenStatus || showTimeDifference) && (
          <div className="notification__time">
            {showSeenStatus && !seen && (
              <div className="notification__read-status" />
            )}
            {showTimeDifference && timeDifference}
          </div>
        )}
      </>
    ),
    className: `notification${compact ? ' notification--compact' : ''}${hoverable ? ' notification--hoverable' : ''}`,
    onClick: () => onClick(id)
  };

  const Link = linkComponent;

  return linkPath ? (
    <Link
      {...componentProps}
      to={linkPath}
    >
      {componentProps.children}
    </Link>
  ) : (
    <div {...componentProps} />
  );
};

Notification.propTypes = {
  compact: PropTypes.bool,
  date: PropTypes.string,
  hoverable: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  linkComponent: PropTypes.shape({
    render: PropTypes.func
  }),
  linkPath: PropTypes.string,
  onClick: PropTypes.func,
  seen: PropTypes.bool,
  showSeenStatus: PropTypes.bool,
  showTimeDifference: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string
};

Notification.defaultProps = {
  compact: false,
  date: null,
  hoverable: false,
  id: null,
  linkComponent: null,
  linkPath: null,
  onClick: () => { },
  seen: false,
  showSeenStatus: false,
  showTimeDifference: false,
  text: null,
  type: null
};

export default Notification;
