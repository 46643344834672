
export default class helper {
    
    static getFlagByLocale (locale) {
        // 'https://flag.pk/flags/4x3/us.svg';
        //`https://flag.pk/flags/4x3/${currentLanguage.value}.svg`;
        // (locale === 'en') ? 'https://flag.pk/flags/4x3/us.svg' : `https://flag.pk/flags/4x3/${locale}.svg`;

        return require(`@youship/assets/images/flags/4x3/${locale.toLowerCase()}.svg`);
    }

    static getFlagByCountry(countryCode) {

        if (countryCode === 'aq') {
            return require('@youship/assets/images/icons/countries/aq.svg');
        }

        let locale = countryCode;
        return helper.getFlagByLocale(locale);
    }
}