import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Modal from '@youship/components/objects/modal';

import deleteIcon from 'images/icons/close.svg';
import couponIcon from 'images/icons/coupon.svg';

const DiscountModal = ({ defaultDiscountCode, disabled, discountCodeError, onClose, onSave }) => {
 
  const intl = useIntl();

  const [discountCode, setDiscountCode] = useState(null);

  const handleDiscountModalClose = () => {
    //sem alteracao
    onClose();
  };

  const handleDiscountCodeButtonClick = () => {
    if( discountCode !== defaultDiscountCode){
      //com alteracao
      onSave(discountCode);
    }else{
      //sem alteracao
      onClose();
    }
  };

  const handleDeleteDiscountCodeClick  = () => {
    //com alteracao
    onSave(null);
  };

  return (
    <Modal
      footer={
        <Button
          context="primary"
          disabled={disabled}
          text={defaultDiscountCode ? intl.formatMessage({ id: 'new-order.discount_code.close' }) : intl.formatMessage({ id: 'new-order.discount_code.apply' })}
          onClick={() => defaultDiscountCode ? handleDiscountModalClose() : handleDiscountCodeButtonClick() }
        />
      }
      footerAlignRight
      title={intl.formatMessage({ id: 'new-order.discount_code.title' })}
      onClose={() => handleDiscountModalClose()}
    >
      <div className="new-order-shipment-options-modal">
        {!defaultDiscountCode ? (
          <Input
            backgroundImage={couponIcon}
            block
            inputId="discount-code"
            message={ discountCodeError }
            placeholder={intl.formatMessage({ id: 'new-order.discount_code.placeholder' })}
            status={discountCodeError ? 'error' : 'default'}
            value={discountCode ?? ''}
            onChange={event => setDiscountCode(event?.target?.value ?? '')}
          />
        ) : (
          <div className="new-order-shipment-options-modal__discount-code">
            {defaultDiscountCode}
            <img
              alt="Delete Coupon Code"
              className="new-order-shipment-options-modal__delete-icon"
              src={deleteIcon}
              onClick={handleDeleteDiscountCodeClick}
            />
          </div>
        )}
      </div>
  </Modal>
  );
};

DiscountModal.propTypes = {
  defaultDiscountCode: PropTypes.string,
  disabled: PropTypes.bool,
  discountCodeError: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

DiscountModal.defaultProps = {
  defaultDiscountCode: null,
  disabled: false,
  discountCodeError: null,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

export default DiscountModal;
