import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import ShoppingApi, {
  DASHBOARD_SECTION_TYPE_CATEGORIES,
  DASHBOARD_SECTION_TYPE_HIGHLIGHTS,
  DASHBOARD_SECTION_TYPE_SHOPS
} from '@youship/api/shopping';

import { FormatRequestLocation } from 'utils/shopping';

const dashboardAdapter = createEntityAdapter();

const initialState = dashboardAdapter.getInitialState({
  isLoading: false
});


// Thunks:

export const fetchDashboard = createAsyncThunk(
  'dashboard/fetchDashboard',
  (argument, {getState}) => {
    
    const location = FormatRequestLocation(getState());

    //console.log(location);
    return ShoppingApi.dashboard({
      location: location
    })
    .then((response) => {
      if (!Array.isArray(response?.rows)) return null;

      // Reverse sections order from the API response
      response.rows.reverse();

      return response.rows.map((section, sectionIndex) => {
        const {
          [DASHBOARD_SECTION_TYPE_CATEGORIES]: categories,
          [DASHBOARD_SECTION_TYPE_HIGHLIGHTS]: highlights,
          [DASHBOARD_SECTION_TYPE_SHOPS]: shops
        } = section;

        section.id = sectionIndex;

        if (Array.isArray(categories)) {
          section[DASHBOARD_SECTION_TYPE_CATEGORIES] = categories.map(item => ({
            ...item,
            iconUrl: item.icon_url ?? null,
            id: item.order_type ?? null,
            imageUrl: item.photo_url ?? null
          }));
        }

        if (Array.isArray(highlights)) {
          section[DASHBOARD_SECTION_TYPE_HIGHLIGHTS] = highlights.map((item, highlightIndex) => ({
            ...item,
            actionName: item.action_name ?? null,
            actionUrl: item.action_url ?? null,
            iconUrl: item.icon_url ?? null,
            id: highlightIndex,
            imageUrl: item.photo_url ?? null
          }));
        }

        if (Array.isArray(shops)) {
          section[DASHBOARD_SECTION_TYPE_SHOPS] = shops.map(item => ({
            ...item,
            alertMessage: item.alert_message ?? null,
            alertColor: item.alert_color ?? null,
            averagePrice: item.avg_price ?? null,
            closedMessage: item.closed_message ?? null,
            id: item.shop_code ?? null,
            imageUrl: item.photo_url ?? null
          }));
        }

        return section;
      });
    });
  }
);

export const fetchDashboardShops = createAsyncThunk(
  'dashboard/fetchDashboardShops',
  searchValue => ShoppingApi.listShops({ str: searchValue })
    .then((response) => {
      if (response && Array.isArray(response.rows)) {
        return response.rows.map(shop => ({
          ...shop,
          alertColor: shop.alert_color,
          alertMessage: shop.alert_message,
          averagePrice: shop.avg_price,
          closedMessage: shop.closed_message,
          fulfillment: typeof shop.fulfillment === 'string' ? shop.fulfillment.replace(/-|–/g, '–') : shop.fulfillment,
          id: shop.shop_code,
          imageUrl: shop.photo_url,
          schedule: shop.hours,
          shopTypes: Array.isArray(shop.shop_types) ? shop.shop_types : []
        }));
      }

      return null;
    })
);

// Slice:

const dashboardSlice = createSlice({
  name: 'dashboard',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        const sections = action.payload;

        if (Array.isArray(sections)) dashboardAdapter.setAll(state, sections);

        state.isLoading = false;
      })
      .addCase(fetchDashboard.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDashboardShops.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDashboardShops.fulfilled, (state, action) => {
        const shops = action.payload;

        if (Array.isArray(shops)) {
          state.dashboardShops = shops;
        }

        state.isLoading = false;
      })
      .addCase(fetchDashboardShops.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default dashboardSlice.reducer;

// Selectors:

export const {
  selectAll: selectSections,
  selectById: selectSectionById
} = dashboardAdapter.getSelectors(state => state.dashboard);

export const selectDashboardShops = state => state.dashboard.dashboardShops;

export const selectIsLoadingDashboard = state => state.dashboard.isLoading;
