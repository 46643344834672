import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Dropdown from '@youship/components/objects/dropdown';
import Notification from '@youship/components/notification';

import './styles.scss';

const NotificationsDropdown = ({ linkComponent, notifications, onClose, onNotificationClick, show }) => {
  const Link = linkComponent;
  const dropdownContent = useRef(null);
  const [isContentScrolled, setIsContentScrolled] = useState(false);

  const handleContentScroll = () => {
    if (dropdownContent.current.scrollTop > 0 && !isContentScrolled) {
      setIsContentScrolled(true);
    } else if (dropdownContent.current.scrollTop <= 0 && isContentScrolled) {
      setIsContentScrolled(false);
    }
  };

  useEffect(() => {
    const dropdownContentElement = dropdownContent.current;

    if (notifications && notifications.length && dropdownContentElement) {
      dropdownContentElement.addEventListener('scroll', handleContentScroll);
    }

    return () => {
      if (dropdownContentElement) {
        dropdownContentElement.removeEventListener('scroll', handleContentScroll);
      }
    };
  });

  const handleNotificationClick = (id) => {
    onClose();

    onNotificationClick(id);
  };

  return (
    <Dropdown
      className="notifications-dropdown"
      hasBackgroundOverlay
      horizontalPosition="right"
      show={show}
      onClose={onClose}
    >
      <>
        <div className={`notifications-dropdown__title${isContentScrolled ? ' notifications-dropdown__title--bordered' : ''}`}>
          <FormattedMessage id="notifications.title" />
        </div>
        {Array.isArray(notifications) && notifications.length ? (
          <>
            <div
              ref={dropdownContent}
              className="notifications-dropdown__list"
            >
              {notifications.map(notification => (
                <Notification
                  {...notification}
                  key={notification.id}
                  compact
                  hoverable
                  linkComponent={Link}
                  showSeenStatus
                  showTimeDifference
                  onClick={() => handleNotificationClick(notification.id)}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="notifications-dropdown__empty">
            <FormattedMessage id="notifications.empty" />
          </div>
        )}
        <Link
          to="/notifications"
          onClick={onClose}
        >
          <div className="notifications-dropdown__footer">
            <FormattedMessage id="notifications.all" />
          </div>
        </Link>
      </>
    </Dropdown>
  );
};


NotificationsDropdown.propTypes = {
  linkComponent: PropTypes.shape({
    render: PropTypes.func
  }),
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  })),
  onClose: PropTypes.func,
  onNotificationClick: PropTypes.func,
  show: PropTypes.bool
};

NotificationsDropdown.defaultProps = {
  linkComponent: null,
  notifications: null,
  onClose: () => {},
  onNotificationClick: () => {},
  show: false
};

export default NotificationsDropdown;
