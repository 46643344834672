import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';

import { useIntl } from 'react-intl';

import greenCheckIcon from 'images/icons/check-green.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';

const ActionModal = ({ description, finalMessage, finishPath, finishText, isLoading, notice, onClose, onConfirm, title, back}) => {
  const intl = useIntl();

  return (
    <Modal
      classNames="action-modal"
      footer={(
        <div className="action-modal__footer">
          {finalMessage ? (
            <div className="action-modal__footer-button-wrapper">
              <Button
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                noArrow
                text={finishText}
                to={finishPath}
              />
            </div>
          ) : (
            <>
              <div className="action-modal__footer-button-wrapper">
                <Button
                  disabled={isLoading}
                  noBackground
                  noShadow
                  text={back ? back : intl.formatMessage({ id: 'order.action.back' })}
                  onClick={!isLoading ? onClose : () => {}}
                />
              </div>
              <div className="action-modal__footer-button-wrapper">
                <Button
                  context="primary"
                  disabled={isLoading}
                  largeHorizontalPadding
                  text={intl.formatMessage({ id: 'order.action.confirm' })}
                  onClick={onConfirm}
                />
              </div>
            </>
          )}
        </div>
      )}
      small
      subtitle={description}
      title={title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <>
        {(!!finalMessage || !!notice) && (
          <Notice
            classNames="action-modal__notice"
            description={finalMessage || notice}
            fixed
            icon={finalMessage ? greenCheckIcon : noticeIcon}
          />
        )}
      </>
    </Modal>
  );
};

ActionModal.propTypes = {
  description: PropTypes.string,
  finalMessage: PropTypes.string,
  finishPath: PropTypes.string,
  finishText: PropTypes.string,
  isLoading: PropTypes.bool,
  notice: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  back:  PropTypes.string
};

ActionModal.defaultProps = {
  description: null,
  finalMessage: null,
  finishPath: null,
  finishText: 'Finish',
  isLoading: false,
  notice: null,
  back: null,
  onClose: () => {},
  onConfirm: () => {}
};

export default ActionModal;
