import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { addAddress, editAddress, selectUserAddresses } from 'store/slices/user-addresses';
import { selectUser, selectIsAuthenticated } from 'store/slices/authentication';

import AddressForm from '@youship/components/address-form';
import MapPoint from '@youship/components/map-point';
import Button from '@youship/components/objects/button';
import Modal from '@youship/components/objects/modal';

import bookIcon from '@youship/assets/images/icons/book.svg';
import mapIcon from '@youship/assets/images/icons/map.svg';
import markerPickupIcon from '@youship/assets/images/icons/marker-blue.svg';

import './styles.scss';

const AddressModal = ({ currentAddress, onClose, onSave,editAddressMode, title, type, isFirst }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAddressInAddressBook = currentAddress?.addressCode?.trim() ? true : false;
  
  const [address, setAddress] = useState(currentAddress);
  const [addressChangeIsLoading, setAddressChangeIsLoading] = useState(false);
  const [addressContact, setAddressContact] = useState(null);
  const [mapIsLoading, setMapIsLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [searchStringAddress, setSearchStringAddress] = useState(currentAddress?.streetAddress);



  const handleEditAddressModalSaveButton = () => {

    if (addressIsValid) {
      console.log("handleEditAddressModalSaveButton:");
      console.log("type="+type + " isAddressInAddressBook=" + isAddressInAddressBook);

      if( isAuthenticated )
      {
        switch (type) {
          case 'user':
            
            if (editAddressMode){
              //edit user address
              dispatchRequestEditAddress();
              break;
            }
            
            if (isAddressInAddressBook) {
              //edit user address
              dispatchRequestEditAddress();
            } else {
              //add user address
              dispatchRequestAddAddress();
            }
            break;
          default:
            console.log("onSave address:");
            console.log(address);
            onSave(address, addressContact);
        }
      }else{
        //not autenticated
        onSave(address, addressContact);
      }
    }
  };

  const dispatchRequestAddAddress = () => {
    dispatch(addAddress({
      address: {
        apartmentAddress: address.apartmentAddress,
        city: address.city,
        countryCode: address.countryCode,
        formattedAddress: address.formattedAddress,
        lat: address.lat,
        lng: address.lng,
        numberAddress: address.numberAddress,
        postalCode: address.postalCode,
        streetAddress: address.streetAddress
      },
        contact:   {
             name: "",
            company: "",
            phone: "",
            countryCallingCode: "",
            phoneCodeId: "",
      },
      isDefault: !!isFirst
    }))
      .then((response) => {
        onSave();

        return response;
      })
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const dispatchRequestEditAddress = () => {
    dispatch(editAddress({
      address: {
        apartmentAddress: address.apartmentAddress,
        city: address.city,
        countryCode: address.countryCode,
        formattedAddress: address.formattedAddress,
        lat: address.lat,
        lng: address.lng,
        numberAddress: address.numberAddress,
        postalCode: address.postalCode,
        streetAddress: address.streetAddress
      },
      addressCode: address.addressCode,
      contact: address.contact,
      isDefault: false
    }))
      .then((response) => {
        onSave();
        return response;
      })
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  // quando a morada muda ao deslocar o mapa 
  // ou qd no formulario o user altera algum dado da morada
  const handleFormChange = (formAddress, selectedAddressContact) => {

    /*
    setIsAddressInAddressBook(addressesBook.some(item => 
      item.apartmentAddress === formAddress.apartmentAddress &&
      item.city === formAddress.city &&
      item.countryCode === formAddress.countryCode &&
      item.numberAddress === formAddress.numberAddress &&
      item.postalCode === formAddress.postalCode &&
      item.streetAddress === formAddress.streetAddress
      )
    );
    */

    //para manter os campos mesmos campos da API e do React actualizados
    formAddress.apartment_address = formAddress.apartmentAddress;
    formAddress.country_code = formAddress.countryCode;
    formAddress.number_address = formAddress.numberAddress;
    formAddress.postalcode = formAddress.postalCode;
    formAddress.street_address = formAddress.streetAddress;
    formAddress.formatted_address = formAddress.formattedAddress;

    setAddress(prevState => ({
      ...prevState,
      ...formAddress
    }));

    if (formAddress?.streetAddress) setSearchStringAddress(formAddress.streetAddress);

    setAddressContact(selectedAddressContact);
  };

  //para manter o state do input da pesquisa da morada no formulario (address-form)
  const handleFormSearchChange = (formSearchAddress) => {
    setSearchStringAddress(formSearchAddress);
  };

  useEffect(() => {
    setShowMap(address && address.streetAddress !== '');

    return () => {
      setShowMap(false);
    };
  }, [address]);

  const handleModalClose = () => {
    if (!mapIsLoading || !addressChangeIsLoading) onClose();
  };

  const addressIsValid = !!address && !!address.countryCode && 
    ( address.countryCode.toLowerCase() === 'ao' ? 
        (!!address.streetAddress && !!address.city) : 
        (!!address.streetAddress && !!address.postalCode && !!address.city)
    );

  const intl = useIntl();

  let subtitleTranslationKey = '';
  let titleTranslateKey = '';

  if (type === 'pickup') {
    subtitleTranslationKey = 'map.pickup.subtitle';
    titleTranslateKey = 'map.pickup.title';
  } else if (type === 'delivery') {
    subtitleTranslationKey = 'map.delivery.subtitle';
    titleTranslateKey = 'map.delivery.title';
  } else if (type === 'dropoff') {
    subtitleTranslationKey = 'map.dropoff.subtitle';
    titleTranslateKey = 'map.dropoff.title';
  } else if (type === 'stop') {
    subtitleTranslationKey = 'map.stop.subtitle';
    titleTranslateKey = 'map.stop.title';
  } else if (type === 'user') {
    if (!editAddressMode){
      subtitleTranslationKey = 'map.user.subtitle.addAddress';
      titleTranslateKey = 'map.user.title';
    }else{
      subtitleTranslationKey = 'map.user.subtitle.editAddress';
      titleTranslateKey = 'map.user.title';
    }

  }

  const finalSubtitle = (subtitleTranslationKey && intl.formatMessage({ id: subtitleTranslationKey })) ?? null;
  const finalTitle    = title ?? ( titleTranslateKey ? intl.formatMessage({ id: titleTranslateKey }) : null);

  return (
    <Modal
      footer={
        <Button
          context="primary"
          disabled={!addressIsValid || mapIsLoading || addressChangeIsLoading}
          largeHorizontalPadding
          text={type === 'user' ? ( !editAddressMode ? intl.formatMessage({ id: 'map.add' }) : intl.formatMessage({ id: 'map.save' }) ): intl.formatMessage({ id: 'map.select' })}
          onClick={handleEditAddressModalSaveButton}
        />
      }
      footerAlignRight
      subtitle={finalSubtitle}
      title={finalTitle}
      onClose={handleModalClose}
      onEnter={handleEditAddressModalSaveButton}
    >
      <div className="address-modal">
        {/* Map */}
        {showMap ? (
          <MapPoint
            address={address?.formattedAddress}
            coordinates={{ lat: address.lat, lng: address.lng }}
            id="modalMap"
            location={address?.formattedAddress}
            markerIcon={markerPickupIcon}
            onAddressChange={handleFormChange}
            onLoadingAddressChange={(value) => {
              setAddressChangeIsLoading(value);
            }}
            onLoadingStateChange={(value) => {
              setMapIsLoading(value);
            }}
          />
        ) : (
          <div className="address-modal__map-placeholder">
            <img
              alt="Map Icon"
              className="address-modal__map-placeholder-icon"
              src={mapIcon}
            />
            <div>
              {isAuthenticated ? (
                <>
                <FormattedMessage id="map.choose_location" />
                <img
                  alt="User addresses"
                  className="address-modal__user-addresses-msg-icon"
                  src={bookIcon}
                />
                </>
              ) : (
                <FormattedMessage id="map.choose_location_not_logged" />  
              )}
            </div>
          </div>
        )}
        <AddressForm
          address={address}
          addressIsChanging={addressChangeIsLoading}
          compact
          isInModal
          mapIsLoading={mapIsLoading}
          searchAddress={searchStringAddress}
          type={type}
          /*userAddress={userDefaultAddress}*/
          onChange={handleFormChange}
          onSearchInputChange={handleFormSearchChange}
        />
      </div>
    </Modal>
  );
};

AddressModal.propTypes = {
  currentAddress: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  editAddressMode: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  isFirst: PropTypes.bool,
};

AddressModal.defaultProps = {
  currentAddress: null,
  onClose: () => {},
  onSave: () => {},
  editAddressMode: false,
  title: null,
  type: 'user',
  isFirst: false
};

export default AddressModal;
