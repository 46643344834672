import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loginFromSession, logout, selectIsAuthenticated } from 'store/slices/authentication';

import Loader from '@youship/components/objects/loader';

const URL_SEARCH_PARAM_SESSION_TOKEN = 't';
const URL_SEARCH_PARAM_REDIRECTION_PATH = 'p';

const LoginFromSession = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [redirectionPath, setRedirectionPath] = useState('/');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const urlParameters = new URLSearchParams(location.search);
    const sessionToken = urlParameters.get(URL_SEARCH_PARAM_SESSION_TOKEN);
    const redirectionPathParam = urlParameters.get(URL_SEARCH_PARAM_REDIRECTION_PATH);

    if (sessionToken) {
      if (isAuthenticated) dispatch(logout());

      dispatch(loginFromSession({ token: sessionToken }))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while trying to login.');

          return response;
        })
        .then((response) => {
          setRedirectionPath(redirectionPathParam || '/');

          setShouldRedirect(true);

          return response;
        })
        .catch((error) => {
          // TODO: add proper error handling
          // eslint-disable-next-line no-console
          console.error(error);

          setRedirectionPath(redirectionPathParam || '/login');
          setShouldRedirect(true);
        });
    } else {
      setRedirectionPath(redirectionPathParam || '/404');
      setShouldRedirect(true);
    }
  }, [dispatch, isAuthenticated, location]);

  
  if (shouldRedirect) return <Redirect to={{ pathname: redirectionPath }} />;

  return (
    <div className="d-flex justify-content-center">
      <Loader />
    </div>
  );
};

export default LoginFromSession;
