import React from 'react';

import NewOrderDetails from 'components/sections/new-concierge-details';

const NewConcierge = () => (
  <>
    <NewOrderDetails />
  </>
);

export default NewConcierge;
