import BaseApi from '..';
import {
  SHOPPING_CHECKOUT,
  SHOPPING_DASHBOARD,
  SHOPPING_ORDER_CREATE,
  SHOPPING_PAYMENT_CONFIRM,
  SHOPPING_SHOP_CATEGORIES,
  SHOPPING_SHOP_DETAILS,
  SHOPPING_SHOP_DETAILS_COMPLETE,
  SHOPPING_SHOP_LIST,
  SHOPPING_SHOP_PRODUCT_DETAILS,
  SHOPPING_SHOP_PRODUCTS
} from '../endpoints';

const CHECKOUT_DEFAULT_TIME_INTERVAL = '00:00';

const DASHBOARD_SECTION_TYPE_CATEGORIES = 'categories';
const DASHBOARD_SECTION_TYPE_HIGHLIGHTS = 'highlights';
const DASHBOARD_SECTION_TYPE_SHOPS = 'shops';
const DASHBOARD_SECTION_TYPES = [
  DASHBOARD_SECTION_TYPE_CATEGORIES,
  DASHBOARD_SECTION_TYPE_HIGHLIGHTS,
  DASHBOARD_SECTION_TYPE_SHOPS
];

export {
  CHECKOUT_DEFAULT_TIME_INTERVAL,
  DASHBOARD_SECTION_TYPE_CATEGORIES,
  DASHBOARD_SECTION_TYPE_HIGHLIGHTS,
  DASHBOARD_SECTION_TYPE_SHOPS,
  DASHBOARD_SECTION_TYPES
};

export default class ShoppingApi extends BaseApi {
  /* eslint-disable camelcase */

  static checkoutQuote = ({
    shop_code,
    voucher,
    //dropoff,
    dropoff = {
      address: {
        street_address: '',
        number_address: '', // optional
        apartment_address: '', // optional
        state_address: '', // optional
        postalcode: '',
        city: '',
        countrycode: '',
        notes: '', // optional
        lat: 0,
        lng: 0,
        formatted_address: ''
      },
      contact: {
        name: '',
        phonecode: '',
        phone: '',
        phonelist: '', // optional
        company: '', // optional
        email: '' // optional
      },
      schedule: {
        ready: '', // optional
        deadline: '' // optional
      },
      notes: '' // optional
    },
    products
  }) => {
    const data = {
      shop_code,
      dropoff,
      products
    };

    if (voucher) data.voucher = voucher;

    return this.post(SHOPPING_CHECKOUT, data);
  };

  static confirmPayment = ({
    ys_token
  }) => this.post(SHOPPING_PAYMENT_CONFIRM, {
    ys_token
  });

  static createOrder = ({
    shop_code,
    voucher,
    //dropoff,
    dropoff = {
      address: {
        street_address: '',
        number_address: '', // optional
        apartment_address: '', // optional
        state_address: '', // optional
        postalcode: '',
        city: '',
        countrycode: '',
        notes: '', // optional
        lat: 0,
        lng: 0,
        formatted_address: ''
      },
      contact: {
        name: '',
        phonecode: '',
        phone: '',
        phonelist: '', // optional
        company: '', // optional
        email: '' // optional
      },
      schedule: {
        ready: '', // optional
        deadline: '' // optional
      },
      notes: '' // optional
    },
    payment_code,
    products
  }) => {
    const data = {
      shop_code,
      dropoff,
      products
    };

    if (payment_code) data.payment_code = payment_code;
    if (voucher) data.voucher = voucher;

    return this.post(SHOPPING_ORDER_CREATE, data);
  };

  static dashboard = (payload) => {
    let data = null;

    if (payload) {
      const { location, order_type } = payload;

      if (location || order_type) {
        data = {};

        if (location) {
          const { address, schedule, delivery_type } = location;

          if (address || schedule || delivery_type) data.location = {};

          if (address) {
            const { lat, lng } = address;

            if (lat && lng) data.location.address = { lat, lng };
          }
          if (schedule) data.location.schedule = schedule;
          if (delivery_type) data.location.delivery_type = delivery_type;
        }

        if (order_type) data.order_type = order_type;
      }
    }

    return this.post(SHOPPING_DASHBOARD, data);
  };

  static listShops = ({
    order_type,
    str,
    location
  }) => this.post(SHOPPING_SHOP_LIST, {
    order_type,
    str,
    location
  });

  static shopCategories = ({
    shop_code
  }) => this.post(SHOPPING_SHOP_CATEGORIES, {
    shop_code
  });

  static shopDetails = ({
    shop_code,
    location
  }) => this.post(SHOPPING_SHOP_DETAILS, {
    shop_code,
    location
  });

  static shopDetailsComplete = ({
    shop_code,
    location
  }) => this.post(SHOPPING_SHOP_DETAILS_COMPLETE, {
    shop_code,
    location
  });

  static shopProductDetails = ({
    product_code
  }) => this.post(SHOPPING_SHOP_PRODUCT_DETAILS, {
    product_code
  });

  static shopProducts = ({
    category_code
  }) => this.post(SHOPPING_SHOP_PRODUCTS, {
    category_code
  });

  /* eslint-enable camelcase */
}
