import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormattedMessage, useIntl } from 'react-intl';

import calendarIcon from 'images/icons/calendar.svg';
import clockIcon from 'images/icons/clock.svg';

import Input from '@youship/components/objects/input';
import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
//import Textarea from '@youship/components/objects/textarea';

import './styles.scss';

const DoBidModal = ({
  isLoading,
  errorMessageModal,
  title,
  onClose,
  onSave,
  schedule
}) => {

  
  const pickupDate = schedule?.pickupSchedule && schedule?.pickupSchedule.includes('-') ? moment(schedule?.pickupSchedule).format('YYYY-MM-DD') : null;
  const pickupTime = schedule?.pickupSchedule && schedule?.pickupSchedule.includes(':') ? moment(schedule?.pickupSchedule).format('HH:mm') : null;

  const dropoffDate = schedule?.dropoffSchedule && schedule?.dropoffSchedule.includes('-') ? moment(schedule?.dropoffSchedule).format('YYYY-MM-DD') : null;
  const dropoffTime = schedule?.dropoffSchedule && schedule?.dropoffSchedule.includes(':') ? moment(schedule?.dropoffSchedule).format('HH:mm') : null;


  const [isFormValid, setIsFormValid] = useState(false);

  const [pickupScheduleDate, setPickupScheduleDate] = useState(pickupDate || null);
  const [pickupScheduleTime, setPickupScheduleTime] = useState(pickupTime || null);

  const [dropoffScheduleDate, setDropoffScheduleDate] = useState(dropoffDate || null);
  const [dropoffScheduleTime, setDropoffScheduleTime] = useState(dropoffTime || null);
  

  const formattedDropoffSchedule = moment(`${dropoffScheduleDate} ${dropoffScheduleTime}`).format('YYYY-MM-DD HH:mm');
  const formattedPickupSchedule  = moment(`${pickupScheduleDate} ${pickupScheduleTime}`).format('YYYY-MM-DD HH:mm');


  const [errorMessage, setErrorMessage] = useState( errorMessageModal || null);
  

  const [bid, setBid] = useState(
            { 
              value: null, 
              notes: '', 
              schedule: {
                ready: null,
                deadline: null
              } 
            }
      );

  const handleInputChange = (event, name) => { 
    setBid( { ...bid, [name]: event?.target?.value } );
  };

  const handleDoBidSaveClick = (event) => {

    event.preventDefault();

    const currentDate = moment();

    const areDatesFormatValid = !!formattedDropoffSchedule &&
                                !!formattedPickupSchedule &&
                                moment(formattedDropoffSchedule).isValid() &&
                                moment(formattedPickupSchedule).isValid();

    const areDatesInFuture = areDatesFormatValid && 
            currentDate.isBefore(formattedDropoffSchedule) &&
            currentDate.isBefore(formattedPickupSchedule) &&
            !!dropoffScheduleDate &&
            !!pickupScheduleDate &&
            !!dropoffScheduleTime &&
            !!pickupScheduleTime;

    bid.schedule.ready    = formattedPickupSchedule;
    bid.schedule.deadline = formattedDropoffSchedule;

    if (!areDatesFormatValid) {
      setErrorMessage( intl.formatMessage({ id: 'app.date_format_invalid' }) );
    }else if (!areDatesInFuture) {
        setErrorMessage( intl.formatMessage({ id: 'app.date_format_past' }) );
    }else{ 
      setErrorMessage(null);
      onSave(event, bid);
    }
  };

  const handleDoBidInputChange = (value, context) => {
    if (context === 'dropoff-date') setDropoffScheduleDate(value);
    else if (context === 'dropoff-time') setDropoffScheduleTime(value);
    else if (context === 'pickup-date') setPickupScheduleDate(value);
    else if (context === 'pickup-time') setPickupScheduleTime(value);
    setErrorMessage(null);
  };

  useEffect(() => {

    setIsFormValid(false);

    if( bid.value != null && bid.value !== "" &&
        pickupScheduleDate != null && pickupScheduleDate !== "" &&
        pickupScheduleTime != null && pickupScheduleTime !== "" &&
        dropoffScheduleDate != null && dropoffScheduleDate !== "" &&
        dropoffScheduleTime != null && dropoffScheduleTime !== ""
      ){
        setIsFormValid(true);
      }
  }, [bid, pickupScheduleDate, pickupScheduleTime, dropoffScheduleDate, dropoffScheduleTime]);

  const intl = useIntl();

  return (
    <Modal
      classNames="do-bid-modal"
      footer={
        <div className="do-bid-modal__footer">
          { (errorMessage || errorMessageModal) && (
            <p className="do-bid-modal__error-message">
              {errorMessage || errorMessageModal}
            </p>
          )}
          <Button
            classNames="do-bid-modal__button"
            context="primary"
            disabled={isLoading || !isFormValid}
            largeHorizontalPadding
            text={intl.formatMessage({ id: 'order.bid.do_bid' })}
            onClick={!isLoading ? event => handleDoBidSaveClick(event) : () => {}}
          />
        </div>
      }
      title={title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="do-bid-modal__content">
        <div className="do-bid-modal__item">
          <div className="do-bid-modal__item-title">
            <FormattedMessage id="order.reschedule.pickup.title" />  (*)
          </div>
          <div className="do-bid-modal__item-subtitle">
            <FormattedMessage id="order.reschedule.pickup.subtitle" />
          </div>
          <div className="do-bid-modal__item-wrapper">
            <div className="do-bid-modal__item-date">
              <img
                alt="Calendar Icon"
                className="do-bid-modal__item-icon"
                src={calendarIcon}
              />
              <Input
                placeholder="yyyy-mm-dd"
                size="10"
                type="text"
                value={pickupScheduleDate}
                onChange={event => handleDoBidInputChange(event?.target?.value, 'pickup-date')}
              />
            </div>
            <div className="do-bid-modal__item-time">
              <img
                alt="Clock Icon"
                className="do-bid-modal__item-icon"
                src={clockIcon}
              />
              <Input
                placeholder="hh:mm"
                size="10"
                type="text"
                value={pickupScheduleTime}
                onChange={event => handleDoBidInputChange(event?.target?.value, 'pickup-time')}
              />
            </div>
          </div>
        </div>

        <div className="do-bid-modal__item">
          <div className="do-bid-modal__item-title">
            <FormattedMessage id="order.reschedule.dropoff.title" />  (*)
          </div>
          <div className="do-bid-modal__item-subtitle">
            <FormattedMessage id="order.reschedule.dropoff.subtitle" />
          </div>
          <div className="do-bid-modal__item-wrapper">
            <div className="do-bid-modal__item-date">
              <img
                alt="Calendar Icon"
                className="do-bid-modal__item-icon"
                src={calendarIcon}
              />
              <Input
                placeholder="yyyy-mm-dd"
                size="10"
                type="text"
                value={dropoffScheduleDate}
                onChange={event => handleDoBidInputChange(event?.target?.value, 'dropoff-date')}
              />
            </div>
            <div className="do-bid-modal__item-time">
              <img
                alt="Clock Icon"
                className="do-bid-modal__item-icon"
                src={clockIcon}
              />
              <Input
                placeholder="hh:mm"
                size="10"
                type="text"
                value={dropoffScheduleTime}
                onChange={event => handleDoBidInputChange(event?.target?.value, 'dropoff-time')}
              />
            </div>
          </div>
        </div>

        <div className="do-bid-modal__item">
          <div className="do-bid-modal__item-wrapper">
            <Input
                block
                label={intl.formatMessage({ id: 'order.bid.value.title' }) +  " (*)"}
                placeholder={intl.formatMessage({ id: 'order.bid.value.placeholder' })}
                type="number"
                value={bid.value}
                onChange={event => handleInputChange(event, 'value')}
              />
          </div>
        </div>

        <div className="do-bid-modal__item">
            <Input
                block
                label={intl.formatMessage({ id: 'order.bid.notes.title' })}
                placeholder={intl.formatMessage({ id: 'order.bid.notes.placeholder' })}
                inputRows="2"
                type="textarea"
                value={bid.notes}
                onChange={event => handleInputChange(event, 'notes')}
              />
        </div>

      </div>
    </Modal>
  );
};

DoBidModal.propTypes = {
  errorMessageModal: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  schedule: PropTypes.shape({
    deadline: PropTypes.string,
    ready: PropTypes.string
  })
};

DoBidModal.defaultProps = {
  errorMessageModal: null,
  isLoading: false,
  onClose: () => {},
  onSave: () => {},
  schedule: null
};

export default DoBidModal;
