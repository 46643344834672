/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getDeliveryEstimate,
  selectDropoffs,
  selectPickups,
  setDefaultPickup,
  setDeliveryPickups
} from 'store/slices/new-delivery';
import { selectIsAuthenticated, selectUser } from 'store/slices/authentication';

import Button from 'components/objects/button';
import InfoBox from 'components/new-order/info-box';
import NewOrderPreview from 'components/new-order/preview';
import NewOrderShipmentOptions from 'components/new-order/shipment-options';
import Map from 'components/map';
import StaticMap from 'components/static-map';
import Banner from 'components/banner';

import previewIllustration from 'images/preview/illustration.svg';
import chevronLeftIcon from 'images/icons/chevron-left.svg';
import markerIcon from 'images/icons/marker-blue.svg';

import './styles.scss';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/login?nextpage=new-order'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'header.sign_in'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/register?nextpage=new-order'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'header.sign_up'
  }
];

const NewOrderPublicDetails = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const preview = useRef(null);
  const [userLocation, setUserLocation] = useState(null);
  const intl = useIntl();

  const previewComponent = preview.current;

  const setOptionsWidth = useCallback(() => {
    if (preview.current) {
      const bounds = preview.current.getBoundingClientRect();

      if (bounds) {
        const previewWidth = bounds.width;

        setPreviewWidth(previewWidth);
      }
    }
  }, []);

  const [previewWidth, setPreviewWidth] = useState(0);

  useEffect(() => {
    setOptionsWidth();
  }, [previewComponent, setOptionsWidth]);

  setTimeout(() => {
    setOptionsWidth();
  }, 4000);

  useEffect(() => {
    window.addEventListener('resize', setOptionsWidth);

    document.body.classList.add('is-new-order-logged-out-page');
    
    return () => {
      window.removeEventListener('resize', setOptionsWidth);
      document.body.classList.remove('is-new-order-logged-out-page');
    };
  });

  const handleGeolocation = (position) => {
    if (position?.coords?.latitude && position?.coords?.longitude) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      setUserLocation(`${lat},${lng}`);
    }
  };

  navigator.geolocation.getCurrentPosition(handleGeolocation);

  return (
    <div className="new-order-details">
      <div className="container">
        <Button
          classNames="new-order-details__previous"
          icon={chevronLeftIcon}
          noBackground
          noPadding
          text={intl.formatMessage({ id: 'header.back' })}
          onClick={() => history.goBack()}
        />
        <div className="row">
          <div className="col col-12 col-md-6">
            
            <NewOrderPreview
              ref={preview}
            />
           
            <div className="new-order-details__separator" />
            
            <div
              className="new-order-details__banner-wrapper"
              style={{ width: `${previewWidth}px` }}
            >
              <Banner
                buttons={bannerButtons}
                classNames="new-order-details__banner"
                image={previewIllustration}
                text="new-order.promo.text"
                title="new-order.promo.title"
              />
            </div>
          
          </div>
          <div className="col col-12 offset-0 col-md-5 offset-lg-1">
            <div className="new-order-details__map-wrapper">
                <StaticMap
                  classNames="new-order-details__static-map"
                  instructions={intl.formatMessage({ id: 'map.location_permission' })}
                  location={userLocation}
                  markerIcon={markerIcon}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderPublicDetails;
