import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {FormattedMessage, useIntl} from 'react-intl';

import './styles.scss';
import infoIcon from "../../../images/icons/info-gray.svg";
import addressIcon from "../../../images/icons/address.svg";
import {Link} from "react-router-dom";
import plusIcon from "../../../images/icons/plus--white.svg";


import {getUser, selectUser} from "../../../store/slices/authentication";
import Button from "../../objects/button";
import Notice from "../../../../../shared/components/objects/notice";
import Pagination from '@youship/components/objects/pagination';
import AddressCard from "../../address-card";
import {
    editAddress,
    removeAddress,
    setDefaultAddress
} from 'store/slices/user-addresses';

import Loader from "../../../../../shared/components/objects/loader";
import AddressModal from "../../../../../shared/components/address-modal";
import ActionModal from "../../order/action-modal";
import ContactModal from "../../../../../shared/components/contact-modal";
import {getAddressesList, selectPagesList, selectUserAddressesList} from "../../../store/slices/user-addresses-list";



const MAXIMUM_NUMBER_OF_ADDRESSES = 4;

const AddressesArea = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);
    const [showDiv, setShowDiv] = useState(false);

    const [showActionModal, setShowActionModal] = useState(false);

    const [address, setAddress] = useState({
        apartmentAddress: user?.default_address?.address.apartmentAddress || '',
        city: user?.default_address?.address.city || '',
        countryCode: user?.default_address?.address.countryCode || '',
        formattedAddress: user?.default_address?.address.formattedAddress || '',
        numberAddress: user?.default_address?.address.numberAddress || '',
        postalCode: user?.default_address?.address.postalCode || '',
        streetAddress: user?.default_address?.address.streetAddress || ''
    });

    const [selectedAddressCode, setSelectedAddressCode] = useState(null);
    let [selectedAddress, setSelectedAddressToEdit] = useState(address);

    const [showEditAddressModal, setShowEditAddressModal] = useState(false);
    const [showAddAddressModal, setShowAddAddressModal] = useState(false);
    const [showAddContactModal, setShowAddContactModal] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    const pages = useSelector(selectPagesList);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const numberOfPages = pages.lastPage + 1;
    const showPagination = !!numberOfPages;
    const intl = useIntl();



    //lista de moradas do user
    const [isLoadingAddressesList, setLoadingAddressesList] = useState(true);
    const userAddresses = useSelector(selectUserAddressesList);
    const defaultAddress = userAddresses.filter(item => item.isdefault === true)[0]
    const restOfList = userAddresses.filter(item => item.isdefault !== true)
    const newAddressesList = [defaultAddress, ...restOfList]

    function handleRefresh() {
        setRefresh(!refresh);
    }

    useEffect(
        () => {
            dispatch(getAddressesList({
                pages: {
                    current: currentPageNumber - 1,
                    resultsbypage: MAXIMUM_NUMBER_OF_ADDRESSES
                }
            }))
                .then(((response) => {
                    setLoadingAddressesList(false)
                }))

                .catch((error) => {
                    // Add proper error handling
                    setErrorMessage(error?.message);
                    console.log(error);
                });
        }, [refresh]
    );


    useEffect(() => {
        setTimeout(() => {
            setShowDiv(true);
        }, 500);
    }, []);





    const handleAddAddressButtonClick = () => {
        setShowAddAddressModal(true);
    };

    const handleCloseEditAddressModal = () => {
        setShowEditAddressModal(false);
    };

    const handleCloseAddAddressModal = () => {
        setShowAddAddressModal(false);
    };


    const handleEditAddressModalSaveButton = () => {
        dispatch(getUser())
            .then(((response) => {
                handleRefresh()
                return response;
            }))
            .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
            });

        handleCloseEditAddressModal();
    };


    const handleAddAddressModalSaveButton = () => {
        dispatch(getUser())
            .then(((response) => {
                handleRefresh()
                return response;
            }))
            .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
            });

        handleCloseAddAddressModal();
    };


    const handleSetPrimaryAddressButton = (addressCode) => {

        dispatch(setDefaultAddress({
            addressCode: addressCode
        }))
            .then((response) => {
                handleRefresh()
                return response;
            })
            .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
            });
    };


    const handleEditAddressButton = (address) => {
        setSelectedAddressToEdit(address)
        setShowEditAddressModal(true);
    };

    const handlePaginationOnChange = (pageNumber) => {
        setCurrentPageNumber(pageNumber);
        handleRefresh()
    };



    const handleActionModalCloseButtonClick = () => {
        setShowActionModal(false);
    };

    const handleCloseAddContactModal = () => {
        setShowAddContactModal(false);
    };

    const handleActionModalConfirmButtonClick = (event) => {
        event.preventDefault();

        dispatch(removeAddress({
            addressCode: selectedAddressCode
        }))
            .then((response) => {
                handleRefresh()

                setShowActionModal(false);
                return response;
            })
            .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
            });
    };


    const updateSelectedAddressCode = (addressCode) => {
        setSelectedAddressCode(addressCode)
    }

    const updateRemoveModalShow = (showActionModal) => {
        setShowActionModal(showActionModal)
    }

    const updateShowEditModalShow = (showEditAddressModal) => {
        setShowEditAddressModal(showEditAddressModal)
    }

    const handleSetContactButton = (address) => {
        setSelectedAddressToEdit(address)
        setShowAddContactModal(true);
    };

    const setContact = (contact) => {
        dispatch(editAddress({
            address: {
                apartmentAddress: selectedAddress.address.apartmentAddress,
                city: selectedAddress.address.city,
                countryCode: selectedAddress.address.countryCode,
                formattedAddress: selectedAddress.address.formattedAddress,
                lat: selectedAddress.address.lat,
                lng: selectedAddress.address.lng,
                numberAddress: selectedAddress.address.numberAddress,
                postalCode: selectedAddress.address.postalCode,
                streetAddress: selectedAddress.address.streetAddress
            },
            contact: {
                name: contact.name,
                company: contact.company,
                phone: contact.phone,
                countryCallingCode: contact.countryCallingCode,
                phoneCodeId: "",
            },
            addressCode: selectedAddress.addressCode,
            isDefault: selectedAddress.isDefault
        }))
            .then((response) => {
                handleRefresh()

                setShowAddContactModal(false);
                return response;
            })
            .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
            });
    };

    const handleAddContactModalSaveButton = (newContact) => {
        setContact(newContact, selectedAddress);
    };

    const header = (
        <>
            <div className="row" style={{ display: "flex", alignItems: "center" }}>
                <div className="col-12" style={{ flex: 1 }}>
                    <h1 className="addresses__title">
                        <FormattedMessage id="addresses.title"/>
                    </h1>
                </div>
                <div style={{paddingRight: '16px'}}>
                    <Button
                        linkComponent={Link}
                        to='#'
                        noArrow
                        largeHorizontalPadding
                        icon={plusIcon}
                        text={intl.formatMessage({id: 'addresses.add-address'})}
                        onClick={handleAddAddressButtonClick}
                    />
                </div>
            </div>
        </>
    );


    if (isLoadingAddressesList) {
        return (
            <div className="addresses">
                <div className="container">
                    {header}
                    <div className="addresses__loader-wrapper">
                        <Loader/>
                    </div>
                </div>
            </div>
        );
    }else {
        return (
            <div className="addresses-list">
                <div className="container">
                    {header}
                    {errorMessage ? (
                        <Notice
                            description={errorMessage}
                            fixed
                            icon={infoIcon}
                        />
                    ) : (
                        <>
                            {userAddresses.length ? (
                                <div>
                                    <div className="row">
                                        {newAddressesList.map(
                                            (address, index) => (
                                                <>
                                                    {address != null && (
                                                        <div
                                                            // key={address.address_code}
                                                            className="col col-12 col-lg-6">
                                                            <AddressCard
                                                                address={address}
                                                                index={index}
                                                                setSelectedAddressCode={updateSelectedAddressCode}
                                                                setShowRemoveModal={updateRemoveModalShow}
                                                                setShowEditModal={updateShowEditModalShow}
                                                                handleEditAddress={handleEditAddressButton}
                                                                handleSetPrimaryAddress={handleSetPrimaryAddressButton}
                                                                handleAddContactButtonClick={handleSetContactButton}
                                                            />
                                                        </div>
                                                    )}
                                                    {showActionModal && (
                                                        <ActionModal
                                                            title={intl.formatMessage({id: 'addresses.remove_address_title'})}
                                                            description={intl.formatMessage({id: 'addresses.remove_address_description'})}
                                                            back={intl.formatMessage({id: 'addresses.go_back'})}
                                                            onClose={handleActionModalCloseButtonClick}
                                                            onConfirm={handleActionModalConfirmButtonClick}
                                                        />
                                                    )}

                                                </>
                                            ))

                                        }


                                    </div>

                                </div>
                            ) : ( showDiv && (
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="addresses__no-addresses">
                                                <p>
                                                    <img
                                                        alt="New address"
                                                        src={addressIcon}
                                                    />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="addresses.no_results"/>
                                                </p>
                                                <Button
                                                    linkComponent={Link}
                                                    linkProps={{ to: '#' }}
                                                    noArrow
                                                    largeHorizontalPadding
                                                    icon={plusIcon}
                                                    isTextLeft
                                                    text={intl.formatMessage({id: 'addresses.add-address'})}
                                                    onClick={handleAddAddressButtonClick}

                                                />
                                            </p>
                                        </div>
                                    </div>
                                )
                            )
                            }
                            {showPagination && numberOfPages > 1 && (
                                <div className="row justify-content-center mt-4">
                                    <Pagination
                                        length={numberOfPages}
                                        selected={currentPageNumber}
                                        onChange={handlePaginationOnChange}
                                    />
                                </div>
                            )}
                        </>


                    )}
                </div>

                {showEditAddressModal && selectedAddress && (
                    <AddressModal
                        currentAddress={{
                            apartmentAddress: selectedAddress?.address.apartmentAddress || '',
                            city: selectedAddress.address.city || '',
                            countryCode: selectedAddress.countryCode || '',
                            formattedAddress: selectedAddress.address.formattedAddress || '',
                            numberAddress: selectedAddress.address.numberAddress || '',
                            postalCode: selectedAddress.address.postalCode || '',
                            streetAddress: selectedAddress.address.streetAddress || '',
                            addressCode: selectedAddress.addressCode,
                            contact: selectedAddress.contact,
                            isDefault: selectedAddress.isDefault
                        }}
                        /*title="Your addresses"*/
                        editAddressMode={true}
                        type="user"
                        onClose={handleCloseEditAddressModal}
                        onSave={handleEditAddressModalSaveButton}
                    />
                )}
                
                

                {showAddAddressModal && (
                    <AddressModal
                        /*title="Your addresses"*/
                        editAddressMode={false}
                        type="user"
                        isFirst={newAddressesList.length === 0}
                        onClose={handleCloseAddAddressModal}
                        onSave={handleAddAddressModalSaveButton}
                    />
                )}
                {showAddContactModal && (
                    <ContactModal
                        companyName={selectedAddress.contact.company}
                        contactName={selectedAddress.contact.name}
                        contactPhoneNumber={selectedAddress.contact.phone}
                        countryCallingCode={Array.from(selectedAddress.contact.phonecode)[0] === "+" ?
                            selectedAddress.contact.phonecode.replace(/^\+/, '') : selectedAddress.contact.phonecode}
                        showTaxField={false}
                        onClose={handleCloseAddContactModal}
                        onSave={handleAddContactModalSaveButton}
                    />
                )}
            </div>
        );
    }
};


export default AddressesArea;
