import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ShoppingApi from '@youship/api/shopping';
import DeliveryApi from '@youship/api/delivery';

import formatPrice from '@youship/utils/format-price';
import paymentIcon from '@youship/utils/payment-icons';

import './styles.scss';

const OrderPayment = ({ payment, resume, type, isConfirmPayment, onOrderPayment }) => {
  const paymentMethodCode = payment?.method?.code;
  const paymentMethodName = payment?.method?.name;
  const priceDetails = resume?.priceDetails;
  const [paymentConfirmation, setPaymentConfirmation] = useState(null);
  const ysToken = payment?.ysToken;
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  useEffect(() => {

    console.log("---confirmPayment useEffect----- isConfirmPayment=" + isConfirmPayment);

    if (isConfirmPayment && ysToken && !isLoadingConfirm) {

      setIsLoadingConfirm(true);

      console.log("---call confirmPayment-----");

      setPaymentConfirmation({ message: 'Validating payment…' });

      const actionFunc = type === "ship" ?  DeliveryApi.confirmPayment({ ys_token: ysToken }) :  ShoppingApi.confirmPayment({ ys_token: ysToken }) ;
      
      actionFunc
        .then((response) => {
          setPaymentConfirmation(response);

          //console.log(response);

          if (response.success) {
            onOrderPayment(response.success, response.isdone);
          }

          setIsLoadingConfirm(false);

          return response;
        })
        .catch((error) => {
          
          setPaymentConfirmation({ message: error?.message || 'Could not confirm payment, please reload the page.' });
          
          setIsLoadingConfirm(false);

        });
    }
  }, [ysToken]);

  const paymentConfirmationMessage = typeof paymentConfirmation?.message === 'string' ? paymentConfirmation.message : null;
  const paymentConfirmed = typeof payment?.isdone === 'boolean' ? payment?.isdone : null;

  return (
    <>
      {(!!priceDetails) && (
        <div className="order-payment">
          <h3 className="order-payment__title">
            <FormattedMessage id="order.payment" />
          </h3>
          {(paymentMethodCode || paymentMethodName) && (
          <>
            <div className="order-payment__description">
              <FormattedMessage id="order.payment_method" />
            </div>
            <div className="order-payment__type">
              {paymentMethodCode ? (
                <img
                  alt="Card Icon"
                  className="order-payment__type-icon"
                  src={paymentIcon(paymentMethodCode)}
                />
              ) : paymentMethodName}
            </div>
          </>
          )}      
          <ul className="order-payment__details">
            {Array.isArray(priceDetails) && !!priceDetails.length && priceDetails.map((entry, index) => (
              <li
                key={index}
                className="order-payment__details-item"
              >
                <div className="order-payment__details-title">
                  {entry.text}
                </div>
                <div className="order-payment__details-value order-payment__details-value">
                  {entry.svalue}
                </div>
              </li>
            ))}
          </ul>
          {!!paymentConfirmationMessage && (
            <div className={`order-payment__confirmation${
              paymentConfirmed === true ? ' order-payment__confirmation--success' : ''
            }${
              paymentConfirmed === false ? ' order-payment__confirmation--error' : ''
            }`}
            >
              {paymentConfirmationMessage}
            </div>
          )}
        </div>
      )}
    </>
  );
};

OrderPayment.propTypes = {
  onOrderPayment: PropTypes.func,
  isConfirmPayment: PropTypes.string,
  payment: PropTypes.shape({
    paymentMethodCode: PropTypes.string,
    paymentMethodName: PropTypes.string,
    ysToken: PropTypes.string
  }),
  resume: PropTypes.shape({
    priceDetails: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    }))
  }),
  type: PropTypes.string
};

OrderPayment.defaultProps = {
  isConfirmPayment: null,
  onOrderPayment: () => {},
  payment: null,
  resume: null,
  type: null
};

export default OrderPayment;
