/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import getItemIcon from '@youship/utils/shipment-items-icons';
import formatPrice from '@youship/utils/format-price';

import './styles.scss';

const OrderShipment = ({ items, type }) => (
  <div className="order-shipment">
    <h3 className="order-shipment__title">
      {type === 'order' ? (
        <FormattedMessage id="order.items" />
      ) : (
        <FormattedMessage id="order.shipment" />
      ) }
    </h3>
    {type === 'order' ? (
      <ul className="order-shipment-list">
        {items.map((item, index) => (
          <li
            key={index}
            className="order-shipment-list__item"
          >
            <span className="order-shipment-list__item-quantity">
              {item.quantity}
            </span>
            <div className="order-shipment-list__item-details">
              <div className="order-shipment-list__item-name">
                {item.description}
              </div>
              <div className="order-shipment-list__item-description">
                {item.subtitle}
              </div>
              {!!item.price && (
                <div className="order-shipment-list__item-price">
                  {item.price.svalue}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <ul className="order-shipment-list order-shipment-list--ship">
        {items.map((item, index) => (
          <li
            key={index}
            className="order-shipment-list__item"
          >
            <img
              alt="Package Icon"
              className="order-shipment-list__icon"
              src={getItemIcon(item.icon)}
            />
            <div className="order-shipment-list__item-details">
              <div className="order-shipment-list__item-name">
                <span className="order-shipment-list__item-description">
                  {item.quantity}
                  ×
                  {' '}
                </span>
                {item.description}
              </div>
              <div className="order-shipment-list__item-description">
                {item.subtitle}
              </div>
            </div>
          </li>
        ))}
      </ul>
    )}
  </div>
);

OrderShipment.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    location: PropTypes.string,
    value: PropTypes.number,
    quantity: PropTypes.number,
    size: PropTypes.string,
    subtitle: PropTypes.string
  })),
  type: PropTypes.string
};

OrderShipment.defaultProps = {
  items: null,
  type: null
};

export default OrderShipment;
