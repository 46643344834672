import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Route from 'utils/Route';

import { selectIsAuthenticated, selectRequirePhoneActivation } from 'store/slices/authentication';

import ImagePanel from '@youship/components/objects/image-panel';
import TransporterForm from 'components/transporter-form';
import ShopForm from 'components/shop-form';

import './styles.scss';

const AccountConfirmationArea = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const requirePhoneActivation = useSelector(selectRequirePhoneActivation);

  // Fallback if route protection fails:
  if (isAuthenticated && requirePhoneActivation) return <Redirect to="/register" />;

  return (
    <div className="account-confirmation-area">
      <ImagePanel />
      <div className="container">
        <div className="row">
          <div className="col col-12 col-sm-6 offset-sm-6">
            <Switch>
              <Route path="/account-confirmation/carrier">
                <h1 className="account-confirmation-area__title">
                  <FormattedMessage id="r_transporter.title" />
                </h1>
                <div className="row">
                  <div className="col col-12 col-lg-10">
                    <TransporterForm />
                  </div>
                </div>
              </Route>
              <Route path="/account-confirmation/partner">
                <h1 className="account-confirmation-area__title">
                  <FormattedMessage id="r_partner.title" />
                </h1>
                <div className="row">
                  <div className="col col-12 col-lg-10">
                    <ShopForm />
                  </div>
                </div>
              </Route>
              <Route path="/account-confirmation/transporter">
                <Redirect to="/account-confirmation/carrier" />
              </Route>
              <Route path="/account-confirmation/shop">
                <Redirect to="/account-confirmation/partner" />
              </Route>
              <Route>
                <Redirect to="/account-confirmation/carrier" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountConfirmationArea;
