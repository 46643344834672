/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import starSolidIcon from 'images/icons/star-solid.svg';
import orderImagePlaceholder from 'images/order-placeholder.png';

import './styles.scss';

const ShopCard = ({ alertColor, alertMessage, averagePrice, closedMessage, deliveryFee, fulfillment, id, imageUrl, name, subtitle, subsubtitle, open, rating }) => (
  <Link to={`/venue/${id}`}>
    <div className="item-card">
      <div className="item-card__image-wrapper">
        <img
          alt="Category Card"
          className="item-card__image"
          src={imageUrl ?? orderImagePlaceholder}
        />
        {!!alertMessage && (
          <div
            className="item-card__alert"
            style={{ backgroundColor: alertColor }}
          >
            {alertMessage}
          </div>
        )}
        {!open && !!closedMessage && (
          <div className="item-card__closed-overlay">
            {closedMessage}
          </div>
        )}
      </div>
      <div className="item-card__details">
        <div className="item-card__details-top">
          <div className="item-card__name">
            {name}
          </div>
          {!!rating && (
            <div className="item-card__rating">
              <img
                alt="Rating Icon"
                className="item-card__rating-icon"
                src={starSolidIcon}
              />
              {rating}
            </div>
          )}
        </div>
        {subsubtitle && (
          <div className="item-card__details-bottom">
           {subsubtitle}
          </div>
        )}
      </div>
    </div>
  </Link>
);

ShopCard.propTypes = {
  averagePrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  closedMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  deliveryFee: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  fulfillment: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  subsubtitle: PropTypes.string,
  open: PropTypes.bool,
  rating: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

ShopCard.defaultProps = {
  averagePrice: null,
  closedMessage: null,
  deliveryFee: null,
  fulfillment: null,
  imageUrl: null,
  name: null,
  subtitle: null,
  subsubtitle: null,
  open: null,
  rating: null
};

export default ShopCard;
