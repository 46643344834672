import React from 'react';

import EmailSentArea from 'components/sections/email-sent-area';

const EmailSent = () => (
  <>
    <EmailSentArea />
  </>
);

export default EmailSent;
