import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ShopTypeList = ({ items, loading, onItemClick, selected }) => {
  // TODO: add loading state handling
  if (!Array.isArray(items) || !items.length) return null;

  return (
    <ul className={`shop-type-list${loading ? ' shop-type-list--loading' : ''}`}>
      {items.map((item, index) => (
        <li
          key={index}
          className={`shop-type-list__item shop-type-list-item${
            item.imageUrl ? ' shop-type-list-item--has-image' : ''
          }${
            selected === item.id ? ' shop-type-list-item--selected' : ''
          }`}
          onClick={() => onItemClick(item.id)}
        >
          {!!item.imageUrl && (
            <img
              className="shop-type-list-item__image"
              src={item.imageUrl}
              alt={item.name}
            />
          )}
          <div className="shop-type-list-item__name">
            {item.name}
          </div>
        </li>))
      }
    </ul>
  );
};

ShopTypeList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    name: PropTypes.string.isRequired
  })),
  loading: PropTypes.bool,
  onItemClick: PropTypes.func,
  selected: PropTypes.string
};

ShopTypeList.defaultProps = {
  items: [],
  loading: false,
  onItemClick: () => {},
  selected: ''
};

export default ShopTypeList;
