/* eslint-disable no-undefined */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  PRODUCT_OPTION_INPUT_TYPE_CHECKBOX,
  PRODUCT_OPTION_INPUT_TYPE_QUANTITY,
  PRODUCT_OPTION_INPUT_TYPE_RADIO,
  PRODUCT_OPTION_INPUT_TYPES,
  resetCustomization,
  saveCustomization,
  selectCustomization,
  setCustomizationQuantity,
  toggleCustomizationChoice,
  selectShopId
} from 'store/slices/shopping-cart';

import { fetchShop, selectIsLoading, selectShopById } from 'store/slices/shops';

import Button from '@youship/components/objects/button';
import Checkbox from '@youship/components/objects/checkbox';
import CustomQuantity from '@youship/components/objects/custom-quantity';
import Modal from '@youship/components/objects/modal';
import Radio from '@youship/components/objects/radio';
import Quantity from '@youship/components/objects/quantity';


import './styles.scss';

const ShoppingCartItemCustomizationModal = () => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const customization = useSelector(selectCustomization);

  const shoppingCartShopId = useSelector(selectShopId);
  const shop = useSelector(state => selectShopById(state, shoppingCartShopId));

  if (!customization?.product) return null;

  const { amountAvailable, choices, product } = customization;
  const { options } = product;
  let customizationIsValid = false;

  // product.limit value of 0 means that there is no limit
  customizationIsValid = customization.quantity > 0 && (!product.limit || customization.quantity <= product.limit) && options.every((option) => {
    const { id, mandatory } = option;
    const choiceExtrasIds = Object.keys(choices[id]);

    return !mandatory || choiceExtrasIds.some(extraId => choices[id][extraId]);
  });

  //se open=false, nao deixa adicionar
  if(!shop?.open ){
    customizationIsValid = false;
  }

  const handleChoiceSelect = (optionId, extraId, quantity) => {
    dispatch(toggleCustomizationChoice({ extraId, optionId, quantity }));
  };

  const handleClose = () => {
    dispatch(resetCustomization());
  };

  const handleQuantityChange = (quantity) => {
    dispatch(setCustomizationQuantity(quantity));
  };

  const handleSave = () => {
    dispatch(saveCustomization());
  };

  let title = customization.product.options.length ?  intl.formatMessage({ id: 'shop.customize' }) + " " +  customization.product.name : '';

  if (amountAvailable === 0) title = intl.formatMessage({ id: 'shop.available_amount_cart' });

  return (
    <Modal
      classNames={`shopping-cart-item-customization-modal${!customization.product.options.length ? ' shopping-cart-item-customization-modal--no-body' : ''}`}
      footer={
        <div className="shopping-cart-item-customization-modal__footer">
          <div className="shopping-cart-item-customization-modal__footer-quantity-label">
            <FormattedMessage id="shop.quantity" />
          </div>
          <Quantity
            max={typeof amountAvailable === 'number' ? amountAvailable : null}
            min={1}
            value={customization.quantity}
            onChange={handleQuantityChange}
          />
          <Button
            context="primary"
            disabled={!customizationIsValid}
            largeHorizontalPadding
            text={typeof customization.index === 'number' ? intl.formatMessage({ id: 'shop.update' }) : intl.formatMessage({ id: 'shop.add' }) }
            onClick={handleSave}
          />
        </div>
      }
      header={
        <div className="shopping-cart-item-customization-modal__header">
          {!!customization.product.imageUrl && (
            <img
              alt="Product"
              className="shopping-cart-item-customization-modal__header-image"
              src={customization.product.imageUrl}
            />
          )}
          <div className="shopping-cart-item-customization-modal__header-content">
            <div className="shopping-cart-item-customization-modal__header-title">
              {customization.product.name}
            </div>
            <div className="shopping-cart-item-customization-modal__header-description">
              {customization.product.description}
            </div>
            <div className="shopping-cart-item-customization-modal__header-price">
              {customization.product.price?.svalue}
            </div>
            {typeof amountAvailable === 'number' && (
              <div className="shopping-cart-item-customization-modal__header-limit">
                <FormattedMessage id="shop.limit" />
                {' '}
                {amountAvailable}
              </div>
            )}
          </div>
        </div>
      }
      noScroll={!!customization.product.options.length}
      title={title}
      onClose={handleClose}
    >
      {customization.product.options.length ?
        (
          <div className="shopping-cart-item-customization-modal__body">
            {customization.product.options.map(option => PRODUCT_OPTION_INPUT_TYPES.includes(option.input) && (
              <div
                key={option.id}
                className="shopping-cart-item-customization-modal__option"
              >
                <div className="shopping-cart-item-customization-modal__option-title">
                  {option.name}
                  {!!option.subtitle && ` – ${option.subtitle}`}
                  {!!option.mandatory && ' ('+intl.formatMessage({ id: 'shop.required' })+')' }
                </div>
                {option.input === PRODUCT_OPTION_INPUT_TYPE_CHECKBOX && option.extras.map(extra => (
                  <div
                    key={extra.id}
                    className="shopping-cart-item-customization-modal__option-extra"
                  >
                    <Checkbox
                      checked={!!customization.choices[option.id] && !!customization.choices[option.id][extra.id]}
                      description={extra.description !== extra.name ? extra.description : null}
                      disabled={extra.soldOut || amountAvailable === 0}
                      label={extra.name}
                      note={extra.price ? `+\xa0${extra.price?.svalue}` : ''}
                      noteContext="success"
                      onChange={() => handleChoiceSelect(option.id, extra.id)}
                    />
                  </div>
                ))}
                {option.input === PRODUCT_OPTION_INPUT_TYPE_QUANTITY && option.extras.map((extra) => {
                  // extra.limit or option.limit value of 0 means that there is no limit
                  const max = extra.limit && typeof extra.limit === 'number' && option.limit && typeof option.limit === 'number' ?
                    Math.min(extra.limit, option.limit) :
                    extra.limit || option.limit || null;

                  return (
                    <div
                      key={extra.id}
                      className="shopping-cart-item-customization-modal__option-extra"
                    >
                      <CustomQuantity
                        description={extra.description !== extra.name ? extra.description : null}
                        disabled={extra.soldOut || amountAvailable === 0}
                        label={extra.name}
                        max={max}
                        note={ extra.price ? `+\xa0${extra.price?.svalue}` : ''}
                        noteContext="success"
                        value={customization.choices[option.id] && typeof customization.choices[option.id][extra.id] === 'number' ?
                          customization.choices[option.id][extra.id] :
                          0}
                        onChange={value => handleChoiceSelect(option.id, extra.id, value)}
                      />
                    </div>
                  );
                })}
                {option.input === PRODUCT_OPTION_INPUT_TYPE_RADIO && option.extras.map(extra => (
                  <div
                    key={extra.id}
                    className="shopping-cart-item-customization-modal__option-extra"
                  >
                    <Radio
                      description={extra.description !== extra.name ? extra.description : null}
                      disabled={extra.soldOut || amountAvailable === 0}
                      label={extra.name}
                      note={extra.price ? `+\xa0${extra.price?.svalue}` : ''}
                      noteContext="success"
                      selected={customization.choices[option.id] && customization.choices[option.id][extra.id] ? extra.id : null}
                      value={extra.id}
                      onChange={() => handleChoiceSelect(option.id, extra.id)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) :
        null}
    </Modal>
  );
};

export default ShoppingCartItemCustomizationModal;
