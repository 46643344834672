import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const OrderNotes = ({ notes }) => (
  <div className="order-notes">
    <h3 className="order-notes__title">
      <FormattedMessage id="order.notes" />
    </h3>
    {Array.isArray(notes) && notes.map((note, index) => (
      <div
        key={index}
        className="order-notes__note"
      >
        <div className="order-notes__note-title">
          {note.title}
        </div>
        <div className="order-notes__note-text">
          {note.text}
        </div>
      </div>
    ))}
  </div>
);

OrderNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string
  }))
};

OrderNotes.defaultProps = {
  notes: null
};

export default OrderNotes;
