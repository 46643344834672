import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const OrderTypeCard = ({ classNames, description, imageUrl, imgComponent, linkComponent, linkProps, name, noMargin }) => {
  const Img = imgComponent;

  const card = (
    <div className={`order-type-card${noMargin ? ' order-type-card--no-margin' : ''}${classNames ? ` ${classNames}` : ''}`}>
      <div className="order-type-card__image-wrapper">
        {!!imageUrl && (
          <>
            {Img ? (
              <>
                <Img
                  alt={`${name} orders`}
                  classNames="order-type-card__image"
                  src={imageUrl}
                />
                <Img
                  classNames="order-type-card__image order-type-card__image--blurred"
                  src={imageUrl}
                />
              </>
            ) : (
              <>
                <img
                  alt={`${name} orders`}
                  className="order-type-card__image"
                  src={imageUrl}
                />
                {/* Blurred overlay does not need alt attribute */}
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img
                  className="order-type-card__image order-type-card__image--blurred"
                  src={imageUrl}
                />
              </>
            )}
          </>
        )}
      </div>
      <svg height="0">
        <defs>
          <filter id="blurFilter">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
            />
          </filter>
        </defs>
      </svg>
      <div className="order-type-card__content">
        {!!name && (
          <div className="order-type-card__name">
            {name}
          </div>
        )}
        {!!description && (
          <div className="order-type-card__description">
            {description}
          </div>
        )}
      </div>
    </div>
  );

  if (!linkComponent && !linkProps?.to) return card;

  if (!linkComponent) {
    return (
      <a href={linkProps.to}>
        {card}
      </a>
    );
  }

  // Card with Link:

  const Link = linkComponent;
  const props = linkProps || {};

  return (
    <Link {...props}>
      {card}
    </Link>
  );
};

OrderTypeCard.propTypes = {
  classNames: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  imgComponent: PropTypes.func,
  linkComponent: PropTypes.oneOfType([
    PropTypes.shape({
      render: PropTypes.func
    }),
    PropTypes.func
  ]),
  linkProps: PropTypes.shape(),
  name: PropTypes.string,
  noMargin: PropTypes.bool
};

OrderTypeCard.defaultProps = {
  classNames: null,
  description: null,
  imageUrl: null,
  imgComponent: null,
  linkComponent: null,
  linkProps: {},
  name: null,
  noMargin: false
};

export default OrderTypeCard;
