import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { 
  getUser, logout, selectIsAuthenticated, selectUser, selectDefaultAddress, setDefaultDeliveryAddress, selectShowNavBarAddressModal,
  selectMenuNewOrder, selectMenuNewConcierge, selectUseAddressBasic 
} from 'store/slices/authentication';
import { markNotificationAsSeen, resetNotifications, selectNotifications, selectUnseenNotifications } from 'store/slices/notifications';
import { resetDelivery, setDefaultPickup } from 'store/slices/new-delivery';
import { resetCheckoutData, selectShoppingCartItems } from 'store/slices/shopping-cart';
import { selectLocale } from 'store/slices/locales';
import { setDefaultAddress } from 'store/slices/user-addresses';

import Dropdown from '@youship/components/objects/dropdown';
import OffScreenSlider from '@youship/components/objects/off-screen-slider';

//import AccountDropdown from '@youship/components/account-dropdown';
import AddressModal from '@youship/components/address-modal';
import AddressListModal from '@youship/components/address-list-modal';
import AvatarButton from '@youship/components/objects/avatar-button';
import Button from '@youship/components/objects/button';
import NotificationsDropdown from '@youship/components/notifications-dropdown';
import AccountBar from 'components/account-bar';
import LocaleSelect from 'components/locale-select';
import helper from '@youship/utils/helper';
import locales from '@youship/i18n/locales';

import avatarImage from '@youship/assets/images/avatar.jpg';
import addressIcon from '@youship/assets/images/icons/address.svg';
import logo from '@youship/assets/images/logo.svg';
import menuIcon from '@youship/assets/images/icons/menu.svg';
import notificationsReadIcon from '@youship/assets/images/icons/notifications.svg';
import notificationsUnreadIcon from '@youship/assets/images/icons/notifications-unread.svg';
import orderIcon from '../../images/icons/order.svg';
import closeIcon from '../../images/icons/close.svg';
import bagShopIcon from '../../images/icons/bag-shop.svg';

import './styles.scss';

const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

const DROPDOWN_HORIZONTAL_POSITION_LEFT = 'left';
const DROPDOWN_HORIZONTAL_POSITION_RIGHT = 'right';
const MOBILE_BREAKPOINT = 576;

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Account
  const user = useSelector(selectUser);
  const isAuthenticated     = useSelector(selectIsAuthenticated);
  const isMenuNewOrder      = useSelector(selectMenuNewOrder);
  const isMenuNewConcierge  = useSelector(selectMenuNewConcierge); 
  const isUseAddressBasic   = useSelector(selectUseAddressBasic);

  //dispatched action to show address modal
  const isActionShowNavBarAddressModal = useSelector(selectShowNavBarAddressModal);

  //cart items
  const items = useSelector(selectShoppingCartItems);
  const shoppingCartIsEmpty = !items || !items.length;

  //const address = user?.default_address?.address;
  //to show address on nav bar - se autenticado usa user.defaultAddress e não, guarda em sessão a morada
  const address = useSelector(selectDefaultAddress); //user default address or public default address

  //for AddressModal
  const userAddress = {
    apartmentAddress: address?.apartmentAddress || '',
    city: address?.city || '',
    formattedAddress: address?.formattedAddress || '',
    numberAddress: address?.numberAddress || '',
    postalCode: address?.postalCode || '',
    streetAddress: address?.streetAddress || '',
    addressCode: address?.addressCode || null
  };

  
  const userImageUrl = user?.photo_url;
  const userName     = user?.firstname;
  //const userEmail    = user?.email;

  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);

  const currentPathName = useLocation().pathname;

  const isCurrentPageLogin = currentPathName === '/login';
  const isCurrentPageRegister = currentPathName === '/register';
  const authenticationButtonMessageId = isCurrentPageLogin ? 'header.sign_up' : 'header.sign_in';
  const authenticationLinkPath = isCurrentPageLogin ? '/register' : '/login';
  const authenticationContextMessageId = isCurrentPageLogin ? 'header.sign_up_context' : 'header.sign_in_context';

  // tem efeito qd existir uma alteracao isActionShowNavBarAddressModal
  useEffect(() => {
    console.log(" useEffectisActionShowNavBarAddressModal");
    if( isActionShowNavBarAddressModal ){
      setShowAddressModal(true);
    }
  }, [isActionShowNavBarAddressModal]);

  const handleAccountDropdownClose = () => {
    //console.log("handleAccountDropdownClose");
    setShowAccountDropdown(false);
    setShowLanguageOptionsList(false);

    return true;
  };

  const handleAvatarButtonClick = (e) => {
    e.preventDefault();
    setShowAccountDropdown(true);
  };

  const keepAccountDropdownOpenned = (isMobileMenu) => {
    if(isMobileMenu){
      setShowMobileMenuSlider(true);
    }else{
      setShowAccountDropdown(true);
    }
  };

  const handleLogoutClick = () => {
    setShowMobileMenuSlider(false);
    setShowAccountDropdown(false);
    dispatch(logout())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while trying to logout.');

        return response;
      })
      .then(async (response) => {
        await dispatch(resetCheckoutData());
        await dispatch(resetDelivery());
        await dispatch(resetNotifications());
        history.push('/');

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  // Notifications
  const notifications = useSelector(selectNotifications);
  const unseenNotifications = useSelector(selectUnseenNotifications);

  const notificationsIcon = unseenNotifications && unseenNotifications.length ? notificationsUnreadIcon : notificationsReadIcon;

  const handleNotificationClick = (id) => {
    // To use in combination with the onClick event handler in Notification’s wrapper of PushNotifications, which has some padding
    dispatch(markNotificationAsSeen(id));
  };

  const handleNotificationsDropdownClose = () => {
    setShowNotificationsDropdown(false);
  };

  const handleNotificationsIconClick = () => {
    setShowNotificationsDropdown(true);
  };

  const handleNotificationsIconKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowNotificationsDropdown(true);
    }
  };

  const [showAddressModal, setShowAddressModal] = useState(false);

  const closeAddressModal = () => {
    setShowAddressModal(false);
  };

  const handleAccountDropdownAddAddressClick = () => {
    setShowAccountDropdown(false);
    openAddressModal();
  };

  const handleAddressModalSave = (formattedAddressData) => {

    console.log("-nav bar--handleAddressModalSave----");
    console.log(formattedAddressData);

    //se modal de escolher apenas uma morada da lista
    if( isAuthenticated && isUseAddressBasic && formattedAddressData.addressCode){
      //envia para a API que é morada por default
      dispatchRequestSetDefaultAddress(formattedAddressData.addressCode);
      console.log("dispatchRequestSetDefaultAddress");
    }


    if( isAuthenticated ){
      //se modal de escolher apenas uma morada da lista
      if( isUseAddressBasic && formattedAddressData.addressCode){
        //envia para a API que é morada por default
        dispatchRequestSetDefaultAddress(formattedAddressData.addressCode);
        console.log("dispatchRequestSetDefaultAddress");
      }else{
        //AQUI apenas guarda no state - no address modal é que é enviado pela API
        dispatchLoadUserDefaultAddress();
      }
    }else{
      //console.log("handleAddressModalSave - not autenticated:");
      //console.log(formattedAddressData);
      //apenas guarda no state 
      //state.curDeliveryAddress = address
      dispatch( setDefaultDeliveryAddress({ address: formattedAddressData }) );
    }

    closeAddressModal();
  };

  const dispatchLoadUserDefaultAddress = () => {
    //AQUI apenas guarda no state - no address modal é que é enviado pela API

    dispatch(getUser())
      .then(((response) => {
        //apenas guarda no state - no address modal é que é enviado pela API
        //state.pickups[0]
        console.log("dispatch(setDefaultPickup())");
        dispatch(setDefaultPickup());
        return response;
      }))
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
   
  };

  const handleAddressModalClose = () => {
    closeAddressModal();
  };

  const handleNavbarChangeAddress = () => {
    setShowMobileMenuSlider(false);
    openAddressModal();
  };

  const handleNavbarChangeAddressKeyDown = (event) => {
    if (event.keyCode === 13) {
      openAddressModal();
    }
  };

  const openAddressModal = () => {
    //console.log("userAddress:");
    //console.log(userAddress);
    setShowAddressModal(true);
  };

  // Menu dropdown
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const [horizontalPosition, setHorizontalPosition] = useState(typeof window !== 'undefined' && window.innerWidth < MOBILE_BREAKPOINT ?
    DROPDOWN_HORIZONTAL_POSITION_RIGHT :
    DROPDOWN_HORIZONTAL_POSITION_LEFT);
  const [showLanguageOptionsList, setShowLanguageOptionsList] = useState(false);
  const [showMenuDropdown, setShowMenuDropdown] = useState(false);
  const [showMobileMenuSlider, setShowMobileMenuSlider] = useState(false);
  const languageLocales = Object.values(locales);
  const languageLocalesFormat = languageLocales.map(language => ({
    value: language.path,
    text: language.locale
  }));
  const currentLocaleValue = useSelector(selectLocale);
  const [currentLanguage, setCurrentLanguage] = useState(languageLocalesFormat.find(item => item.value === currentLocaleValue));

  currentLanguage.icon = helper.getFlagByLocale(currentLanguage.value);

  const handleSetCurrentLanguage = (value, icon, text) => {
    setCurrentLanguage({
      value,
      icon,
      text
    });
    
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(false);
    }, 0);
  };

  const handleShowLanguageOptionsList = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(true);
    }, 0);
  };

  const handleHideLanguageOptionsList = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(false);
    }, 0);
  };

  const handleDropdownClose = () => {
    setShowMenuDropdown(false);
  };

  const handleMenuTogglerClick = () => {
    setShowMenuDropdown(true);
  };

  const handleMenuTogglerKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowMenuDropdown(!showMenuDropdown);
    }
  };

  const handleMobileMenuClose = () => {
    setShowMobileMenuSlider(false);
    document.querySelector('body').style.overflowY = 'scroll';
  };

  const handleMobileMenuTogglerClick = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowMobileMenuSlider(true);
    }, 1);
    document.querySelector('body').style.overflowY = 'hidden';
  };

  const handleMobileMenuTogglerKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowMobileMenuSlider(!showMobileMenuSlider);
    }
  };

  const handlePageScroll = () => {
    if (typeof window !== 'undefined' && window.pageYOffset === 0) {
      setIsPageScrolled(false);
    } else if (!isPageScrolled) {
      setIsPageScrolled(true);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handlePageScroll);
    }

    //fix dashboard mobile - navbar width
    //document.querySelector('header.navbar').style.width =  document.querySelector('div.dashboard').offsetWidth + "px";

    return () => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handlePageScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  });

  const handleResize = () => {
    if (typeof window !== 'undefined' && window.innerWidth < MOBILE_BREAKPOINT) {
      setHorizontalPosition(DROPDOWN_HORIZONTAL_POSITION_RIGHT);
    } else {
      setHorizontalPosition(DROPDOWN_HORIZONTAL_POSITION_LEFT);
    }
  };

  const dispatchRequestSetDefaultAddress = (addressCode) => {
    dispatch(setDefaultAddress({
      addressCode: addressCode
    }))
      .then((response) => {
        //Carrega a default addrress do user e coloca no state
        dispatchLoadUserDefaultAddress();
        console.log("resonse setDefaultAddress:");
        console.log(response);
        return response;
      })
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };


  const isSearchAddressOption = !isCurrentPageRegister;//!!user && !isCurrentPageRegister;

  return (
    <>
      <header className={`navbar${!user ? ' navbar--authentication' : ''}`}>
        <div className="container">
          <div className="navbar__content">
            <div className={`navbar__logo-wrapper${!isCurrentPageLogin && !isCurrentPageRegister ? ' navbar__logo-wrapper--center' : ''}`}>
              <Link
                className="navbar__logo-link"
                to="/"
              >
                <img
                  alt="logo"
                  className="navbar__logo"
                  src={logo}
                />
              </Link>
            </div>

            <div className={`navbar__items-container${!isCurrentPageLogin && !isCurrentPageRegister ? ' order-2 order-lg-3' : ' navbar__items-container--left-margin'}`}>
              <div className="navbar__icons-wrapper">
                { !shoppingCartIsEmpty && (
                  <Link
                        className="navbar__orders-link"
                        to="/checkout"
                      >
                    <div className="navbar__icon-wrapper">
                      <img
                        alt="Request Icon"
                        className="navbar__icon"
                        src={bagShopIcon}
                      />
                    </div>
                  </Link>
                )}
                {!user && (
                  <div className="navbar__language-selector d-none d-lg-block">
                    <LocaleSelect />
                  </div>
                )}
                {user && (
                  <>
                    <Link
                      className="navbar__orders-link"
                      to="/orders"
                    >
                      <div className="navbar__icon-wrapper">
                        <img
                          alt="Request Icon"
                          className="navbar__icon"
                          src={orderIcon}
                        />
                      </div>
                    </Link>
                    <div className="navbar__notifications">
                      <div
                        className="navbar__icon-wrapper"
                        role="button"
                        tabIndex={0}
                        onClick={handleNotificationsIconClick}
                        onKeyDown={handleNotificationsIconKeyDown}
                      >
                        <img
                          alt="Notifications Icon"
                          className="navbar__icon"
                          src={notificationsIcon}
                        />
                      </div>
                    </div>
                    <NotificationsDropdown
                      linkComponent={Link}
                      notifications={notifications}
                      show={showNotificationsDropdown}
                      onClose={handleNotificationsDropdownClose}
                      onNotificationClick={handleNotificationClick}
                    />
                  </>
                )}
              </div>

              { /*  PC menu right (Authenticated) */ }      
              {user && (
                <div className="navbar__account-button-wrapper d-none d-lg-block">
                  <AvatarButton
                    imageUrl={userImageUrl}
                    name={userName}
                    text={(
                      <FormattedMessage
                        id="header.greeting"
                        values={{ name: userName }}
                      />
                    )}
                    onClick={handleAvatarButtonClick}
                  />
                  <Dropdown
                    hasBackgroundOverlay
                    horizontalPosition="right"
                    show={showAccountDropdown}
                    onClose={handleAccountDropdownClose}
                  >
                    <AccountBar
                      currentLanguage={currentLanguage}
                      hideLanguageOptionsList={handleHideLanguageOptionsList}
                      isLanguageMenuVisible={showLanguageOptionsList}
                      setCurrentLanguage={handleSetCurrentLanguage}
                      showLanguageOptionsList={handleShowLanguageOptionsList}
                      
                      user={user}
                      onAddAddressClick={handleAccountDropdownAddAddressClick}
                      onClose={handleAccountDropdownClose}
                      onLogoutClick={handleLogoutClick}
                      keepAccountDropdownOpenned={keepAccountDropdownOpenned}
                    />
                  </Dropdown>
                </div>
              )}
              { /*  END: PC menu right (Authenticated) */ }   

              {!user && isAuthenticated && (
                <Button
                  context="primary"
                  largeHorizontalPadding
                  noShadow
                  outline
                  smallVerticalPadding
                  onClick={handleLogoutClick}
                >
                  <FormattedMessage id="header.sign_out" />
                </Button>
              )}
              {!user && !isAuthenticated && (
                <>
                  {isCurrentPageRegister || isCurrentPageLogin ? (
                    <div className="navbar__authentication-options d-none d-lg-flex">
                      <div className="navbar__authentication-options-text">
                        <FormattedMessage id={authenticationContextMessageId} />
                      </div>
                      <Link to={authenticationLinkPath}>
                        <Button
                          context="primary"
                          largeHorizontalPadding
                          noShadow
                          outline
                          small
                        >
                          <FormattedMessage id={authenticationButtonMessageId} />
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div className="navbar__items-container d-none d-lg-block order-lg-3">
                      <Button
                        classNames="navbar__button"
                        linkComponent={Link}
                        linkProps={{ to: '/login' }}
                        noArrow
                        noBackground
                        noNewTab
                        small
                      >
                        <FormattedMessage id="header.sign_in" />
                      </Button>
                      <Button
                        classNames="navbar__button"
                        context="primary"
                        linkComponent={Link}
                        linkProps={{ to: '/register' }}
                        noArrow
                        noNewTab
                        small
                      >
                        <FormattedMessage id="header.sign_up" />
                      </Button>
                    </div>
                  )}
                </>
              )}

            </div>

            { /*  Mobile menu right */ }
            <div className="navbar__menu-container d-lg-none order-3">
              <div className="navbar__menu-wrapper">
                <div
                  className="navbar__menu-toggler"
                  role="button"
                  tabIndex={0}
                  onClick={handleMobileMenuTogglerClick}
                  onKeyDown={handleMobileMenuTogglerKeyDown}
                >
                  <img
                    alt="Menu"
                    className="navbar__menu-icon"
                    src={menuIcon}
                  />
                </div>
                <OffScreenSlider
                  show={showMobileMenuSlider}
                  side="right"
                  onClose={handleMobileMenuClose}
                >
                    { isAuthenticated && (    
                      <AccountBar
                        currentLanguage={currentLanguage}
                        hideLanguageOptionsList={handleHideLanguageOptionsList}
                        isLanguageMenuVisible={showLanguageOptionsList}
                        setCurrentLanguage={handleSetCurrentLanguage}
                        showLanguageOptionsList={handleShowLanguageOptionsList}
                        
                        isMobileMenu={true}
                        handleNavbarChangeAddress={handleNavbarChangeAddress}
                        handleNavbarChangeAddressKeyDown={handleNavbarChangeAddressKeyDown}

                        user={user}
                        onAddAddressClick={handleAccountDropdownAddAddressClick}
                        onClose={handleMobileMenuClose}
                        onLogoutClick={handleLogoutClick}
                        keepAccountDropdownOpenned={keepAccountDropdownOpenned}
                      />    
                    )}

                    { !isAuthenticated && (  
                      <ul className="navbar__menu-list">
                        {showLanguageOptionsList && (
                          <>
                            <li
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-back-chevron"
                              onClick={handleHideLanguageOptionsList}
                            >
                              <FormattedMessage id="header.back" />
                            </li>
                            <LocaleSelect
                              setSelectedLanguage={handleSetCurrentLanguage}
                              showAsList
                            />
                          </>  
                        )}
                        {!showLanguageOptionsList && (
                          <>
                            <div className="navbar__close-icon">
                              <img
                                alt="Close Icon"
                                src={closeIcon}
                                onClick={handleMobileMenuClose}
                                />
                            </div>
                            <a
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-link--orange-text"
                              href="/login"
                            >
                              <FormattedMessage id="header.sign_in" />
                            </a>
                            <a
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--orange-background navbar__mobile-menu-list-link--white-text"
                              href="/register"
                            >
                              <FormattedMessage id="header.sign_up" />
                            </a>
                            <li
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-chevron"
                              onClick={handleShowLanguageOptionsList}
                            >
                              <img
                                alt="Language Icon"
                                className="navbar__mobile-menu-list-item__language-icon"
                                src={currentLanguage?.icon}
                              />
                              <span>
                                {currentLanguage?.text}
                              </span>
                            </li>
                            <a
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                              href={MARKETING_WEBSITE_URL}
                            >
                              <FormattedMessage id="header.navigation_links.go_to_website" />
                            </a>
                          </>
                        )}
                      </ul>
                    )}  
  
                </OffScreenSlider>
              </div>
            </div>
            { /*  END: Mobile menu right */ }

            { /*  PC Menu left */ }              
            <div className="navbar__menu-container d-none d-lg-block order-lg-1">
              <div className="navbar__menu-wrapper">
                {!isCurrentPageRegister && !isCurrentPageLogin && (
                  <>
                    <div
                      className="navbar__menu-toggler"
                      role="button"
                      tabIndex={0}
                      onClick={handleMenuTogglerClick}
                      onKeyDown={handleMenuTogglerKeyDown}
                    >
                      <img
                        alt="Menu"
                        className="navbar__menu-icon"
                        src={menuIcon}
                      />
                    </div>
                    <Dropdown
                      autoWidth
                      className="navbar__menu-dropdown"
                      hasBackgroundOverlay
                      horizontalPosition={horizontalPosition}
                      show={showMenuDropdown}
                      onClose={handleDropdownClose}
                    >
                      <ul className="navbar__menu-list">
                       { isMenuNewOrder && (
                       <li className="navbar__menu-list-item">
                          <a
                            className="navbar__menu-list-link"
                            href='/new-order'
                          >
                            <FormattedMessage id="header.navigation_links.new-order" />
                          </a>
                        </li>
                       )}
                       { isMenuNewConcierge && (
                       <li className="navbar__menu-list-item">
                          <a
                            className="navbar__menu-list-link"
                            href='/new-concierge'
                          >
                            <FormattedMessage id="header.navigation_links.new-concierge" />
                          </a>
                        </li>
                       )}
                        <li className="navbar__menu-list-item">
                          <a
                            className="navbar__menu-list-link"
                            href={MARKETING_WEBSITE_URL}
                          >
                            <FormattedMessage id="header.navigation_links.go_to_website" />
                          </a>
                        </li>
                      </ul>
                    </Dropdown>
                    {isSearchAddressOption && (
                      <div className="navbar__address d-none d-lg-flex">
                        <div className="navbar__icon-wrapper">
                          <img
                            alt="Address Icon"
                            className="navbar__icon"
                            src={addressIcon}
                          />
                        </div>
                        <div
                          className={`navbar__address-text${!address?.streetAddress ? ' navbar__address-text--underline' : ''}`}
                          role="button"
                          /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                          tabIndex={10}
                          onClick={handleNavbarChangeAddress}
                          onKeyDown={handleNavbarChangeAddressKeyDown}
                        >
                          {address?.streetAddress || <FormattedMessage id="header.add_default_address" />}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            { /*  END: PC menu left */ }              

          </div>
        </div>
      </header>
      {showAddressModal && (
        isUseAddressBasic ? (
          <AddressListModal
            currentAddress={userAddress}
            type="user"
            onClose={handleAddressModalClose}
            onSave={handleAddressModalSave}
          />
        ) : (  
          <AddressModal
            currentAddress={userAddress}
            type="user"
            editAddressMode={true}
            onClose={handleAddressModalClose}
            onSave={handleAddressModalSave}
          />
        )
      )}
    </>
  );
};

export default Navbar;
