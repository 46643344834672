import React from 'react';

import ProductsManagementArea from "../components/sections/products-management-area";

const ProductsManagement = () => (
    <>
        <ProductsManagementArea/>
    </>
);

export default ProductsManagement;
