import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import getItemIcon from '@youship/utils/shipment-items-icons';

import { getUser, selectUser } from 'store/slices/authentication';
import {
  setDefaultPickup,
  addDeliveryDropoff,
  addDeliveryPickup,
  addDeliveryEmptyShipment,
  addDeliveryShipmentItem,
  addDeliveryStop,
  addDeliveryStopShipment,
  createDelivery,
  getDeliveryEstimate,
  getDeliveryQuote,
  removeDeliveryDropoff,
  removeDeliveryPickup,
  removeDeliveryShipment,
  removeDeliveryShipmentItem,
  removeDeliveryStop,
  resetDelivery,
  selectDeliveryDistance,
  selectDeliveryDuration,
  selectDeliveryIsLoading,
  selectDeliveryQuote,
  selectDeliveryQuoteIsLoading,
  selectDestinationSchedule,
  selectDropoffs,
  selectHasReturn,
  selectOriginSchedule,
  selectPaymentTypeId,
  selectPickups,
  selectRates,
  selectServiceCode,
  selectShipmentsOptions,
  selectShipments,
  selectShipmentMethod,
  selectStops,
  selectVoucherCode,
  setAddressForDropoff,
  setAddressForPickup,
  setAddressForStop,
  setContactForDropoff,
  setContactForPickup,
  setContactForStop,
  setDeliveryDropoffs,
  setDeliveryHasReturn,
  setDeliveryPickups,
  setDeliveryStops,
  setDestinationScheduleDate,
  setDestinationScheduleTimeSlotValue,
  setNotesForDropoff,
  setNotesForPickup,
  setNotesForStop,
  setOriginScheduleDate,
  setOriginScheduleTimeSlotValue,
  setPaymentTypeId,
  setRequestType,
  setServiceCode, 
  setVoucherCode,
  updateDeliveryShipmentItem,
  removeDeliveryAllShipments,
  updateDeliveryShipmentOptions,
  resetDeliveryStateQuoteData,
  selectDraftRef
} from 'store/slices/new-delivery';

import { DELIVERY_QUOTE_DISTANCE_ERROR_CODE, DELIVERY_VOUCHER_ERROR_CODE } from '@youship/api/delivery';

import ActionModal from 'components/order/action-modal';
import Button from '@youship/components/objects/button';
import DateSelect from 'components/date-select';
import HourSelect from '@youship/components/objects/hour-select';
import Notice from '@youship/components/objects/notice';
import Radio from '@youship/components/objects/radio';
import Tooltip from '@youship/components/objects/tooltip';
import ContactModal from '@youship/components/contact-modal';

import AddressModal from '@youship/components/address-modal';
import AddressListModal from '@youship/components/address-list-modal';
import ShipmentOptionsNotesModal from 'components/new-order/shipment-options/information-modal';
import ShipmentOptionsOption from 'components/new-order/shipment-options/option';
import ShipmentOptionsShipmentDetails from 'components/new-order/shipment-options/shipment-details';
import DiscountModal from 'components/new-order/shipment-options/discount-modal';
import ItemModal from 'components/new-order/shipment-options/item-modal';

import infoGrayIcon from 'images/icons/info-gray.svg';

import locationIcon from 'images/icons/location.svg';
import userIcon from 'images/icons/user.svg';
import noticeIcon from 'images/icons/notice.svg';
import truckIcon from 'images/icons/truck.svg';
import boxIcon from 'images/icons/box.svg';
import paymentIcon from '@youship/utils/payment-icons';
import mapIcon from 'images/icons/map.svg';
import clockIcon from 'images/icons/clock.svg';
import couponIcon from 'images/icons/coupon.svg';
// Not used for now
// import searchIcon from 'images/icons/search.svg';
import fileIcon from 'images/icons/file.svg';
import trashIcon from 'images/icons/trash.svg';
import trashRedIcon from 'images/icons/trash--red.svg';

import './styles.scss';

/*
- Os pickups, dropoffs e stops são guardados em cada index
Cada um deles são guardados os dados: address, contact, notes
pickups[0]{address, contact, notes}

- Os items são guardados em cada index
   shipmentsItems[index][{item 1}, {item2},...]

- As options (reference, charge, chargevalue) são guardados em cada index
  shipmentsOptions[index]{....}

- se hasReturn, o retorno é o mesmo do pickup e os items e options ficam no index=1
shipmentsItems[1][--]  e shipmentsOptions[1]{....}
 ou seja, o retorno, não fica num dropoff ou stop.

 - requestType: delivery, concierge, ride
*/


const formatShipSubtitle = shipment => `${
  shipment.length
}×${
  shipment.width
}×${
  shipment.height
} cm  · 
${
  shipment.weight
} Kg`;


const NewOrderShipmentOptions = ({ onRouteChange, onDeliveryError, onStartLocationChange, onEndLocationChange, requestType }) => {
  const dispatch = useDispatch();
  const intl     = useIntl();
  const user     = useSelector(selectUser);
  const isConcierge = requestType && requestType === 'concierge' ? true : false;

  const isPaymentMandory = isConcierge ? false : true;

  // Default Values
  const defaultCountryCallingCode = (user.phone?.phonecode) ? user.phone?.phonecode.replace(/^\+/, '') : "351";

  const addressDefault = {
    apartmentAddress: '',
    city: '',
    formattedAddress: '',
    numberAddress: '',
    postalCode: '',
    streetAddress: ''
  };

  const searchDefault = {
    search: ''
  };

  const contactDefault = {
    name: '',
    company: '',
    countryCallingCode: defaultCountryCallingCode,
    phone: ''
  };

  const defaultShipmentOptionsValues = {
    reference: '',
    comments: '',
    shipper: '',
    fragile: false,
    return: false,
    handDelivery: false,
    marketing: false,
    chargeValue: '',
    chargeInfo: '',
    statedValue: '',
    insuredValue: ''
  };

  const notesDefault = '';

  // Selectors
  const deliveryDistance = useSelector(selectDeliveryDistance);
  const deliveryDuration = useSelector(selectDeliveryDuration);
  const deliveryQuote = useSelector(selectDeliveryQuote);
  const deliveryQuoteIsLoading = useSelector(selectDeliveryQuoteIsLoading);
  const deliveryHasReturn = useSelector(selectHasReturn);
  const dropoffSchedule = useSelector(selectDestinationSchedule);
  const pickupSchedule = useSelector(selectOriginSchedule);
  const orderIsLoading = useSelector(selectDeliveryIsLoading);
  const paymentTypeId = useSelector(selectPaymentTypeId);
  const dropoffs = useSelector(selectDropoffs);
  const pickups = useSelector(selectPickups);
  const stops = useSelector(selectStops);
  const draftRef = useSelector(selectDraftRef);
  

  const shipmentMethodValue = useSelector(selectShipmentMethod); //shipmentMethodValue: normal, multiple_pickups, ...
  const serviceCode = useSelector(selectServiceCode);
  const discountCode = useSelector(selectVoucherCode);
  
  const shipmentsOptions = useSelector(selectShipmentsOptions); //shipment options adicionados: reference, charge, shipper
  const shipmentsItems = useSelector(selectShipments); //items adicionados
  const shipmentsRates = useSelector(selectRates); //quote rates

  const pickupScheduleDate = pickupSchedule?.pickupDate || null;
  const pickupScheduleTimeSlotValue = pickupScheduleDate ? pickupSchedule?.pickupTimeSlotValue || null : null;
  const paymentMessage = deliveryQuote?.paymentMessage || null;
  const infoMessage = deliveryQuote?.infoMessage || null;

  const paymentTypes = deliveryQuote?.paymentTypes || null;
  const priceDetails = deliveryQuote?.priceDetails || null;
  const priceTotal = deliveryQuote?.total || null;
  const schedulePickupIntervals = deliveryQuote?.schedulePickupIntervals || null;
  const allowedPickupDates = schedulePickupIntervals ? Object.entries(schedulePickupIntervals).map(date => date?.[1].pickupDate) : null;
  const getDeliveryQuoteTimeout = useRef();

  const [deliveryDistanceErrorValue, setDeliveryDistanceErrorValue] = useState(null);
  const [deliveryDurationErrorValue, setDeliveryDurationErrorValue] = useState(null);
  const [deliveryError, setDeliveryError] = useState(false);
  const [pickupHourSelectInstructions, setPickupHourSelectInstructions] = useState('');
  const [pickupTimeSlots, setPickupTimeSlots] = useState([]);

  const showBtnDiscountCode = priceTotal?.svalue || (!priceTotal?.svalue && discountCode);

  //addressTypeSelected - valores: pickup, stop, dropoff
  const [addressTypeSelected, setAddressTypeSelected] = useState(null);
  //index da morada a editar
  const [addressIndexSelected, setAddressIndexSelected] = useState(null);

  //set requestType to state
  useEffect(() => {
    if (requestType) {
      dispatch(setRequestType(requestType));
    }
  }, [dispatch]);
  
  // para manter os pickups, dropoffs, stops atualizados  
  useEffect(() => {
    if (pickups[addressIndexSelected]) {
      dispatch(setDeliveryPickups(pickups));
    }
  }, [dispatch, addressIndexSelected, pickups]);

  useEffect(() => {
    if (dropoffs[addressIndexSelected]) {
      dispatch(setDeliveryDropoffs(dropoffs));
    }
  }, [dispatch, addressIndexSelected, dropoffs]);

  useEffect(() => {
    if (stops[addressIndexSelected]) {
      dispatch(setDeliveryStops(stops));
    }
  }, [dispatch, addressIndexSelected, stops]);
  

  //pre-preenche com a morada do user no pickup ou uma morada vazia
  useEffect(() => {
    if( pickups.length <= 0 ){
      console.log("useEffect setDefaultPickup pickup moradas - AQUI");
      if (user && user?.defaultAddress) {
        dispatch(setDefaultPickup());
      }else{  
        dispatch(addDeliveryPickup({
          address: { ...addressDefault },
          contact: { ...contactDefault },
          search: { ...searchDefault },
          notes: notesDefault
        }));
      }    
    }
  }, [pickups[0]]);

  useEffect(() => {
    if (!dropoffs.length) {
      dispatch(addDeliveryDropoff({
        address: { ...addressDefault },
        contact: { ...contactDefault },
        search: { ...searchDefault },
        notes: notesDefault
      }));
      //dispatch - remove all shipments
      resetShipments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dropoffs.length, searchDefault, user]);

  const filteredServiceRates   = serviceCode ? shipmentsRates?.filter(rate => rate.service?.code === serviceCode) : shipmentsRates;
  const serviceShipmentOptions = serviceCode && filteredServiceRates ? filteredServiceRates[0]?.service?.options : {};
  const serviceName            = serviceCode && filteredServiceRates ? filteredServiceRates[0]?.service?.name : "";

  // --- Items -----------------

  //index do shipmentItems[index]
  const [shipmentIndexSelected, setShipmentIndexSelected] = useState(null);
  //index do item[] dentro do shipmentItems[index]
  //const [shipmentEditIndex, setShipmentEditIndex] = useState(null);
  const [shipmentItemIndexEdit, setShipmentItemIndexEdit] = useState(null);

  const [showItemModal, setShowItemModal] = useState(false);
  const [editItemDataModal, setEditItemDataModal] = useState(null);

  const closeItemModal = () => {
    setShowItemModal(false);
    setEditItemDataModal(null);
  }

  const openItemModal = () => {
    setShowItemModal(true);
  }

 
  const handleAddItemClick = (index) => {
    setShipmentIndexSelected(index);
    //nao é para editar
    setShipmentItemIndexEdit(null);
    setEditItemDataModal(null);
    //open modal
    openItemModal();
  };

  const handleItemEditClick = (index, shipmentItemIndex) => {
    setShipmentIndexSelected(index);
    //edit item
    setShipmentItemIndexEdit(shipmentItemIndex);
    setEditItemDataModal(shipmentsItems[index][shipmentItemIndex]);
    //open modal
    openItemModal();
  };

  const handleItemDeleteClick = (index, shipmentItemIndex) => {
    dispatch(removeDeliveryShipmentItem({ index, shipmentItemIndex }));
    //faz quote feito pelo useEffect do shipmentsItems
  };

  const saveItemModal = (item, editItemIndex) => {
    closeItemModal();
    const isEdit = editItemIndex != null ? true : false;
    if( isEdit ){
      //edit
      dispatch(updateDeliveryShipmentItem({ 
          shipmentIndex: shipmentIndexSelected,
          itemData: item, 
          itemIndex: editItemIndex
        }));  
    }else{
      //the serviceCode
      dispatch(setServiceCode(item.serviceCode));
      //add
      dispatch(addDeliveryShipmentItem({
        shipmentIndex: shipmentIndexSelected,
        itemData: item
      }));
    }
    //faz quote feito pelo useEffect do shipmentsItems
  }

  const resetShipments = () => {
    //remove todos os items e options de todas as orders
    dispatch(removeDeliveryAllShipments());
  }

  // ---- Shipment Options - charge, reference, ..

  const handleShipmentDetailsChange = (type, values, index) => {
    clearTimeout(getDeliveryQuoteTimeout.current);
    let needsNewQuote = false;

    if( values?.chargeValue != shipmentsOptions[index]?.chargeValue ){
      needsNewQuote = true; 
    }else if( values?.fragile != shipmentsOptions[index]?.fragile ){
      needsNewQuote = true; 
    }

    dispatch(updateDeliveryShipmentOptions({ shipmentIndex: index, options: values }))

    if( needsNewQuote ){
      getDeliveryQuoteTimeout.current = setTimeout(() => {
        //console.log("needsNewQuote - ispatchRequestDeliveryQuote");
        doDeliveryQuote();
      }, 1000);
    }
  };

  const isShipmentFilled = (index) => {
    return (shipmentsItems[index]?.length > 0 );
  };

  const isShipmentOptionsFilled = (index) => {
    let isShipmentValid = true;
    //shipmentsOptions  
    if ( shipmentsItems[index]?.length > 0 && serviceShipmentOptions) {
      console.log(serviceShipmentOptions);
      
      if (serviceShipmentOptions.hasShipper) {
        if( isShipmentValid ){
          isShipmentValid = !! (shipmentsOptions[index]?.shipper && shipmentsOptions[index]?.shipper.trim() !== "" );
        }
      }
      if (serviceShipmentOptions.hasReference) {
        if( isShipmentValid ){
          isShipmentValid = !! (shipmentsOptions[index]?.reference && shipmentsOptions[index]?.reference.trim() !== "" );
        }
      }
      
      if (serviceShipmentOptions.hasCharge) {
        if (shipmentsOptions[index]?.charge && shipmentsOptions[index]?.charge === 'yes') {
          if( isShipmentValid ){
            isShipmentValid = !! (shipmentsOptions[index]?.chargeValue && shipmentsOptions[index]?.chargeValue.trim() !== "" ) ;
          }
        }
      }
    }

    console.log("isShipmentValid Options="+isShipmentValid);

    return isShipmentValid;
  };

  //---- Flags for validations  
  
  const existsPickupAddress    = index => !!pickups[index]?.address && !!pickups[index]?.address?.streetAddress;  
  const isPickupAddressFilled  = index => !!pickups[index]?.address?.streetAddress?.trim() && !!pickups[index]?.address?.city?.trim() && !!pickups[index]?.address?.countryCode?.trim();  
  const isPickupContactFilled  = index => !!pickups[index]?.contact?.name && !!pickups[index]?.contact?.phone;
  const arePickupNotesFilled   = index => !!pickups[index]?.notes;

  const existsDropoffAddress   = index => !!dropoffs[index]?.address && !!dropoffs[index]?.address?.streetAddress;
  const isDropoffAddressFilled = index => !!dropoffs[index]?.address?.streetAddress?.trim() && !!dropoffs[index]?.address?.city?.trim() && !!dropoffs[index]?.address?.countryCode?.trim();
  const isDropoffContactFilled = index => !!dropoffs[index]?.contact?.name && !!dropoffs[index]?.contact?.phone;
  const areDropoffNotesFilled  = index => !!dropoffs[index]?.notes;  
  
  const existsStopAddress      = index => !!stops[index]?.address && !!stops[index]?.address?.streetAddress;
  const isStopAddressFilled    = index => !!stops[index]?.address?.streetAddress?.trim() && !!stops[index]?.address?.city?.trim() && !!stops[index]?.address?.countryCode?.trim();
  const isStopContactFilled    = index => !!stops[index]?.contact?.name && !!stops[index]?.contact?.phone;
  const areStopNotesFilled     = index => !!stops[index]?.notes;

  const isDataValidForQuote = (serviceCode && pickups.length > 0 && dropoffs.length &&
      pickups.every((pickup, index) => isPickupAddressFilled(index)) &&
      dropoffs.every((dropoff, index) => isDropoffAddressFilled(index)) &&
      shipmentsItems.length > 0 &&
      shipmentsItems.every((shipmentItem, index) => isShipmentFilled(index)) 
      );

  const isDataValidForAllShipmentSection = isDataValidForQuote && 
      pickups.every((pickup, index) => 
        isPickupContactFilled(index) &&
        isShipmentOptionsFilled(index) 
      ) &&
      dropoffs.every((dropoff, index) => 
        isDropoffContactFilled(index) &&
        isShipmentOptionsFilled(index)
      );

  const showMandatoryFieldsInfo   = (!isDataValidForQuote || !isDataValidForAllShipmentSection);
  const isScheduleSectionDisabled = showMandatoryFieldsInfo;
  const isPaymentSectionDisabled  = showMandatoryFieldsInfo;

  const isOrderValid = isDataValidForQuote && isDataValidForAllShipmentSection && (isPaymentMandory ? paymentTypeId : true);
  const disabled     = !deliveryQuote || deliveryQuoteIsLoading || orderIsLoading;

  //Schedule

  const dropoffScheduleDate = dropoffSchedule?.dropoffDate || null;
  const dropoffScheduleTimeSlotValue = dropoffScheduleDate ? dropoffSchedule?.dropoffTimeSlotValue || null : null;
  const [dropoffHourSelectInstructions, setDropoffHourSelectInstructions] = useState('');
  const [dropoffTimeSlots, setDropoffTimeSlots] = useState([]);
  const scheduleDropoffIntervals = deliveryQuote?.scheduleDropoffIntervals || null;
  const allowedDropoffDates = scheduleDropoffIntervals ? Object.entries(scheduleDropoffIntervals).map(date => date?.[1].dropoffDate) : null;
  
  
  // Payment
  const handleSelectedPaymentOption = (selected) => {
    dispatch(setPaymentTypeId(selected));
  };

  // Discount
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [discountCodeError, setDiscountCodeError] = useState(null);

  const handleDiscountCodeSave = (value) => {
    //console.log("setVoucherCode=" + value);
    dispatch( setVoucherCode(value) );
    doDeliveryQuote();
  };

  const openDiscountModal = () => {
    setShowDiscountModal(true);
  };

  const closeDiscountModal = () => {
    setShowDiscountModal(false);
  };

  // --- For multiple pickups/droppoffs ----

  const [hasReturn, setHasReturn] = useState(false);

  useEffect(() => {
    setHasReturn(deliveryHasReturn);
  }, [deliveryHasReturn]);

  const handleAddReturnButtonClick = () => {
    dispatch(setDeliveryHasReturn({ hasReturn: true, shipmentsItems }));
    doDeliveryQuote();
    setHasReturn(true);
  };

  const handleReturnDeleteButtonClick = () => {
    dispatch(setDeliveryHasReturn({ hasReturn: false, shipmentsItems }));
    doDeliveryQuote();
    setHasReturn(false);
  };

  const handleCreateDeliveryButtonClick = () => {
    //console.log("handleCreateDeliveryButtonClick");
    dispatchRequestDeliveryCreate();
  };

  const handleAddAnotherPickupButtonClick = () => {
    addAnotherPickup();
  };

  const addAnotherPickup = () => {
    dispatch(addDeliveryPickup({
      address: { ...addressDefault },
      contact: { ...contactDefault },
      search: { ...searchDefault },
      notes: notesDefault
    }));
    dispatch(addDeliveryEmptyShipment());
    doDeliveryQuote();
  };

  const handleAddAnotherDropoffButtonClick = () => {
    addAnotherDropoff();
  };

  const addAnotherDropoff = () => {
    dispatch(addDeliveryDropoff({
      address: { ...addressDefault },
      contact: { ...contactDefault },
      search: { ...searchDefault },
      notes: notesDefault
    }));
    dispatch(addDeliveryEmptyShipment());
    doDeliveryQuote();
  };

  const handleAddAnotherStopButtonClick = () => {
    addAnotherStop();
  };

  const addAnotherStop = () => {
    dispatch(addDeliveryStop({
      address: { ...addressDefault },
      contact: { ...contactDefault },
      search: { ...searchDefault },
      notes: notesDefault
    }));
    dispatch(addDeliveryStopShipment());
    doDeliveryQuote();
  };

  const handlePickupDeleteButtonClick = (index) => {
    dispatch(removeDeliveryPickup(index));
    dispatch(removeDeliveryShipment(index));
    doDeliveryQuote();
  };

  const handleStopDeleteButtonClick = (index) => {
    dispatch(removeDeliveryStop(index));
    dispatch(removeDeliveryShipment(index));
    doDeliveryQuote();
  };

  const handleDropoffDeleteButtonClick = (index) => {
    dispatch(removeDeliveryDropoff(index));
    dispatch(removeDeliveryShipment(index));
    doDeliveryQuote();
  };
 

  //--- Tooltip
  const [showTotalInfoTooltip, setShowTotalInfoTooltip] = useState(false);

  const handleTotalInfoMouseEnter = () => {
    setShowTotalInfoTooltip(true);
  };

  const handleTotalInfoTooltipClose = () => {
    closeTotalInfoTooltipClose();
  };

  const closeTotalInfoTooltipClose = () => {
    setShowTotalInfoTooltip(false);
  };


  //--- Notes ---

  const [notesModalNotes, setNotesModalNotes] = useState('');
  const [showNotesModal, setShowNotesModal] = useState(false);

  const handleAddNotesButtonClick = (type, index) => {
    //usa o index das moradas
    setAddressTypeSelected(type);
    setAddressIndexSelected(index);
    openNotesModal(type, index);
  };

  const openNotesModal = (type, index) => {
    let notes = '';
    if (type === 'pickup') {
      notes = pickups[index].notes;
    } else if (type === 'stop') {
      notes = stops[index].notes;
    } else if (type === 'dropoff') {
      notes = dropoffs[index].notes;
    }
    setNotesModalNotes(notes);
    setShowNotesModal(true);
  };

  const handleNotesModalSave = (notes) => {
    if (addressTypeSelected === 'pickup') {
      dispatch(setNotesForPickup({ notes: notes, index: addressIndexSelected }));
    } else if (addressTypeSelected === 'stop') {
      dispatch(setNotesForStop({ notes: notes, index: addressIndexSelected }));
    } else if (addressTypeSelected === 'dropoff') {
      dispatch(setNotesForDropoff({ notes: notes, index: addressIndexSelected }));
    }
    closeNotesModal();
  };

  const handleNotesModalClose = () => {
    closeNotesModal();
  };

  const closeNotesModal = () => {
    setShowNotesModal(false);
    //setNotesTypeSelected(null);
    setAddressTypeSelected(null);
    setNotesModalNotes('');
  };

  
  // --- Addresses ------
  const [addressModalAddress, setAddressModalAddress] = useState(addressDefault);
  const [searchModalAddress, setSearchModalAddress] = useState(searchDefault);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showAddressModalConfirmation, setShowAddressModalConfirmation] = useState(false);

  const [modalContact, setModalContact] = useState(contactDefault);
  
  const handleAddAddressButtonClick = (type, index) => {
    setAddressTypeSelected(type);
    setAddressIndexSelected(index);

    openAddressModal(type, index);
  };

  const openAddressModal = (type, index) => {
    let address = '';
    let search = '';

    if (type === 'pickup') {
      address = pickups[index].address;
      search = pickups[index].search;
    } else if (type === 'stop') {
      address = stops[index].address;
      search = stops[index].search;
    } else if (type === 'dropoff') {
      address = dropoffs[index].address;
      search = dropoffs[index].search;
    }

    setAddressModalAddress(address);
    setSearchModalAddress(search);
    setShowAddressModal(true);
  };

  const openAddressModalConfirmation = (address, contact, search) => {
    setShowAddressModalConfirmation(true);
    setAddressModalAddress(address);
    setModalContact(contact);
    setSearchModalAddress(search);
  };

  //limpar items relacionados com a morada
  const handleAddressModalConfirmation = () => {
    //close modals
    closeAddressModal();
    closeAddressModalConfirmation();
    //limpa o shipment (items + options) relacionados com esta morada
    dispatch(removeDeliveryShipment({index: addressIndexSelected}));
    //guarda dados
    saveAddressModal(addressModalAddress, searchModalAddress);
    //e faz novo quote
    doDeliveryQuote();
  };
  
  const handleAddressModalSelect = (address, contact, search) => {
    if (isDataValidForQuote) {
      //se tem dados validos para fazer quote, então perguntar se quer limpar os items e aceitar a nova morada
      openAddressModalConfirmation(address, contact, search);
    } else {
      //se nao, adiciona a morada normalmente
      //close modals
      closeAddressModal();
      closeAddressModalConfirmation();
      //e guarda dados
      saveAddressModal(address, contact, search);
    }
  };

  const saveAddressModal = (address, contact, search) => {
    if (addressTypeSelected === 'pickup') {
      onStartLocationChange(address);
      dispatch(setAddressForPickup({ index: addressIndexSelected, address: address, search: search }));
      if (contact) dispatch(setContactForPickup({ contact: contact, index: addressIndexSelected }));
    } else if (addressTypeSelected === 'stop') {
      onEndLocationChange(address);
      dispatch(setAddressForStop({ index: addressIndexSelected, address: address, search: search }));
      if (contact) dispatch(setContactForStop({contact: contact, index: addressIndexSelected  }));
    } else if (addressTypeSelected === 'dropoff') {
      onEndLocationChange(address);
      dispatch(setAddressForDropoff({index: addressIndexSelected, address: address, search: search }));
      if (contact) dispatch(setContactForDropoff({ contact: contact, index: addressIndexSelected  }));
    }
  
  };

  const handleAddressModalClose = () => {
    closeAddressModal();
  };

  const closeAddressModal = () => {
    setShowAddressModal(false);
    setAddressTypeSelected(null);
    setAddressModalAddress({});
  };

  const closeAddressModalConfirmation = () => {
    setShowAddressModalConfirmation(false);
  };

  const formatAddressDescription = (address) => {
    const addressOptionsArray = [
      address?.numberAddress || null,
      address?.apartmentAddress || null,
      address?.postalCode || null,
      address?.city || null
    ];
    const formattedAddress = addressOptionsArray.filter(Boolean).join(' • ');
    return formattedAddress;
  };

  //-- Contact -----

  const [showContactModal, setShowContactModal] = useState(false);

  const handleAddContactButtonClick = (type, index) => {
    setAddressTypeSelected(type);
    setAddressIndexSelected(index);
    let contact = '';
    if (type === 'pickup') {
      contact = pickups[index].contact;
    } else if (type === 'stop') {
      contact = stops[index].contact;
    } else if (type === 'dropoff') {
      contact = dropoffs[index].contact;
    }

    setModalContact(contact ? contact : contactDefault);
    setShowContactModal(true);
  };

  const handleContactModalClose = () => {
    setShowContactModal(false);
    setModalContact(null);
  };

  const handleContactModalSave = (contact) => {
    const index = addressIndexSelected;
    const type  = addressTypeSelected;
    
    if (type === 'pickup') {
      dispatch(setContactForPickup({ contact: contact, index: index }));
    } else if (type === 'dropoff') {
      dispatch(setContactForDropoff({ contact: contact, index: index}));
    } else if (type === 'stop') {
      dispatch(setContactForStop({ contact: contact, index: index}));
    }else{
      console.log("invalid type="+type);
    }
    
    handleContactModalClose();
  };

  // -- Dates

  const handlePickupDateSelectChange = (value) => {
    const formattedDate      = moment(value).format('YYYY-MM-DD');
    const isAfterDropoffDate = moment(formattedDate).isAfter(dropoffScheduleDate);

    dispatch(setOriginScheduleDate(formattedDate));
    /*
    if (isAfterDropoffDate) {
      dispatch(setDestinationScheduleDate(formattedDate));
    }
    */
    console.log("handlePickupDateSelectChange");
    dispatch(setOriginScheduleTimeSlotValue(null));
    dispatch(setDestinationScheduleDate(null));
    dispatch(setDestinationScheduleTimeSlotValue(null));

    doDeliveryQuote();
  };

  const handlePickupHourSelectChange = (value) => {
    
    dispatch(setOriginScheduleTimeSlotValue(value));

    console.log("handlePickupHourSelectChange");
    dispatch(setDestinationScheduleDate(null));
    dispatch(setDestinationScheduleTimeSlotValue(null));

    doDeliveryQuote();
  };

  const handleDropoffDateSelectChange = (value) => {
    const formattedDate      = moment(value).format('YYYY-MM-DD');
    const isBeforePickupDate = moment(formattedDate).isBefore(dropoffScheduleDate);

    dispatch(setDestinationScheduleDate(formattedDate));
    
    if (isBeforePickupDate){
      dispatch(setOriginScheduleDate(formattedDate));
      dispatch(setDestinationScheduleDate(null));
    }
    
    dispatch(setDestinationScheduleTimeSlotValue(null));

    doDeliveryQuote();
  };

  const handleDropoffHourSelectChange = (value) => {
    dispatch(setDestinationScheduleTimeSlotValue(value));
    doDeliveryQuote();
  };

  useEffect(() => {
    if (schedulePickupIntervals) {
      if (pickupScheduleDate) {
        const schedulePickupInterval = schedulePickupIntervals.find(interval => interval.pickupDate === pickupScheduleDate);

        if (schedulePickupInterval) {
          setPickupTimeSlots(schedulePickupInterval.pickupTimeSlots);
          setPickupHourSelectInstructions( intl.formatMessage({ id: 'shop.checkout.schedule_select_time' }) );
        } else {
          setPickupTimeSlots([]);
          setPickupHourSelectInstructions(intl.formatMessage({ id: 'shop.checkout.schedule_empty_slots' }));
        }
      } else {
        setPickupTimeSlots([]);
        setPickupHourSelectInstructions(intl.formatMessage({ id: 'shop.checkout.schedule_select_date' }));
      }
    }
  }, [pickupScheduleDate, schedulePickupIntervals]);

  useEffect(() => {
    if (scheduleDropoffIntervals) {
      if (dropoffScheduleDate) {
        const scheduleDropoffInterval = scheduleDropoffIntervals.find(interval => interval.dropoffDate === dropoffScheduleDate);
        if (scheduleDropoffInterval) {
          setDropoffTimeSlots(scheduleDropoffInterval.dropoffTimeSlots);
          setDropoffHourSelectInstructions(intl.formatMessage({ id: 'shop.checkout.schedule_select_time' }));
        } else {
          setDropoffTimeSlots([]);
          setDropoffHourSelectInstructions(intl.formatMessage({ id: 'shop.checkout.schedule_empty_slots' }));
        }
      } else {
        setDropoffTimeSlots([]);
        setDropoffHourSelectInstructions(intl.formatMessage({ id: 'shop.checkout.schedule_select_date' }));
      }
    }
  }, [dropoffScheduleDate, scheduleDropoffIntervals]);

  const handleDeliveryQuoteError = (error) => {
    if (error?.errorcode === DELIVERY_QUOTE_DISTANCE_ERROR_CODE) {
      setDeliveryError(true);
      setDeliveryDistanceErrorValue(error.resume?.distance);
      setDeliveryDurationErrorValue(error.resume?.duration);
    }else if (error?.errorcode === DELIVERY_VOUCHER_ERROR_CODE) {
      setDeliveryError(true);
    }else{
      //limpar schedule, pois o pedido não devolve dados com os intervalos, e o user fica impedido de escolher novas datas
      clearSchedule();
    }
  };

  const clearSchedule = () => {
    //console.log("--clearSchedule---");
    dispatch(setDestinationScheduleDate(null));
    dispatch(setDestinationScheduleTimeSlotValue(null));
    dispatch(setOriginScheduleDate(null));
    dispatch(setOriginScheduleTimeSlotValue(null));
  };
  
  //callback for map route
  const mapPickups = pickups.filter( item => item?.address?.lat && item?.address?.lng ).map(pickup => ({
    city: pickup?.address?.city,
    lat: pickup?.address?.lat,
    lng: pickup?.address?.lng,
    postalCode: pickup?.address?.postalCode,
    streetAddress: pickup?.address?.streetAddress
  }));

  const mapStops = stops.filter( item => item?.address?.lat && item?.address?.lng ).map(stop => ({
    city: stop.address?.city,
    lat: stop.address?.lat,
    lng: stop.address?.lng,
    postalCode: stop.address?.postalCode,
    streetAddress: stop.address?.streetAddress
  }));

  const mapDropoffs = dropoffs.filter( item => item?.address?.lat && item?.address?.lng ).map(dropoff => ({
    city: dropoff?.address?.city,
    lat: dropoff?.address?.lat,
    lng: dropoff?.address?.lng,
    postalCode: dropoff?.address?.postalCode,
    streetAddress: dropoff?.address?.streetAddress
  }));

  //.filter(rate => rate.service?.code === serviceCode)

  useEffect(() => {
    console.log("useEffect onRouteChange");
    onRouteChange({
      pickups: mapPickups,
      stops: mapStops,
      dropoffs: mapDropoffs
    });
    //se usar mapPickups, mapStops, mapDropoffs, esta sempre a fazer o dispach por casa do map em cima
    // [mapPickups, mapStops, mapDropoffs]);
  }, [dispatch, pickups, stops, dropoffs]);


  // useEffect for estimate e quotes
  useEffect(() => {
    console.log("useEffect - dispatchRequestDeliveryEstimate?");
    console.log("draftRef=" + draftRef);

    if (pickups[0]?.address && dropoffs[0]?.address) {
      if (pickups[0].address.lng && pickups[0].address.lat && dropoffs[0].address.lng && dropoffs[0].address.lat ) {
        dispatchRequestDeliveryEstimate();
      }
    }
  }, [pickups[0]?.address, dropoffs[0]?.address ]);

  useEffect(() => {
    if (isDataValidForQuote) {
      doDeliveryQuote();
    }
  }, []);

  //se existe alguma alteracao no items, deve fazer o quote
  //feito aqui e nao apos as ações de add item, remve, etc,.. para pode validar o pedido antes
  //pois o shipmentsItems não fica atualizado na sequencia da ação no redux
  useEffect(() => {
    doDeliveryQuote(); 
  }, [shipmentsItems]);
  
  const doDeliveryQuote = () => {
      if( isDataValidForQuote ){
        dispatchRequestDeliveryQuote();
      }else{
        //limpa preços e infos do deliveryQuote state
        console.log("clean quote data - resetDeliveryStateQuoteData")
        dispatch(resetDeliveryStateQuoteData());
      }
  }

  /*
    ----------------- Delivery API dispatch -----------------------------------------------
  */
  const dispatchRequestDeliveryEstimate = () => {

    console.log("dispatchRequestDeliveryEstimate");
    
    //selectDraftRef

    let pickupAddress  = pickups[0].address;
    let dropoffAddress = dropoffs[0].address;

    dispatch(getDeliveryEstimate({ dropoff: dropoffAddress, pickup: pickupAddress }))
    .then((response) => {
      if (response?.payload?.success) return response.payload;
      throw response; // vai para o catch
    })
    .then((response) => {
      console.log(response);
      if (response?.error) {
        console.log("Error");
        if (response.payload) {
          //response.payload.message
          onDeliveryError(response.payload);
          throw new Error(response.payload.message || 'Something went wrong while requesting an estimate for your delivery.');
        } else {
          throw new Error(response.message || 'Something went wrong while requesting an estimate for your delivery.');
        }
      }
      return response;
    })
    .catch((error) => {
      console.log("Estimate error:");
      console.log(error);
      onDeliveryError(error?.payload?.message || error?.error?.message || error);
      setDeliveryError(true);
    });
  };

  const dispatchRequestDeliveryQuote = () => {
    onDeliveryError(null);
    setDeliveryDistanceErrorValue(false);
    setDeliveryDurationErrorValue(false);
    setDeliveryError(false);

    setDiscountCodeError(null);

    console.log("dispatchRequestDeliveryQuote");

    dispatch(getDeliveryQuote())
      .then((response) => {
        
        if (response?.error) {
          if (response.payload) {
            handleDeliveryQuoteError(response.payload);
            onDeliveryError(response.payload);
            throw new Error(response.payload.message || 'Something went wrong while requesting a quote for your delivery.');
          } else {
            onDeliveryError(response.error);
            setDeliveryError(false);
            throw new Error(response.message || 'Something went wrong while requesting a quote for your delivery.');
          }
        }else if(response?.payload ){

          if (response?.payload?.errorcode ){
            handleDeliveryQuoteError(response.payload);
            onDeliveryError(response.payload);

            let payload = response?.payload;
            if (payload?.errorcode === DELIVERY_VOUCHER_ERROR_CODE) {
                //mensagem para o lodal
                setDiscountCodeError(payload?.message || intl.formatMessage({ id: 'new-order.discount_code.invalid' }) );
                //limpar discountCode
                //setModalDiscountCode(null);
                dispatch( setVoucherCode(null) );
            }
          }else{
            //caso tenha a modal do vlucher aberta
            setShowDiscountModal(false); 
          }
        
        }

        return response;
      })
      .catch((error) => {
        console.log("Quote error:");
        console.log(error);
        onDeliveryError(error?.payload?.message || error?.error?.message || error);
        setDeliveryError(true);
        ///limpar schedule, pois o pedido não devolve dados com os intrevalos, e o user fica impedido de escolher novas datas
        clearSchedule();

      });
  };

  const dispatchRequestDeliveryCreate = () => {
    
    console.log("dispatchRequestDeliveryCreate");

    dispatch(createDelivery())
      .then((response) => {
        if (response?.payload?.success) return response.payload;
        throw response; // vai para o catch
      })
      .then((response) => {
        const { orderCode, paymentUrl } = response;

        if (orderCode) {

          dispatch(resetDelivery());
          
          if (paymentUrl) {
            //history.replace(`/order/${orderCode}/cpay`);
            //window.location = `${paymentUrl}&ys_return_url=${window.location}`;
            const paymentUrlWithReturn = `${paymentUrl}&ys_return_url=${origin}/order/${orderCode}/cpay`;
            window.location.replace(paymentUrlWithReturn);

          } else {
            //history.push(`/order/${orderCode}`);
            window.location.replace(`/order/${orderCode}`);
          }
         
        }

        return response;
      })
      .catch((error) => {
        console.log("Create error:");
        console.log(error);
        onDeliveryError(error?.payload?.message || error?.error?.message || error);
        setDeliveryError(true);
      });
  };

  return (
    <>
      <div className="new-order-shipment-options__route">
        {pickups.map((pickup, index) => (
          <div className="new-order-shipment-options__route__point">
          <div
            key={index}
            className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--start"
          >
            <h3 className="new-order-shipment-options__title">
              <FormattedMessage id="new-order.pickup.title" />
              {index !== 0 && ` #${index + 1}`}
              {' '}
              *
              {shipmentMethodValue === 'multiple_pickups' && index !== 0 && (
                <img
                  alt="Delete"
                  className="new-order-shipment-options__delete-icon"
                  src={trashIcon}
                  onClick={() => handlePickupDeleteButtonClick(index)}
                  onMouseOut={(event) => {
                    event.currentTarget.src = trashIcon;
                  }}
                  onMouseOver={(event) => {
                    event.currentTarget.src = trashRedIcon;
                  }}
                />
              )}
            </h3>
            <div className="new-order-shipment-options__description">
              <FormattedMessage id="new-order.pickup.description" />
            </div>
            <div className="new-order-shipment-options__action">
              {!existsPickupAddress(index) ? (
                <Button
                  block
                  highlightedBorder={!isPickupAddressFilled(index)}
                  icon={truckIcon}
                  light
                  lightBorder
                  outline
                  text={intl.formatMessage({ id: 'new-order.pickup.add_address' })}
                  onClick={() => handleAddAddressButtonClick('pickup', index)}
                />
              ) : (
                <div>
                  <ShipmentOptionsOption
                    description={formatAddressDescription(pickup.address)}
                    hasError={!isPickupAddressFilled(index)}
                    icon={locationIcon}
                    title={pickup.address.streetAddress}
                    onEdit={() => handleAddAddressButtonClick('pickup', index)}
                  />
                </div>
              )}
            </div>
            {existsPickupAddress(index) && (
              <div className="new-order-shipment-options__action">
                {!isPickupContactFilled(index) ? (
                  <Button
                    block
                    highlightedBorder={!isPickupContactFilled(0)}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.pickup.add_contact' })}
                    onClick={() => handleAddContactButtonClick('pickup', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={`+${pickup.contact.countryCallingCode} ${pickup.contact.phone}`}
                    icon={userIcon}
                    title={pickup.contact.name}
                    onEdit={() => handleAddContactButtonClick('pickup', index) }
                  />
                )}
              </div>
            )}
            {isPickupContactFilled(index) && (
              <div className="new-order-shipment-options__action">
                {!arePickupNotesFilled(index) ? (
                  <Button
                    block
                    icon={fileIcon}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.pickup.add_notes' })}
                    onClick={() => handleAddNotesButtonClick('pickup', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={pickup?.notes || ''}
                    icon={fileIcon}
                    title={intl.formatMessage({ id: 'new-order.pickup.notes' })}
                    onEdit={() => handleAddNotesButtonClick('pickup', index)}
                  />
                )}
              </div>
            )}
            {shipmentMethodValue === 'multiple_pickups' && (
              <div className="new-order-shipment-options__shipment-wrapper">
                <h3 className="new-order-shipment-options__title">
                  <FormattedMessage id="new-order.shipment.title" />
                </h3>
                <div className="new-order-shipment-options__description">
                  <FormattedMessage id="new-order.shipment.description" />
                </div>
                {Array.isArray(shipmentsItems[index]) && shipmentsItems[index].map((shipment, shipmentItemIndex) => (
                  <div
                    key={shipmentItemIndex}
                    className="new-order-shipment-options__action"
                  >
                    <ShipmentOptionsOption
                      deletable
                      description={formatShipSubtitle(shipment)}
                      icon={getItemIcon(shipment.icon)}
                      quantity={shipment.quantity}
                      title={shipment.description || shipment.name}
                      onDelete={() => handleItemDeleteClick(index, shipmentItemIndex)}
                      onEdit={() => handleItemEditClick(index, shipmentItemIndex)}
                    />
                  </div>
                ))}
                <div className="new-order-shipment-options__action">
                  <div className="row">
                    <div className="col col-12">
                      <Button
                        block
                        highlightedBorder={!isShipmentFilled(0)}
                        icon={boxIcon}
                        light
                        lightBorder
                        outline
                        // text={`Add Shipment${!shipmentsItems[index]?.length ? ' *' : ''}`}
                        text={shipmentsItems[index]?.length ?
                          intl.formatMessage({ id: 'new-order.shipment.add_shipment_more' }) :
                          intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                        onClick={() => handleAddItemClick(index)}
                      />
                    </div>
                    {/* Hidden for now */}
                    {/* <div className="col col-6">
                      <Input
                        backgroundImage={searchIcon}
                        block
                        inputId="search-reference"
                        placeholder="Search by reference"
                      />
                    </div> */}
                  </div>
                </div>
                {Array.isArray(shipmentsItems[index]) && !!shipmentsItems[index].length && (
                  <ShipmentOptionsShipmentDetails
                    details={serviceShipmentOptions || {}}
                    values={shipmentsOptions[index] || {}}
                    onChange={values => handleShipmentDetailsChange('pickup', values, index)}
                  />
                )}
              </div>
            )}
          </div>
          </div>
        ))}

        {shipmentMethodValue === 'multiple_pickups' && (
          <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
            <div
              className="new-order-shipment-options__add-option"
              onClick={() => handleAddAnotherPickupButtonClick()}
            >
              <FormattedMessage id="new-order.stop.add_pickup" />
            </div>
          </div>
        )}

        {stops.map((stop, index) => (
          <div className="new-order-shipment-options__route__point">
          <div
            key={index}
            className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--middle"
          >
            <h3 className="new-order-shipment-options__title">
              <FormattedMessage id="new-order.stop.title" />
              {index !== 0 && ` #${index + 1}`}
              {' '}
              *
              {shipmentMethodValue === 'multiple_stops' && index !== 0 && (
                <img
                  alt="Delete"
                  className="new-order-shipment-options__delete-icon"
                  src={trashIcon}
                  onClick={() => handleStopDeleteButtonClick(index)}
                  onMouseOut={(event) => {
                    event.currentTarget.src = trashIcon;
                  }}
                  onMouseOver={(event) => {
                    event.currentTarget.src = trashRedIcon;
                  }}
                />
              )}
            </h3>
            <div className="new-order-shipment-options__description">
              <FormattedMessage id="new-order.stop.description" />
            </div>
            <div className="new-order-shipment-options__action">
              {!existsStopAddress(index) ? (
                <Button
                  block
                  icon={truckIcon}
                  light
                  lightBorder
                  outline
                  text={intl.formatMessage({ id: 'new-order.stop.add_address' })}
                  onClick={() => handleAddAddressButtonClick('stop', index)}
                />
              ) : (
                <div>
                  <ShipmentOptionsOption
                    description={formatAddressDescription(stop.address)}
                    icon={locationIcon}
                    title={stop.address.streetAddress}
                    hasError={!isStopAddressFilled(index)}
                    onEdit={() => handleAddAddressButtonClick('stop', index)}
                  />
                </div>
              )}
            </div>
            {existsStopAddress(index) && (
              <div className="new-order-shipment-options__action">
                {!isStopContactFilled(index) ? (
                  <Button
                    block
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.stop.add_contact' })}
                    onClick={() => handleAddContactButtonClick('stop', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={`+${stop.contact.countryCallingCode} ${stop.contact.phone}`}
                    icon={userIcon}
                    title={stop.contact.name}
                    onEdit={() => handleAddContactButtonClick('stop', index)}
                  />
                )}
              </div>
            )}
            {isStopContactFilled(index) && (
              <div className="new-order-shipment-options__action">
                {!areStopNotesFilled(index) ? (
                  <Button
                    block
                    icon={fileIcon}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.stop.add_notes' })}
                    onClick={() => handleAddNotesButtonClick('stop', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={stop?.notes || ''}
                    icon={fileIcon}
                    title={intl.formatMessage({ id: 'new-order.stop.notes' })}
                    onEdit={() => handleAddNotesButtonClick('stop', index)}
                  />
                )}
              </div>
            )}
            <div className="new-order-shipment-options__shipment-wrapper">
              <h3 className="new-order-shipment-options__title">
                <FormattedMessage id="new-order.shipment.title" />
              </h3>
              <div className="new-order-shipment-options__description">
                <FormattedMessage id="new-order.shipment.description" />
              </div>
              {shipmentsItems[index].map((shipment, shipmentItemIndex) => (
                <div
                  key={shipmentItemIndex}
                  className="new-order-shipment-options__action"
                >
                  <ShipmentOptionsOption
                    deletable
                    description={formatShipSubtitle(shipment)}
                    icon={getItemIcon(shipment.icon)}
                    quantity={shipment.quantity}
                    title={shipment.description || shipment.name}
                    onDelete={() => handleItemDeleteClick(index, shipmentItemIndex)}
                    onEdit={() => handleItemEditClick(index, shipmentItemIndex)}
                  />
                </div>
              ))}
            </div>
            <div className="new-order-shipment-options__action">
              <div className="row">
                <div className="col col-12">
                  <Button
                    block
                    highlightedBorder={!isShipmentFilled(0)}
                    icon={boxIcon}
                    light
                    lightBorder
                    outline
                    // text={`Add Shipment${!shipmentsItems[index]?.length ? ' *' : ''}`}
                    text={shipmentsItems[index]?.length ?
                      intl.formatMessage({ id: 'new-order.shipment.add_shipment_more' }) :
                      intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                    onClick={() => handleAddItemClick(index)}
                  />
                </div>
                {/* Hidden for now */}
                {/* <div className="col col-6">
                  <Input
                    backgroundImage={searchIcon}
                    block
                    inputId="search-reference"
                    placeholder="Search by reference"
                  />
                </div> */}
              </div>
            </div>
            {Array.isArray(shipmentsItems[index]) && !!shipmentsItems[index].length && (
              <ShipmentOptionsShipmentDetails
                details={serviceShipmentOptions || {}}
                values={shipmentsOptions[index] || {}}
                onChange={values => handleShipmentDetailsChange('stop', values, index)}
              />
            )}
          </div>
          </div>
        ))}
        {shipmentMethodValue === 'multiple_stops' && (
          <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
            <div
              className="new-order-shipment-options__add-option"
              onClick={() => handleAddAnotherStopButtonClick()}
            >
              <FormattedMessage id="new-order.stop.add_stop" />
            </div>
          </div>
        )}

        {dropoffs.map((dropoff, index) => {
          const dropoffShipmentItem = shipmentsItems[shipmentMethodValue === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index];

          return (
            <div className="new-order-shipment-options__route__point">
            <div
              key={index}
              className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--end"
            >
              <h3 className="new-order-shipment-options__title">
                <FormattedMessage id="new-order.dropoff.title" />

                {index !== 0 && ` #${index + 1}`}
                {' '}
                *
                {shipmentMethodValue === 'multiple_dropoffs' && index !== 0 && (
                  <img
                    alt="Delete"
                    className="new-order-shipment-options__delete-icon"
                    src={trashIcon}
                    onClick={() => handleDropoffDeleteButtonClick(index)}
                    onMouseOut={(event) => {
                      event.currentTarget.src = trashIcon;
                    }}
                    onMouseOver={(event) => {
                      event.currentTarget.src = trashRedIcon;
                    }}
                  />
                )}
              </h3>
              <div className="new-order-shipment-options__description">
                <FormattedMessage id="new-order.dropoff.description" />
              </div>
              <div className="new-order-shipment-options__action">
                {!existsDropoffAddress(index) ? (
                  <Button
                    block
                    highlightedBorder={!isDropoffAddressFilled(0)}
                    icon={truckIcon}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.dropoff.add_address' })}
                    onClick={() => handleAddAddressButtonClick('dropoff', index)}
                  />
                ) : (
                  <div>
                    <ShipmentOptionsOption
                      description={formatAddressDescription(dropoff.address)}
                      icon={locationIcon}
                      title={dropoff.address.streetAddress}
                      hasError={!isDropoffAddressFilled(index)}
                      onEdit={() => handleAddAddressButtonClick('dropoff', index)}
                    />
                  </div>
                )}
              </div>
              {existsDropoffAddress(index) && (
                <div className="new-order-shipment-options__action">
                  {!isDropoffContactFilled(index) ? (
                    <Button
                      block
                      highlightedBorder={!isDropoffContactFilled(0)}
                      light
                      lightBorder
                      outline
                      text={intl.formatMessage({ id: 'new-order.dropoff.add_contact' })}
                      onClick={() => handleAddContactButtonClick('dropoff', index)}
                    />
                  ) : (
                    <ShipmentOptionsOption
                      description={`+${dropoff.contact.countryCallingCode} ${dropoff.contact.phone}`}
                      icon={userIcon}
                      title={dropoff.contact.name}
                      onEdit={() =>  handleAddContactButtonClick('dropoff', index)}
                    />
                  )}
                </div>
              )}
              {isDropoffContactFilled(index) && (
                <div className="new-order-shipment-options__action">
                  {!areDropoffNotesFilled(index) ? (
                    <Button
                      block
                      icon={fileIcon}
                      light
                      lightBorder
                      outline
                      text={intl.formatMessage({ id: 'new-order.dropoff.add_notes' })}
                      onClick={() => handleAddNotesButtonClick('dropoff', index)}
                    />
                  ) : (
                    <ShipmentOptionsOption
                      description={dropoff?.notes || ''}
                      icon={fileIcon}
                      title={intl.formatMessage({ id: 'new-order.dropoff.notes' })}
                      onEdit={() => handleAddNotesButtonClick('dropoff', index)}
                    />
                  )}
                </div>
              )}
              {shipmentMethodValue !== 'multiple_pickups' && (
                <div className={`${
                  index === dropoffs.length - 1 && !hasReturn ? ' new-order-shipment-options__route-filler' : ''}${
                  !isPickupAddressFilled(0) ||
                  !isDropoffAddressFilled(0) ?
                    ' new-order-shipment-options__route-filler--disabled' :
                    ''}
                `}
                >
                  <div className="new-order-shipment-options__shipment-wrapper">
                    <h3 className="new-order-shipment-options__title">
                      <FormattedMessage id="new-order.shipment.title" />
                    </h3>
                    <div className="new-order-shipment-options__description">
                      <FormattedMessage id="new-order.shipment.description" />
                    </div>
                    {Array.isArray(dropoffShipmentItem) && dropoffShipmentItem.map((shipment, shipmentItemIndex) => (
                      <div
                        key={shipmentItemIndex}
                        className="new-order-shipment-options__action"
                      >
                        <ShipmentOptionsOption
                          deletable
                          // description={shipment.info?.description}
                          description={formatShipSubtitle(shipment)}
                          /*icon={shipment.info?.iconUrl}*/
                          icon={getItemIcon(shipment.icon)}
                          quantity={shipment.quantity}
                          title={shipment.description || shipment.name}
                          onDelete={() => handleItemDeleteClick(shipmentMethodValue === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index, shipmentItemIndex)}
                          onEdit={() => handleItemEditClick(shipmentMethodValue === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index, shipmentItemIndex)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="new-order-shipment-options__action">
                    <div className="row">
                      <div className="col col-12">
                        <Button
                          block
                          highlightedBorder={!isShipmentFilled(0)}
                          icon={boxIcon}
                          light
                          lightBorder
                          outline
                          // text={`Add Shipment${!shipmentsItems[index]?.length ? ' *' : ''}`}
                          text={shipmentsItems[index]?.length ?
                            intl.formatMessage({ id: 'new-order.shipment.add_shipment_more' }) :
                            intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                          onClick={() => handleAddItemClick(shipmentMethodValue === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index)}
                        />
                      </div>
                      {/* Hidden for now */}
                      {/* <div className="col col-6">
                        <Input
                          backgroundImage={searchIcon}
                            block
                          inputId="search-reference"
                          placeholder="Search by reference"
                        />
                      </div> */}
                    </div>
                  </div>
                  {Array.isArray(shipmentsItems[index]) && !!shipmentsItems[index].length && (
                    <ShipmentOptionsShipmentDetails
                      details={serviceShipmentOptions || {}}
                      values={shipmentsOptions[index] || {}}
                      onChange={values => handleShipmentDetailsChange('dropoff', values, index)}
                    />
                  )}
                </div>
              )}
            </div>
            </div>
          );
        })}
        {hasReturn && Array.isArray(shipmentsItems) && (
          <div className="new-order-shipment-options__route__point">
          <div className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--return">
            <h3 className="new-order-shipment-options__title">
              <FormattedMessage id="new-order.return.title" />
              <img
                alt="Delete"
                className="new-order-shipment-options__delete-icon"
                src={trashIcon}
                onClick={() => handleReturnDeleteButtonClick()}
                onMouseOut={(event) => {
                  event.currentTarget.src = trashIcon;
                }}
                onMouseOver={(event) => {
                  event.currentTarget.src = trashRedIcon;
                }}
              />
            </h3>
            <div className="new-order-shipment-options__action">
              <ShipmentOptionsOption
                description={formatAddressDescription(pickups[0]?.address)}
                edit={false}
                icon={locationIcon}
                title={pickups[0]?.address?.streetAddress}
              />
            </div>
            <div className="new-order-shipment-options__route-filler">
              <div className="new-order-shipment-options__shipment-wrapper">
                <h3 className="new-order-shipment-options__title">
                  <FormattedMessage id="new-order.shipment.title" />
                </h3>
                <div className="new-order-shipment-options__description">
                  <FormattedMessage id="new-order.shipment.description" />
                </div>
                {Array.isArray(shipmentsItems[1]) && shipmentsItems[1].map((shipment, shipmentItemIndex) => (
                  <div
                    key={shipmentItemIndex}
                    className="new-order-shipment-options__action"
                  >
                    <ShipmentOptionsOption
                      deletable
                      description={formatShipSubtitle(shipment)}
                      icon={getItemIcon(shipment.icon)}
                      quantity={shipment.quantity || shipment.quantity}
                      title={shipment.description || shipment.name}
                      onDelete={() => handleItemDeleteClick(1, shipmentItemIndex)}
                      onEdit={() => handleItemEditClick(1, shipmentItemIndex)}
                    />
                  </div>
                ))}
              </div>
              <div className="new-order-shipment-options__action">
                <div className="row">
                  <div className="col col-12">
                    <Button
                      block
                      highlightedBorder={!isShipmentFilled(1)}
                      icon={boxIcon}
                      light
                      lightBorder
                      outline
                      text={intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                      onClick={() => handleAddItemClick(1)}
                    />
                  </div>
                  {/* Hidden for now */}
                  {/* <div className="col col-6">
                    <Input
                      backgroundImage={searchIcon}
                      block
                      inputId="search-reference"
                      placeholder="Search by reference"
                    />
                  </div> */}
                </div>
              </div>
              {Array.isArray(shipmentsItems[1]) && !!shipmentsItems[1].length && (
                <ShipmentOptionsShipmentDetails
                  details={serviceShipmentOptions || {}}
                  values={shipmentsOptions[1] || {}}
                  onChange={values => handleShipmentDetailsChange('pickup', values, 1)}
                />
              )}
            </div>
          </div>
          </div>
        )}
      </div>
      <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
        {shipmentMethodValue === 'normal' && !hasReturn && !!isDropoffAddressFilled(0) && (
          <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
            <div
              className={`new-order-shipment-options__add-option${!isPickupAddressFilled(0) || !isPickupContactFilled(0) ? ' new-order-shipment-options__add-option--disabled' : ''}`}
              onClick={isPickupAddressFilled(0) && isPickupContactFilled(0) ? handleAddReturnButtonClick : () => {}}
            >
              <FormattedMessage id="new-order.stop.add_return" />
            </div>
          </div>
        )}
        {shipmentMethodValue === 'multiple_dropoffs' && (
          <div
            className="new-order-shipment-options__add-option"
            onClick={() => handleAddAnotherDropoffButtonClick()}
          >
            <FormattedMessage id="new-order.stop.add_dropoff" />
          </div>
        )}
      </div>

      {showMandatoryFieldsInfo && (
        <div className="new-order-shipment-options__action">
          <div className="row">
            <div className="col col-12">
              <div className="new-order-shipment-options__mandatory-field-messages">
                <Notice
                  description={intl.formatMessage({ id: 'new-order.fill_fields' })}
                  fixed
                  icon={noticeIcon}
                  noBottomMargin
                  noTopMargin
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`new-order-shipment-options__gray-section${isScheduleSectionDisabled ? ' new-order-shipment-options__gray-section--disabled' : ''}`}>
        <h3 className="new-order-shipment-options__title new-order-shipment-options__title--margin-top">
          <FormattedMessage id="new-order.schedule.title" />
        </h3>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.schedule.description" />
        </div>
        { infoMessage && (
        <Notice
          description={infoMessage ? infoMessage : intl.formatMessage({ id: 'new-order.schedule.alert' })}
          fixed
          icon={noticeIcon}
        />
        )}
        <div className="new-order-shipment-options__subtitle">
          <FormattedMessage id="new-order.schedule.pickup.title" />
        </div>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.schedule.pickup.description" />
        </div>
        <div className="new-order-shipment-options__inputs-wrapper">
          <div className="row">
            <div className="col col-12 col-md-6">
              <DateSelect
                allowedDates={allowedPickupDates}
                id="initial"
                left
                startDate={pickupScheduleDate}
                onChange={handlePickupDateSelectChange}
              />
            </div>
            <div className="col col-12 col-md-6">
              <HourSelect
                instructions={pickupHourSelectInstructions}
                options={pickupTimeSlots}
                selected={pickupScheduleTimeSlotValue}
                onChange={handlePickupHourSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="new-order-shipment-options__subtitle">
          <FormattedMessage id="new-order.schedule.dropoff.title" />
        </div>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.schedule.dropoff.description" />
        </div>
        <div className="new-order-shipment-options__inputs-wrapper">
          <div className="row">
            <div className="col col-12 col-md-6">
              <DateSelect
                allowedDates={allowedDropoffDates}
                id="final"
                left
                startDate={dropoffScheduleDate}
                onChange={handleDropoffDateSelectChange}
              />
            </div>
            <div className="col col-12 col-md-6">
              <HourSelect
                instructions={dropoffHourSelectInstructions}
                options={dropoffTimeSlots}
                selected={dropoffScheduleTimeSlotValue}
                onChange={handleDropoffHourSelectChange}
              />
            </div>
          </div>
        </div>
      </div>

      {isPaymentMandory && (    
      <div className={`new-order-shipment-options__gray-section${isPaymentSectionDisabled ? ' new-order-shipment-options__gray-section--disabled' : ''}`}>
        <h3 className="new-order-shipment-options__title new-order-shipment-options__title--margin-top">
          <FormattedMessage id="new-order.payment.title" />
        </h3>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.payment.description" />
        </div>
        {!!paymentMessage && (
          <Notice
            description={paymentMessage}
            fixed
            icon={noticeIcon}
          />
        )}
        <div className="new-order-shipment-options__input-group">
          {paymentTypes?.map(paymentType => (
            <Radio
              key={paymentType.id}
              alignTop
              description={paymentType.description}
              disabled={disabled}
              icon={paymentIcon(paymentType.id)}
              iconRight
              label={paymentType.name}
              light
              selected={paymentTypeId}
              value={paymentType.id}
              onChange={() => handleSelectedPaymentOption(paymentType.id)}
            />
          ))}
        </div>
        <div className="new-order-shipment-options__required-info">
          <FormattedMessage id="new-order.required_fields" />
        </div>
      </div>
      )}

      <div className="new-order-shipment-options__footer">
        <div className="new-order-shipment-options__footer-left">
          <ul className="new-order-shipment-options__footer-list">
            {(deliveryDistanceErrorValue || deliveryDistance) && (
            <li className="new-order-shipment-options__footer-list-item">
              <img
                alt="Address Icon"
                className="new-order-shipment-options__footer-icon"
                src={mapIcon}
              />
              <div className="new-order-shipment-options__footer-value">
                {deliveryDistanceErrorValue || deliveryDistance}
              </div>
            </li>
            )}
            {(deliveryDurationErrorValue || deliveryDuration) && (
            <li className="new-order-shipment-options__footer-list-item">
              <img
                alt="Clock Icon"
                className="new-order-shipment-options__footer-icon"
                src={clockIcon}
              />
              <div className="new-order-shipment-options__footer-value">
                {deliveryDurationErrorValue || deliveryDuration}
              </div>
            </li>
             )}
            {(serviceName) && (
            <li className="new-order-shipment-options__footer-list-item">
              <img
                alt="Service Icon"
                className="new-order-shipment-options__footer-icon"
                src={truckIcon}
              />
              <div className="new-order-shipment-options__footer-value">
                {serviceName}
              </div>
            </li>
             )}


            {showBtnDiscountCode && (
              <li className="new-order-shipment-options__footer-list-item">
                <img
                  alt="Coupon Icon"
                  className="new-order-shipment-options__footer-icon"
                  src={couponIcon}
                />
                <div
                  className={`new-order-shipment-options__footer-value${!discountCode ? ' new-order-shipment-options__footer-value--highlight' : ' new-order-shipment-options__footer-value--discount'}`}
                  onClick={() => openDiscountModal()}
                >
                  {discountCode ? discountCode : intl.formatMessage({ id: 'new-order.discount_code.label' }) }
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="new-order-shipment-options__footer-right">
          <div className="new-order-shipment-options__footer-total">
            {priceTotal?.svalue}
            {Array.isArray(priceDetails) && !!priceDetails.length && (
              <div
                className="new-order-shipment-options__footer-total-info"
                onMouseEnter={handleTotalInfoMouseEnter}
              >
                <img
                  alt="Total Info"
                  src={infoGrayIcon}
                />
                <Tooltip
                  className="total-info-tooltip"
                  horizontalPosition="center"
                  show={showTotalInfoTooltip}
                  verticalPosition="top"
                  onClose={handleTotalInfoTooltipClose}
                >
                  <ul className="total-info-tooltip__list">
                    {priceDetails.map((price, index) => {
                      // Template string is breaking eslint
                      const priceClassName = `total-info-tooltip__list-item-value${price === 'Total' ? ' total-info-tooltip__list-item-value--total' : ''}`;
                      return (
                        <li
                          key={index}
                          className="total-info-tooltip__list-item"
                        >
                          <span className="total-info-tooltip__list-item-name">
                            {price?.text}
                          </span>
                          <span className={priceClassName}>
                            {price?.svalue}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </Tooltip>
              </div>
            )}
          </div>
          <Button
            context="primary"
            disabled={!isOrderValid || disabled}
            largeHorizontalPadding
            text={intl.formatMessage({ id: 'new-order.submit' })}
            onClick={handleCreateDeliveryButtonClick}
          />
        </div>
      </div>
      
      {showItemModal && (  
          <ItemModal
            serviceRates={filteredServiceRates}
            editItemIndex={shipmentItemIndexEdit}
            editItemData={editItemDataModal}
            onClose={closeItemModal}
            onSave={saveItemModal}
          />
      )}

      {showContactModal && (
        <ContactModal
          companyName={modalContact.company}
          contactName={modalContact.name}
          contactPhoneNumber={modalContact.phone}
          countryCallingCode={modalContact.countryCallingCode}
          showTaxFiedl={false}
          title={intl.formatMessage({ id: 'new-order.contact.'+addressTypeSelected+'.title' })}
          subtitle={intl.formatMessage({ id: 'new-order.contact.'+addressTypeSelected+'.subtitle' })}
          onClose={handleContactModalClose}
          onSave={handleContactModalSave}
        />
      )}  
     
      {showDiscountModal && (
        <DiscountModal
          defaultDiscountCode={discountCode}
          disabled={deliveryQuoteIsLoading}
          discountCodeError={discountCodeError}
          onClose={closeDiscountModal}
          onSave={handleDiscountCodeSave}

        />
      )}

      {showNotesModal && (
        <ShipmentOptionsNotesModal
          notes={notesModalNotes ?? ''}
          onClose={handleNotesModalClose}
          onSave={handleNotesModalSave}
        />
      )}
    
      {showAddressModal && (
        isConcierge ? (
          <AddressListModal
            currentAddress={addressModalAddress}
            search={searchModalAddress}
            type={addressTypeSelected}
            onClose={handleAddressModalClose}
            onSave={handleAddressModalSelect}
          />
        ) : (  
          <AddressModal
              
            currentAddress={addressModalAddress}
            search={searchModalAddress}
            type={addressTypeSelected}
            addAddress={false}
            onClose={handleAddressModalClose}
            onSave={handleAddressModalSelect}
          />
        )
      )}

      {showAddressModalConfirmation && (
        <ActionModal
          notice={intl.formatMessage({ id: 'new-order.address_changed.subtitle' })}
          title={intl.formatMessage({ id: 'new-order.address_changed.title' })}
          onClose={closeAddressModalConfirmation}
          onConfirm={handleAddressModalConfirmation}
        />
      )}

    </>
  );
};

NewOrderShipmentOptions.propTypes = {
  requestType: PropTypes.string,
  onDeliveryError: PropTypes.func,
  onEndLocationChange: PropTypes.func,
  onRouteChange: PropTypes.func,
  onStartLocationChange: PropTypes.func
};

NewOrderShipmentOptions.defaultProps = {
  requestType: "delivery",
  onDeliveryError: () => {},
  onEndLocationChange: () => {},
  onRouteChange: () => {},
  onStartLocationChange: () => {}
};

export default NewOrderShipmentOptions;
