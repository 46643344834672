import React from 'react';

import './styles.scss';

const Loader = () => (
  <div className="loader">
    <div className="loader__circle loader__circle--first" />
    <div className="loader__circle loader__circle--second" />
  </div>
);

export default Loader;
