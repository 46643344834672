import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LiveMap from 'components/live-map';

const MapRoute = ({ id, route }) => {
  return (
    <LiveMap
        id={id}
        route={route}
    />
  );
};

MapRoute.propTypes = {
  id: PropTypes.string.isRequired,
  route: PropTypes.arrayOf(PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    type: PropTypes.string,
    map_icon: PropTypes.string
  }))
};

MapRoute.defaultProps = {
  route: []
};

export default MapRoute;
