import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';

import {
  getDeliveryDetails,
  getDeliveryLiveTracking,
  getPublicDeliveryDetails,
  getPublicDeliveryTracking,
  publicRateCustomer,
  publicRateShop,
  publicRateTransporter,
  publicRateUser,
  rateCustomer,
  rateShop,
  rateTransporter,
  privateRateUser,
  selectDeliveryById,
  selectLiveTracking,
  selectIsLoadingDelivery
} from 'store/slices/deliveries';
import { selectInitialized, selectIsAuthenticated, selectUser, selectUserIsTransporter } from 'store/slices/authentication';

//import Map from 'components/map';
import LiveMap from 'components/live-map';
import Button from '@youship/components/objects/button';
import OrderActions from 'components/order/actions';
import OrderDropOff from 'components/order/drop-off';
import OrderReview from 'components/order/review';
import OrderSummary from 'components/order/summary';
import OrderShipment from 'components/order/shipment';
import OrderPayment from 'components/order/payment';
import OrderMessages from 'components/order/messages';
import OrderNotes from 'components/order/notes';
import OrderProgress from 'components/order/progress';
import OrderProposals from 'components/order/proposals';
import ReviewModal from 'components/order/review-modal';
import Loader from '@youship/components/objects/loader';

import chevronLeftIcon from 'images/icons/chevron-left.svg';

import './styles.scss';

const OrderDetails = ({isPublicTrack}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { orderId, isConfirmPayment } = useParams();


  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthenticationInitialized = useSelector(selectInitialized);

  const isLoadingDelivery = useSelector(selectIsLoadingDelivery);
  const liveTracking = useSelector(selectLiveTracking);
  const hasLiveTracking = liveTracking?.hasLiveTracking;
  const order = useSelector(state => selectDeliveryById(state, orderId));
  const user = useSelector(selectUser);
  const userType = user?.type;

  // NOTE: Leonel’s changes
  // eslint-disable-next-line no-unused-vars
  const bids = order?.bids;
  const dropoffSchedule = order?.dropOff?.schedule?.deadline;
  const goBackUrl = `${userType === 'transporter' ? '/' : '/orders'}${location?.state?.orderListSearch || ''}`;
  const orderType = order?.type;
  const paymentUrl = order?.payment?.paymentUrl;
  const pickupSchedule = order?.pickUp?.schedule?.ready;
  const rateTransporterActionExists = order?.rateTransporterActionExists;
  const rateShopActionExists = order?.rateShopActionExists;
  const rateUserActionExists = order?.rateUserActionExists;
  const showBids = order?.showBids;

  const [deliveryRoute, setDeliveryRoute] = useState(null);
  const [liveRoute, setLiveRoute] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rateModalSuccessMessage, setRateModalSuccessMessage] = useState(null);
  const [showPaymentButton, setShowPaymentButton] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showShopReviewModal, setShowShopReviewModal] = useState(false);
  const [modalRatingNumber, setModalRatingNumber] = useState(0);
  const [showInteractiveMap, setShowInteractiveMap] = useState(false);

  const handleMapLoaded = () => {
    setShowProgress(true);
  };

  const handleRatingChange = (value) => {
    setModalRatingNumber(value);
  };

  const handleOrderReviewClick = () => {
    setRateModalSuccessMessage(null);
    setShowReviewModal(true);
  };

  const handleOrderShopReviewClick = () => {
    setShowShopReviewModal(true);
  };

  const intl = useIntl();

  const handleReviewModalConfirmationClick = (event, context) => {
    event.preventDefault();

    let action = null;

    if ( !isPublicTrack ){
      if (context === 'customer') action = dispatch(rateCustomer({ orderId, userType, rating: modalRatingNumber }));
      if (context === 'shop') action = dispatch(rateShop({ orderId, userType, rating: modalRatingNumber }));
      if (context === 'transporter') action = dispatch(rateTransporter({ orderId, userType, rating: modalRatingNumber }));
      if (context === 'user') action = dispatch(privateRateUser({ orderId, userType, rating: modalRatingNumber }));
    }else{
      if (context === 'customer') action = dispatch(publicRateCustomer({ orderId, userType, rating: modalRatingNumber }));
      if (context === 'shop') action = dispatch(publicRateShop({ orderId, userType, rating: modalRatingNumber }));
      if (context === 'transporter') action = dispatch(publicRateTransporter({ orderId, userType, rating: modalRatingNumber }));
      if (context === 'user') action = dispatch(publicRateUser({ orderId, userType, rating: modalRatingNumber }));
    }

    if (action) {
      action
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

          return response;
        })
        .then((response) => {

          console.log("getDeliveryDetails - apos action");
          
          if( !isPublicTrack){
            dispatch(getDeliveryDetails(orderId))
          }else{
            dispatch(getPublicDeliveryDetails(orderId));
          }

          setRateModalSuccessMessage( intl.formatMessage({ id: 'order.review.success' }) );

          return response;
        })
        .catch((error) => {
          // NOTE: Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
          alert(error);
        });
    }else{
      console.log("context=" + context + " handleReviewModalConfirmationClick - no action");
    }
  };

  const actionDispatched = (action) => {
    if ( action === 'live_tracking' ){
      handleShowInteractiveMap(); 
    }
  };

  const handleShowInteractiveMap = () => {
    if (deliveryRoute) {
      setShowInteractiveMap(true);
    }
  };

  const closeReviewModal = () => {
    setShowReviewModal(false);
  };

  const closeShopReviewModal = () => {
    setShowShopReviewModal(false);
  };

  const handlePaymentButtonClick = () => {
    const paymentUrlWithReturn = `${paymentUrl}&ys_return_url=${origin}/order/${orderId}/cpay`;
    window.location.replace(paymentUrlWithReturn);
  };

  const doRequestLiveTracking = () => {

    console.log("doRequestLiveTracking");

    if ( !isPublicTrack) {
      dispatch(getDeliveryLiveTracking(orderId))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while tracking this delivery.');

          return response;
        })
        .catch((error) => {
          setErrorMessage(error?.message);
        });
    } else {
      dispatch(getPublicDeliveryTracking(orderId))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while tracking this delivery.');
          
          return response;
        })
        .catch((error) => {
          setErrorMessage(error?.message);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (orderId) {

      console.log("getDeliveryDetails");

      const action = isPublicTrack ? dispatch(getPublicDeliveryDetails(orderId)) : dispatch(getDeliveryDetails(orderId));
      
      action
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading delivery data.');
          return response;
        })
        .catch((error) => {
          setErrorMessage(error?.message);
        });
    }
  }, [dispatch, isPublicTrack, orderId]);

  useEffect(() => {
    if (order && !deliveryRoute) {
      const route = [];

      if (typeof order.pickUp?.address?.lat === 'number' && typeof order.pickUp?.address?.lng === 'number') {
        route.push({
          location: {
            lat: order.pickUp.address.lat,
            lng: order.pickUp.address.lng
          },
          type: 'pickup'
        });
      }

      if (typeof order.dropOff?.address?.lat === 'number' && typeof order.dropOff?.address?.lng === 'number') {
        route.push({
          location: {
            lat: order.dropOff.address.lat,
            lng: order.dropOff.address.lng
          },
          type: 'dropoff'
        });
      }

      if (route.length) setDeliveryRoute(route);
    }
  }, [deliveryRoute, order]);

  //detect new data on liveTraking, and update liveRoute to pass to LiveMap
  useEffect(() => {
    if (hasLiveTracking) {
      console.log("hasLiveTracking=true");
      if (order && liveTracking?.user_location){
        const route = [];

        if (typeof liveTracking.user_location?.address?.lat !== '' && typeof liveTracking.user_location?.address?.lng !== '') {
          route.push({
            location: {
              lat: liveTracking.user_location?.address.lat,
              lng: liveTracking.user_location?.address.lng
            },
            type: 'pickup',
            map_icon: liveTracking.user_location?.map_icon
          });
        }
  
        if( liveTracking?.routeto === 'pickup' ){
          if (typeof order.pickUp?.address?.lat === 'number' && typeof order.pickUp?.address?.lng === 'number') {
            route.push({
              location: {
                lat: order.pickUp.address.lat,
                lng: order.pickUp.address.lng
              },
              type: 'dropoff'
            });
          }
        }else if( liveTracking?.routeto === 'dropoff' ){
          if (typeof order.dropOff?.address?.lat === 'number' && typeof order.dropOff?.address?.lng === 'number') {
            route.push({
              location: {
                lat: order.dropOff.address.lat,
                lng: order.dropOff.address.lng
              },
              type: 'dropoff'
            });
          }
        }

        if (route.length && route.length == 2){
          setLiveRoute(route);
        }
      }
    }
  }, [liveTracking]);

  //fisrt request to know if there is livetracking
  useEffect(() => {
    if( showInteractiveMap ){
      if( !userType || (userType !== 'transporter' && userType !== 'driver') ){
        doRequestLiveTracking();
      }
    }
  }, [dispatch, isPublicTrack, orderId, showInteractiveMap]);

  //live tracking interval
  useEffect(() => {
    let liveTrackingInterval = null;
    //console.log("hasLiveTracking=" + hasLiveTracking);
    if (hasLiveTracking) {
      liveTrackingInterval = setInterval(() => {
        doRequestLiveTracking();
      }, 30000); //30 segundos
    }

    return () => clearInterval(liveTrackingInterval);
  }, [dispatch, hasLiveTracking, orderId]);

  const header = (
    <>
      { !isPublicTrack && (
      <Link
        className="order-details__previous"
        to={goBackUrl}
      >
        <img
          alt="Go to Orders page"
          className="order-details__previous-icon"
          src={chevronLeftIcon}
        />
        <FormattedMessage id="orders.title" />
      </Link>
      )}
      <h1 className="order-details__title">
        <FormattedMessage id="order.title" />
      </h1>
    </>
  );

  if (!order) {
    return (
      <div className="order-details">
        <div className="container">
          {header}
          {isLoadingDelivery ? (
            <div className="order-details__loader-wrapper">
              <Loader />
            </div>
          ) : (
            <p>
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    );
  }

  const tracking = liveTracking ? liveTracking?.tracking : order?.tracking;

  const orderProgressStatus = tracking?.status?.name ? tracking.status.name : null;
  const orderProgressInfo = tracking?.status?.description ? tracking.status.description : null;
  const orderStatusColor = tracking?.status?.status_color || null;
  const orderStatusIcon = tracking?.status?.icon || null;
  let orderProgress = 0;

  if (typeof tracking?.status?.pos === 'number' && typeof tracking?.status?.steps && tracking.status.pos > 0) {
    orderProgress = (tracking.status.pos * 100) / tracking.status.steps;
  } else if (Array.isArray(tracking?.history)) {
    const trackingStates = tracking.history;
    const trackingStatesConcludedCount = tracking.history.filter(entry => entry.isDone === true || entry.isCurrent === true);

    orderProgress = (trackingStatesConcludedCount.length * 100) / trackingStates.length;
  }

  //chamado se delivery/payment/confirm devolve sucesso
  const handleOrderPayment = (success, isdone) => {
    //if (paymentCode === 'mb') {
      //setShowPaymentButton(false);
    //}
    if(isdone){
      setShowPaymentButton(false);
    }
  };


  // Filter pay action if selected payment method is Multibanco
  // NOTE: prone to errors so it should be refactored later
  const orderActionsList = Array.isArray(order?.actionsList) && order?.actionsList.filter(actionItem => !(order.payment?.method?.code === 'mb' && actionItem.action === 'pay'));

  let publicRateShop = null;
  let rateUser = null;
  let orderUserType = order?.user?.user_type;

  if ( Array.isArray(order?.allActions) ){
    order.allActions.forEach((action) => {
      if ( isPublicTrack ){
        if (action.action === 'rate_shop'){
            publicRateShop = action;
        } 
      }
      if (orderUserType === "shop" && action.action === 'rate_shop'){
         rateUser = action;
      }else  if (orderUserType === "user" && action.action === 'rate_user'){
        rateUser = action;
      }else  if (orderUserType === "transporter" && action.action === 'rate_transporter'){
        rateUser = action;
        
      }  

    });
  }

  return (
    <div className={`order-details${ (!order.isPaid && paymentUrl && showPaymentButton) ? ' order-details--unpaid' : ''}`}>
      <div className="container">
        {header}
        <div className="row">
          <div className="col col-12 col-md-6">
            <div className="order-details__order-summary">
              <OrderSummary {...order} />
            </div>
            {orderType === 'order' && order.dropOff && (
              <OrderDropOff {...order.dropOff} />
            )}
            {order.items && (
              <OrderShipment
                items={order.items}
                type={order.type}
              />
            )}
            {!!order.notes && (
              <OrderNotes
                notes={order.notes}
              />
            )}
            {!!order.user && !!order.user?.name && (
              <OrderReview
                {...order.user}
                showReviewButton={!!rateUser}
                onReviewClick={handleOrderReviewClick}
              />
            )}
            { userType === 'transporter' && !!order.driver && (
              <OrderReview
                {...order.driver}
                showReviewButton={false}
                onReviewClick={handleOrderReviewClick}
              />
            )}
            { publicRateShop && (
              <OrderReview
                {...publicRateShop}
                showReviewButton={!!publicRateShop}
                onReviewClick={handleOrderShopReviewClick}
              />
            )}
            {showBids && (
              <OrderProposals
                {...order}
              />
            )}
            <OrderPayment
              {...order}
              isConfirmPayment={isConfirmPayment}
              onOrderPayment={handleOrderPayment}
            />
            {Array.isArray(orderActionsList) && orderActionsList.length > 0 && (
              <OrderActions
                actions={orderActionsList}
                dropoffSchedule={dropoffSchedule}
                goBackUrl={goBackUrl}
                id={orderId}
                pickupSchedule={pickupSchedule}
                userDriver={order.driver}
                onChange={actionDispatched}
              />
            )}
            <OrderMessages
              messages={order.messages || []}
              orderId={orderId}
              publicDeliveryMessage={order.publicDelivery}
            />
            {!order.isPaid && paymentUrl && showPaymentButton && (
              <div className="order-details__footer">
                <div className="order-details__footer-price">
                  {order.payment?.value}
                </div>
                <Button
                  context="primary"
                  largeHorizontalPadding
                  text={intl.formatMessage({ id: 'order.action.paynow' })}
                  onClick={handlePaymentButtonClick}
                />
              </div>
            )}
          </div>
          <div className="col col-12 offset-0 col-md-5 offset-lg-1">
            <div
              className={`order-details__map-wrapper${deliveryRoute ? ' order-details__map-wrapper--overlay-cursor' : ''}`}
              onClick={handleShowInteractiveMap}
            >
              {!showInteractiveMap && order.imageUrl && (
                <>
                  {deliveryRoute && (
                    <div className="order-details__map-overlay">
                      <FormattedMessage id="order.action.interactive_map" />
                    </div>
                  )}
                  {order.imageUrl && (
                  <img
                    alt="Map"
                    className="order-details__map-image"
                    src={order.imageUrl}
                  />
                  )}
                </>
              )}
              {showInteractiveMap && (
                <LiveMap
                  id="orderTrackerMap"
                  route={deliveryRoute}
                  liveRoute={liveRoute}
                  onMapLoaded={handleMapLoaded}
                />
              )}
              {showProgress && (
                <div className="order-details__progress">
                  <OrderProgress
                    color={orderStatusColor}
                    info={orderProgressInfo}
                    percentage={orderProgress}
                    status={orderProgressStatus}
                    icon={orderStatusIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showReviewModal && (
        <ReviewModal
          {...order?.user}
          finalMessage={rateModalSuccessMessage}
          rating={modalRatingNumber}
          onClose={closeReviewModal}
          onConfirm={event => handleReviewModalConfirmationClick(event, order?.user?.user_type)}
          onRate={handleRatingChange}
        />
      )}
      {showShopReviewModal && (userType === 'user' || userType === 'transporter' || userType === 'driver') && (
        <ReviewModal
          {...order?.shop}
          finalMessage={rateModalSuccessMessage}
          rating={modalRatingNumber}
          onClose={closeShopReviewModal}
          onConfirm={event => handleReviewModalConfirmationClick(event, order?.shop?.user_type)}
          onRate={handleRatingChange}
        />
      )}
    </div>
  );
};

/*
OrderDetails.propTypes = {
  isPublicTrack: PropTypes.bool
};

OrderDetails.defaultProps = {
  isPublicTrack: false
};
*/

export default OrderDetails;
