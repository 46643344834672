import BaseApi from '..';
import {
  TRANSPORTER_CANCEL,
  TRANSPORTER_DELIVERIES_ACTIVE,
  TRANSPORTER_DELIVERIES_CURRENT,
  TRANSPORTER_DELIVERIES_HISTORY,
  TRANSPORTER_DELIVERIES_SEARCH,
  TRANSPORTER_DRIVERS_LIST,
  TRANSPORTER_DRIVERS_BASIC_LIST,
  TRANSPORTER_SIGN_UP,
  TRANSPORTER_ADD_DRIVER,
  TRANSPORTER_EDIT_DRIVER,
  TRANSPORTER_REMOVE_DRIVER, ADDRESS_LIST,
} from '../endpoints';


export default class TransporterApi extends BaseApi {
  /* eslint-disable camelcase */

  static signUpAsTransporter = ({
                                  company_name,
                                  companycode,
                                  worktype,
                                  insurancetype,
                                  charter, // optional
                                  insurance, // optional
                                  policy, // optional
                                  coverage, // optional
                                  address,
                                  postalcode,
                                  city,
                                  vat,
                                  drivinglicense,
                                  vehicletype,
                                  vehiclecapacity,
                                  maincity,
                                  availability,
                                  about,
                                  bank_name, // optional
                                  bank_account // optional
                                }) => {
    const data = {
      worktype,
      insurancetype,
      address,
      postalcode,
      city,
      vat,
      drivinglicense,
      vehicletype,
      vehiclecapacity,
      maincity,
      availability,
      about
    };

    if (bank_name) data.bank_name = bank_name;
    if (bank_account) data.bank_account = bank_account;
    if (company_name) data.company_name = company_name;
    if (companycode) data.companycode = companycode;
    if (charter) data.charter = charter;
    if (coverage) data.coverage = coverage;
    if (insurance) data.insurance = insurance;
    if (policy) data.policy = policy;

    return this.post(TRANSPORTER_SIGN_UP, data);
  };

  static cancelSignUpRequest = () => this.post(TRANSPORTER_CANCEL);

  static currentDeliveries = () => this.post(TRANSPORTER_DELIVERIES_CURRENT);

  static activeDeliveries = ({
                               grouped = '', // optional: true/false -> default=true
                               str = '', // optional
                               reference = '', // optional
                               datefrom = '', // optional
                               dateto = '', // optional
                               user_code = '', // optional
                               pages = { // optional
                                 current: 0,
                                 resultsbypage: 0
                               }
                             }) => {
    const data = {
      grouped,
      str,
      reference,
      datefrom,
      dateto,
      user_code,
      pages
    };

    if (user_code) data.user_code = user_code;

    return this.post(TRANSPORTER_DELIVERIES_ACTIVE, data);
  };

  static historyDeliveries = ({
                                grouped = '', // optional: true/false -> default=true
                                str = '', // optional
                                reference = '', // optional
                                datefrom = '', // optional
                                dateto = '', // optional
                                user_code = '', // optional
                                pages = { // optional
                                  current: 0,
                                  resultsbypage: 0
                                }
                              }) => {
    const data = {
      grouped,
      str,
      reference,
      datefrom,
      dateto,
      user_code,
      pages
    };

    if (user_code) data.user_code = user_code;

    return this.post(TRANSPORTER_DELIVERIES_HISTORY, data);
  };

  static searchDeliveries = ({
                               grouped = '', // optional: true/false -> default=true
                               str = '', // optional
                               reference = '', // optional
                               datefrom = '', // optional
                               dateto = '', // optional
                               user_code = '', // optional
                               pages = { // optional
                                 current: 0,
                                 resultsbypage: 0
                               }
                             }) => {
    const data = {
      grouped,
      str,
      reference,
      datefrom,
      dateto,
      user_code,
      pages
    };

    if (user_code) data.user_code = user_code;

    return this.post(TRANSPORTER_DELIVERIES_SEARCH, data);
  };

  static getDrivers = ({
      pages = { // optional
          current: 0,
          resultsbypage: 20
      }
      }) =>  this.post(TRANSPORTER_DRIVERS_LIST, {pages}
  );
  
  static getDriversBasic = () =>  this.post(TRANSPORTER_DRIVERS_BASIC_LIST);

  static addDriver = ({
                        firstname,
                        lastname,
                        email,
                        phonecode,
                        phonenumber
                      }) => {
    const data = {
      firstname,
      lastname,
      email,
      phonecode,
      phonenumber
    };

    return this.post(TRANSPORTER_ADD_DRIVER, data);
  };

  static editDriver = ({
                         user_code,
                         firstname,
                         lastname,
                         phonecode,
                         phonenumber
                       }) => {
    const data = {
      user_code,
      firstname,
      lastname,
      phonecode,
      phonenumber
    };

    return this.post(TRANSPORTER_EDIT_DRIVER, data);
  };

  static removeDriver = ({ user_code }) => {

    return this.post(TRANSPORTER_REMOVE_DRIVER, user_code);
  };

  /* eslint-enable camelcase */
}
