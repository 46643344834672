import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import ShopApi from '@youship/api/shop';
import Shop from "../../templates/shop";
import {TRANSPORTER_REMOVE_DRIVER} from "../../../../shared/api/endpoints";

const shopAdapter = createEntityAdapter();

const initialState = shopAdapter.getInitialState({
  isLoading: false
});


export const fetchAllMenus = createAsyncThunk(
    'shop/fetchAllMenus',
    (argument, {getState}) => {
          
    return ShopApi.listAllMenus({})
    .then((response) => {
      
      
      if (response && Array.isArray(response.rows)) {
        return response.rows.map(menu => ({
          ...menu, 
            id: menu.category_code,
          category_code: menu.category_code,
          name: menu.name,
          state: menu.state,
        }));
      }

      return null;
    })
  }
);

export const fetchGetMenu = createAsyncThunk(
    'shop/fetchGetMenu',
    async (category_code) => {
        try {
            const response = await ShopApi.getMenu({ category_code });
            if (response && response.menu && response.menu.categories && Array.isArray(response.menu.categories)) {
                return response.menu.categories.map((category) => ({
                    state: category.state,
                    id: category.category_code,
                    category_code: category.category_code,
                    name: category.name,
                    products: category.products.map((product) => ({
                        id: product.product_code,
                        product_code: product.product_code,
                        name: product.name,
                        description: product.description,
                        small_description: product.small_description,
                        photo_url: product.photo_url,
                        limit: product.limit,
                        sold_out: product.sold_out,
                        price_info: product.price_info,
                        price: product.price && {
                            text: product.price.text,
                            svalue: product.price.svalue,
                            value: product.price.value,
                        },
                        options: product.options.map((option) => ({
                            id: option.category_code,
                            name: option.name,
                            subtitle: option.subtitle,
                            category_code: option.category_code,
                            input: option.input,
                            mandatory: option.mandatory,
                            limit: option.limit,
                            extras: option.extras.map((extra) => ({
                                id: extra.product_code,
                                product_code: extra.product_code,
                                name: extra.name,
                                limit: extra.limit,
                                sold_out: extra.sold_out,
                                price: extra.price && {
                                    text: extra.price.text,
                                    svalue: extra.price.svalue,
                                    value: extra.price.value,
                                },
                            })),
                        })),
                    })),
                }));
            }
        } catch (error) {
            console.error('Error fetching menu:', error);
        }
        return null;
    }
);


export const addMenu = createAsyncThunk(
    'shop/addMenu',
    (formData) => {
        const data = {
            name: formData.name,
            state: formData.state
        };

        return ShopApi.addMenu(data);
    }
);

export const editMenu = createAsyncThunk(
    'shop/editMenu',
    (formData) => {
        const data = {
            category_code: formData.category_code,
            name: formData.name,
            state: formData.state
        };

        return ShopApi.editMenu(data);
    }
);

export const removeMenu = createAsyncThunk(
    'shop/removeMenu',
    (category_code) => {
        return ShopApi.removeMenu(category_code);
    }
);


export const addCategory = createAsyncThunk(
    'shop/addCategory',
    (formData) => {
        const data = {
            menu_code: formData.menu_code,
            name: formData.name,
            state: formData.state
        };

        return ShopApi.addCategory(data);
    }
);

export const editCategory = createAsyncThunk(
    'shop/editCategory',
    (formData) => {
        const data = {
            category_code: formData.category_code,
            name: formData.name,
            state: formData.state
        };

        return ShopApi.editCategory(data);
    }
);

export const removeCategory = createAsyncThunk(
    'shop/removeCategory',
    (category_code) => {
        return ShopApi.removeCategory(category_code);
    }
);

export const addProduct = createAsyncThunk(
    'shop/addProduct',
    (formData) => {
        const data = {
            category_code: formData.category_code,
            name: formData.name,
            state: formData.state,
            small_description: formData.small_description,
            description: formData.description,
            photo: formData.photo,
            unitprice: formData.description,
            limit: formData.limit,
        };

        return ShopApi.addProduct(data);
    }
);

export const editProduct = createAsyncThunk(
    'shop/editProduct',
    (formData) => {
        const data = {
            product_code: formData.product_code,
            name: formData.name,
            state: formData.state,
            small_description: formData.small_description,
            description: formData.description,
            photo: formData.photo,
            unitprice: formData.description,
            limit: formData.limit,
        };
        return ShopApi.editProduct(data);
    }
);

export const removeProduct = createAsyncThunk(
    'shop/removeProduct',
    (product_code) => {
        return ShopApi.removeProduct(product_code);
    }
);

export const addOption = createAsyncThunk(
    'shop/addOption',
    (formData) => {
        const data = {
            product_code: formData.product_code,
            name: formData.name,
            state: formData.state,
            subtitle: formData.subtitle,
            mandatory: formData.mandatory,
            limit: formData.limit,
        };
        return ShopApi.addOption(data);
    }
);


export const editOption = createAsyncThunk(
    'shop/editOption',
    (formData) => {
        const data = {
            category_code: formData.category_code,
            name: formData.name,
            state: formData.state,
            subtitle: formData.subtitle,
            mandatory: formData.mandatory,
            limit: formData.limit,
        };
        return ShopApi.addOption(data);
    }
);


export const removeOption = createAsyncThunk(
    'shop/removeOption',
    (category_code) => {
        return ShopApi.removeOption(category_code);
    }
);

export const addExtra = createAsyncThunk(
    'shop/addExtra',
    (formData) => {
        const data = {
            category_code: formData.category_code,
            name: formData.name,
            state: formData.state,
            subtitle: formData.subtitle,
            unitprice: formData.unitprice,
            limit: formData.limit,
        };
        return ShopApi.addExtra(data);
    }
);


export const editExtra = createAsyncThunk(
    'shop/editExtra',
    (formData) => {
        const data = {
            product_code: formData.product_code,
            name: formData.name,
            state: formData.state,
            subtitle: formData.subtitle,
            unitprice: formData.unitprice,
            limit: formData.limit,
        };
        return ShopApi.addExtra(data);
    }
);


export const removeExtra = createAsyncThunk(
    'shop/removeExtra',
    (product_code) => {
        return ShopApi.removeExtra(product_code);
    }
);



const shopSlice = createSlice({
  name: 'shop',

  initialState,
  reducers: {
  },
});

export default shopSlice.reducer;
