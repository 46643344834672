import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from 'store/slices/authentication';

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const urlParameters = new URLSearchParams(window.location.search);
  const browserRedirectionUrl = urlParameters.get('redirectionurl');

  dispatch(logout())
    .then((response) => {
      if (response?.error) throw new Error(response.error.message || 'Something went wrong while trying to logout.');

      if (browserRedirectionUrl && window) {
        window.location.replace(browserRedirectionUrl);
      } else {
        history.push(browserRedirectionUrl || '/');
      }

      return response;
    })
    .catch((error) => {
      // TODO: add proper error handling
      // eslint-disable-next-line no-console
      console.error(error);
    });

  return null;
};

export default Logout;
