import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormattedMessage, useIntl } from 'react-intl';

import calendarIcon from 'images/icons/calendar.svg';
import clockIcon from 'images/icons/clock.svg';

import Input from '@youship/components/objects/input';
import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';

import './styles.scss';

const RescheduleModal = ({
  isLoading,
  errorMessage,
  title,
  onClose,
  onSave,
  schedule
}) => {

  const pickupDate = schedule?.pickupSchedule && schedule?.pickupSchedule.includes('-') ? moment(schedule?.pickupSchedule).format('YYYY-MM-DD') : null;
  const pickupTime = schedule?.pickupSchedule && schedule?.pickupSchedule.includes(':') ? moment(schedule?.pickupSchedule).format('HH:mm') : null;

  const dropoffDate = schedule?.dropoffSchedule && schedule?.dropoffSchedule.includes('-') ? moment(schedule?.dropoffSchedule).format('YYYY-MM-DD') : null;
  const dropoffTime = schedule?.dropoffSchedule && schedule?.dropoffSchedule.includes(':') ? moment(schedule?.dropoffSchedule).format('HH:mm') : null;
  
  const [dropoffScheduleDate, setDropoffScheduleDate] = useState(dropoffDate || null);
  const [pickupScheduleDate, setPickupScheduleDate] = useState(pickupDate || null);
  const [dropoffScheduleTime, setDropoffScheduleTime] = useState(dropoffTime || null);
  const [pickupScheduleTime, setPickupScheduleTime] = useState(pickupTime || null);

  const formattedDropoffSchedule = moment(`${dropoffScheduleDate} ${dropoffScheduleTime}`).format('YYYY-MM-DD HH:mm');
  const formattedPickupSchedule = moment(`${pickupScheduleDate} ${pickupScheduleTime}`).format('YYYY-MM-DD HH:mm');

  const handleRescheduleSaveClick = (event) => {
    event.preventDefault();

    const currentDate = moment();

    const areDatesValid = !!formattedDropoffSchedule &&
    !!formattedPickupSchedule &&
    moment(formattedDropoffSchedule).isValid() &&
    moment(formattedPickupSchedule).isValid() &&
    currentDate.isBefore(formattedDropoffSchedule) &&
    currentDate.isBefore(formattedPickupSchedule) &&
    !!dropoffScheduleDate &&
    !!pickupScheduleDate &&
    !!dropoffScheduleTime &&
    !!pickupScheduleTime;

    onSave(areDatesValid, formattedDropoffSchedule, formattedPickupSchedule);
  };

  const handleScheduleInputChange = (value, context) => {
    if (context === 'dropoff-date') setDropoffScheduleDate(value);
    else if (context === 'dropoff-time') setDropoffScheduleTime(value);
    else if (context === 'pickup-date') setPickupScheduleDate(value);
    else if (context === 'pickup-time') setPickupScheduleTime(value);
  };

  const intl = useIntl();

  return (
    <Modal
      classNames="reschedule-modal"
      footer={
        <div className="reschedule-modal__footer">
          {errorMessage && (
            <p className="reschedule-modal__error-message">
              {errorMessage}
            </p>
          )}
          <Button
            classNames="reschedule-modal__button"
            context="primary"
            disabled={isLoading}
            largeHorizontalPadding
            text={intl.formatMessage({ id: 'order.action.save' })}
            onClick={!isLoading ? event => handleRescheduleSaveClick(event) : () => {}}
          />
        </div>
      }
      title={title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="reschedule-modal__content">
        <div className="reschedule-modal__item">
          <div className="reschedule-modal__item-title">
            <FormattedMessage id="order.reschedule.pickup.title" />
          </div>
          <div className="reschedule-modal__item-subtitle">
            <FormattedMessage id="order.reschedule.pickup.subtitle" />
          </div>
          <div className="reschedule-modal__item-wrapper">
            <div className="reschedule-modal__item-date">
              <img
                alt="Calendar Icon"
                className="reschedule-modal__item-icon"
                src={calendarIcon}
              />
              <Input
                placeholder="yyyy-mm-dd"
                size="10"
                type="text"
                value={pickupScheduleDate}
                onChange={event => handleScheduleInputChange(event?.target?.value, 'pickup-date')}
              />
            </div>
            <div className="reschedule-modal__item-time">
              <img
                alt="Clock Icon"
                className="reschedule-modal__item-icon"
                src={clockIcon}
              />
              <Input
                placeholder="hh:mm"
                size="10"
                type="text"
                value={pickupScheduleTime}
                onChange={event => handleScheduleInputChange(event?.target?.value, 'pickup-time')}
              />
            </div>
          </div>
        </div>
        <div className="reschedule-modal__item">
          <div className="reschedule-modal__item-title">
            <FormattedMessage id="order.reschedule.dropoff.title" />
          </div>
          <div className="reschedule-modal__item-subtitle">
            <FormattedMessage id="order.reschedule.dropoff.subtitle" />
          </div>
          <div className="reschedule-modal__item-wrapper">
            <div className="reschedule-modal__item-date">
              <img
                alt="Calendar Icon"
                className="reschedule-modal__item-icon"
                src={calendarIcon}
              />
              <Input
                placeholder="yyyy-mm-dd"
                size="10"
                type="text"
                value={dropoffScheduleDate}
                onChange={event => handleScheduleInputChange(event?.target?.value, 'dropoff-date')}
              />
            </div>
            <div className="reschedule-modal__item-time">
              <img
                alt="Clock Icon"
                className="reschedule-modal__item-icon"
                src={clockIcon}
              />
              <Input
                placeholder="hh:mm"
                size="10"
                type="text"
                value={dropoffScheduleTime}
                onChange={event => handleScheduleInputChange(event?.target?.value, 'dropoff-time')}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RescheduleModal.propTypes = {
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  schedule: PropTypes.shape({
    deadline: PropTypes.string,
    ready: PropTypes.string
  })
};

RescheduleModal.defaultProps = {
  errorMessage: null,
  isLoading: false,
  onClose: () => {},
  onSave: () => {},
  schedule: null
};

export default RescheduleModal;
