import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const AlertBasic = ({ classNames, message }) => {
 
  return (
    <div className={`alert-basic${classNames ? ` ${classNames}` : ''}`}>
        {message}
    </div>
  );
};

AlertBasic.propTypes = {
  classNames: PropTypes.string,
  message: PropTypes.string
};

AlertBasic.defaultProps = {
  classNames: null,
  message: null
};

export default AlertBasic;
