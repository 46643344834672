import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import avatarImage from 'images/avatar.jpg';

import Button from '@youship/components/objects/button';
import Rating from '@youship/components/objects/rating';

import './styles.scss';


const OrderReview = ({ name, photoUrl, rating, showReviewButton, title, onReviewClick }) => {
  const intl = useIntl();

  return (
    <div className="order-review">
      <h3 className="order-review__title">
        {title}
      </h3>
      <div className="order-review__details">
        <img
          alt=""
          className="order-review__image"
          src={photoUrl || avatarImage}
        />
        <div>
          <div className="order-review__name">
            {name}
          </div>
          <Rating
            value={rating}
          />
        </div>
        {showReviewButton && (
          <div className="order-review__button-wrapper">
            <Button
              context="primary"
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'order.review.button' })}
              onClick={onReviewClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

OrderReview.propTypes = {
  name: PropTypes.string,
  onReviewClick: PropTypes.func,
  photoUrl: PropTypes.string,
  rating: PropTypes.number,
  showReviewButton: PropTypes.bool,
  title: PropTypes.string
};

OrderReview.defaultProps = {
  name: null,
  onReviewClick: () => {},
  photoUrl: '',
  rating: 0,
  showReviewButton: true,
  title: ''
};

export default OrderReview;
