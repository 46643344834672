import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import MessageApi from '@youship/api/message';

const messagesAdapter = createEntityAdapter();

const initialState = messagesAdapter.getInitialState({
  isSubmittingMessage: false,
  isSubmittingReply: false
});

// Thunks:

export const submitMessage = createAsyncThunk(
  'message/submitMessage',
  ({
    orderId,
    message
  }) => MessageApi.newMessage({
    // eslint-disable-next-line camelcase
    order_code: orderId,
    msg: message
  })
);

export const submitReply = createAsyncThunk(
  'message/submitReply',
  ({
    messageCode,
    reply
  }) => MessageApi.reply({
    // eslint-disable-next-line camelcase
    message_code: messageCode,
    msg: reply
  })
);

// Slice:

const messagesSlice = createSlice({
  name: 'messages',

  initialState,

  reducers: {
    initialize (state) {
      state.initialized = true;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(submitMessage.pending, (state) => {
        state.isSubmittingMessage = true;
      })
      .addCase(submitMessage.fulfilled, (state, action) => {
        state.isSubmittingMessage = false;

        return action.response;
      })
      .addCase(submitMessage.rejected, (state) => {
        state.isSubmittingMessage = false;
      })
      .addCase(submitReply.pending, (state) => {
        state.isSubmittingReply = true;
      })
      .addCase(submitReply.fulfilled, (state, action) => {
        state.isSubmittingReply = false;

        return action.response;
      })
      .addCase(submitReply.rejected, (state) => {
        state.isSubmittingReply = false;
      });
  }
});

export default messagesSlice.reducer;

export const {
  selectAll: selectMessages
} = messagesAdapter.getSelectors(state => state);

export const selectIsSubmittingMessage = state => state.isSubmittingMessage;

export const selectIsSubmittingReply = state => state.isSubmittingReply;
