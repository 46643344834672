import React from 'react';

import ShopTemplate from 'templates/shop';

const Venue = props => (
  <>
    <ShopTemplate {...props} />
  </>
);


export default Venue;
