import React, {useEffect, useRef, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectUser } from 'store/slices/authentication';

import Button from '@youship/components/objects/button';

import './styles.scss';
import ShipmentOptionsOption from "../new-order/shipment-options/option";
import locationIcon from "../../images/icons/location.svg";
import userIcon from "../../images/icons/user.svg";
import removeIcon from "../../images/icons/trash--red.svg";

const AddressCard = ({
  address,
  index,
  setSelectedAddressCode,
  setShowRemoveModal, 
  handleEditAddress,
  handleSetPrimaryAddress,
  handleAddContactButtonClick,
}) => {
  
  const intl = useIntl();


  const handleRemoveClick = () => {
    setSelectedAddressCode(address.address_code);
    setShowRemoveModal(true)
  }
  
  

  const formatAddressDescription = (address) => {
    const addressOptionsArray = [
      address?.number_address || null,
      address?.apartment_address || null,
      address?.postalcode || null,
      address?.city || null
    ];
    const formattedAddress = addressOptionsArray.filter(Boolean).join(' • ');
    return formattedAddress;
  };


  return (
      
      <>
        <div className="new-order-shipment-options__route">         
              <div className="new-order-shipment-options__route__point">
                <div className="new-order-shipment-options__option">
                  
                  <h3 className="new-order-shipment-options__title">
                    {index === 0 ? (
                        <FormattedMessage id="addresses.firstAddress" />
                    ): (
                        <Button
                            style={{ color: "#ec5344"}}
                            small
                            noBackground
                            noShadow
                            linkComponent={Link}
                            linkProps={{ to: '#' }}
                            noArrow
                            text={intl.formatMessage({id: 'addresses.set_primary_address'})}
                            onClick={  () => handleSetPrimaryAddress(address.address_code) }
                        />
                    )
                    }
                    {index === 0 ? (
                        <Button
                            noBackground
                            noShadow
                            small
                            largeHorizontalPadding
                            style={{ visibility: 'hidden' }}
                            disabled
                        >
                          <img
                              className={""}
                              alt="Remove Icon"
                              src={removeIcon}
                          />
                        </Button>
                    ): (
                    <Button
                        noBackground
                        noShadow
                        small
                        largeHorizontalPadding
                        onClick={ handleRemoveClick}
                    >
                      <img
                          alt="Remove Icon"
                          src={removeIcon}
                      />
                    </Button>
                        )
                    }
                  </h3>

                  <div className="new-order-shipment-options__action">
                    { (
                        <div>
                          <ShipmentOptionsOption
                              description={formatAddressDescription(address.address)}
                              icon={locationIcon}
                              title={address.address.street_address}
                              onEdit={() => handleEditAddress(address)}
                          />
                        </div>
                    )}
                  </div>

                      <div className="new-order-shipment-options__action">

                        { (address.contact.phonenumber === "" || address.contact.phonecode === "" || address.contact.idphonecode === "0") ? (
                            <Button
                                block
                                verticalPadding
                                light
                                lightBorder
                                isTextLeft={true}
                                outline
                                text={intl.formatMessage({ id: 'addresses.add_contact' })}
                                icon={userIcon}
                                onClick={() => handleAddContactButtonClick(address)}
                            />
                        ) : (
                          <ShipmentOptionsOption
                              description={`${address.contact.phonecode} ${address.contact.phone}`}
                              icon={userIcon}
                              title={address.contact.name}
                              onEdit={() => handleAddContactButtonClick(address)}
                          />
                          ) 
                        }
                      </div>
                  
                </div>
              </div>
        </div>
      </>
  )
};


AddressCard.defaultProps = {
  actions: null,
  dropOff: null,
  full: false,
  hasCloseIcon: false,
  items: null,
  onClose: () => {},
  onSeeDetails: () => {},
  payment: null,
  pickUp: null,
  resume: null,
  totalPrice: null,
  contact : null,
};

export default AddressCard;
