import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import CategoryApi from '@youship/api/category';
import { FormatRequestLocation } from 'utils/shopping';

const orderTypesAdapter = createEntityAdapter();

const initialState = orderTypesAdapter.getInitialState({
  isLoading: false
});

// Thunks:

export const fetchOrderTypes = createAsyncThunk(
  'categories/fetchOrderTypes',
  (argument, {getState}) => {
    const location = FormatRequestLocation(getState());
    return CategoryApi.listOrderTypes({ location: location})
    .then((response) => {
      if (response && Array.isArray(response.rows)) {
        return response.rows.map(orderType => ({
          ...orderType,
          id: orderType.code,
          imageUrl: orderType.photo_url || orderType.icon_url
        }));
      }

      return null;
    })
  }
);

export const fetchShopTypes = createAsyncThunk(
  'categories/fetchShopTypes',
  // eslint-disable-next-line camelcase
  (orderTypeId, {getState}) => {
    const location = FormatRequestLocation(getState());
    return CategoryApi.listShopTypes({ order_type: orderTypeId, location: location })
    .then((response) => {
      if (response && Array.isArray(response.rows)) {
        return {
          orderTypeId,
          shopTypes: response.rows.map(shopType => ({
            ...shopType,
            imageUrl: shopType.photo_url || shopType.icon_url
          }))
        };
      }

      return null;
    })
  }
);

// Slice:

const categoriesSlice = createSlice({
  name: 'categories',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderTypes.fulfilled, (state, action) => {
        const orderTypes = action.payload;

        if (Array.isArray(orderTypes)) orderTypesAdapter.setAll(state, orderTypes);

        state.isLoading = false;
      })
      .addCase(fetchOrderTypes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchShopTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchShopTypes.fulfilled, (state, action) => {
        const { orderTypeId, shopTypes } = action.payload;

        if (Array.isArray(shopTypes)) {
          const shopTypesWithId = shopTypes.map(shopType => ({ ...shopType, id: shopType.code }));

          if (state.entities[orderTypeId]) {
            state.entities[orderTypeId].shopTypes = shopTypesWithId;
          } else {
            state.entities[orderTypeId] = { shopTypes: shopTypesWithId };
          }
        }

        state.isLoading = false;
      })
      .addCase(fetchShopTypes.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default categoriesSlice.reducer;

// Selectors:

export const {
  selectAll: selectOrderTypes,
  selectById: selectOrderTypeById
} = orderTypesAdapter.getSelectors(state => state.categories);

export const selectIsLoading = state => state.categories.isLoading;

export const selectShopTypesByOrderTypeId = createSelector(
  selectOrderTypeById,
  orderType => orderType?.shopTypes
);
