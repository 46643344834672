import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {useIntl } from 'react-intl';


import Input from '@youship/components/objects/input';
import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';

import greenCheckIcon from 'images/icons/check-green.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';


const MenuCategoryModal = ({
  data,
  isLoading,
  errorMessageModal,
  text,
  title,
  notice,
  finalMessage,
  onClose,
  onSave
}) => {

  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState(errorMessageModal || null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const [formData, setFormData] = useState({
    name: data?.name || '',
    state: data?.state || false,
  });

  const handleInputChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleToggle = () => {
    const updatedIsOn = !isOn;
    setIsOn(updatedIsOn);
    setFormData({ ...formData, state: updatedIsOn });
  };

  useEffect(() => {
    setIsOn(formData.state);
    setIsFormValid(formData.name !== '' && formData.state !== null);
  }, [formData]);

  const handleFormSaveClick = (event) => {
    event.preventDefault();

    setErrorMessage(null);

    onSave(event, formData);
  };
 
  return (
    <Modal
      classNames="user-modal"
      footer={
        <div className="user-modal__footer">
          { (errorMessage || errorMessageModal) && (
            <p className="user-modal__error-message">
               {errorMessage || errorMessageModal}
            </p>
          )}
          {finalMessage ? (
              <Button
                classNames="user-modal__button"
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                noArrow
                text={intl.formatMessage({ id: 'app.close' })}
                onClick={onClose}
              />
          ) : (  
            <Button
              classNames="user-modal__button"
              context="primary"
              disabled={isLoading || !isFormValid}
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'app.save' })}
              onClick={!isLoading ? event => handleFormSaveClick(event) : () => {}}
            />
          )}

        </div>
      }
      title={text || title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="user-modal__content">
        { (!!finalMessage || !!notice) && (
          <>
            <Notice
              classNames="action-modal__notice"
              description={finalMessage || notice}
              fixed
              icon={finalMessage ? greenCheckIcon : noticeIcon}
            />
          </>  
        )}
        { (!finalMessage && !notice) && (
        <>
        <div className="user-modal__item">
          <Input
              block
              label= {intl.formatMessage({ id: 'menu.form.name.label' })}
              type="text"
              value={formData.name}
              onChange={e => handleInputChange(e.target.value, 'name')}
            />
        </div>
        <div className="user-modal__item">
          <label
              className="input-label"
          >
            {intl.formatMessage({ id: 'menu.form.state.label' })}
          </label>

          <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
            <div className="slider" />
          </div>
          
        </div>
        </>
        )}

      </div>
    </Modal>
  );
};

MenuCategoryModal.propTypes = {
  errorMessageModal: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

MenuCategoryModal.defaultProps = {
  errorMessageModal: null,
  isLoading: false,
  onClose: () => {},
  onSave: () => {}
};

export default MenuCategoryModal;
