import BaseApi from '..';
import {
  SHOP_CANCEL,
  SHOP_DELIVERIES_ACTIVE,
  SHOP_DELIVERIES_HISTORY,
  SHOP_PRODUCT_STOCK,
  SHOP_SIGNUP,
  SHOP_LIST_ALL_MENUS,
  SHOP_ADD_MENU,
  SHOP_GET_MENU,
  SHOP_EDIT_MENU,
  SHOP_REMOVE_MENU,
  SHOP_ADD_CATEGORY,
  SHOP_EDIT_CATEGORY,
  SHOP_ASSIGN_CATEGORY,
  SHOP_REMOVE_CATEGORY,
  SHOP_REMOVE_OPTION,
  SHOP_REMOVE_EXTRA,
  SHOP_REMOVE_PRODUCT,
  SHOP_EDIT_EXTRA, SHOP_ADD_EXTRA, SHOP_EDIT_OPTION, SHOP_ADD_OPTION, SHOP_EDIT_PRODUCT, SHOP_ADD_PRODUCT
} from '../endpoints';


export default class ShopApi extends BaseApi {
  /* eslint-disable camelcase */

  static cancelSignUpRequest = () => this.post(SHOP_CANCEL);

  static signUpAsShop = ({
    company_name,
    companycode, // optional
    business_name,
    description,
    address,
    postalcode,
    city,
    vat,
    about,
    schedule,
    bank_name, // optional
    bank_account // optional
  }) => {
    const data = {
      address,
      company_name,
      description,
      postalcode,
      city,
      vat,
      about,
      schedule
    };

    if (bank_account) data.bank_account = bank_account;
    if (bank_name) data.bank_name = bank_name;
    if (business_name) data.business_name = business_name;
    if (companycode) data.companycode = companycode;

    return this.post(SHOP_SIGNUP, data);
  };

  static activeDeliveries = ({
    grouped = '', // optional: true/false -> default=true
    str = '', // optional
    reference = '', // optional
    datefrom = '', // optional
    dateto = '', // optional
    user_code = '', // optional
    tab = "", // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(SHOP_DELIVERIES_ACTIVE, {
    grouped,
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    tab,
    pages
  });

  static historyDeliveries = ({
    grouped = '', // optional: true/false -> default=true
    str = '', // optional
    reference = '', // optional
    datefrom = '', // optional
    dateto = '', // optional
    user_code = '', // optional
    tab = "", // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(SHOP_DELIVERIES_HISTORY, {
    grouped,
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    tab,
    pages
  });

  static setProductAvailable = ({
    product_code,
    available
  }) => this.post(SHOP_PRODUCT_STOCK, {
    product_code,
    available
  });



  static listAllMenus = () => this.post(SHOP_LIST_ALL_MENUS);


  static getMenu = ({ category_code }) => this.post(SHOP_GET_MENU, 
      {category_code });

  static addMenu = ({ name, state }) => this.post(SHOP_ADD_MENU,
      {name,state });


  static editMenu = ({ category_code, name, state }) => this.post(SHOP_EDIT_MENU,
      {category_code,name,state });

  static removeMenu = ({ category_code }) => this.post(SHOP_REMOVE_MENU,
      {category_code});

  static addCategory = ({ name, menu_code, state }) => this.post(SHOP_ADD_CATEGORY,
      {name,menu_code,state });

  static editCategory = ({ category_code, name, state }) => this.post(SHOP_EDIT_CATEGORY,
      {category_code,name,state });

  static removeCategory = ({ category_code }) => this.post(SHOP_REMOVE_CATEGORY,
      {category_code});

    static assignCategory = ({ name, menu_code, assign }) => this.post(SHOP_ASSIGN_CATEGORY,
      {name,menu_code,assign });

  static addProduct = ({name, category_code, state, small_description, description, photo, unitprice, limit
  }) => this.post(SHOP_ADD_PRODUCT,
      {name, category_code, state, small_description, description, photo, unitprice, limit});

  static editProduct = ({name, product_code, state, small_description, description, photo, unitprice, limit }) => this.post(SHOP_EDIT_PRODUCT,
      {name, product_code, state, small_description, description, photo, unitprice, limit });

  static removeProduct = ({ product_code }) => this.post(SHOP_REMOVE_PRODUCT,
      {product_code});

  static addOption = ({ product_code, name, state, subtitle, mandatory, limit, }) => this.post(SHOP_ADD_OPTION,
      {product_code, name, state, subtitle, mandatory, limit });

  static editOption = ({ category_code,  name, state, subtitle, mandatory, limit }) => this.post(SHOP_EDIT_OPTION,
      {category_code,  name, state, subtitle, mandatory, limit});


  static removeOption = ({ category_code }) => this.post(SHOP_REMOVE_OPTION,
      {category_code});

  static addExtra = ({ category_code, name, state, subtitle, unitprice, limit }) => this.post(SHOP_ADD_EXTRA,
      {category_code, name, state, subtitle, unitprice, limit });

  static editExtra = ({product_code, name, state, subtitle, unitprice, limit }) => this.post(SHOP_EDIT_EXTRA,
      { product_code, name, state, subtitle, unitprice, limit});


  static removeExtra = ({ product_code }) => this.post(SHOP_REMOVE_EXTRA,
      {product_code});

}
