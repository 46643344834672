/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  requestCheckoutQuote,
  selectContactName,
  selectContactPhoneCountryCode,
  selectContactPhoneNumber,
  selectDropoffAddress,
  selectShoppingCartItems,
  selectShopId as selectShoppingCartShopId,
  selectUserCompanyForContact,
  setUserCompanyForContact,
  setUserDefaultAddressForDropoff,
  setUserNameForContact,
  setUserPhoneCountryCodeForContact,
  setUserPhoneNumberForContact
} from 'store/slices/shopping-cart';
import { selectShopById } from 'store/slices/shops';

import CheckoutOptions from 'components/checkout-options';
import CheckoutPaymentModal from 'components/checkout-payment-modal';
import ShoppingCart from 'components/shopping-cart';

import chevronLeftIcon from 'images/icons/chevron-left.svg';

import './styles.scss';


const CheckoutDetails = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const contactName = useSelector(selectContactName);
  const contactCompany = useSelector(selectUserCompanyForContact);
  const contactPhoneCountryCode = useSelector(selectContactPhoneCountryCode);
  const contactPhoneNumber = useSelector(selectContactPhoneNumber);
  const dropoffAddress = useSelector(selectDropoffAddress);
  const shoppingCartItems = useSelector(selectShoppingCartItems);
  const shoppingCartShopId = useSelector(selectShoppingCartShopId);
  const shoppingCartShop = useSelector(state => selectShopById(state, shoppingCartShopId));

  useEffect(() => {
    // There is no need to fetch the shop from the shopping cart because that's already being done inside the shopping cart component
    if (!shoppingCartShopId && typeof shoppingCartShopId !== 'number') {
      history.push('/');
    }
  }, [dispatch, history, shoppingCartShopId]);

  //Se nao existem estes dados, coloca o contact e phone do user logado (state.autentication.user....) no state para ficar acessivel no checkout
  useEffect(() => {
    if (!contactName) dispatch(setUserNameForContact());
    if (!contactPhoneCountryCode) dispatch(setUserPhoneCountryCodeForContact());
    if (!contactPhoneNumber) dispatch(setUserPhoneNumberForContact());
    if (!contactCompany) dispatch(setUserCompanyForContact());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  //Coloca a default address do user no state para ficar acessivel no checkout
  useEffect(() => {
    if (shoppingCartItems.length) {
      // useEffect callback can not be async
      (async () => {
        if (!dropoffAddress) {
          await dispatch(setUserDefaultAddressForDropoff())
            .then(async (response) => {
              if (response?.error) throw new Error(response.error.message || 'Something went wrong setting user default address for dropoff.');

              return response;
            })
            .catch((error) => {
              // TODO: add proper error handling
              // eslint-disable-next-line no-console
              console.error(error);
            });
        }

        dispatch(requestCheckoutQuote())
          .then((requestCheckoutQuoteResponse) => {
            if (requestCheckoutQuoteResponse?.error) throw new Error(requestCheckoutQuoteResponse.error.message || 'Something went wrong while requesting a quote for your order.');

            return requestCheckoutQuoteResponse;
          })
          .catch((error) => {
            // TODO: add proper error handling
            // eslint-disable-next-line no-console
            console.error(error);
          });
      })();
    } else {
      history.push('/');
    }

    // dropoffAddress is not subscribed to prevent hook from running when it changes, the usage of async already ensures that requestCheckoutQuote awaits for it
  }, [dispatch, history, shoppingCartItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckoutPaymentModalClose = () => {
    history.push('/orders');
  };

  return (
    <div className="checkout-details">
      <div className="container">
        {!!shoppingCartShop && (shoppingCartShop.id || typeof shoppingCartShop.id === 'number') && (
          <Link
            className="checkout-details__previous"
            to={`/venue/${shoppingCartShop.id}`}
          >
            <img
              alt={`Go to ${shoppingCartShop.name || 'Venue'} page}`}
              className="checkout-details__previous-icon"
              src={chevronLeftIcon}
            />
            {shoppingCartShop.name || 'Store'}
          </Link>
        )}
        <div className="row">
          <div className="col col-12 col-md-6">
            <CheckoutOptions />
          </div>
          <div className="col col-12 col-md-6 col-lg-4 offset-lg-2">
            <ShoppingCart />
          </div>
        </div>
      </div>
      <CheckoutPaymentModal onClose={handleCheckoutPaymentModalClose} />
    </div>
  );
};

export default CheckoutDetails;
