import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { getAddresses, selectUserAddresses } from 'store/slices/user-addresses';

import Dropdown from '@youship/components/objects/dropdown';
import Input from '@youship/components/objects/input';
import InputPlaceholder from '@youship/components/objects/input-placeholder';

import bookIcon from '@youship/assets/images/icons/book.svg';

import './styles.scss';

const AddressForm = forwardRef(({
  address,
  addressIsChanging,
  classNames,
  compact,
  fullWidth,
  isInModal,
  mapIsLoading,
  onChange,
  onSearchInputChange,
  onSubmit,
  searchAddress,
  showCountryInput,
  type
  /*userAddress*/
}, ref) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  //lista de moradas do user
  const userAddresses = useSelector(selectUserAddresses);

  const [showUserAddressesDropdown, setShowUserAddressesDropdown] = useState(false);
  const [userAddressSearchTerm, setUserAddressSearchTerm] = useState('');

  const handleAddressChange = (googleAddress, coordinates) => {
    const addressComponents = googleAddress?.address_components;

    if (Array.isArray(addressComponents) && addressComponents.length) {
      const formattedAddressData = { addressComponents: {} };

      formattedAddressData.lat = coordinates?.lat ?? googleAddress.geometry.location.lat();
      formattedAddressData.lng = coordinates?.lng ?? googleAddress.geometry.location.lng();
      formattedAddressData.formattedAddress = googleAddress.formatted_address;

      console.log("Address Form - handleAddressChange:");
      console.log(googleAddress);

      addressComponents.forEach((addressComponent) => {
        if (Array.isArray(addressComponent?.types) && addressComponent.types.length) {
          if (addressComponent.types.includes('postal_code')) {
            formattedAddressData.postalCode = addressComponent.long_name;
            formattedAddressData.addressComponents.postalCode = addressComponent.long_name;
          } else if (addressComponent.types.includes('locality')) {
            formattedAddressData.city = addressComponent.long_name;
            formattedAddressData.addressComponents.city = addressComponent.long_name;
          } else if (addressComponent.types.includes('street_number')) {
            formattedAddressData.numberAddress = addressComponent.long_name;
            formattedAddressData.addressComponents.numberAddress = addressComponent.long_name;
          } else if (addressComponent.types.includes('route')) {
            formattedAddressData.streetAddress = addressComponent.long_name;
            formattedAddressData.addressComponents.route = addressComponent.long_name;
          } else if (addressComponent.types.includes('sublocality')) {
            formattedAddressData.addressComponents.sublocality = addressComponent.long_name;
          } else if (addressComponent.types.includes('country')) {
            formattedAddressData.countryCode = addressComponent.short_name;
            formattedAddressData.addressComponents.country = addressComponent.short_name;
          }
        }
      });

      //fill streetAddress when streetAddress is empty
      if( !formattedAddressData.streetAddress ){
        let lines = formattedAddressData.formattedAddress.split(',');
        if( lines.length >= 4 ){
          formattedAddressData.streetAddress = lines[0] + ", " + lines[1];
        }else{
          formattedAddressData.streetAddress = lines[0];
        }
      }

    
      /*
      if (
        formattedAddressData.city !== userAddress?.city ||
        formattedAddressData.countryCode !== userAddress?.countryCode ||
        formattedAddressData.numberAddress !== userAddress?.numberAddress ||
        formattedAddressData.postalCode !== userAddress?.postalCode ||
        formattedAddressData.streetAddress !== userAddress?.streetAddress
      ) {
        onAddressChange({
          city: '',
          countryCode: '',
          numberAddress: '',
          postalCode: '',
          streetAddress: '',
          ...formattedAddressData
        });
      }
      */

      onAddressChange({
        city: '',
        countryCode: '',
        numberAddress: '',
        postalCode: '',
        streetAddress: '',
        ...formattedAddressData
      });
    
      if (formattedAddressData.streetAddress) {
        onSearchInputChange(formattedAddressData.streetAddress);
      }
    }
  };

  const handleEnterKeyClick = (event) => {
    //escape - 27, ENTER - 13
    if (event.keyCode === 13 || event.keyCode === 27) {
      event.preventDefault();
      //guarda o nome da rua que o user escreveu
      onAddressChange({ streetAddress: event?.target?.value ?? '' });
    }
  };

  const onAddressChange = (value, contact) => {
    const formAddress = {
      ...address,
      ...value
    };

    console.log("onAddressChange - formAddress:");
    console.log(formAddress);

    onChange(formAddress, contact);
  };

  const handleUserAddressClick = () => {
    setShowUserAddressesDropdown(true);
  };

  const handleUserAddressesDropdownClose = () => {
    setShowUserAddressesDropdown(false);
  };

  const handleUserAddressSelectClick = (index) => {
    const selectedAddress        = userAddresses[index].address;
    const selectedAddressContact = userAddresses[index].contact;
    const selectedAddressCode    = userAddresses[index].addressCode;

    const selectedAddressData = {
      apartmentAddress: selectedAddress.apartmentAddress,
      city: selectedAddress.city,
      countryCode: selectedAddress.countryCode,
      formattedAddress: selectedAddress.formattedAddress,
      lat: selectedAddress.lat,
      lng: selectedAddress.lng,
      numberAddress: selectedAddress.numberAddress,
      postalCode: selectedAddress.postalCode,
      streetAddress: selectedAddress.streetAddress,
      addressCode: selectedAddressCode
    };

    //console.log("handleUserAddressSelectClick:");
    //console.log(selectedAddressData);

    const selectedAddressContactData = {
      ...selectedAddressContact,
      countryCallingCode: selectedAddressContact.phonecode.replace("+", "")
    };

    if (type === 'user') {
      onSearchInputChange(selectedAddressData.streetAddress);
      onAddressChange({
        city: '',
        countryCode: '',
        numberAddress: '',
        postalCode: '',
        streetAddress: '',
        ...selectedAddressData
      }, selectedAddressContactData);
    } else if (
      selectedAddressData.apartmentAddress !== address?.apartmentAddress ||
      selectedAddressData.city !== address?.city ||
      selectedAddressData.countryCode !== address?.countryCode ||
      selectedAddressData.numberAddress !== address?.numberAddress ||
      selectedAddressData.postalCode !== address?.postalCode ||
      selectedAddressData.streetAddress !== address?.streetAddress
    ) {
      onSearchInputChange(selectedAddressData.streetAddress);
      onAddressChange({
        city: '',
        countryCode: '',
        numberAddress: '',
        postalCode: '',
        streetAddress: '',
        ...selectedAddressData
      }, selectedAddressContactData);
    } else {
      onSearchInputChange(selectedAddressData.streetAddress);
      onAddressChange({
        city: '',
        countryCode: '',
        numberAddress: '',
        postalCode: '',
        streetAddress: '',
        ...selectedAddressData
      }, selectedAddressContactData);
    }

    setShowUserAddressesDropdown(false);
  };

  const filteredUserAddresses = userAddressSearchTerm && Array.isArray(userAddresses) ?
    userAddresses.filter(entry => entry?.strAddress && entry.strAddress.toLowerCase().includes(userAddressSearchTerm.toLowerCase())) :
    userAddresses;

  useEffect(
    () => {
      dispatch(getAddresses({
        pages: {
          current: 0,
          resultsbypage: 100
        }
      }))
        .catch((error) => {
          // Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    if (isInModal) {
      document.body.classList.add('has-address-form-inside-modal');
    }

    return () => {
      document.body.classList.remove('has-address-form-inside-modal');
    };
  }, [isInModal]);

  return (
    <form
      ref={ref}
      className={`address-form ${classNames ? classNames : ''} ${isInModal ? 'address-form--is-in-modal' : ''}`}
      onSubmit={onSubmit}
    >
      <div className={`address-form__inputs ${fullWidth ? 'address-form__inputs--full-width' : ''}`}>
        <div className="address-form__input-group">
          <div className="address-form__address-input-wrapper">
            <InputPlaceholder
              items={[33]}
              loading={addressIsChanging}
            >
              <Input
                block
                inputId={`${type}-address`}
                label={intl.formatMessage({ id: 'map.street_address.label' })}
                placeholder={!addressIsChanging ? intl.formatMessage({ id: 'map.street_address.placeholder' }) : ''}
                role="presentation"
                type="address"
                value={!addressIsChanging ? searchAddress : ''}
                onAddressChange={handleAddressChange}
                onChange={event => onSearchInputChange(event?.target?.value ?? '')}
                onKeyDown={handleEnterKeyClick}
              />
            </InputPlaceholder>
            {Array.isArray(userAddresses) && userAddresses.length > 0 && (
              <>
                <img
                  alt="User addresses"
                  className="address-form__user-addresses-icon"
                  src={bookIcon}
                  onClick={() => {
                    if (!mapIsLoading || !addressIsChanging) handleUserAddressClick();
                  }}
                />
                <Dropdown
                  fullWidth
                  scrollable
                  show={showUserAddressesDropdown}
                  onClose={handleUserAddressesDropdownClose}
                >
                  <input
                    className="input input--search input--block"
                    value={userAddressSearchTerm}
                    onChange={event => setUserAddressSearchTerm(event?.target?.value ?? '')}
                  />
                  <ul className="user-addresses-dropdown">
                    {Array.isArray(filteredUserAddresses) && filteredUserAddresses.map((entry, index) => (
                      <li
                        key={index}
                        className="user-addresses-dropdown__item"
                        onClick={() => {
                          handleUserAddressSelectClick(index);
                        }}
                      >
                        {entry?.lineAddress}
                      </li>
                    ))}
                  </ul>
                </Dropdown>
              </>
            )}
          </div>
        </div>
        <div className={`address-form__input-group address-form__input-group--grid ${compact ? 'address-form__input-group--is-grid-compact' : ''}`}>
          {/* Input fields blocked until user chooses an address */}
          {/* Door, Floor, Postal-Code and City */}
          <InputPlaceholder
            items={[40]}
            loading={addressIsChanging}
          >
            <Input
              block
              disabled={!(address?.streetAddress)}
              inputId={`${type}-door`}
              label={intl.formatMessage({ id: 'map.number_address.label' })}
              placeholder={intl.formatMessage({ id: 'map.number_address.placeholder' })}
              role="presentation"
              value={(!addressIsChanging ? address?.numberAddress : '') || ''}
              onChange={event => onAddressChange({ numberAddress: event?.target?.value ?? '' })}
            />
          </InputPlaceholder>
          <InputPlaceholder
            items={[40]}
            loading={addressIsChanging}
          >
            <Input
              block
              disabled={!(address?.streetAddress)}
              inputId={`${type}-floor`}
              label={intl.formatMessage({ id: 'map.apartment_address.label' })}
              placeholder={intl.formatMessage({ id: 'map.apartment_address.placeholder' })}
              role="presentation"
              value={(!addressIsChanging ? address?.apartmentAddress : '') || ''}
              onChange={event => onAddressChange({ apartmentAddress: event?.target?.value ?? '' })}
            />
          </InputPlaceholder>
          <InputPlaceholder
            items={[50, 50]}
            loading={addressIsChanging}
          >
            <Input
              block
              disabled={!(address?.streetAddress)}
              inputId={`${type}-postal-code`}
              label={intl.formatMessage({ id: 'map.postalcode.label' })}
              placeholder={!addressIsChanging ? intl.formatMessage({ id: 'map.postalcode.placeholder' }) : ''}
              role="presentation"
              value={(!addressIsChanging ? address?.postalCode : '') || ''}
              onChange={event => onAddressChange({ postalCode: event?.target?.value ?? '' })}
            />
          </InputPlaceholder>
          <InputPlaceholder
            items={[50]}
            loading={addressIsChanging}
          >
            <Input
              block
              disabled={!(address?.streetAddress)}
              inputId={`${type}-city`}
              label={intl.formatMessage({ id: 'map.city.label' })}
              placeholder={intl.formatMessage({ id: 'map.city.placeholder' })}
              role="presentation"
              value={(!addressIsChanging ? address?.city : '') || ''}
              onChange={event => onAddressChange({ city: event?.target?.value ?? '' })}
            />
          </InputPlaceholder>
        </div>
        {showCountryInput && (
          <InputPlaceholder
            items={[50]}
            loading={addressIsChanging}
          >
            <Input
              block
              disabled
              inputId="location"
              label={intl.formatMessage({ id: 'map.country.label' })}
              placeholder={intl.formatMessage({ id: 'map.country.placeholder' })}
              type="text"
              value={address.countryCode || ''}
            />
          </InputPlaceholder>
        )}
      </div>
    </form>
  );
});

AddressForm.propTypes = {
  address: PropTypes.shape({
    apartmentAddress: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    numberAddress: PropTypes.string,
    postalCode: PropTypes.string,
    streetAddress: PropTypes.string
  }),
  addressIsChanging: PropTypes.bool,
  classNames: PropTypes.string,
  compact: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isInModal: PropTypes.bool,
  mapIsLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  searchAddress: PropTypes.string,
  showCountryInput: PropTypes.bool,
  type: PropTypes.string,
  userAddress: PropTypes.shape({
    apartmentAddress: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    numberAddress: PropTypes.string,
    postalCode: PropTypes.string,
    streetAddress: PropTypes.string
  })
};

AddressForm.defaultProps = {
  address: null,
  addressIsChanging: false,
  classNames: null,
  compact: false,
  fullWidth: false,
  isInModal: false,
  mapIsLoading: false,
  onChange: () => {},
  onSearchInputChange: () => {},
  onSubmit: () => {},
  searchAddress: null,
  showCountryInput: false,
  type: 'pickup',
  userAddress: null
};

export default AddressForm;
