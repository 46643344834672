import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import ShoppingApi, { CHECKOUT_DEFAULT_TIME_INTERVAL } from '@youship/api/shopping';
import SessionStorageManager from '@youship/utils/SessionStorageManager';

import formatPrice from '@youship/utils/format-price';


const shoppingCartAdapter = createEntityAdapter({
  selectId: item => item.index,
  sortComparer: (a, b) => a - b
});

const initialState = shoppingCartAdapter.getInitialState({
  checkoutQuote: null,

  checkoutQuoteError: null,

  checkoutQuoteIsLoading: false,

  checkoutQuoteVoucherCode: null,

  contactName: null,

  company: null,

  contactPhoneCountryCode: null,

  contactPhoneNumber: null,

  customization: null, // for the customization modal: setting customization to an object with a truthy product value opens the modal

  dropoffAddress: null,

  dropoffSchedule: {
    date: new Date().toISOString(),
    timeSlotValue: CHECKOUT_DEFAULT_TIME_INTERVAL
  },

  order: null,

  orderError: null,

  orderIsLoading: null,

  paymentIsInProcess: false, // for the payment modal: setting paymentIsInProcess to true opens the modal

  paymentTypeId: null,

  shopId: null,

  voucherCode: null // for the voucher modal: setting voucherCode to a string opens the modal
});

// Thunks:

export const createOrder = createAsyncThunk(
  'shoppingCart/createOrder',
  (argument, { getState }) => {
    const state = getState().shoppingCart;

    if (!state) return Promise.reject(new Error('Unable to access shopping cart data.'));

    const { checkoutQuote, checkoutQuoteVoucherCode, dropoffSchedule, paymentTypeId } = state;

    if (!checkoutQuote) return Promise.reject(new Error('A checkout quote must be requested before creating an order.'));
    if (!dropoffSchedule) return Promise.reject(new Error('A dropoff schedule must be selected before creating an order.'));
    if (!paymentTypeId) return Promise.reject(new Error('A payment method must be selected before creating an order.'));

    const { dropoffAddress, products, shopId } = checkoutQuote;
    const { date, timeSlotValue } = dropoffSchedule;

    if (!dropoffAddress) return Promise.reject(new Error('The stored checkout quote does not include a dropoff address.'));
    if (!Array.isArray(products) || !products.length) return Promise.reject(new Error('The stored checkout quote does not include a product list.'));
    if (!shopId) return Promise.reject(new Error('The stored checkout quote does not include a shop code.'));

    if (!date) return Promise.reject(new Error('A dropoff schedule date must be selected before creating an order.'));
    if (!timeSlotValue) return Promise.reject(new Error('A dropoff schedule time slot must be selected before creating an order.'));

    const schedule = `${date} ${timeSlotValue}`;

    /* eslint-disable camelcase */
    const data = {
      shop_code: shopId,
      payment_code: paymentTypeId,
      dropoff: {
        address: dropoffAddress,
        schedule: {
          deadline: schedule
        }
      },
      products
    };

    if (checkoutQuoteVoucherCode) data.voucher = checkoutQuoteVoucherCode;
    /* eslint-enable camelcase */

    return ShoppingApi.createOrder(data)
      .then((response) => {
        const { order_code: id, payment_url: paymentUrl, ys_token: ysToken } = response;

        const { origin } = window.location;

        if (id) {
          const payload = {
            ...response,
            id
          };

          if (paymentUrl && ysToken) {
            const paymentUrlWithReturn = `${paymentUrl}&ys_return_url=${origin}/order/${id}/cpay`;

            payload.paymentUrl = paymentUrl;
            payload.paymentUrlWithReturn = paymentUrlWithReturn;
            payload.ysToken = ysToken;

            // Redirect to orders page, which will redirect to external payment page, which in its turn will redirect to order page when the process is complete
            payload.redirectUrl = `orders?${new URLSearchParams({ redirectUrl: paymentUrlWithReturn }).toString()}`;
          } else {
            // Redirect directly to order page if no payment is required
            payload.redirectUrl = `/order/${id}`;
          }

          return payload;
        }

        throw new Error('Unexpected response while creating your order.');
      });
  }
);

export const requestCheckoutQuote = createAsyncThunk(
  'shoppingCart/requestCheckoutQuote',
  (argument, { getState }) => {
    const state = getState().shoppingCart;

    if (!state) return Promise.reject(new Error('Unable to access shopping cart data.'));

    const { dropoffAddress, dropoffSchedule, entities, ids, shopId, checkoutQuoteVoucherCode } = state;
    const items = ids.map(id => entities[id]);

    if (!shopId && typeof shopId !== 'number') return Promise.reject(new Error('Invalid shop code.'));
    if (!items.length) return Promise.reject(new Error('Shopping cart is empty.'));

    /* eslint-disable camelcase */
    if (!dropoffAddress) return Promise.reject(new Error('Dropoff address is invalid.'));

    const { city, countryCode, lat, lng, postalCode, streetAddress } = dropoffAddress;

    const checkoutQuoteDropoffAddress = {};

    if (typeof lat === 'number' && typeof lng === 'number') {
      checkoutQuoteDropoffAddress.lat = lat;
      checkoutQuoteDropoffAddress.lng = lng;
    }

    if (streetAddress) {
      checkoutQuoteDropoffAddress.street_address = streetAddress;
    }
    if (postalCode) {
      checkoutQuoteDropoffAddress.postalcode = postalCode;
    }
    if (city) {
      checkoutQuoteDropoffAddress.city = city;
    }
    if (countryCode) {
      checkoutQuoteDropoffAddress.countrycode = countryCode;
    }

    if (!Object.keys(checkoutQuoteDropoffAddress).length) return Promise.reject(new Error('Dropoff address is invalid.'));

    const requestDropoffAddress = {
      ...dropoffAddress,
      ...checkoutQuoteDropoffAddress
    };

    const dropoff = {
      address: requestDropoffAddress
    };

    let requestDopoffSchedule = null;

    // Add dropoff schedule only if it has been changed from the default value
    if (dropoffSchedule) {
      const { date, timeSlotValue } = dropoffSchedule;

      if (date !== new Date().toISOString() && timeSlotValue !== CHECKOUT_DEFAULT_TIME_INTERVAL) {
        const schedule = `${date} ${timeSlotValue}`;

        requestDopoffSchedule = dropoffSchedule;
        dropoff.schedule = { deadline: schedule };
      }
    }

    const products = [];

    items.forEach((item) => {
      const { choices, product, quantity } = item;

      if (product && quantity) {
        const checkoutProduct = {
          product_code: item.product.id,
          quantity: item.quantity
        };

        if (choices) {
          const extras = [];
          const optionsIds = Object.keys(choices);

          optionsIds.forEach((optionId) => {
            const option = choices[optionId];

            if (option) {
              const extrasIds = Object.keys(option);

              extrasIds.forEach((extraId) => {
                const extraQuantity = option[extraId];

                if (extraQuantity) extras.push({ product_code: extraId, quantity: extraQuantity });
              });
            }
          });

          if (extras.length) checkoutProduct.extras = extras;
        }

        products.push(checkoutProduct);
      }
    });

    const data = {
      shop_code: shopId,
      dropoff,
      products
    };

    if (checkoutQuoteVoucherCode) data.voucher = checkoutQuoteVoucherCode;
    /* eslint-enable camelcase */

    return ShoppingApi.checkoutQuote(data)
      .then((response) => {
        if (!response?.quote) throw new Error('Unexpected response while requesting a quote for your order.');

        const { quote } = response;
        const {
          payment_message: paymentMessage,
          info_message: infoMessage,
          payment_types: paymentTypesFromQuote,
          price_details: priceDetailsFromQuote,
          schedule_intervals: scheduleIntervalsFromQuote,
          total
        } = quote;

        //  throw new Error será apanhado no state rejected e passa para a paganina no: state.checkoutQuoteError = action?.error
        // Make sure that there are schedule intervals, since a valid schedule is required to create order
        if (!Array.isArray(scheduleIntervalsFromQuote) || !scheduleIntervalsFromQuote.length) {
          throw new Error('The provided quote did not include schedule intervals.');
        }

        // Make sure that there are payment options
        if (!Array.isArray(paymentTypesFromQuote) || !paymentTypesFromQuote.length) {
          throw new Error('The provided quote did not include payment options.');
        }
        

        const priceDetails = Array.isArray(priceDetailsFromQuote) ? priceDetailsFromQuote : [];

        // Last entry of quote.price_details should be the total:
        // * set useTotalFromPriceDetails to true to use it as total (shopping-cart will use the entry.text as label)
        // * set useTotalFromPriceDetails to false to ignore it and use the quote.total (shopping-cart will use a custom label and include total.text as a note)
        const useTotalFromPriceDetails = true;
        let priceDetailsIncludesTotal = false;

        // Remove 'total' entry from price details if it is present
        if (priceDetails.length && typeof total.value === 'number' && priceDetails[priceDetails.length - 1].value === total.value) {
          // It’s considered a 'total' entry if it’s the last entry with the same value as total
          if (!useTotalFromPriceDetails) priceDetails.pop();
          priceDetailsIncludesTotal = true;
        }

        const scheduleIntervals = scheduleIntervalsFromQuote
          .map(day => ({
            date: day?.date?.datestring,
            timeSlots: Array.isArray(day?.timeinterval) ?
              day.timeinterval.map(({ hour, minute, timestring }) => ({
                label: timestring,
                value: `${hour < 10 ? 0 : ''}${hour}:${minute < 10 ? 0 : ''}${minute}`
              })) :
              []
          }))
          .filter(({ date, timeSlots }) => date && timeSlots.length);

        const paymentTypes = paymentTypesFromQuote
          .map(paymentType => ({
            ...paymentType,
            id: paymentType.code,
            imageUrl: paymentType.icon_url
          }))
          .filter(({ id, name }) => (id || typeof id === 'number') && name);

        const payload = {
          ...quote,
          dropoffAddress: requestDropoffAddress,
          paymentMessage,
          infoMessage,
          paymentTypes,
          priceDetails,
          products,
          scheduleIntervals,
          shopId,
          total: useTotalFromPriceDetails && priceDetailsIncludesTotal ? null : total
        };

        if (requestDopoffSchedule) payload.dropoffSchedule = requestDopoffSchedule;

        return payload;
      });
  }
);

export const setUserDefaultAddressForDropoff = createAsyncThunk(
  'shoppingCart/setUserDefaultAddressForDropoff',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const { defaultAddress } = user;

    if (!defaultAddress) return Promise.reject(new Error('User data does not include a default address.'));

    return Promise.resolve(defaultAddress);
  }
);

export const setUserNameForContact = createAsyncThunk(
  'shoppingCart/setUserNameForContact',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const { firstName, lastName, name } = user;

    if (!name && !firstName && !lastName) return Promise.reject(new Error('User data does not include a name.'));

    const payload = name ? name : [firstName, lastName].join(' ');

    return Promise.resolve(payload);
  }
);

export const setUserCompanyForContact = createAsyncThunk(
  'shoppingCart/setUserCompanyForContact',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const company = user?.defaultAddress?.contact?.company;

    if (company) return Promise.reject(new Error('User data does not include a company.'));

    return Promise.resolve(company);
  }
);

export const setUserPhoneCountryCodeForContact = createAsyncThunk(
  'shoppingCart/setUserPhoneCountryCodeForContact',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const { phone } = user;

    if (!phone) return Promise.reject(new Error('User data does not include phone data.'));

    const { code } = phone;

    if (!code) return Promise.reject(new Error('User data does not include a phone country code'));

    const payload = code;

    return Promise.resolve(payload);
  }
);

export const setUserPhoneNumberForContact = createAsyncThunk(
  'shoppingCart/setUserPhoneNumberForContact',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const { phone, phoneNumber } = user;

    if (!phone?.number && !phoneNumber) return Promise.reject(new Error('User data does not include a phone number'));

    const payload = phone?.number || phoneNumber;

    return Promise.resolve(payload);
  }
);


// Slice:

const shoppingCartSlice = createSlice({
  name: 'shoppingCart',

  initialState,

  reducers: {
    addVoucherCodeToCheckoutQuote (state) {
      // A requestCheckoutQuote() should be dispatched when dispatching this reducer

      const { voucherCode } = state;

      if (voucherCode && typeof voucherCode === 'string') {
        state.checkoutQuoteVoucherCode = voucherCode;
        state.voucherCode = null;

        SessionStorageManager.setCheckoutVoucherCode(voucherCode);
      }
    },

    initialize (state) {
      const checkoutQuoteVoucherCode = SessionStorageManager.getCheckoutVoucherCode();
      const contactName = SessionStorageManager.getCheckoutContactName();
      const contactPhoneCountryCode = SessionStorageManager.getCheckoutContactPhoneCountryCode();
      const contactPhoneNumber = SessionStorageManager.getCheckoutContactPhoneNumber();
      const dropoffSchedule = SessionStorageManager.getCheckoutDropoffSchedule();
      const items = SessionStorageManager.getShoppingCartItems();
      const paymentTypeId = SessionStorageManager.getCheckoutPaymentTypeId();
      const shopId = SessionStorageManager.getShoppingCartShopId();
      // TODO: add dropoffAddress

      if (checkoutQuoteVoucherCode) state.checkoutQuoteVoucherCode = checkoutQuoteVoucherCode;
      if (contactName) state.contactName = contactName;
      if (contactPhoneCountryCode) state.contactPhoneCountryCode = contactPhoneCountryCode;
      if (contactPhoneNumber) state.contactPhoneNumber = contactPhoneNumber;
      if (dropoffSchedule?.date) state.dropoffSchedule.date = dropoffSchedule.date;
      if (dropoffSchedule?.timeSlotValue) state.dropoffSchedule.timeSlotValue = dropoffSchedule.timeSlotValue;
      if (items) shoppingCartAdapter.setAll(state, items);
      if (paymentTypeId) state.paymentTypeId = paymentTypeId;
      if (shopId) state.shopId = shopId;
    },

    removeItemByIndex (state, action) {
      const index = action.payload;

      if (typeof index === 'number') {
        const { entities, ids } = state;
        const previousItems = ids.map(id => entities[id]);
        const items = previousItems.filter(item => item.index !== index);

        // Reset indexes:
        items.forEach((item, i) => {
          item.index = i;
        });

        shoppingCartAdapter.setAll(state, items);

        SessionStorageManager.setShoppingCartItems(items);
        SessionStorageManager.getShoppingCartShopId(state.shopId);
      }
    },

    removeVoucherCodeFromCheckoutQuote (state) {
      state.checkoutQuoteVoucherCode = null;
      state.voucherCode = null;

      SessionStorageManager.removeCheckoutVoucherCode();
    },

    resetCheckoutData (state) {
      shoppingCartAdapter.setAll(state, []);
      state.contactName = null;
      state.contactPhoneCountryCode = null;
      state.contactPhoneNumber = null;
      state.dropoffAddress = null;
      state.dropoffSchedule = {
        date: new Date().toISOString(),
        timeSlotValue: CHECKOUT_DEFAULT_TIME_INTERVAL
      };
      state.voucherCode = null;
      state.paymentTypeId = null;
      state.shopId = null;

      SessionStorageManager.removeCheckoutContactName();
      SessionStorageManager.removeCheckoutContactPhoneCountryCode();
      SessionStorageManager.removeCheckoutContactPhoneNumber();
      SessionStorageManager.removeCheckoutDropoffAddress();
      SessionStorageManager.removeCheckoutDropoffSchedule();
      SessionStorageManager.removeCheckoutPaymentTypeId();
      SessionStorageManager.removeCheckoutVoucherCode();
      SessionStorageManager.removeShoppingCartItems();
      SessionStorageManager.removeShoppingCartShopId();
    },

    resetCheckoutQuote (state) {
      // Possible improvement: reset checkoutQuoteVoucherCode if checkoutQuoteError is invalid voucher

      state.checkoutQuote = null;
      state.checkoutQuoteError = null;
      state.dropoffSchedule = {
        date: new Date().toISOString(),
        timeSlotValue: CHECKOUT_DEFAULT_TIME_INTERVAL
      };
      state.paymentTypeId = null;

      // Do not store default dropoffSchedule because it was not user input
      SessionStorageManager.removeCheckoutDropoffSchedule();
      SessionStorageManager.removeCheckoutPaymentTypeId();
    },

    resetCustomization (state) {
      state.customization = null;
    },

    resetDropoffAddress (state) {
      state.dropoffAddress = null;
    },

    resetPaymentTypeId (state) {
      state.paymentTypeId = null;

      SessionStorageManager.removeCheckoutPaymentTypeId();
    },

    resetShopId (state) {
      state.shopId = null;

      SessionStorageManager.removeShoppingCartShopId();
    },

    resetShoppingCartItems (state) {
      shoppingCartAdapter.setAll(state, []);

      SessionStorageManager.removeShoppingCartItems();
    },

    resetVoucherCode (state) {
      state.voucherCode = null;
    },

    saveCustomization (state) {
      const { customization, entities, ids } = state;

      if (customization) {
        const { choices, index, product, quantity } = customization;
        const choiceKeys = Object.keys(choices);
        const items = ids.map(id => entities[id]);

        // Check if there is an item with the same exact product and extras
        const similarItemIndex = items.findIndex((item) => {
          if (item.index === index) return false;

          const itemChoiceKeys = Object.keys(item.choices);

          return item.product.id === product.id &&
            itemChoiceKeys.length === choiceKeys.length &&
            itemChoiceKeys.every(key => JSON.stringify(item.choices[key]) === JSON.stringify(choices[key]));
        });

        if (similarItemIndex !== -1) {
          // There is a similar item in the cart:

          // Update quantity
          items[similarItemIndex].quantity += quantity;

          // If item was in the cart before customization
          if (typeof index === 'number') {
            // Remove original entry
            items.splice(index, 1);

            // Reset indexes:
            items.forEach((item, i) => {
              item.index = i;
            });
          }
        } else if (typeof index === 'number') {
          // There is no similar item in the cart and item was in the cart before customization

          // Replace original entry with new one
          items.splice(index, 1, customization);
        } else {
          // There is no similar item in the cart and item was not in the cart before customization

          // Add new item
          items.push({ ...customization, index: items.length });
        }

        shoppingCartAdapter.setAll(state, items);

        state.customization = null;

        SessionStorageManager.setShoppingCartItems(items);
        SessionStorageManager.getShoppingCartShopId(state.shopId);
      }
    },

    setContactName (state, action) {
      const contactName = action.payload;

      if (contactName) {
        state.contactName = contactName;

        SessionStorageManager.setCheckoutContactName(contactName);

        // Reset order when the contact name is changed
        state.order = null;
        state.orderError = null;
      }
    },

    setContactPhoneCountryCode (state, action) {
      const contactPhoneCountryCode = action.payload;

      if (contactPhoneCountryCode) {
        state.contactPhoneCountryCode = contactPhoneCountryCode;

        SessionStorageManager.setCheckoutContactPhoneCountryCode(contactPhoneCountryCode);

        // Reset order when the contact phone country code is changed
        state.order = null;
        state.orderError = null;
      }
    },

    setContactPhoneNumber (state, action) {
      const contactPhoneNumber = action.payload;

      if (contactPhoneNumber) {
        state.contactPhoneNumber = contactPhoneNumber;

        SessionStorageManager.setCheckoutContactPhoneNumber(contactPhoneNumber);

        // Reset order when the contact phone number is changed
        state.order = null;
        state.orderError = null;
      }
    },

    setCustomizationByItemIndex (state, action) {
      const { entities, ids } = state;
      const items = ids.map(id => entities[id]);
      const index = action.payload;

      if (typeof index === 'number') {
        const item = state.entities[index];

        if (item) {
          const { product } = item;
          const { id: productId, limit: productLimit } = product;

          // Check if there are items with the same exact product
          const itemsWithSimilarProduct = items.filter(entry => entry.index !== item.index && entry?.product?.id === productId);
          const itemsWithSimilarProductAmount = itemsWithSimilarProduct.reduce((accumulator, currentValue) => accumulator + (currentValue?.quantity || 0), 0);
          const amountAvailable = productLimit ? Math.max(0, productLimit - itemsWithSimilarProductAmount) : null;

          state.customization = {
            ...item,
            amountAvailable
          };
        }
      }
    },

    setCustomizationQuantity (state, action) {
      const { customization } = state;
      const value = action.payload;

      if (customization && typeof value === 'number') {
        const { amountAvailable } = customization;

        state.customization.quantity = typeof amountAvailable === 'number' ? Math.min(value, amountAvailable) : value;
      }
    },

    setCustomizationProduct (state, action) {
      const product = action.payload;

      if (product) {
        const { id: productId, limit: productLimit, options } = product;

        const { entities, ids } = state;
        const items = ids.map(id => entities[id]);

        // Check if there are items with the same exact product
        const itemsWithSimilarProduct = items.filter(entry => entry?.product?.id === productId);
        const itemsWithSimilarProductAmount = itemsWithSimilarProduct.reduce((accumulator, currentValue) => accumulator + (currentValue?.quantity || 0), 0);
        const amountAvailable = productLimit ? Math.max(0, productLimit - itemsWithSimilarProductAmount) : null;

        if (Array.isArray(options)) {
          const defaultChoices = {};

          options.forEach((option) => {
            const { id, input, extras, mandatory } = option;
            const defaultOptionExtras = {};
            let defaultHasBeenAssigned = false;

            if (Array.isArray(extras)) {
              extras.forEach((extra) => {
                const { price, soldOut } = extra;
                let quantity = 0;

                // For mandatory radio inputs, set first available free extra on the array as pre-chosen
                if (!defaultHasBeenAssigned && mandatory && input === PRODUCT_OPTION_INPUT_TYPE_RADIO && !price?.value && !soldOut) {
                  quantity = 1;
                  defaultHasBeenAssigned = true;
                }

                defaultOptionExtras[extra.id] = quantity;
              });
            }

            defaultChoices[id] = defaultOptionExtras;
          });

          state.customization = ({
            amountAvailable,
            choices: defaultChoices,
            index: null,
            product: {
              ...product,
              formattedPrice: product.price?.svalue
            },
            quantity: typeof amountAvailable === 'number' ? Math.min(1, amountAvailable) : 1
          });
        }
      }
    },

    setDropoffAddress (state, action) {
      // A requestCheckoutQuote() should be dispatched when dispatching this reducer

      const dropoffAddress = action.payload;

      if (dropoffAddress) {
        /*  
        const { streetAddress, postalCode, city, countryCode, lat, lng } = dropoffAddress;
        if (
          (typeof lat === 'number' && !typeof lng === 'number') ||
          (streetAddress && postalCode && city && countryCode)
        ) {
          state.dropoffAddress = dropoffAddress;
        }
        */
        state.dropoffAddress = dropoffAddress;
      }
    },

    setDropoffScheduleDate (state, action) {
      // A requestCheckoutQuote() should be dispatched when dispatching this reducer

      const { checkoutQuote, dropoffSchedule } = state;
      const date = action.payload;

      if (date && checkoutQuote) {
        const { scheduleIntervals } = checkoutQuote;
        const scheduleInterval = scheduleIntervals.find(entry => entry.date === date);

        if (scheduleInterval) {
          const { timeSlots } = scheduleInterval;

          // Try to keep the timeSlotValue and fallback to the value of the first available timeSlot
          const timeSlotValue = dropoffSchedule && timeSlots.map(({ value }) => value).includes(dropoffSchedule.timeSlotValue) ?
            dropoffSchedule.timeSlotValue :
            timeSlots[0]?.value || null;

          const newDropoffSchedule = {
            date,
            timeSlotValue
          };

          state.dropoffSchedule = newDropoffSchedule;

          SessionStorageManager.setCheckoutDropoffSchedule(newDropoffSchedule);
        } else if (dropoffSchedule) {
          state.dropoffSchedule.date = null;

          SessionStorageManager.setCheckoutDropoffSchedule({
            date: null,
            timeSlotValue: dropoffSchedule.timeSlotValue
          });
        }else{
          state.dropoffSchedule.date = null;
          SessionStorageManager.setCheckoutDropoffSchedule({
            date: null,
            timeSlotValue: null
          });
        }

        // Reset order when the schedule date is changed
        state.order = null;
        state.orderError = null;
      }else{
        state.dropoffSchedule.date = null;
        SessionStorageManager.setCheckoutDropoffSchedule({
          date: null,
          timeSlotValue: null
        });
      }
    },

    setDropoffScheduleTimeSlotValue (state, action) {
      // A requestCheckoutQuote() should be dispatched when dispatching this reducer

      const { checkoutQuote, dropoffSchedule } = state;
      const timeSlotValue = action.payload;

      if (timeSlotValue && checkoutQuote && dropoffSchedule?.date) {
        const { scheduleIntervals } = checkoutQuote;
        const { date } = dropoffSchedule;
        const scheduleInterval = scheduleIntervals.find(entry => entry.date === date);

        if (scheduleInterval) {
          const { timeSlots } = scheduleInterval;

          if (timeSlots.map(({ value }) => value).includes(timeSlotValue)) {
            state.dropoffSchedule.timeSlotValue = timeSlotValue;

            SessionStorageManager.setCheckoutDropoffSchedule({
              date: dropoffSchedule.date,
              timeSlotValue
            });

            // Reset order when the schedule time slot is changed
            state.order = null;
            state.orderError = null;
          }
        }else{
          state.dropoffSchedule.timeSlotValue = null;

          SessionStorageManager.setCheckoutDropoffSchedule({
            date: null,
            timeSlotValue: null
          });
        }
      }else{
        state.dropoffSchedule.timeSlotValue = null;

        SessionStorageManager.setCheckoutDropoffSchedule({
          date: null,
          timeSlotValue: null
        });
      }
    },

    setPaymentTypeId (state, action) {
      const { checkoutQuote, paymentTypeId: paymentTypeIdFromState } = state;
      const paymentTypeId = action.payload;

      if (paymentTypeId && checkoutQuote && paymentTypeId !== paymentTypeIdFromState) {
        const { paymentTypes } = checkoutQuote;
        const paymentTypeIdIsValid = paymentTypes.findIndex(paymentType => paymentType.id === paymentTypeId) !== -1;

        if (paymentTypeIdIsValid) {
          // Reset order when the payment type is changed
          state.order = null;
          state.orderError = null;

          state.paymentTypeId = paymentTypeId;

          SessionStorageManager.setCheckoutPaymentTypeId(paymentTypeId);
        }
      }
    },

    setShopId (state, action) {
      const shopId = action.payload;

      if ((shopId || typeof shopId === 'number') && shopId !== state.shopId) {
        shoppingCartAdapter.setAll(state, []);

        state.customization = null;
        state.shopId = shopId;

        SessionStorageManager.removeShoppingCartItems();
        if (shopId || typeof shopId === 'number') SessionStorageManager.setShoppingCartShopId(shopId);
        else SessionStorageManager.removeShoppingCartShopId(shopId);
      }
    },

    setVoucherCode (state, action) {
      const voucherCode = action.payload;

      state.voucherCode = voucherCode;
    },

    startPaymentProcess (state) {
      state.paymentIsInProcess = true;
    },

    stopPaymentProcess (state) {
      state.paymentIsInProcess = false;
    },

    toggleCustomizationChoice (state, action) {
      const { customization } = state;
      const args = action.payload;

      const calculateChoiceQuantity = (choiceExtras) => {
        const extras = choiceExtras || {};
        const extrasIds = Object.keys(extras);

        return extrasIds.reduce((accumulator, currentValue) => accumulator + extras[currentValue], 0);
      };

      if (customization && args) {
        const { choices: previousChoices, product } = customization;
        const choices = previousChoices || {};

        const { extraId, optionId, quantity: quantityFromArgs } = args;
        const quantity = typeof quantityFromArgs === 'number' ? quantityFromArgs : 1;

        if (product && (extraId || typeof extraId === 'number') && (optionId || typeof optionId === 'number')) {
          const { options } = product;
          const option = options.find(entry => entry && entry.id === optionId);
          const extras = choices[optionId] || {};
          const extrasIds = Object.keys(extras);

          if (option) {
            if (extrasIds.length) {
              const { extras: optionExtras, input, limit: optionLimit, mandatory } = option;
              const extra = optionExtras.find(entry => entry.id === extraId);
              const choiceQuantity = calculateChoiceQuantity(extras);

              if (extra) {
                const { limit: extraLimit, soldOut } = extra;

                if ([PRODUCT_OPTION_INPUT_TYPE_CHECKBOX, PRODUCT_OPTION_INPUT_TYPE_RADIO].includes(input)) {
                  // Toggle extra if option input type dictates that quantity is binary
                  if (extras[extraId]) {
                    // Toggle-off the extra unless the option is mandatory and this is the only selected extra
                    if (!(mandatory && choiceQuantity <= 1)) extras[extraId] = 0;
                  } else if ((!optionLimit || optionLimit > choiceQuantity || input === PRODUCT_OPTION_INPUT_TYPE_RADIO) && !soldOut) {
                    // Toggle-on the extra unless the limit quantity has been reached
                    //  * option limit is ignored if option input type is radio because the other extras will be toggled-off in next if statement
                    //  * note: if there is no limit, its value is 0
                    extras[extraId] = 1;

                    // Toggle-off other extras if option input type is radio
                    if (input === PRODUCT_OPTION_INPUT_TYPE_RADIO) {
                      optionExtras.forEach((optionExtra) => {
                        if (optionExtra.id !== extraId) extras[optionExtra.id] = 0;
                      });
                    }
                  }
                } else if (input === PRODUCT_OPTION_INPUT_TYPE_QUANTITY) {
                  const newExtras = JSON.parse(JSON.stringify(extras));

                  if (quantity >= 0 && (!extraLimit || extraLimit >= quantity) && !soldOut) {
                    newExtras[extraId] = quantity;

                    const newChoiceQuantity = calculateChoiceQuantity(newExtras);

                    if (!optionLimit || optionLimit >= newChoiceQuantity) extras[extraId] = quantity;
                  }
                }

                choices[optionId] = extras;
              }
            } else {
              // Generate choices for this option id if object is empty
              //  * this should never happen, since default choices are generated in setCustomizationProduct reducer
              const { extras: optionExtras } = option;

              if (Array.isArray(optionExtras)) {
                optionExtras.forEach((extra) => {
                  if (extra) {
                    const { id, soldOut } = extra;

                    extras[id] = id === extraId && !soldOut ? quantity : 0;
                  }
                });
              }

              choices[optionId] = extras;
            }

            state.customization.choices = choices;
          }
        }
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.orderError = null;
        state.orderIsLoading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const order = action.payload;

        if (order) {
          state.order = order;

          // Trigger redirect to external payment page or trigger payment modal – not being used; the user is being redirected to the orders page before payment
          // // Auto-redirect to payment page if there is a payment url present
          // if (order.paymentUrlWithReturn) {
          //   // Remove items and shop id from sessionStorage to empty the cart when returning to app
          //   SessionStorageManager.removeShoppingCartItems();
          //   SessionStorageManager.removeShoppingCartShopId();

          //   // Using replace() instead of assing(): if user goes back from the payment page, the store is reset and they will be redirected to home page
          //   // This may be avoided if we store this slice in sessionStorage, so the user can go back to checkout and adjust the order after re-entering the app
          //   window.location.replace(order.paymentUrlWithReturn);

          //   // Trigger payment modal — not being used; alternative to using external payment page
          //   // state.paymentIsInProcess = true;
          //   // state.shopId = null;
          //   // shoppingCartAdapter.setAll(state, []);
          // }
        }

        state.orderIsLoading = false;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.orderError = action?.error || 'Something went wrong while requesting a quote for your order.';
        state.orderIsLoading = false;
      })
      .addCase(requestCheckoutQuote.pending, (state) => {
        state.checkoutQuoteError = null;
        state.checkoutQuoteIsLoading = true;

        // Reset order when a new checkout quote is requested
        state.order = null;
        state.orderError = null;
      })
      .addCase(requestCheckoutQuote.fulfilled, (state, action) => {
        const { dropoffSchedule: currentDropoffSchedule } = state;
        const quote = action.payload;

        if (quote) {
          const { dropoffSchedule: quoteDropoffSchedule, scheduleIntervals } = quote;

          const dropoffSchedule = quoteDropoffSchedule || currentDropoffSchedule;

          // Use the current dropoffSchedule if possible and fallback to the first values present as fallback
          if( scheduleIntervals && scheduleIntervals.length > 0 ){
            const scheduleInterval = scheduleIntervals.find(({ date }) => date === dropoffSchedule.date) || scheduleIntervals[0];
            const { timeSlots } = scheduleInterval;
            const timeSlot = timeSlots.find(({ value }) => value === dropoffSchedule.timeSlotValue) || timeSlots[0];

            const newDropoffSchedule = {
              date: scheduleInterval.date,
              timeSlotValue: timeSlot.value
            };

            state.dropoffSchedule = newDropoffSchedule;

            SessionStorageManager.setCheckoutDropoffSchedule(newDropoffSchedule);
          }

          state.checkoutQuote = quote;
          
        }

        state.checkoutQuoteIsLoading = false;
      })
      .addCase(requestCheckoutQuote.rejected, (state, action) => {
        state.checkoutQuote = null;
        state.checkoutQuoteError = action?.error || 'Something went wrong while requesting a quote for your order.';
        state.checkoutQuoteIsLoading = false;
      })
      .addCase(setUserDefaultAddressForDropoff.fulfilled, (state, action) => {
        const address = action.payload;

        if (address) state.dropoffAddress = address;
      })
      .addCase(setUserNameForContact.fulfilled, (state, action) => {
        const name = action.payload;

        if (name) {
          state.contactName = name;
          SessionStorageManager.setCheckoutContactName(name);
        }
      })
      .addCase(setUserCompanyForContact.fulfilled, (state, action) => {
        const company = action.payload;

        if (company) {
          state.company = company;
          SessionStorageManager.setCheckoutCompany(company);
        }
      })
      .addCase(setUserPhoneCountryCodeForContact.fulfilled, (state, action) => {
        const phoneCountryCode = action.payload;

        if (phoneCountryCode) {
          state.contactPhoneCountryCode = phoneCountryCode;
          SessionStorageManager.setCheckoutContactPhoneCountryCode(phoneCountryCode);
        }
      })
      .addCase(setUserPhoneNumberForContact.fulfilled, (state, action) => {
        const phoneNumber = action.payload;

        if (phoneNumber) {
          state.contactPhoneNumber = phoneNumber;
          SessionStorageManager.setCheckoutContactPhoneNumber(phoneNumber);
        }
      });
  }
});

export default shoppingCartSlice.reducer;

// Actions

export const {
  addVoucherCodeToCheckoutQuote,
  initialize,
  removeItemByIndex,
  removeVoucherCodeFromCheckoutQuote,
  resetCheckoutData,
  resetCheckoutQuote,
  resetCustomization,
  resetDropoffAddress,
  resetPaymentTypeId,
  resetShopId,
  resetShoppingCartItems,
  resetVoucherCode,
  saveCustomization,
  setContactName,
  setContactPhoneCountryCode,
  setContactPhoneNumber,
  setCustomizationByItemIndex,
  setCustomizationProduct,
  setCustomizationQuantity,
  setDropoffAddress,
  setDropoffScheduleDate,
  setDropoffScheduleTimeSlotValue,
  setPaymentTypeId,
  setShopId,
  setVoucherCode,
  startPaymentProcess,
  stopPaymentProcess,
  toggleCustomizationChoice
} = shoppingCartSlice.actions;

// Selectors:

export const {
  selectAll: selectShoppingCartItems,
  selectById: selectShoppingCartItemById
} = shoppingCartAdapter.getSelectors(state => state.shoppingCart);

export const selectCheckoutQuote = state => state.shoppingCart.checkoutQuote;

export const selectCheckoutQuoteError = state => state.shoppingCart.checkoutQuoteError;

export const selectCheckoutQuoteIsLoading = state => state.shoppingCart.checkoutQuoteIsLoading;

export const selectCheckoutQuoteVoucherCode = state => state.shoppingCart.checkoutQuoteVoucherCode;

export const selectContactName = state => state.shoppingCart.contactName;

export const selectUserCompanyForContact = state => state.shoppingCart.company;

export const selectContactPhoneCountryCode = state => state.shoppingCart.contactPhoneCountryCode;

export const selectContactPhoneNumber = state => state.shoppingCart.contactPhoneNumber;

export const selectCustomization = state => state.shoppingCart.customization;

export const selectDropoffAddress = state => state.shoppingCart.dropoffAddress;

export const selectDropoffSchedule = state => state.shoppingCart.dropoffSchedule;

export const selectOrder = state => state.shoppingCart.order;

export const selectOrderError = state => state.shoppingCart.orderError;

export const selectOrderIsLoading = state => state.shoppingCart.orderIsLoading;

export const selectPaymentIsInProcess = state => state.shoppingCart.paymentIsInProcess;

export const selectPaymentTypeId = state => state.shoppingCart.paymentTypeId;

export const selectShopId = state => state.shoppingCart.shopId;

export const selectVoucherCode = state => state.shoppingCart.voucherCode;

export const selectVoucherIsValid = state => state.shoppingCart.voucherCode && typeof state.shoppingCart.voucherCode === 'string';

// Type constants:

export const PRODUCT_OPTION_INPUT_TYPE_CHECKBOX = 'checkbox';
export const PRODUCT_OPTION_INPUT_TYPE_QUANTITY = 'quantity';
export const PRODUCT_OPTION_INPUT_TYPE_RADIO = 'radio';

export const PRODUCT_OPTION_INPUT_TYPES = [
  PRODUCT_OPTION_INPUT_TYPE_CHECKBOX,
  PRODUCT_OPTION_INPUT_TYPE_QUANTITY,
  PRODUCT_OPTION_INPUT_TYPE_RADIO
];
