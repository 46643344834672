
const FormatRequestLocation = (state) => {
  const address = (state.authentication.user?.defaultAddress) ? state.authentication.user?.defaultAddress : state.authentication.curDeliveryAddress;
 
  return {
    delivery_type: state.authentication.curDeliveryType,
    schedule: state.authentication.curDeliverySchedule,
    address: (address && address.lat &&  address.lng) ? { lat: address.lat, lng: address.lng } : null
  }
};

export {
  FormatRequestLocation
};
