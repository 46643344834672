const ADDRESS = 'address';
const ADDRESS_ADD = `${ADDRESS}/add`;
const ADDRESS_DETAILS = `${ADDRESS}/details`;
const ADDRESS_EDIT = `${ADDRESS}/edit`;
const ADDRESS_LIST = `${ADDRESS}/list`;
const ADDRESS_REMOVE = `${ADDRESS}/remove`;
const ADDRESS_SET_DEFAULT = `${ADDRESS}/setdefault`;

const AUTHENTICATION_ACTIVATE_PHONE = 'activatephone2';
const AUTHENTICATION_LOGIN = 'login';
const AUTHENTICATION_LOGIN_FROM_SESSION = 'loginfromsession';
const AUTHENTICATION_REQUEST_A_CONTACT = 'contact';
const AUTHENTICATION_REQUEST_RECOVER_TOKEN = 'requestrecovertoken';
const AUTHENTICATION_RESET_PASSWORD = 'resetpassword';
const AUTHENTICATION_CONFIRM_EMAIL = 'confirmemail';
const AUTHENTICATION_SIGN_UP = 'signup';

const BID = 'bid';
const BID_ACCEPT = `${BID}/accept`;
const BID_ADD = `${BID}/add`;
const BID_DETAILS = `${BID}/details`;
const BID_LIST = `${BID}/list`;
const BID_PAYMENT_CONFIRM = `${BID}/payment/confirm`;
const BID_REFUSE = `${BID}/refuse`;
const BID_REMOVE = `${BID}/remove`;

const CATEGORY = 'category';
const CATEGORY_CURRENCIES = `${CATEGORY}/currencies`;
const CATEGORY_DELIVERY_REQUIREMENTS = `${CATEGORY}/deliveryrequirements`;
const CATEGORY_DELIVERY_TYPES = `${CATEGORY}/deliverytypes`;
const CATEGORY_DIMENSION_UNITS_TYPES = `${CATEGORY}/dimensionunits`;
const CATEGORY_DISTANCE_UNITS = `${CATEGORY}/distanceunits`;
const CATEGORY_HIGH_LIGHTS = `${CATEGORY}/highlights`;
const CATEGORY_ITEM_TYPES = `${CATEGORY}/itemtypes`;
const CATEGORY_MEASURE_UNITS = `${CATEGORY}/measureunits`;
const CATEGORY_ORDER_TYPES = `${CATEGORY}/ordertypes`;
const CATEGORY_SERVICE_TYPES = `${CATEGORY}/servicetypes`;
const CATEGORY_SHIPMENT_AND_ITEM_TYPES = `${CATEGORY}/shipmentitemtypes`;
const CATEGORY_SHIPMENT_TYPES = `${CATEGORY}/shipmenttypes`;
const CATEGORY_SHOP_TYPES = `${CATEGORY}/shoptypes`;
const CATEGORY_SHOPPING_LOCATIONS = `${CATEGORY}/shopping/locations`;
const CATEGORY_VEHICLE_CATEGORIES = `${CATEGORY}/vehiclecategories`;
const CATEGORY_VEHICLE_TYPES = `${CATEGORY}/vehicletypes`;
const CATEGORY_WEIGHT_UNITS = `${CATEGORY}/weightunits`;

const DELIVERY = 'delivery';
const DELIVERY_ESTIMATE = `${DELIVERY}/estimate`;
const DELIVERY_QUOTE = `${DELIVERY}/quote`;
const DELIVERY_QUOTE_MULTIPLE = `${DELIVERY_QUOTE}/multiple`;
const DELIVERY_CREATE = `${DELIVERY}/create`;
const DELIVERY_CREATE_MULTIPLE = `${DELIVERY_CREATE}/multiple`;

const DELIVERY_ITEM_TYPE = `${DELIVERY}/itemtype`;
const DELIVERY_ITEM_TYPES = `${DELIVERY}/itemtypes`;

const DELIVERY_PAYMENT_CONFIRM = `${DELIVERY}/payment/confirm`;
const DELIVERY_PAYMENT_TYPES = `${DELIVERY}/paymenttypes`;

const DELIVERY_DETAILS             = `${DELIVERY}/details`;
const DELIVERY_PROOF_OF_COLLECTION = `${DELIVERY}/proof/collection`;
const DELIVERY_PROOF_OF_DELIVERY   = `${DELIVERY}/proof/delivery`;
const DELIVERY_PROOF_OF_RETURN     = `${DELIVERY}/proof/return`;
const DELIVERY_TRACKING            = `${DELIVERY}/track/live_tracking`;

const DELIVERY_OPS = `${DELIVERY}/ops`;
const DELIVERY_OPS_ACCEPT = `${DELIVERY_OPS}/accept`;
const DELIVERY_OPS_ASSIGN = `${DELIVERY_OPS}/assign`;
const DELIVERY_OPS_CANCEL = `${DELIVERY_OPS}/cancel`;
const DELIVERY_OPS_COLLECT = `${DELIVERY_OPS}/collect`;
const DELIVERY_OPS_COLLECTION_IN_TRANSIT = `${DELIVERY_OPS}/collection_intransit`;
const DELIVERY_OPS_COURIER_ARRIVED_AT_COLLECTION_LOCATION = `${DELIVERY_OPS}/at_collection_location`;
const DELIVERY_OPS_COURIER_ARRIVED_AT_DELIVERY_LOCATION = `${DELIVERY_OPS}/at_delivery_location`;
const DELIVERY_OPS_COURIER_ARRIVED_AT_RETURN_LOCATION = `${DELIVERY_OPS}/at_return_location`;
const DELIVERY_OPS_DELIVER = `${DELIVERY_OPS}/deliver`;
const DELIVERY_OPS_DELIVERY_IN_TRANSIT = `${DELIVERY_OPS}/delivery_intransit`;
const DELIVERY_OPS_PAY = `${DELIVERY_OPS}/pay`;
const DELIVERY_OPS_RATE_CUSTOMER = `${DELIVERY_OPS}/rate_customer`;
const DELIVERY_OPS_RATE_SHOP = `${DELIVERY_OPS}/rate_shop`;
const DELIVERY_OPS_RATE_TRANSPORTER = `${DELIVERY_OPS}/rate_transporter`;
const DELIVERY_OPS_RATE_USER = `${DELIVERY_OPS}/rate_user`;
const DELIVERY_OPS_REFUSE = `${DELIVERY_OPS}/refuse`;
const DELIVERY_OPS_REMOVE = `${DELIVERY_OPS}/remove`;
const DELIVERY_OPS_RESCHEDULE = `${DELIVERY_OPS}/reschedule`;
const DELIVERY_OPS_RETURN_IN_TRANSIT = `${DELIVERY_OPS}/return_intransit`;
const DELIVERY_OPS_RETURNING = `${DELIVERY_OPS}/returning`;
const DELIVERY_OPS_SHOP_ACCEPT = `${DELIVERY_OPS}/shop_accept`;
const DELIVERY_OPS_SHOP_FINISH_PREPARATION = `${DELIVERY_OPS}/shop_finish_preparation`;
const DELIVERY_OPS_SHOP_PREPARE = `${DELIVERY_OPS}/shop_prepare`;
const DELIVERY_OPS_SHOP_REFUSE = `${DELIVERY_OPS}/shop_refuse`;

const MESSAGE = 'message';
const MESSAGE_ADD = `${MESSAGE}/add`;
const MESSAGE_DETAILS = `${MESSAGE}/details`;
const MESSAGE_LIST = `${MESSAGE}/list`;
const MESSAGE_REPLY = `${MESSAGE}/reply`;

const NOTIFICATION = 'notification';
const NOTIFICATION_LIST = `${NOTIFICATION}/list`;
const NOTIFICATION_SEEN = `${NOTIFICATION}/seen`;

const PUSH = 'push';
const PUSH_SIGN_UP = `${PUSH}/signup`;

const SHOP = 'shop';
const SHOP_CANCEL = `${SHOP}/cancel`;
const SHOP_DELIVERIES = `${SHOP}/deliveries`;
const SHOP_DELIVERIES_ACTIVE = `${SHOP_DELIVERIES}/active`;
const SHOP_DELIVERIES_HISTORY = `${SHOP_DELIVERIES}/history`;
const SHOP_PRODUCT_STOCK = `${SHOP}/product/stock`;
const SHOP_SIGNUP = `${SHOP}/signup`;

const SHOP_LIST_ALL_MENUS = `${SHOP}/menu/list`;

const SHOP_GET_MENU = `${SHOP}/menu/details`;

const SHOP_ADD_MENU = `${SHOP}/menu/add`;
const SHOP_EDIT_MENU = `${SHOP}/menu/edit`;
const SHOP_REMOVE_MENU = `${SHOP}/menu/remove`;
const SHOP_ADD_CATEGORY = `${SHOP}/category/add`;
const SHOP_EDIT_CATEGORY = `${SHOP}/category/edit`;
const SHOP_REMOVE_CATEGORY = `${SHOP}/category/remove`;
const SHOP_ASSIGN_CATEGORY = `${SHOP}/category/assign`;

const SHOP_ADD_PRODUCT = `${SHOP}/product/add`;

const SHOP_EDIT_PRODUCT = `${SHOP}/product/edit`;
const SHOP_REMOVE_PRODUCT = `${SHOP}/product/remove`;

const SHOP_ADD_OPTION = `${SHOP}/option/add`;

const SHOP_EDIT_OPTION = `${SHOP}/option/edit`;
const SHOP_REMOVE_OPTION = `${SHOP}/option/remove`;

const SHOP_ADD_EXTRA = `${SHOP}/extra/add`;

const SHOP_EDIT_EXTRA = `${SHOP}/extra/edit`;
const SHOP_REMOVE_EXTRA = `${SHOP}/extra/remove`;

const SHOPPING = 'shopping';
const SHOPPING_CHECKOUT = `${SHOPPING}/checkout`;
const SHOPPING_DASHBOARD = `${SHOPPING}/dashboard`;
const SHOPPING_ORDER_CREATE = `${SHOPPING}/order/create`;
const SHOPPING_PAYMENT_CONFIRM = `${SHOPPING}/payment/confirm`;
const SHOPPING_SHOP = `${SHOPPING}/shop`;
const SHOPPING_SHOP_CATEGORIES = `${SHOPPING_SHOP}/category/list`;
const SHOPPING_SHOP_DETAILS = `${SHOPPING_SHOP}/details`;
const SHOPPING_SHOP_DETAILS_COMPLETE = `${SHOPPING_SHOP_DETAILS}/categories/products/options`;
const SHOPPING_SHOP_LIST = `${SHOPPING_SHOP}/list`;
const SHOPPING_SHOP_PRODUCT_DETAILS = `${SHOPPING_SHOP}/product/details`;
const SHOPPING_SHOP_PRODUCTS = `${SHOPPING_SHOP}/product/list`;

const TRACK = 'track';
const TRACK_DETAILS = `${TRACK}/details`;
const TRACK_LIVE_TRACKING = `${TRACK}/live_tracking`;
const TRACK_SEND_MESSAGE = `${TRACK}/send_message`;
const TRACK_RATE_CUSTOMER = `${TRACK}/rate_customer`;
const TRACK_RATE_SHOP = `${TRACK}/rate_shop`;
const TRACK_RATE_TRANSPORTER = `${TRACK}/rate_transporter`;
const TRACK_RATE_USER = `${TRACK}/rate_user`;

const TRANSPORTER = 'transporter';
const TRANSPORTER_CANCEL = `${TRANSPORTER}/cancel`;
const TRANSPORTER_DELIVERIES = `${TRANSPORTER}/deliveries`;
const TRANSPORTER_DELIVERIES_ACTIVE = `${TRANSPORTER_DELIVERIES}/active`;
const TRANSPORTER_DELIVERIES_CURRENT = `${TRANSPORTER_DELIVERIES}/current`;
const TRANSPORTER_DELIVERIES_HISTORY = `${TRANSPORTER_DELIVERIES}/history`;
const TRANSPORTER_DELIVERIES_SEARCH = `${TRANSPORTER_DELIVERIES}/search`;
const TRANSPORTER_DRIVERS_LIST = `${TRANSPORTER}/drivers/list/details`;
const TRANSPORTER_DRIVERS_BASIC_LIST = `${TRANSPORTER}/drivers/list`;
const TRANSPORTER_SIGN_UP = `${TRANSPORTER}/signup`;
const TRANSPORTER_ADD_DRIVER = `${TRANSPORTER}/drivers/add`;
const TRANSPORTER_EDIT_DRIVER = `${TRANSPORTER}/drivers/edit`;
const TRANSPORTER_REMOVE_DRIVER = `${TRANSPORTER}/drivers/remove`;

const UPLOAD = 'upload';
const UPLOAD_DOCUMENT = `${UPLOAD}/document`;

const USER = '/user';
const USER_ADD_PHOTO = `${USER}/addphoto`;
const USER_CHANGE_PASSWORD = `${USER}/changepassword`;
const USER_DELIVERIES = `${USER}/deliveries`;
const USER_DELIVERIES_ACTIVE = `${USER_DELIVERIES}/active`;
const USER_DELIVERIES_ALL = `${USER_DELIVERIES}/all`;
const USER_DELIVERIES_CURRENT = `${USER_DELIVERIES}/current`;
const USER_DELIVERIES_DRAFT = `${USER_DELIVERIES}/draft`;
const USER_DELIVERIES_EXPORT = '/export/deliveries';
const USER_DELIVERIES_HISTORY = `${USER_DELIVERIES}/history`;
const USER_EDIT = `${USER}/edit`;
const USER_PROFILE = `${USER}/profile/public`;
const USER_REMOVE_PHOTO = `${USER}/removephoto`;
const USER_RESUME = `${USER}/resume`;
const USER_TRACK = `${USER}/track`;
const USER_WORKING = `${USER}/working/update`;

const USER_WORKING_CHECKIN = `${USER}/working/checkin`;
const USER_WORKING_CHECKOUT = `${USER}/working/checkout`;

const USER_REMOVE = `${USER}/removeaccount`;

const endpoints = {
  ADDRESS_ADD,
  ADDRESS_DETAILS,
  ADDRESS_EDIT,
  ADDRESS_LIST,
  ADDRESS_REMOVE,
  AUTHENTICATION_ACTIVATE_PHONE,
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGIN_FROM_SESSION,
  AUTHENTICATION_REQUEST_A_CONTACT,
  AUTHENTICATION_REQUEST_RECOVER_TOKEN,
  AUTHENTICATION_RESET_PASSWORD,
  AUTHENTICATION_CONFIRM_EMAIL,
  AUTHENTICATION_SIGN_UP,
  BID_ACCEPT,
  BID_ADD,
  BID_DETAILS,
  BID_LIST,
  BID_PAYMENT_CONFIRM,
  BID_REFUSE,
  BID_REMOVE,
  CATEGORY_CURRENCIES,
  CATEGORY_DELIVERY_REQUIREMENTS,
  CATEGORY_DELIVERY_TYPES,
  CATEGORY_DIMENSION_UNITS_TYPES,
  CATEGORY_DISTANCE_UNITS,
  CATEGORY_HIGH_LIGHTS,
  CATEGORY_ITEM_TYPES,
  CATEGORY_MEASURE_UNITS,
  CATEGORY_ORDER_TYPES,
  CATEGORY_SERVICE_TYPES,
  CATEGORY_SHIPMENT_AND_ITEM_TYPES,
  CATEGORY_SHIPMENT_TYPES,
  CATEGORY_SHOP_TYPES,
  CATEGORY_SHOPPING_LOCATIONS,
  CATEGORY_VEHICLE_CATEGORIES,
  CATEGORY_VEHICLE_TYPES,
  CATEGORY_WEIGHT_UNITS,
  DELIVERY_CREATE,
  DELIVERY_CREATE_MULTIPLE,
  DELIVERY_DETAILS,
  DELIVERY_ESTIMATE,
  DELIVERY_ITEM_TYPE,
  DELIVERY_ITEM_TYPES,
  DELIVERY_OPS,
  DELIVERY_OPS_ACCEPT,
  DELIVERY_OPS_ASSIGN,
  DELIVERY_OPS_CANCEL,
  DELIVERY_OPS_COLLECT,
  DELIVERY_OPS_COLLECTION_IN_TRANSIT,
  DELIVERY_OPS_COURIER_ARRIVED_AT_COLLECTION_LOCATION,
  DELIVERY_OPS_COURIER_ARRIVED_AT_DELIVERY_LOCATION,
  DELIVERY_OPS_COURIER_ARRIVED_AT_RETURN_LOCATION,
  DELIVERY_OPS_DELIVER,
  DELIVERY_OPS_DELIVERY_IN_TRANSIT,
  DELIVERY_OPS_PAY,
  DELIVERY_OPS_RATE_CUSTOMER,
  DELIVERY_OPS_RATE_SHOP,
  DELIVERY_OPS_RATE_TRANSPORTER,
  DELIVERY_OPS_RATE_USER,
  DELIVERY_OPS_REFUSE,
  DELIVERY_OPS_REMOVE,
  DELIVERY_OPS_RETURN_IN_TRANSIT,
  DELIVERY_OPS_RETURNING,
  DELIVERY_OPS_RESCHEDULE,
  DELIVERY_OPS_SHOP_ACCEPT,
  DELIVERY_OPS_SHOP_FINISH_PREPARATION,
  DELIVERY_OPS_SHOP_PREPARE,
  DELIVERY_OPS_SHOP_REFUSE,
  DELIVERY_PAYMENT_CONFIRM,
  DELIVERY_PAYMENT_TYPES,
  DELIVERY_PROOF_OF_COLLECTION,
  DELIVERY_PROOF_OF_DELIVERY,
  DELIVERY_PROOF_OF_RETURN,
  DELIVERY_QUOTE,
  DELIVERY_QUOTE_MULTIPLE,
  DELIVERY_TRACKING,
  MESSAGE_ADD,
  MESSAGE_DETAILS,
  MESSAGE_LIST,
  MESSAGE_REPLY,
  NOTIFICATION_LIST,
  NOTIFICATION_SEEN,
  PUSH_SIGN_UP,
  SHOP_CANCEL,
  SHOP_DELIVERIES_ACTIVE,
  SHOP_DELIVERIES_HISTORY,
  SHOP_PRODUCT_STOCK,
  SHOP_SIGNUP,

  SHOP_LIST_ALL_MENUS,
  SHOP_GET_MENU,
  SHOP_ADD_MENU,
  SHOP_EDIT_MENU,
  SHOP_REMOVE_MENU,
  SHOP_ADD_CATEGORY,
  SHOP_EDIT_CATEGORY,
  SHOP_REMOVE_CATEGORY,
  SHOP_ASSIGN_CATEGORY,

  SHOP_ADD_PRODUCT,
  SHOP_EDIT_PRODUCT ,
  SHOP_REMOVE_PRODUCT,
  SHOP_ADD_OPTION,
  SHOP_EDIT_OPTION ,
  SHOP_REMOVE_OPTION,
  SHOP_ADD_EXTRA,
  SHOP_EDIT_EXTRA ,
  SHOP_REMOVE_EXTRA,
  
  SHOPPING_CHECKOUT,
  SHOPPING_DASHBOARD,
  SHOPPING_ORDER_CREATE,
  SHOPPING_PAYMENT_CONFIRM,
  SHOPPING_SHOP_CATEGORIES,
  SHOPPING_SHOP_DETAILS,
  SHOPPING_SHOP_DETAILS_COMPLETE,
  SHOPPING_SHOP_LIST,
  SHOPPING_SHOP_PRODUCT_DETAILS,
  SHOPPING_SHOP_PRODUCTS,
  TRANSPORTER_CANCEL,
  TRANSPORTER_DELIVERIES_ACTIVE,
  TRANSPORTER_DELIVERIES_CURRENT,
  TRANSPORTER_DELIVERIES_HISTORY,
  TRANSPORTER_DELIVERIES_SEARCH,
  TRANSPORTER_DRIVERS_LIST,
  TRANSPORTER_DRIVERS_BASIC_LIST,
  TRANSPORTER_SIGN_UP,
  TRANSPORTER_ADD_DRIVER,
  UPLOAD_DOCUMENT,
  USER_ADD_PHOTO,
  USER_CHANGE_PASSWORD,
  USER_DELIVERIES_ACTIVE,
  USER_DELIVERIES_ALL,
  USER_DELIVERIES_CURRENT,
  USER_DELIVERIES_DRAFT,
  USER_DELIVERIES_EXPORT,
  USER_DELIVERIES_HISTORY,
  USER_EDIT,
  USER_PROFILE,
  USER_REMOVE_PHOTO,
  USER_RESUME,
  USER_TRACK,
  USER_WORKING,
  USER_WORKING_CHECKOUT,
  USER_WORKING_CHECKIN,
  USER_REMOVE
};

export default endpoints;

export {
  ADDRESS_ADD,
  ADDRESS_DETAILS,
  ADDRESS_EDIT,
  ADDRESS_LIST,
  ADDRESS_REMOVE,
  ADDRESS_SET_DEFAULT,
  AUTHENTICATION_ACTIVATE_PHONE,
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGIN_FROM_SESSION,
  AUTHENTICATION_REQUEST_A_CONTACT,
  AUTHENTICATION_REQUEST_RECOVER_TOKEN,
  AUTHENTICATION_RESET_PASSWORD,
  AUTHENTICATION_CONFIRM_EMAIL,
  AUTHENTICATION_SIGN_UP,
  BID_ACCEPT,
  BID_ADD,
  BID_DETAILS,
  BID_LIST,
  BID_PAYMENT_CONFIRM,
  BID_REFUSE,
  BID_REMOVE,
  CATEGORY_CURRENCIES,
  CATEGORY_DELIVERY_REQUIREMENTS,
  CATEGORY_DELIVERY_TYPES,
  CATEGORY_DIMENSION_UNITS_TYPES,
  CATEGORY_DISTANCE_UNITS,
  CATEGORY_HIGH_LIGHTS,
  CATEGORY_ITEM_TYPES,
  CATEGORY_MEASURE_UNITS,
  CATEGORY_ORDER_TYPES,
  CATEGORY_SERVICE_TYPES,
  CATEGORY_SHIPMENT_AND_ITEM_TYPES,
  CATEGORY_SHIPMENT_TYPES,
  CATEGORY_SHOP_TYPES,
  CATEGORY_SHOPPING_LOCATIONS,
  CATEGORY_VEHICLE_CATEGORIES,
  CATEGORY_VEHICLE_TYPES,
  CATEGORY_WEIGHT_UNITS,
  DELIVERY_CREATE,
  DELIVERY_CREATE_MULTIPLE,
  DELIVERY_DETAILS,
  DELIVERY_ESTIMATE,
  DELIVERY_ITEM_TYPE,
  DELIVERY_ITEM_TYPES,
  DELIVERY_OPS,
  DELIVERY_OPS_ACCEPT,
  DELIVERY_OPS_ASSIGN,
  DELIVERY_OPS_CANCEL,
  DELIVERY_OPS_COLLECT,
  DELIVERY_OPS_COLLECTION_IN_TRANSIT,
  DELIVERY_OPS_COURIER_ARRIVED_AT_COLLECTION_LOCATION,
  DELIVERY_OPS_COURIER_ARRIVED_AT_DELIVERY_LOCATION,
  DELIVERY_OPS_COURIER_ARRIVED_AT_RETURN_LOCATION,
  DELIVERY_OPS_DELIVER,
  DELIVERY_OPS_DELIVERY_IN_TRANSIT,
  DELIVERY_OPS_PAY,
  DELIVERY_OPS_RATE_CUSTOMER,
  DELIVERY_OPS_RATE_SHOP,
  DELIVERY_OPS_RATE_TRANSPORTER,
  DELIVERY_OPS_RATE_USER,
  DELIVERY_OPS_REFUSE,
  DELIVERY_OPS_REMOVE,
  DELIVERY_OPS_RESCHEDULE,
  DELIVERY_OPS_RETURN_IN_TRANSIT,
  DELIVERY_OPS_RETURNING,
  DELIVERY_OPS_SHOP_ACCEPT,
  DELIVERY_OPS_SHOP_FINISH_PREPARATION,
  DELIVERY_OPS_SHOP_PREPARE,
  DELIVERY_OPS_SHOP_REFUSE,
  DELIVERY_PAYMENT_CONFIRM,
  DELIVERY_PAYMENT_TYPES,
  DELIVERY_PROOF_OF_COLLECTION,
  DELIVERY_PROOF_OF_DELIVERY,
  DELIVERY_PROOF_OF_RETURN,
  DELIVERY_QUOTE,
  DELIVERY_QUOTE_MULTIPLE,
  DELIVERY_TRACKING,
  MESSAGE_ADD,
  MESSAGE_DETAILS,
  MESSAGE_LIST,
  MESSAGE_REPLY,
  NOTIFICATION_LIST,
  NOTIFICATION_SEEN,
  PUSH_SIGN_UP,
  SHOP_CANCEL,
  SHOP_DELIVERIES_ACTIVE,
  SHOP_DELIVERIES_HISTORY,
  SHOP_PRODUCT_STOCK,
  SHOP_SIGNUP,
    
  SHOP_LIST_ALL_MENUS,
  SHOP_GET_MENU,
  SHOP_ADD_MENU,
  SHOP_EDIT_MENU,
  SHOP_REMOVE_MENU,
 SHOP_ADD_CATEGORY,
 SHOP_EDIT_CATEGORY ,
 SHOP_REMOVE_CATEGORY,
 SHOP_ASSIGN_CATEGORY,
  SHOP_ADD_PRODUCT,
  SHOP_EDIT_PRODUCT ,
 SHOP_REMOVE_PRODUCT,
  SHOP_ADD_OPTION,
  SHOP_EDIT_OPTION ,
 SHOP_REMOVE_OPTION,
  SHOP_ADD_EXTRA,
  SHOP_EDIT_EXTRA ,
 SHOP_REMOVE_EXTRA,
    
  SHOPPING_CHECKOUT,
  SHOPPING_DASHBOARD,
  SHOPPING_ORDER_CREATE,
  SHOPPING_PAYMENT_CONFIRM,
  SHOPPING_SHOP_CATEGORIES,
  SHOPPING_SHOP_DETAILS,
  SHOPPING_SHOP_DETAILS_COMPLETE,
  SHOPPING_SHOP_LIST,
  SHOPPING_SHOP_PRODUCT_DETAILS,
  SHOPPING_SHOP_PRODUCTS,
  TRACK_DETAILS,
  TRACK_LIVE_TRACKING,
  TRACK_SEND_MESSAGE,
  TRACK_RATE_CUSTOMER,
  TRACK_RATE_SHOP,
  TRACK_RATE_TRANSPORTER,
  TRACK_RATE_USER,
  TRANSPORTER_CANCEL,
  TRANSPORTER_DELIVERIES_ACTIVE,
  TRANSPORTER_DELIVERIES_CURRENT,
  TRANSPORTER_DELIVERIES_HISTORY,
  TRANSPORTER_DELIVERIES_SEARCH,
  TRANSPORTER_DRIVERS_LIST,
  TRANSPORTER_DRIVERS_BASIC_LIST,
  TRANSPORTER_SIGN_UP,
  TRANSPORTER_ADD_DRIVER,
  TRANSPORTER_EDIT_DRIVER,
  TRANSPORTER_REMOVE_DRIVER,
  UPLOAD_DOCUMENT,
  USER_ADD_PHOTO,
  USER_CHANGE_PASSWORD,
  USER_DELIVERIES_ACTIVE,
  USER_DELIVERIES_ALL,
  USER_DELIVERIES_CURRENT,
  USER_DELIVERIES_DRAFT,
  USER_DELIVERIES_EXPORT,
  USER_DELIVERIES_HISTORY,
  USER_EDIT,
  USER_PROFILE,
  USER_REMOVE_PHOTO,
  USER_RESUME,
  USER_TRACK,
  USER_WORKING,
  USER_WORKING_CHECKIN,
  USER_WORKING_CHECKOUT,
  USER_REMOVE
};
