import React from 'react';

import ConfirmEmailForm from 'components/confirm-email';
import ImagePanel from '@youship/components/objects/image-panel';
import { FormattedMessage } from 'react-intl';

const ConfirmEmail = () => (
  <div className="confirm-email-area">
    <ImagePanel />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-12 col-sm-6 offset-sm-6">
          <h1 className="confirm-email-area__title">
            <FormattedMessage id="login.confirm_email.title" />
          </h1>
          <div className="confirm-email-area__description">
            &nbsp;
          </div>
          <div className="row">
            <div className="col col-12 col-lg-10">
              <ConfirmEmailForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmEmail;

