import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';


import { selectIsAuthenticated, selectIsLoggingIn, selectIsSigningUp, selectRequirePhoneActivation, selectUser } from 'store/slices/authentication';

import Button from '@youship/components/objects/button';
import Loader from '@youship/components/objects/loader';

import PersonalInformationStep from 'components/register/wizard/personal-information-step';
import ConfirmationCodeStep from 'components/register/wizard/confirmation-code-step';
import AddressInformationStep from 'components/register/wizard/address-information-step';

import './styles.scss';

const steps = [PersonalInformationStep, ConfirmationCodeStep, AddressInformationStep];

const RegisterWizard = ({ onStepChange }) => {
  const stepRef = useRef();
  const history = useHistory();
  const user = useSelector(selectUser);
  const userDefaultAddress = user?.defaultAddress?.streetAddress;

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoggingIn = useSelector(selectIsLoggingIn);
  const isSigningUp = useSelector(selectIsSigningUp);
  const requirePhoneActivation = useSelector(selectRequirePhoneActivation);

  const [currentStep, setCurrentStep] = useState(1);
  const Step = steps[currentStep - 1];
  const [isStepValid, setIsStepValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [footerLeftPosition, setFooterLeftPosition] = useState('0px');
  const imageBorder = useRef(null);

  const handleStepSuccess = () => {
    setIsSubmitting(false);

    if (currentStep === 3) {
      history.push('/');
    }
  };

  const handleStepError = () => {
    setIsSubmitting(false);
  };

  const wizardTitle = () => {
    switch (currentStep) {
      case 1:
        return 'register.step_1';
      case 2:
        return 'register.step_2';
      case 3:
        return 'register.step_3';
      default:
        return '';
    }
  };

  const continueButtonText = currentStep === 3 ? 'register.confirm' : 'register.continue';

  useEffect(
    () => {
      onStepChange(currentStep);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep]
  );

  useEffect(
    () => {
      if (isAuthenticated) {
        if (requirePhoneActivation) {
          setCurrentStep(2);
        } else if (userDefaultAddress) {
          //já tem morada por defeito
          setCurrentStep(3);
        } else {
          //ainda nao tem morada - logo pede morada
          setCurrentStep(3);
        }
      } else {
        setCurrentStep(1);
      }
    },
    [isAuthenticated, requirePhoneActivation, userDefaultAddress]
  );

   

  const isButtonDisabled = !isStepValid || isSubmitting || isSigningUp || isLoggingIn;

  const handleContinueButtonClick = () => {
    if (isStepValid) {
      stepRef.current.submitForm();
    }
  };

  const handleFooterLeftPosition = () => {
    const rect = imageBorder.current.getBoundingClientRect();
    let maxWidth = '1140px';
    const windowWidth = document.body.clientWidth;
    const leftPosition = rect.left;

    if (windowWidth > 1200) {
      maxWidth = '1140px';
    } else if (windowWidth > 992) {
      maxWidth = '960px';
    } else if (windowWidth > 720) {
      maxWidth = '720px';
    } else if (windowWidth > 576) {
      maxWidth = '540px';
    }

    setFooterLeftPosition(`calc(
      ${leftPosition}px - ((${windowWidth}px - ${maxWidth}) / 2))`);
  };

  useEffect(() => {
    handleFooterLeftPosition();
    window.addEventListener('resize', handleFooterLeftPosition);

    return () => {
      window.removeEventListener('resize', handleFooterLeftPosition);
    };
  }, []);

  const intl = useIntl();

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className={`col col-12 col-md-6 offset-md-6${currentStep === 3 ? ' register-wizard__custom-position register-wizard__mobile-margin' : ''}`}>
            <div className="register-wizard">
              <div className="register-wizard__steps">
                <FormattedMessage id="register.step" />
                {' '}
                {currentStep}
                /3
              </div>
              <div className={`h1 register-wizard__title${currentStep === 2 ? ' register-wizard__title--small-margin' : ''}`}>
                <FormattedMessage id={wizardTitle()} />
              </div>
              {currentStep === 1 && isAuthenticated && !requirePhoneActivation && !!userDefaultAddress && (
                <>
                  <div className="register-wizard__description">
                    <FormattedMessage id="register.already_registered" />
                  </div>
                  <div className="register-wizard__go-back-button-container">
                    <Button
                      context="primary"
                      largeHorizontalPadding
                      linkComponent={Link}
                      linkProps={{ to: '/' }}
                      noArrow
                      smallVerticalPadding
                    >
                      <FormattedMessage id="register.go_back" />
                    </Button>
                  </div>
                </>
              )}
              {currentStep === 2 && (
                <div className="register-wizard__description">
                  <FormattedMessage id="register.form.confirmation_code.text" />
                </div>
              )}
              <div className="row">
                <div className={`col col-12 col-md-10${currentStep === 3 ? ' register-wizard__custom-position' : ''}`}>
                  {isSubmitting && (
                    <div className="register-wizard__loader-wrapper">
                      <Loader />
                    </div>
                  )}
                  <Step
                    ref={stepRef}
                    onError={handleStepError}
                    onSubmitting={() => setIsSubmitting(true)}
                    onSuccess={handleStepSuccess}
                    onValidation={valid => setIsStepValid(valid)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container register-wizard__footer-container">
        <div className="row image-panel__row">
          <div
            ref={imageBorder}
            className="col col-md-2 offset-md-4 col-lg-1 offset-lg-5"
          >
            <div
              className="register-wizard__footer"
              style={{ left: footerLeftPosition }}
            >
              <div className="register-wizard__footer-button-wrapper">
                {currentStep === 3 && (
                  <Link
                    className="register-wizard__footer-submit-later"
                    to="/"
                  >
                    <FormattedMessage id="register.do_later" />
                  </Link>
                )}
                <Button
                  context="primary"
                  disabled={isButtonDisabled}
                  largeHorizontalPadding
                  smallVerticalPadding
                  text={intl.formatMessage({ id: `${continueButtonText}` })}
                  onClick={handleContinueButtonClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RegisterWizard.propTypes = {
  onStepChange: PropTypes.func
};

RegisterWizard.defaultProps = {
  onStepChange: () => {}
};

export default RegisterWizard;
