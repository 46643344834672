import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SharedButton from '@youship/components/objects/button';


// TO-DO: use this implementation when shared button component handles `to` the same way for internal and external links
// const Button = props => (
//   <SharedButton
//     {...props}
//     linkComponent={Link}
//   />
// );

const Button = props => (
  props.to && !props.external ? (
    <SharedButton
      {...props}
      linkComponent={Link}
      linkProps={{ to: props.to }}
    />
  ) : (
    <SharedButton {...props} />
  )
);


Button.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string
};

Button.defaultProps = {
  external: false,
  to: null
};

export default Button;
