import React from 'react';

import AddressesArea from "../components/sections/addresses-area";

const Addresses = () => (
    <>
        <AddressesArea/>
    </>
);

export default Addresses;
