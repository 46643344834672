import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {useIntl } from 'react-intl';


import Input from '@youship/components/objects/input';
import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';

import greenCheckIcon from 'images/icons/check-green.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';


const UserModal = ({
  data,
  isLoading,
  errorMessageModal,
  text,
  title,
  notice,
  finalMessage,
  phoneCode,
  onClose,
  onSave
}) => {

  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState( errorMessageModal || null);

  const [isFormValid, setIsFormValid] = useState(false);
  
  const [formData, setFormData] = useState(
            {
                firstName: data?.firstname ?? '',
                lastName: data?.lastname ?? '',
                email: data?.email ?? '',
                phoneCode: data?.phonecode?.substring(1) ?? phoneCode ?? '',
                phoneNumber: data?.phonenumber ?? '',
                countryCode: data?.phonecode?.substring(1) ?? phoneCode ?? '',
                company: ''
            }
      );

  const handleInputChange = (value, name) => {    
    setFormData( { ...formData, [name]: value } );
  };


  // tem efeito qd existir uma alteracao no proof
  useEffect(() => {

    setIsFormValid(false);

    if( formData.firstName !== null && formData.firstName !== "" && 
      formData.lastName !== null && formData.lastName !== "" && 
      formData.email !== null && formData.email !== "" &&
      formData.phoneCode !== null && formData.phoneCode !== "" && 
      formData.phoneNumber !== null && formData.phoneNumber !== "" ){
      setIsFormValid(true);
    }
  
  }, [formData]);

  const handleFormSaveClick = (event) => {
    event.preventDefault();

    setErrorMessage(null);
    
    onSave(event, formData);
  };
  
 
  return (
    <Modal
      classNames="user-modal"
      footer={
        <div className="user-modal__footer">
          { (errorMessage || errorMessageModal) && (
            <p className="user-modal__error-message">
               {errorMessage || errorMessageModal}
            </p>
          )}
          {finalMessage ? (
              <Button
                classNames="user-modal__button"
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                noArrow
                text={intl.formatMessage({ id: 'app.close' })}
                onClick={onClose}
              />
          ) : (  
            <Button
              classNames="user-modal__button"
              context="primary"
              disabled={isLoading || !isFormValid}
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'app.save' })}
              onClick={!isLoading ? event => handleFormSaveClick(event) : () => {}}
            />
          )}

        </div>
      }
      title={text || title}
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="user-modal__content">
        { (!!finalMessage || !!notice) && (
          <>
            <Notice
              classNames="action-modal__notice"
              description={finalMessage || notice}
              fixed
              icon={finalMessage ? greenCheckIcon : noticeIcon}
            />
          </>  
        )}
        { (!finalMessage && !notice) && (
        <>
        <div className="user-modal__item">
          <Input
              block
              label={intl.formatMessage({ id: 'register.form.first_name.label' }) }
              placeholder={intl.formatMessage({ id: 'register.form.first_name.placeholder' })}
              type="text"
              value={formData.firstName}
              onChange={e => handleInputChange(e.target.value, 'firstName')}
            />
        </div>
        <div className="user-modal__item">
         <Input
              block
              label={intl.formatMessage({ id: 'register.form.last_name.label' }) }
              placeholder={intl.formatMessage({ id: 'register.form.last_name.placeholder' })}
              type="text"
              value={formData.lastName}
              onChange={e => handleInputChange(e.target.value, 'lastName')}
            />
        </div>
        <div className="user-modal__item">
         <Input
              block
              label={intl.formatMessage({ id: 'register.form.email.label' }) }
              placeholder={intl.formatMessage({ id: 'register.form.email.placeholder' })}
              type="email"
              value={formData.email}
              onChange={e => handleInputChange(e.target.value, 'email')}
              disabled={data != null}
            />
        </div>

        <div className="user-modal__item">
          <Input
                block
                countryCallingCode={formData.phoneCode}
                inputId="phone"
                label={intl.formatMessage({ id: 'register.form.phone.label' })}
                placeholder={intl.formatMessage({ id: 'register.form.phone.placeholder' })}
                type="phone"
                value={formData.phoneNumber}
                onChange={event => handleInputChange(event?.target?.value ?? '', 'phoneNumber')}
                onCountryCallingCodeChange={code => handleInputChange(code, 'phoneCode')}
              />
        </div>
        </>
        )}

      </div>
    </Modal>
  );
};

UserModal.propTypes = {
  errorMessageModal: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

UserModal.defaultProps = {
  errorMessageModal: null,
  isLoading: false,
  onClose: () => {},
  onSave: () => {}
};

export default UserModal;
