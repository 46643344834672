import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '@youship/components/objects/loader';
import './styles.scss';

const GOOGLE_MAPS_API_KEY =  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const MAP_ZOOM = 15;
let map = null;

const MapPoint = ({ address, coordinates, fullHeight, id, onAddressChange, onLoadingAddressChange, onLoadingStateChange }) => {
  // NOTE: Check if address prop is needed for the map point handling
  const [addressIsLoading, setAddressIsLoading] = useState(false);
  const [isMapCreated, setIsMapCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMapErrorMessage] = useState(false);
  const [timeoutFlag, setTimeoutFlag] = useState(false);

  let geocoder = {};
  let timer = 0;

  const usePrevious = (data) => {
    const ref = React.useRef();

    React.useEffect(() => {
      ref.current = data;
    }, [data]);

    return ref.current;
  };

  const prevAddress = usePrevious(address);

  useEffect(() => {
    onLoadingAddressChange(addressIsLoading);
  }, [addressIsLoading, onLoadingAddressChange]);

  useEffect(() => {
    onLoadingStateChange(isLoading);
  }, [isLoading, onLoadingStateChange]);

  const handleGoogleMapsAPILoad = () => {
    loadMap();
  };

  const loadMap = useCallback(() => new Promise((resolve, reject) => {
    if (!address) {
      return reject(new Error('There is no address available.'));
    }
    if (!window.google) {
      return reject(new Error('The Google instance is not ready.'));
    }

    window.gm_authFailure = function() {
         // remove the map div or maybe call another API to load map
         // maybe display a useful message to the user
         //alert('Google maps Auth failed!');
         setIsLoading(false);
    }

    map = new window.google.maps.Map(
      document.getElementById(id),
      {
        disableDefaultUI: true,
        zoom: MAP_ZOOM
      }
    );

    // NOTE: This should be fixed in the future
    // eslint-disable-next-line react-hooks/exhaustive-deps
    geocoder = new window.google.maps.Geocoder();
    setIsMapCreated(true);

    return getCoordinates()
      .then(() => {
        setIsLoading(false);
        centerChanged(map);

        return addMarkers(map);
      })
      .catch((error) => {
        setIsLoading(false);
        reject(error);
      });
  }), [address, id]);

  const updateMap = useCallback(() => new Promise((resolve, reject) => {
    if (!address) {
      return reject(new Error('There is no address available.'));
    }
    if (!window.google) {
      return reject(new Error('The Google instance is not ready.'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    geocoder = new window.google.maps.Geocoder();

    return getCoordinates()
      .then(() => addMarkers(map))
      .catch((error) => {
        reject(error);
      });
  }), [address]);

  const getCoordinates = () => new Promise((resolve, reject) => {
    if (!address) {
      return reject(new Error('There is no address available.'));
    }

    return geocoder.geocode({ address }, (results, status) => {
      if (
        status === 'OK' &&
        results &&
        results[0] &&
        results[0].geometry &&
        results[0].geometry.location
      ) {
        const mapCoordinates = coordinates ?? results[0].geometry.location;

        return resolve(mapCoordinates);
      }

      return reject(new Error('Address not found'));
    });
  });

  const addMarkers = () => new Promise((resolve, reject) => {
    const promises = [];

    promises.push(setMarker(map));

    Promise.all(promises).then(() => resolve())
      .catch(error => reject(error));
  });

  const setMarker = () => new Promise((resolve, reject) => {
    if (!address) {
      return reject(new Error('There is no address available.'));
    }

    return geocoder.geocode({ address }, (results, status) => {
      if (
        status === 'OK' &&
        results &&
        results[0] &&
        results[0].geometry &&
        results[0].geometry.location
      ) {
        let mapCoordinates = null;

        if (coordinates.lat && coordinates.lng) {
          mapCoordinates = coordinates;
        } else {
          mapCoordinates = results[0].geometry.location;
        }

        map.setCenter(mapCoordinates);

        return resolve();
      }

      return reject(new Error('Address not found'));
    });
  });

  const centerMarkerDiv = () => {
    const elm = document.createElement('div');

    elm.setAttribute('id', 'centerMarker');

    return elm;
  };

  const centerChanged = () => new Promise((resolve, reject) => {
    document.getElementById(id).appendChild(centerMarkerDiv());

    const response = map.addListener('dragend', () => {
      clearTimeout(timer);

      // Timeout is necessary to prevent the map pointer to readjust
      timer = setTimeout(() => {
        const position = map.getCenter();

        reverseGeocodeGoogle(position.lat(), position.lng());
      }, 100);

      return response;
    });
  });

  const reverseGeocodeGoogle = (lat, lng) => {
    setAddressIsLoading(true);
    const latlng = { lat, lng };

    const geoCoder = new window.google.maps.Geocoder();

    geoCoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const result = results[0];

          if (result.address_components) {
            handleAddressChange(result, latlng);
            setAddressIsLoading(false);
          }
        }
      } else {
        setAddressIsLoading(false);
      }
    });
  };

  const handleAddressChange = (googleAddress, googleCoordinates) => {
    const addressComponents = googleAddress?.address_components;

    if (Array.isArray(addressComponents) && addressComponents.length) {
      const formattedAddressData = { addressComponents: {} };

      formattedAddressData.lat = googleCoordinates?.lat ?? googleAddress.geometry.location.lat();
      formattedAddressData.lng = googleCoordinates?.lng ?? googleAddress.geometry.location.lng();
      formattedAddressData.formattedAddress = googleAddress.formatted_address;

      console.log("Map point - handleAddressChange:");
      console.log(googleAddress);

      addressComponents.forEach((addressComponent) => {
        if (Array.isArray(addressComponent?.types) && addressComponent.types.length) {
          if (addressComponent.types.includes('postal_code')) {
            formattedAddressData.postalCode = addressComponent.long_name;
            formattedAddressData.addressComponents.postalCode = addressComponent.long_name;
          } else if (addressComponent.types.includes('locality')) {
            formattedAddressData.city = addressComponent.long_name;
            formattedAddressData.addressComponents.city = addressComponent.long_name;
          } else if (addressComponent.types.includes('street_number')) {
            formattedAddressData.numberAddress = addressComponent.long_name;
            formattedAddressData.addressComponents.numberAddress = addressComponent.long_name;
          } else if (addressComponent.types.includes('route')) {
            formattedAddressData.streetAddress = addressComponent.long_name;
            formattedAddressData.addressComponents.route = addressComponent.long_name;
          } else if (addressComponent.types.includes('sublocality')) {
            formattedAddressData.addressComponents.sublocality = addressComponent.long_name;
          } else if (addressComponent.types.includes('country')) {
            formattedAddressData.countryCode = addressComponent.short_name;
            formattedAddressData.addressComponents.country = addressComponent.short_name;
          }
        }
      });

      //fill streetAddress when streetAddress is empty
      if( !formattedAddressData.streetAddress ){
        let lines = formattedAddressData.formattedAddress.split(',');
        if( lines.length >= 4 ){
          formattedAddressData.streetAddress = lines[0] + ", " + lines[1];
        }else{
          formattedAddressData.streetAddress = lines[0];
        }
      }

      if (
        formattedAddressData.city !== address?.city ||
        formattedAddressData.countryCode !== address?.countryCode ||
        formattedAddressData.numberAddress !== address?.numberAddress ||
        formattedAddressData.postalCode !== address?.postalCode ||
        formattedAddressData.streetAddress !== address?.streetAddress
      ) {
        onAddressChange({
          city: '',
          countryCode: '',
          numberAddress: '',
          postalCode: '',
          streetAddress: '',
          ...formattedAddressData
        });
      }
    }
  };

  useEffect(() => {
    if (!document.getElementById('google-maps-react')) {
      const googleMapsScript = document.createElement('script');

      googleMapsScript.setAttribute('id', 'google-maps-react');

      googleMapsScript.type = 'text/javascript';
      googleMapsScript.onload = handleGoogleMapsAPILoad();
      googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

      const googleMapsScriptTag = document.getElementsByTagName('script')[0];

      googleMapsScriptTag.parentNode.insertBefore(googleMapsScript, googleMapsScriptTag);
    }
  });

  useEffect(() => {
    if (!window.google) {
      document.getElementById('google-maps-react').addEventListener('load', () => {
        loadMap();
      });
    } else if (!isMapCreated) {
      if (address) {
        loadMap();
      }
    }

    return () => {
      document.getElementById('google-maps-react').removeEventListener('load', () => {
        loadMap();
      });
    };
  });

  useEffect(() => {
    if (
      address &&
      prevAddress &&
      !timeoutFlag &&
      address !== prevAddress
    ) {
      updateMap()
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      setTimeoutFlag(true);
      setTimeout(() => {
        setTimeoutFlag(false);
      }, 1000);
    }
  }, [address, updateMap, setTimeoutFlag, timeoutFlag, prevAddress]);

  return (
    <div className={`map-point__container${fullHeight ? ' map-point__container--full-height' : ''}`}>
      <div
        className={`map-point__wrapper${addressIsLoading ? ' map-point__wrapper--overlay' : ''}`}
        id={id}
      />
      {isLoading && (
        <div className="map-point__loader">
          <Loader />
        </div>
      )}
      {showMapErrorMessage && (
        <div className="map-point__error">
          Something went wrong, try again later.
        </div>
      )}
    </div>
  );
};

MapPoint.propTypes = {
  address: PropTypes.string,
  coordinates: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  fullHeight: PropTypes.bool,
  id: PropTypes.string,
  onAddressChange: PropTypes.func,
  onLoadingAddressChange: PropTypes.func,
  onLoadingStateChange: PropTypes.func
};

MapPoint.defaultProps = {
  address: null,
  coordinates: null,
  fullHeight: false,
  id: null,
  onAddressChange: () => {},
  onLoadingAddressChange: () => {},
  onLoadingStateChange: () => {}
};

export default MapPoint;
