import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmEmail, resetPassword, selectIsLoggingIn } from 'store/slices/authentication';
import { FormattedMessage, useIntl } from 'react-intl';

import Notice from '@youship/components/objects/notice';
import Input from '@youship/components/objects/input';
import Button from '@youship/components/objects/button';

import greenCheckIcon from 'images/icons/check-green.svg';
import noticeIcon from '@youship/assets/images/icons/notice.svg';

import './styles.scss';

const ConfirmEmailForm = () => {
  const dispatch = useDispatch();
 
  const intl = useIntl();

  const isLoading = useSelector(selectIsLoggingIn);

  const [errorMessage, setErrorMessage] = useState( null);
  const [requestSucceeded, setRequestSucceeded] = useState(false);

  const [stopLoading, setStopLoading] = useState( null);
  
  const [isFormValid, setIsFormValid] = useState(false);
  
  const [formData, setFormData] = useState({});

  const urlParameters = new URLSearchParams(window.location.search);
  const token = urlParameters.get('t');    

  const handleInputChange = (value, name) => {    
    setFormData( { ...formData, [name]: value } );
  };

  // tem efeito qd existir uma alteracao no formData
  useEffect(() => {

    setIsFormValid(false);

    if( token !== null && token !== "" ){
      setIsFormValid(true);
    }

    if( token == null || token === "" ||  token.length < 10){
      setErrorMessage( intl.formatMessage({ id: 'password.token_error' }));
      setStopLoading(true);
    }else{
      handleConfirmEmail();
    }
  
  }, [formData, token]);

  const handleConfirmEmail = () =>  {

    setErrorMessage(null);

    dispatch( confirmEmail( {token: token} ) )
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

        return response;
      })
      .then((response) => {
        
        setRequestSucceeded(response?.message || intl.formatMessage({ id: 'confirm_email.success' }));
        
        return response;
      })
      .catch((error) => {
        // NOTE: Add proper error handling
        // eslint-disable-next-line no-console
        
        setErrorMessage(error?.message);
        setRequestSucceeded(null);
        
      });
      
  };

  return (
    <>
    { (!!requestSucceeded || !!errorMessage) && (
      <>
        <Notice
          classNames="action-modal__notice"
          description={requestSucceeded || errorMessage}
          fixed
          icon={requestSucceeded ? greenCheckIcon : noticeIcon}
        />
      </>  
    )}

    { (isLoading && !stopLoading)  && (
        <div className="confirm-email-form__item">
          <FormattedMessage id="login.confirm_email.loading" />
        </div>
    )}    

    { !(isLoading && !stopLoading)  && (
        <div className="confirm-email-form__footer">
          <Link
              className="confirm-email-form__link"
              to="/login"
            >
            <FormattedMessage id="login.confirm_email.back" />
          </Link>
        </div>
    )}

  </>
  )
};

export default ConfirmEmailForm;
