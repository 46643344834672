import React from 'react';
import { Draggable } from "react-beautiful-dnd";
import menuIcon from '@youship/assets/images/icons/menu.svg';

export const Drag = ({ id, index, ...props }) => {
    return (
        <Draggable draggableId={id} index={index}>
            
            {(provided, snapshot) => {
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
                        <div className="drag-handle" {...provided.dragHandleProps}>
                            <img src={menuIcon} alt="Drag Icon" />
                        </div>
                        {props.children}

                    </div>
                );
            }}
        </Draggable>
    );
};