import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { Controller, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  EMAIL_PATTERN,
  LETTERS_ONLY_PATTERN,
  NUMBERS_ONLY_PATTERN,
  PASSWORD_MINIMUM_LENGTH,
  PASSWORD_PATTERN,
  PHONE_MAXIMUM_LENGTH,
  PHONE_MINIMUM_LENGTH
} from '@youship/utils/form-validation';
import helper from '@youship/utils/helper';

import { getUser, selectUser, signUp } from 'store/slices/authentication';
import { fetchNotifications } from 'store/slices/notifications';

import Checkbox from '@youship/components/objects/checkbox';
import Input from '@youship/components/objects/input';
import Select from '@youship/components/objects/select';
import Tooltip from '@youship/components/objects/tooltip';

import infoGrayIcon from '@youship/assets/images/icons/info-gray.svg';

const countries = [
  {
    value: 'PT',
    text: 'Portugal'
  },
  {
    value: 'AO',
    text: 'Angola'
  },
  {
    value: 'ES',
    text: 'Spain'
  }
];

const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

const PersonalInformationStep = forwardRef(({ classNames, formClassName, onError, onSubmitting, onSuccess, onValidation }, ref) => {
  const form = useRef(null);
  const user = useSelector(selectUser);
  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    submitForm () {
      handleSubmit(handleFormSubmit)();
    }
  }));

  const { control, errors, handleSubmit, register, reset, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      company: '',
      phone: '',
      password: '',
      matchingPassword: ''
    }
  });

  const countryOptions = countries.map((option) => {
    const icon = helper.getFlagByCountry(option.value);
    return {
      ...option,
      icon
    };
  });

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('Sorry, we were unable to process your request at this time. Please try again later.');
  const [failed, setFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneCountryCallingCode, setPhoneCountryCallingCode] = useState(351);
  const [termsAgreed, setTermsAgreed] = useState(false);
  //recaptcha google
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);

  // Tooltip
  const [showTotalInfoTooltip, setShowTotalInfoTooltip] = useState(false);

  const handleTotalInfoMouseEnter = () => {
    setShowTotalInfoTooltip(true);
  };

  const handleTotalInfoTooltipClose = () => {
    closeTotalInfoTooltipClose();
  };

  const closeTotalInfoTooltipClose = () => {
    setShowTotalInfoTooltip(false);
  };

  const getInputMessage = (name) => {
    if (errors[name]) {
      return errors[name].message;
    }

    return '';
  };

  const getInputStatus = (name) => {
    if (errors[name]) {
      return 'error';
    }

    return 'default';
  };

  const handleFormSubmit = (data) => {
    setIsSubmitting(true);
    onSubmitting();

    const signUpData = {
      ...data,
      countryCode: data.country,
      phoneCode: `+${phoneCountryCallingCode}`,
      recaptcha: recaptchaToken
    };

    dispatch(signUp(signUpData))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while creating your account.');

        return response;
      })
      .then(async (response) => {
        await Promise.all([dispatch(getUser()), dispatch(fetchNotifications())]);

        onSuccess();
        setIsSubmitting(false);
        reset();

        return response;
      })
      .catch((error) => {
        setFailed(true);
        setIsSubmitting(false);
        onError(new Error(error.message));

        setErrorMessage(error.message);
      });
  };

  const handleInputChange = (value, props) => {
    const { name } = props;

    props.onChange(value);

    if (errors[name]) {
      trigger(name);
    }

    if (name === 'password' && watch('matchingPassword')) trigger('matchingPassword');
  };

  const isFormValid = () => {
    if (!termsAgreed) return false;
    if (!recaptchaToken) return;

    const inputs = ['firstName', 'lastName', 'email', 'country', 'phone', 'password', 'matchingPassword'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const isButtonDisabled = !isFormValid() || isSubmitting;

  useEffect(() => {
    register({ name: 'firstName' }, {
      pattern: {
        value: LETTERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'register.form.first_name.pattern' })
      },
      required: intl.formatMessage({ id: 'register.form.first_name.required' })
    });

    register({ name: 'lastName' }, {
      pattern: {
        value: LETTERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'register.form.last_name.pattern' })
      },
      required: intl.formatMessage({ id: 'register.form.last_name.required' })
    });

    register({ name: 'email' }, {
      pattern: {
        value: EMAIL_PATTERN,
        message: intl.formatMessage({ id: 'register.form.email.pattern' })
      },
      required: intl.formatMessage({ id: 'register.form.email.required' })
    });

    register({ name: 'country' }, {
      required: intl.formatMessage({ id: 'register.form.country.instructions' })
    });

    register({ name: 'phone' }, {
      pattern: {
        value: NUMBERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'register.form.phone.pattern' })
      },
      minLength: {
        value: PHONE_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'register.form.phone.minlength' },
          { PHONE_MINIMUM_LENGTH }
        )
      },
      maxLength: {
        value: PHONE_MAXIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'register.form.phone.maxlength' },
          { PHONE_MAXIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'register.form.phone.required' })
    });

    register({ name: 'password' }, {
      pattern: {
        value: PASSWORD_PATTERN,
        message: intl.formatMessage({ id: 'register.form.password.pattern' })
      },
      minLength: {
        value: PASSWORD_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'register.form.password.minlength' },
          { PASSWORD_MINIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'register.form.password.required' })
    });

    register({ name: 'matchingPassword' }, {
      validate: value => value === watch('password') || intl.formatMessage({ id: 'register.form.repeat_password.validate' }),
      required: intl.formatMessage({ id: 'register.form.repeat_password.required' })
    });
  });

  useEffect(() => {
    onValidation(!isButtonDisabled);
  }, [isButtonDisabled, onValidation]);

  return (
    <form
      ref={form}
      className={`${formClassName ? formClassName : 'register-wizard'}__step${classNames ? ` ${classNames}` : ''}`}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={`${formClassName ? formClassName : 'register-wizard'}__content`}>
        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-group`}>
          <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
            <Controller
              control={control}
              name="firstName"
              render={props => (
                <Input
                  block
                  disabled={!!user?.defaultAddress?.streetAddress}
                  inputId="first-name"
                  label={intl.formatMessage({ id: 'register.form.first_name.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'register.form.first_name.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
          </div>
          <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
            <Controller
              control={control}
              name="lastName"
              render={props => (
                <Input
                  block
                  disabled={!!user?.defaultAddress?.streetAddress}
                  inputId="last-name"
                  label={intl.formatMessage({ id: 'register.form.last_name.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'register.form.last_name.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
          </div>
        </div>
        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
          <Controller
            control={control}
            name="email"
            render={props => (
              <Input
                block
                disabled={!!user?.defaultAddress?.streetAddress}
                inputId="email"
                label={intl.formatMessage({ id: 'register.form.email.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'register.form.email.placeholder' })}
                status={getInputStatus(props.name)}
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
          <Controller
            control={control}
            name="company"
            render={props => (
              <Input
                block
                disabled={!!user?.defaultAddress?.streetAddress}
                inputId="company"
                label={intl.formatMessage({ id: 'register.form.company.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'register.form.company.placeholder' })}
                status={getInputStatus(props.name)}
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper ${formClassName ? formClassName : 'register-wizard'}__tooltip-wrapper`}>
          <Controller
            control={control}
            name="country"
            render={props => (
              <Select
                inputId="country"
                instructions={intl.formatMessage({ id: 'register.form.country.instructions' })}
                label={intl.formatMessage({ id: 'register.form.country.label' })}
                message={getInputMessage(props.name)}
                options={countryOptions}
                status={getInputStatus(props.name)}
                value={props.value}
                onBlur={props.onBlur}
                onChange={option => handleInputChange(option, props)}
              />
            )}
          />
          <div
            className={`${formClassName ? formClassName : 'register-wizard'}__country-tooltip`}
            onMouseEnter={handleTotalInfoMouseEnter}
          >
            <img
              alt="Total Info"
              src={infoGrayIcon}
            />
            <Tooltip
              horizontalPosition="center"
              show={showTotalInfoTooltip}
              verticalPosition="top"
              onClose={handleTotalInfoTooltipClose}
            >
              <div className={`${formClassName ? formClassName : 'register-wizard'}__country-tooltip-text`}>
                <FormattedMessage id="register.form.country.alert" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
          <Controller
            control={control}
            name="phone"
            render={props => (
              <Input
                block
                disabled={!!user?.defaultAddress?.streetAddress}
                disabledDropdown={!!user?.defaultAddress?.streetAddress}
                inputId="phone"
                label={intl.formatMessage({ id: 'register.form.phone.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'register.form.phone.placeholder' })}
                status={getInputStatus(props.name)}
                type="phone"
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
                onCountryCallingCodeChange={countryCallingCode => setPhoneCountryCallingCode(countryCallingCode)}
              />
            )}
          />
        </div>
        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-group`}>
          <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
            <Controller
              control={control}
              name="password"
              render={props => (
                <Input
                  block
                  disabled={!!user?.defaultAddress?.streetAddress}
                  inputId="password"
                  label={intl.formatMessage({ id: 'register.form.password.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'register.form.password.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="password"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props, 'password')}
                />
              )}
            />
          </div>
          <div className={`${formClassName ? formClassName : 'register-wizard'}__input-wrapper`}>
            <Controller
              control={control}
              name="matchingPassword"
              render={props => (
                <Input
                  block
                  disabled={!!user?.defaultAddress?.streetAddress}
                  inputId="password-confirmation"
                  label={intl.formatMessage({ id: 'register.form.repeat_password.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'register.form.repeat_password.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="password"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
          </div>
        </div>
        <Checkbox
          alignTop
          checked={termsAgreed}
          disabled={!!user?.defaultAddress?.streetAddress}
          label={
            <span className="register-wizard__agreement-text">
              <FormattedMessage id="register.form.terms_agree" />
              <a
                className="register-wizard__link"
                href={`${MARKETING_WEBSITE_URL}/terms-and-conditions`}
                rel="noopener noreferrer"
                target="_blank"
                onClick={event => event.stopPropagation()}
              >
                <FormattedMessage id="register.form.terms_link" />
              </a>
              {' '}
              <FormattedMessage id="register.form.terms_text" />
              {' '}
              <a
                className="register-wizard__link"
                href={`${MARKETING_WEBSITE_URL}/privacy`}
                rel="noopener noreferrer"
                target="_blank"
                onClick={event => event.stopPropagation()}
              >
                <FormattedMessage id="register.form.privacy_link" />
              </a>
            </span>
          }
          noBorder
          smallMargin
          onChange={value => setTermsAgreed(value)}
        />

        <div className={`${formClassName ? formClassName : 'register-wizard'}__input-group`}>
          <div className={`${formClassName ? formClassName : 'register-wizard'}__recaptcha-wrapper`}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lds1mEaAAAAAFpoAcJzajALq0gRq2Sf2npMEqEP"
              size="normal"
              onChange={setRecaptchaToken}
            />
          </div>
        </div>

        {failed && (
          <div className={`${formClassName ? formClassName : 'register-wizard'}__error`}>
            {errorMessage}
          </div>
        )}
      </div>
    </form>
  );
});

PersonalInformationStep.propTypes = {
  componentProps: PropTypes.shape({
    classNames: PropTypes.string,
    formClassName: PropTypes.string,
    onError: PropTypes.func,
    onSubmitting: PropTypes.func,
    onSuccess: PropTypes.func,
    onValidation: PropTypes.func
  })
};

PersonalInformationStep.defaultProps = {
  componentProps: null
};

export default PersonalInformationStep;
