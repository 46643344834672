import paymentTypeCard from 'images/icons/payment/pt_credit_card.svg';
import paymentTypeMb from 'images/icons/payment/pt_mb.svg';
import paymentTypePaypal from 'images/icons/payment/pt_paypal.svg';
import paymentTypeBankTransfer from 'images/icons/payment/pt_tr.svg';
import paymentTypePayLater from 'images/icons/payment/pt_pay_later.svg';
import paymentTypeReceiveProposal from 'images/icons/payment/pt_receive_proposal.svg';
import paymentTypeDemo from 'images/icons/payment/pt_demo.svg';

const getPaymentIcon = (icon) => {
  switch (icon) {
    case 'credit_card':
      return paymentTypeCard;
    case 'mb':
      return paymentTypeMb;
    case 'paypal':
      return paymentTypePaypal;
    case 'bank_transfer':
      return paymentTypeBankTransfer;
    case 'pay_later':
      return paymentTypePayLater;
    case 'receive_proposal':
      return paymentTypeReceiveProposal;
    case 'demo':
      return paymentTypeDemo;
    default:
      return paymentTypeDemo;
  }
};

export default getPaymentIcon;
