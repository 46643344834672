import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import RegisterWizard from 'components/register/wizard';
import ImagePanel from '@youship/components/objects/image-panel';

import { selectIsAuthenticated, selectRequirePhoneActivation, selectUser } from 'store/slices/authentication';


import './styles.scss';

const RegisterArea = () => {

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const requirePhoneActivation = useSelector(selectRequirePhoneActivation);
  const user = useSelector(selectUser);
  const userDefaultAddress = user?.defaultAddress?.streetAddress;

  const [currentStep, setCurrentStep] = useState(null);

  const getRegisterStep = (step) => {
    setCurrentStep(step);
  };

  
  //já tem morada por defeito
  if (isAuthenticated && userDefaultAddress) return <Redirect to="/" />;


  return (
    <div className="register-area">
      {currentStep !== 3 && (
        <ImagePanel
          hiddenSm
          registerStep={currentStep}
        />
      )}
      <RegisterWizard
        onStepChange={getRegisterStep}
      />
    </div>
  );
};

export default RegisterArea;
